import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {RiCloseFill} from 'react-icons/ri'
import style from '../../../_metronic/assets/sass/call.module.css'
import {OrdersType} from '../../models/order_models'
import clsx from 'clsx'
import {FaCopy, FaUserAlt} from 'react-icons/fa'
import {MdEmail, MdLocationOn} from 'react-icons/md'
import {PiPhoneCallFill} from 'react-icons/pi'
import {image_path} from '../../../utils'
import AddCallCompletion from '../calls/AddCallCompletion'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {Tooltip as ReactTooltip} from 'react-tooltip'

const OrderDetails = ({order, status, leaves, showOrder, handleOrderClose}: any) => {
  const [show, setShow] = useState(false)
  const [calls, setCalls] = useState<any[]>([])
  const [isCopy, setIsCopy] = useState(false)
  const handleClose = () => setShow(false)
  const user: any = localStorage.getItem('user_data')
  const user_data = JSON.parse(user)
  const userRole = user_data.role
  useEffect(() => {
    // if (status === 'followup' && order.id === customer.order_id) {
    // console.log('order ->', order)
    if (status === 'followup') {
      getLastFollowup(order.id)
    }
    setIsCopy(false)
  }, [])

  const copyTrackingId = () => {
    const barcodeText = order.tracking_id

    navigator.clipboard.writeText(barcodeText)
    setIsCopy(true)
    // .then(() => {
    // alert('Barcode copied to clipboard')
    // })
    // .catch((err) => {
    //   console.error('Failed to copy barcode: ', err)
    // })
  }

  const getLastFollowup = async (order_id: string) => {
    const data: any = await supabaseData.getLastFollowup(order_id)
    if (data) setCalls(data)
  }

  return (
    <>
      {/* <div className='d-flex align-items-center' onClick={handleShow}>
        <Link to='' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
          <KTIcon iconName='eye' className='fs-1' />
        </Link>
      </div> */}
      <Modal show={showOrder} className={`modal-lg `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>Order Details</Modal.Title>
          <button
            className='border border-none fs-3 text-hover-primary rounded-circle'
            onClick={handleOrderClose}
          >
            <RiCloseFill />
          </button>
        </Modal.Header>

        <Modal.Body style={{maxHeight: '750px', overflowY: 'scroll'}}>
          <div>
            <div className='row border-bottom'>
              <div className='col-7'>
                <div className='d-flex '>
                  <p className='fs-4 fw-bold m-0'>
                    <span>Order Id: </span>
                    <span className='text-gray-700'>{order.order_code}</span>
                    <span
                      className={clsx(
                        'badge-sm fw-bold px-2 ms-3 fs-6 ',
                        {'badge-light-success': order.payment_status === 'Paid'},
                        {'badge-light-primary': order.payment_status === 'Pending'},
                        {
                          'badge-light-danger':
                            order.payment_status === 'RTO Done' ||
                            order.payment_status === 'Refund' ||
                            order.payment_status === 'Cancel' ||
                            order.payment_status === 'Return',
                        }
                      )}
                    >
                      {order.payment_status}
                    </span>
                  </p>
                </div>
                <p className='fw-bold fs-8 text-gray-600'>
                  <span>Order Date:</span>
                  {new Date(order.order_date).toLocaleDateString('en-GB')}
                </p>
              </div>
              <div className='col-5 mb-3'>
                <div className='row'>
                  <p className='col-6 mb-0 fw-bold text-gray-700'>Courier Company:</p>
                  <p className='col-6 mb-0 fw-semibold'>
                    {order.order_courier_provider && order.order_courier_provider.name}
                  </p>
                </div>
                <div className='d-flex row align-items-center'>
                  <p className='col-6 mb-0 fw-bold text-gray-700'>Barcode:</p>
                  <p className=' col-6 d-flex mb-0'>
                    {order.tracking_id && (
                      <>
                        <a
                          href={`${order.order_courier_provider.tracking_url}`}
                          target='_blank'
                          className='mt-2 fw-semibold text-dark text-hover-primary'
                        >
                          {order.tracking_id}
                        </a>
                        <button
                          className='btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm ms-3'
                          onClick={copyTrackingId}
                          disabled={isCopy}
                        >
                          <FaCopy className='text-gray-600 fs-3 text-hover-primary' />
                        </button>
                      </>
                    )}
                  </p>
                </div>
                {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && (
                  <div className='row '>
                    <p className='col-6 mb-0 fw-bold text-gray-700'>Sales Person:</p>
                    <p className='col-6 mb-0 fw-semibold'>{order.sales_man.name}</p>
                  </div>
                )}
              </div>
            </div>
            <div className='border-bottom pb-5'>
              {order.order_items &&
                order.order_items.map((item: any, i: number) => {
                  return (
                    <div key={i}>
                      <div className='d-flex row mt-8'>
                        <div className='col-1'>
                          {/* <img
                          src={`${image_path}${item.image}`}
                          style={{width: '60px', height: '60px', borderRadius: '20%'}}
                        /> */}
                        </div>
                        <div className='ms-4 col-5'>
                          <p className='fs-6 fw-bold mb-1'>{item.label}</p>
                          <p className='fs-8 fw-semibold text-gray-600'>{item.code}</p>
                        </div>
                        <div className='col-2'>
                          <p className='fs-6 fw-bold text-gray-700 ms-5'>₹{item.price}</p>
                        </div>
                        <div className='col-2'>
                          <p className='fs-6 fw-bold text-gray-700 ms-5'>{item.units} Units</p>
                        </div>
                        <div className='col-1'>
                          <p className='fs-6 fw-bold text-gray-700 ms-5'> ₹{item.sub_total} </p>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
            <div className='row mt-5 gap-10'>
              <div className={`col-6 border-bottom px-10 py-5 ms-5 ${style.order_detail_h4}`}>
                <h4 className=' mb-5'>Customer</h4>
                <p className='mb-1'>
                  <FaUserAlt className='text-gray-500 text-hover-primary' />
                  <span className='fs-8 fw-bold ms-2 text-gray-700'>{order.customers.name}</span>
                </p>
                <p className='mb-1 d-flex'>
                  {/* <p>
                    <MdEmail className='text-gray-500 text-hover-primary' />
                    <span className='fs-8 fw-bold ms-2 text-gray-700'>{order.customer.email}</span>
                  </p> */}
                  <p className='ms-8'>
                    <PiPhoneCallFill className='text-gray-500 text-hover-primary' />
                    <span className='fs-8 fw-bold ms-2 text-gray-700'>
                      {order.customers.mobile}
                    </span>
                  </p>
                </p>
                <div>
                  <h6>
                    <MdLocationOn /> <span>Address :</span>
                  </h6>
                  <div className='fs-7 fw-semibold d-flex flex-wrap'>
                    <p className='mb-1 pe-1'>
                      <span>{order.customer_address.address}</span> -{' '}
                      <span>{order.customer_address.pincode}, </span>
                    </p>
                    <p className='mb-1'>
                      <span className='pe-1'> Post - {order.customer_address.at_post},</span>
                    </p>
                    <p className='mb-1 pe-1'>
                      {order.customer_address.city !== null && (
                        <span>city - {order.customer_address.city} </span>
                      )}
                      {order.customer_address.village !== null && (
                        <span> V - {order.customer_address.village} </span>
                      )}
                    </p>
                    , <span className='pe-1'>Ta - {order.customer_address.taluka}, </span>
                    <span className='pe-1'>Dist - {order.customer_address.district}, </span>
                    <span className='pe-1'>State - {order.customer_address.state} </span>
                  </div>
                </div>
              </div>
              <div className={`col-5 border-bottom px-10 py-5 ${style.order_detail_h4}`}>
                <h4 className=' mb-5'>Summary</h4>
                <p
                  className='d-flex justify-content-space-between text-end fs-5 fw-bold mb-2'
                  style={{justifyContent: 'space-between'}}
                >
                  <span>Shipping Charges </span> <span>₹{order.shipping_charges}</span>
                </p>
                <p
                  className='d-flex justify-content-space-between text-end fs-5 fw-bold mb-2 pb-6 border-bottom'
                  style={{justifyContent: 'space-between'}}
                >
                  <span>Paid Amount </span>
                  <span>₹{order.paid_amount === null ? 0 : order.paid_amount}</span>
                </p>
                <p
                  className='d-flex justify-content-space-between text-end fs-5 fw-bold mb-1'
                  style={{justifyContent: 'space-between'}}
                >
                  <span>Total </span> <span>₹{order.total_amount}</span>
                </p>
                <p
                  className={`badge-sm fw-bold px-2 ms-3 badge-light-success ${style.type_payment_mode}`}
                >
                  Payment:
                  {order.payment_mode === 'Prepaid' && order.order_payment_method
                    ? order.order_payment_method.type
                    : order.payment_mode}
                </p>
              </div>
            </div>
          </div>
          {order.return_date !== null && (
            <div className='border-top mt-5 border-top-5 ' style={{position: 'relative'}}>
              <div
                className='d-flex justify-content-between pb-5 '
                style={{borderBottom: '1px dashed #dbdbd9'}}
              >
                <h4 className={`mt-5 ${style.return_heading}`}>Return Items</h4>
                <div className='col-5 mt-2'>
                  <div className='row'>
                    <p className='col-6 mb-0 fw-bold text-gray-700'>Courier Company:</p>
                    <p className='col-6 mb-0 fw-semibold'>{order.return_courier_provider?.name}</p>
                  </div>
                  <div className='row'>
                    <p className='col-6 mb-0 fw-bold text-gray-700'>Barcode:</p>
                    <p className='col-6 mb-0 fw-semibold'>{order?.return_tracking_id}</p>
                  </div>
                </div>
              </div>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-100'></th>
                    <th className='min-w-250'></th>
                    <th className='min-w-50'>Price</th>
                    <th className='min-w-50'>Return Items</th>
                    <th className='min-w-100'>Damage Items</th>
                    <th className='min-w-50'></th>
                  </tr>
                </thead>
                <tbody>
                  {order.order_items.map((item: any, i: number) => {
                    return (
                      <tr key={i}>
                        {/* <div className='d-flex row mt-8'> */}
                        <td>
                          <div className='col-1'>
                            <img
                              src={`${image_path}${item.image}`}
                              style={{width: '60px', height: '60px', borderRadius: '20%'}}
                            />
                          </div>
                        </td>
                        <td>
                          <p className='fs-6 fw-bold mb-1'>{item.label}</p>
                          {/* <p className='fs-8 fw-semibold text-gray-600'>
                            {item.products.description}
                          </p> */}
                        </td>
                        <td>
                          <div className='col-1'>
                            <p className='fs-6 fw-bold text-gray-700 ms-5'>₹{item.price}</p>
                          </div>
                        </td>
                        <td>
                          <div className='col-2'>
                            <p className='fs-6 fw-bold text-gray-700 ms-5'>{item.return_unit}</p>
                          </div>
                        </td>
                        <td>
                          <div className='col-2'>
                            <p className='fs-6 fw-bold text-gray-700 ms-5'>{item.damage_unit}</p>
                          </div>
                        </td>
                        <td>
                          <div className='col-1'>
                            <p className='fs-6 fw-bold text-gray-700 ms-5'>₹{item.refund_amount}</p>
                          </div>
                        </td>
                        {/* </div> */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {!order.is_rto && (
                <div className='row mt-5 gap-10 border-top pt-5'>
                  <div className={`col-7 px-10 ms-5`}>
                    <p
                      className={`badge-sm fw-bold px-2 ms-3 badge-light-info ${style.type_payment_mode}`}
                    >
                      Payment: {order.return_payment && order.return_payment.type}
                    </p>
                  </div>
                  {/* <div className={`col-4 border-bottom px-10 py-5 ${style.order_detail_h4}`}> */}
                  {/* <h4 className=' mb-5'>Summary</h4> */}
                  <p
                    className='col-4 text-end fs-5 fw-bold'
                    style={{justifyContent: 'space-between'}}
                  >
                    <span className='text-gray-600 me-3'>Refund Total: </span>
                    <span className='badge badge-light-danger fs-4'>₹{order.refund_amount}</span>
                  </p>
                </div>
              )}
            </div>
          )}

          {status === 'followup' && (
            <>
              {!order.cancel_at && (
                <div className='d-flex align-items-center justify-content-end'>
                  <button
                    className='btn btn-sm btn-light-primary fw-bold mt-6'
                    onClick={() => setShow(true)}
                  >
                    Follow Up Call
                  </button>
                </div>
              )}
              <h6 className={order.cancel_at ? 'mt-10' : 'text-gray-600'}>
                Last Two Calls History
              </h6>
              <div className={`table-responsive ${style.order_card_wrapper}`}>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <tbody>
                    {calls.map((call: any, i: number) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start text-gray-600 flex-column fw-bolder'>
                                {new Date(call.updated_at).toLocaleDateString('en-GB')}
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className='d-flex align-items-center'>
                              <div
                                className={clsx(
                                  'd-flex justify-content-start flex-column fw-bold',
                                  {'text-success': call.call_status === 'Accepted'},
                                  {'text-danger': call.call_status === 'Rejected'},
                                  {'text-warning': call.call_status === 'Not Response'}
                                )}
                              >
                                {call.call_status}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start text-gray-700 flex-column fw-bold'>
                                {call.caller.name}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column fw-bold text-gray-700'>
                                {call.note}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {/* <div >
            <button>LOG</button>
          </div> */}

          {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && (
            <>
              <div className='text-end px-2 pt-5'>
                <button
                  className='btn btn-sm btn-light-primary fw-bolder'
                  data-tooltip-id='my-tooltip-2'
                >
                  ORDER LOG
                </button>
              </div>
              <ReactTooltip
                id='my-tooltip-2'
                place='bottom'
                variant='info'
                // effect='float'
                // content="I'm a info tooltip"
              >
                <p>
                  Created By - {order.created_by.name}
                  {' - '}
                  {new Date(order.created_at).toLocaleDateString('en-GB', {
                    month: 'numeric',
                    day: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false,
                  })}
                </p>
                {order.edited_by && (
                  <p>
                    Update By - {order.edited_by.name} {' - '}
                    {new Date(order.edited_at).toLocaleDateString('en-GB', {
                      month: 'numeric',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    })}
                  </p>
                )}
                {order.cancel_by && (
                  <p>
                    Cancel - {order.cancel_by.name} {' - '}
                    {new Date(order.cancel_at).toLocaleDateString('en-GB', {
                      month: 'numeric',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    })}
                  </p>
                )}
                {order.return_order_manager && (
                  <p>
                    Return - {order.return_order_manager.name} {' - '}
                    {new Date(order.return_date).toLocaleDateString('en-GB', {
                      month: 'numeric',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    })}
                  </p>
                )}
              </ReactTooltip>
            </>
          )}
        </Modal.Body>
      </Modal>
      {show && (
        <AddCallCompletion order={order} leaves={leaves} show={show} handleClose={handleClose} />
      )}
    </>
  )
}

export default OrderDetails
