import React, {useEffect, useState} from 'react'
import style from '../../../_metronic/assets/sass/barcodepdf.module.css'
import Barcode from 'react-barcode'
// @ts-ignore
import numberToWords from 'number-to-words'

const GenerateBarcodePdf = ({order, companyDetails}: any) => {
  console.log('order ->', order)

  return (
    <div className={style.printable_content}>
      {order.map((order: any, index: number) => {
        const numberInWords: any = numberToWords.toWords(order.total_amount)
        const amountInWords: any = numberInWords.replace(/,/g, '')
        const setSize = order.customer_address.address.length
        const nameSize = order.customers.name.length
        const groupedItems: any = {}
        order.order_items?.forEach((item: any) => {
          console.log('items ->', item)
          if (groupedItems[item.code]) {
            groupedItems[item.code].units += item.units
          } else {
            groupedItems[item.code] = {...item}
          }
        })

        const displayItems = Object.values(groupedItems)
        return (
          <div
            key={index}
            style={{
              margin: '15px 5px 0px 17px',
              // marginTop: '5px',
              position: 'relative',
              height: '1100px',
              pageBreakAfter: 'always',
            }}
          >
            {order?.payment_mode === 'Cash On Delivery' ? (
              <>
                {order.order_courier_provider?.name === 'India Post' ? (
                  <p style={{textAlign: 'center', fontSize: '20px'}}>
                    Article Type : SPCDO (Speed Post -
                    <span className='fw-bold ' style={{fontSize: '25px'}}>
                      Cash On deliver
                    </span>
                    )
                  </p>
                ) : (
                  <p className='fw-bold text-center mb-0 ' style={{fontSize: '25px'}}>
                    Cash On deliver
                  </p>
                )}
                <h5 className='text-center' style={{fontSize: '32px'}}>
                  COD AMOUNT : Rs. {order.total_amount} ({amountInWords} Only)
                </h5>
              </>
            ) : (
              <h5 className='text-center pt-1' style={{fontSize: '32px'}}>
                PREPAID: Do not collect any amount.
              </h5>
            )}
            <div
              className={`text-center ${
                order.order_courier_provider?.name === 'India Post' ? '' : 'mb-20'
              } `}
            >
              {order.tracking_id !== null ? (
                <>
                  <div className='position-relative pb-6 fw-bolder fw-bolder'>
                    <Barcode value={order.tracking_id} fontSize={30} height={120} width={3} />
                    {/* <p
                      className='position-absolute bg-light fw-bold'
                      style={{fontSize: '30px', bottom: '-13px', left: '37%'}}
                    >
                      {order.tracking_id}
                    </p> */}
                  </div>
                </>
              ) : (
                <div style={{height: '170px'}}> </div>
              )}
            </div>
            {order.payment_mode === 'Cash On Delivery' &&
            order.order_courier_provider.name === 'India Post' ? (
              <>
                <div className='mt-0'>
                  <p className='mb-2 fs-1'>
                    {order.order_courier_provider.name === 'India Post' ? (
                      <>
                        <p className='text-start'>
                          * Please contact to BDO Surat Via e-mail ID <br />{' '}
                          nsu.surat@indiaPost@ov.in or telephone No: 0261 - 24261881 to update
                          booking data of COD article, if COD / Booking Date n. found / Available or
                          booking data is not present BDO surat will update data and give reply in
                          e-mail or phone instantly
                          <span className='fw-bold ps-20' style={{fontSize: '27px'}}>
                            {new Date(order.order_date).toLocaleDateString('en-GB')}
                          </span>
                          <span className='fw-bold ps-20' style={{fontSize: '27px'}}>
                            {order.order_code}
                          </span>
                        </p>
                      </>
                    ) : (
                      <p className='fw-bold text-center' style={{fontSize: '27px'}}>
                        {new Date(order.order_date).toLocaleDateString('en-GB')}
                      </p>
                    )}
                  </p>
                  <p className='text-center fw-bold mb-0 fs-1'>
                    Booking Office name & Pincode : BDO Surat(395003)
                  </p>
                </div>
                {/* )} */}

                <div
                  className='row row-cols-2 text-center fw-bold mx-1'
                  style={{fontSize: `${setSize <= 80 ? '27px' : '24px'}`}}
                >
                  <p className='mb-1 ' style={{border: '1px solid black'}}>
                    {order.order_courier_provider.name === 'India Post' && (
                      <span> eBiller ID : {order.order_courier_provider.e_biller_id}</span>
                    )}
                  </p>
                  <p className='mb-1 ' style={{border: '1px solid black'}}>
                    WEIGHT : {order.courier_weight}
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className='d-flex justify-content-around fw-bold' style={{fontSize: '27px'}}>
                  <span>{order.order_courier_provider?.name}</span>
                  <span>{new Date(order.order_date).toLocaleDateString('en-GB')}</span>
                  <span>{order.order_code} </span>
                </div>
              </>
            )}
            <div>
              <div className='row '>
                <p className='mb-0 fw-bolder col-6 text-start' style={{fontSize: '27px'}}>
                  To,
                </p>
                <p className='mb-0 fw-bolder col-6'></p>
              </div>

              <p
                className='mb-0 fw-bolder text-start'
                style={{fontSize: `${nameSize <= 30 ? '27px' : '24px'}`}}
              >
                {order.customers?.name} - {order.customers?.mobile}
              </p>
              <div
                className='text-start'
                style={{
                  fontSize: `${
                    order.order_courier_provider?.name === 'India Post' ? '24px' : '27px'
                  }`,
                }}
              >
                <p className='mb-0 fw-bolder text-start'>
                  ADDRESS- {order.customer_address?.address}
                </p>
                <div
                  className='d-flex'

                  // style={{fontSize: '24px'}}
                >
                  <div className='col-6'>
                    {order.customer_address?.city && (
                      <p
                        className='mb-0 fw-bolder'
                        // style={{
                        //   fontSize: `${order.customer_address.city.length >= 12 ? '24px' : '20px'}`,
                        // }}
                      >
                        <span>CITY</span> - <span>{order.customer_address?.city}</span>
                      </p>
                    )}
                    {order.customer_address?.village && (
                      <p
                        className='mb-0 fw-bolder '
                        // style={{
                        //   fontSize: `${order.customer_address.village.length >= 12 ? '24px' : '20px'}`,
                        // }}
                      >
                        <span>Village</span> - <span>{order.customer_address?.village}</span>
                      </p>
                    )}

                    <p className='mb-0 fw-bolder'>
                      <span>TA</span> - <span>{order.customer_address?.taluka}</span>
                    </p>
                    <p className=' mb-0 fw-bolder'>
                      <span>DIST</span> - <span>{order.customer_address?.district}</span>
                    </p>
                    {/* {!order.address?.at_post && (
                  <p className='mb-0 fw-bolder '>
                    <span>CITY</span> - <span>{order.customer_address?.city}</span>
                  </p>
                )} */}
                  </div>
                  <div className='col-6'>
                    {order.customer_address?.at_post && (
                      <p className='mb-0  fw-bolder '>
                        <span>AT&POST</span> - <span>{order.customer_address?.at_post}</span>
                      </p>
                    )}
                    <p className=' mb-0 fw-bolder'>
                      <span>STATE</span> - <span>{order.customer_address?.state}</span>
                    </p>
                    <p className=' mb-0 fw-bolder'>
                      <span>PINCODE</span> - <span> {order.customer_address?.pincode}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='row row-cols-2 position-absolute mb-3' style={{bottom: '0px'}}>
                <div className='col-6'>
                  <div className='mb-1 fw-bolder d-flex justify-content-between'>
                    <span className='fs-1'>Mobile No.</span>
                    <div>
                      <p className='fw-bolder mb-0 pb-0' style={{fontSize: '25px'}}>
                        {order.customers?.mobile}
                      </p>
                      {order.customers?.mobile_2 && (
                        <p className='fw-bolder mb-0 pb-0' style={{fontSize: '25px'}}>
                          {order.customers?.mobile_2}
                        </p>
                      )}
                      {order.customers?.mobile_3 && (
                        <p className='fw-bolder mb-0 pb-0' style={{fontSize: '25px'}}>
                          {order.customers?.mobile_3}
                        </p>
                      )}
                    </div>
                  </div>
                  <p className='mb-0 fw-bolder d-flex flex-wrap'>
                    {displayItems.map((item: any) => {
                      return (
                        <p className='mb-0 fs-1 pe-2'>
                          {item.units}
                          {item.code},
                        </p>
                      )
                    })}
                  </p>
                </div>
                <div className='col-6 ps-20 text-start align-self-end'>
                  <p className='mb-0 '>
                    <span className='fw-bolder' style={{fontSize: '23px'}}>
                      From
                    </span>
                    <span className='fw-semibold pe-3 fs-1 ps-5 pe-20'>
                      {order.sales_man?.name}
                    </span>
                  </p>
                  <p className='mb-0 fw-bolder fs-2'>{companyDetails.name.toUpperCase()}</p>
                  <p className='mb-0 fw-bolder fs-2'>{companyDetails.address.toUpperCase()}</p>
                  <p className='mb-0 fw-bolder fs-2'>MO.NO {companyDetails.mobile}</p>
                </div>
              </div>
            </div>
            <br />
          </div>
        )
      })}
    </div>
  )
}

export default GenerateBarcodePdf
