import React, {useEffect, useState} from 'react'
// import {allProducts} from '../AppDataDefine'
import StockIn from './StockIn'
import EditProduct from './EditProduct'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {ToastContainer} from 'react-toastify'
import {ProductsType} from '../../models/product_models'
import clsx from 'clsx'
import {KTIcon} from '../../../_metronic/helpers'
import ProductDetails from './ProductDetails'
import {image_path} from '../../../utils'
import AddProducts from './AddProducts'
import loader from '../../../_metronic/assets/sass/loader.module.css'

const ProductsWrapper = () => {
  const [products, setProducts] = useState<ProductsType>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [currentStoke, setCurrentStoke] = useState<any>()
  const [sliceEnd, setSliceEnd] = useState<number>(12)
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [editProduct, setEditProduct] = useState<any>()
  const [showEdit, setShowEdit] = useState<boolean>(false)
  const [showDetails, setShowDetails] = useState(false)

  useEffect(() => {
    allProducts()
  }, [])

  const user: any = localStorage.getItem('user_data')

  const user_data = JSON.parse(user)
  const userRole = user_data.role

  const allProducts = async () => {
    const data: ProductsType | null = await supabaseData.getAllProducts()
    if (data) setProducts(data)
    setIsLoading(false)
  }

  //infinite scroll
  // const onScroll = () => {
  //   if (hasMore) {
  //     const scrollTop = document.documentElement.scrollTop
  //     const scrollHeight = document.documentElement.scrollHeight
  //     const clientHeight = document.documentElement.clientHeight

  //     if (scrollTop + clientHeight >= scrollHeight) {
  //       console.log('IN IF CONDITION')
  //       setTimeout(() => {
  //         fetchData()
  //       }, 1000)
  //     }
  //   }
  // }

  // const fetchData = () => {
  //   setSliceEnd((prev) => prev + 10)

  //   console.log('PRODUCT LENGTH', products.length)
  //   if (sliceEnd >= products.length) {
  //     setHasMore(false)
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', onScroll)
  //   return () => window.removeEventListener('scroll', onScroll)
  // }, [products, hasMore, sliceEnd])

  const setLoweStoke = (product: any) => {
    let lowestStockProduct = product.products[0].current_stock
    for (const prod of product.products) {
      if (prod.current_stock < lowestStockProduct) {
        lowestStockProduct = prod.current_stock
      }
    }

    product.current_stock = lowestStockProduct
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const filteredReturnProducts = products.filter((product: any) => {
    return (
      product.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      product?.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.unit.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.current_stock.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  })

  const handleUpdate = (updateProduct: any) => {
    console.log('UPDATE PRODUCT', updateProduct)

    const updatedProduct = products.map((product: any) => {
      if (product.id === updateProduct.id) {
        return updateProduct
      }
      return product
    })
    setProducts(updatedProduct)
  }

  const handleEditProduct = (product: any) => {
    setEditProduct(product)
    setShowEdit(true)
  }

  const handleShowDetails = (product: any) => {
    setEditProduct(product)
    setShowDetails(true)
  }

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className='card card-xxl-stretch mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Products List</span>
          </h3>
          <div className='d-flex align-items-center'>
            <AddProducts products={products} setProduct={setProducts} userRole={userRole} />

            <div className='d-flex m-5' style={{justifyContent: 'space-between'}}>
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid w-250px ps-14'
                  placeholder='Search...'
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className={loader.custom_loader_container}>
            {' '}
            <p className={loader.custom_loader}></p>
          </div>
        ) : (
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-30px'>Image</th>
                    <th className='min-w-100px'>Name</th>
                    <th className='min-w-120px'>Description</th>
                    <th className='min-w-100px'>Unit</th>

                    <th className='min-w-100px'>Available Stock</th>
                    <th className='min-w-100px'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {filteredReturnProducts.slice(0, sliceEnd)?.map((product: any) => { */}
                  {filteredReturnProducts.map((product: any) => {
                    return (
                      <tr key={product.value}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold'>
                              <img
                                src={`${image_path}${product.image}`}
                                alt=''
                                style={{width: '30px', height: '30px'}}
                              />
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold text-hover-success'>
                              {product.label}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold text-gray-600'>
                              {product.description}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold text-gray-600'>
                              {product.unit} {product.unit_type}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            {product.is_combo && setLoweStoke(product)}
                            <div
                              className={clsx(
                                'd-flex justify-content-start flex-column fw-bold',
                                {
                                  'text-success': product.current_stock >= 50,
                                },
                                {
                                  'text-danger': product.current_stock <= 49,
                                }
                              )}
                            >
                              {product.current_stock}
                              {product.current_stock <= 49 && (
                                <span className='text-gray-500 fs-9'>Manage stock </span>
                              )}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex'>
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 fs-6'
                              onClick={() => handleShowDetails(product)}
                              // disabled={product.is_combo}
                            >
                              <KTIcon iconName='eye' className='fs-1' />
                            </button>
                            {/* <ProductDetails product={product} /> */}
                            {!product.is_combo && (
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 fs-6'
                                // disabled={product.is_combo}
                              >
                                <StockIn product_id={product.value} />
                              </button>
                            )}
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={() => handleEditProduct(product)}
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                              {/* <EditProduct
                                product={product}
                                products={products}
                                onUpdateProduct={handleUpdate}
                              /> */}
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                  {/* {hasMore && (
                  <td colSpan={6}>
                    <div className='col-12 text-center' onClick={fetchData}>
                      <span className='fw-bold fs-2 text-gray-700'>Loading...</span>
                    </div>
                  </td>
                )} */}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {showEdit && (
        <div>
          <EditProduct
            product={editProduct}
            products={products}
            onUpdateProduct={handleUpdate}
            setShowEdit={setShowEdit}
            showEdit={showEdit}
            userRole={userRole}
          />
        </div>
      )}
      {showDetails && (
        <ProductDetails
          product={editProduct}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
        />
      )}
    </>
  )
}

export default ProductsWrapper
