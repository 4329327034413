import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import 'react-toastify/dist/ReactToastify.css'
import AppDataContext from './pages/AppDataContext'

const App = () => {
  const value = 5
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          {/* <AppDataContext.Provider value={value}> */}
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
          {/* </AppDataContext.Provider> */}
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
