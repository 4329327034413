import React, {useEffect, useState} from 'react'
// import {allOrders} from '../AppDataDefine'
import AddPayment from '../payment/AddPayment'
import {Payments} from '../../models/payment_model'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {AccountType} from '../../models/setting_models'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import {MdKeyboardArrowLeft, MdOutlineNavigateNext} from 'react-icons/md'
import {KTIcon} from '../../../_metronic/helpers'

const ReturnPayments = () => {
  const [title, setTitle] = useState('Return Payment Details')
  const [returnPayment, setReturnPayment] = useState<any>([])
  const [totalReturnPayment, setTotalReturnPayment] = useState<number>(0)
  const [pendingReturnOrders, setPendingReturnOrders] = useState([])
  const [accounts, setAccounts] = useState<AccountType[] | undefined>()
  const [isLoading, setIsLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemsPerPage: number = 50
  const [user, setUser] = useState<any>(null)

  useEffect(() => {
    getAllReturnPayments(searchQuery)
    getAllAccounts()
    const user: any = localStorage.getItem('user_data')
    const user_data: any = JSON.parse(user)
    setUser(user_data)
  }, [])

  const getAllReturnPayments = async (searchValue: string) => {
    const data: any = await supabaseData.getAllPayments(searchValue, 'return', currentPage)
    if (data) {
      setTotalReturnPayment(data.total_payment)
      setReturnPayment(data.payments_data)
      setIsLoading(false)
    }
  }

  const getAllAccounts = async () => {
    const data: AccountType[] | undefined = await supabaseData.getAllAccountTypes()
    if (data) setAccounts(data)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    if (event.target.value.length >= 3 || event.target.value.length === 0) {
      setCurrentPage(1)
      setIsLoading(true)
      getAllReturnPayments(event.target.value)
    }
  }

  const renderPaginationControls = () => {
    const totalPages = Math.ceil(totalReturnPayment / itemsPerPage)

    const goToNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1)
      }
    }

    const goToPrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1)
      }
    }
    const goToPage = (pageNumber: any) => {
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber)
        // Update the URL with the current page number
        // history.push(`/customers?page=${pageNumber}`)
      }
    }

    const renderPageNumbers = () => {
      const pageNumbers = []
      const numToShow = 5
      let start = Math.max(1, currentPage - Math.floor(numToShow / 2))
      let end = Math.min(totalPages, start + numToShow - 1)

      if (end - start + 1 < numToShow) {
        start = Math.max(1, end - numToShow + 1)
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i)
      }

      return pageNumbers.map((pageNumber, index) => (
        <button
          key={index}
          onClick={() => goToPage(pageNumber)}
          className={`btn btn-number btn-sm   ${
            pageNumber === currentPage ? 'bg-primary text-light' : 'bg-light text-dark'
          }`}
        >
          {pageNumber}
        </button>
      ))
    }
    // console.log('TOTAL PAGE -->', totalPages)
    // console.log('CURRENT PAGE -->', currentPage)

    return (
      totalReturnPayment >= 50 && (
        <div className='pagination-controls mx-auto'>
          <div className='d-flex'>
            <button
              className='btn btn-primary btn-sm me-2'
              onClick={goToPrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft /> Prev
            </button>
            <div className='page-numbers'>
              {currentPage > 5 && (
                <button
                  onClick={() => goToPage(1)}
                  className={`btn btn-number btn-sm  ${1 === currentPage ? 'active' : ''}`}
                >
                  1
                </button>
              )}
              {currentPage > 5 && <span>...</span>}
              <span> {renderPageNumbers()}</span>

              {currentPage < totalPages - 3 && <span>...</span>}
              {currentPage < totalPages - 3 && (
                <button
                  onClick={() => goToPage(totalPages)}
                  className={`btn btn-number btn-sm ${totalPages === currentPage ? 'active' : ''}`}
                >
                  {totalPages}
                </button>
              )}
            </div>
            <button
              className='btn btn-primary btn-sm ms-2'
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              Next <MdOutlineNavigateNext />
            </button>
          </div>
        </div>
      )
    )
  }

  const handleDeletePayment = async (payment: any) => {
    console.log('delete payment ->', payment)

    if (window.confirm('Are you sure , you want delete order?')) {
      // const deletePayment = {
      //   id: payment.id,
      //   order_id: payment.order_id,
      // }

      const data: boolean = await supabaseData.deleteReturnPayment(payment, user.id)
      if (data) window.location.reload()
    }
  }

  return (
    <div className='card card-xxl-stretch mb-5 mb-xl-8'>
      {isLoading ? (
        <div className={loader.custom_loader_container}>
          {' '}
          <p className={loader.custom_loader}></p>
        </div>
      ) : (
        <div className='card-body py-5'>
          <div className='d-flex align-items-center justify-content-between'>
            <h3>Return Payment</h3>
            <AddPayment title={title} accounts={accounts} user={user} />
          </div>
          <div className='table-responsive'>
            <table className='table table-hover table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-100px '>Date</th>
                  <th className='min-w-100px'>Order Code</th>
                  <th className='min-w-140px'>Payment</th>
                  <th className='min-w-120px'>Amount</th>
                  <th className='min-w-100px '>Note</th>
                  <th className='min-w-100px '>Created By</th>
                  <th className='min-w-100px '>Actions</th>
                </tr>
              </thead>
              <tbody>
                {returnPayment.map((payment: any) => {
                  return (
                    <tr key={payment.id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start  flex-column fw-bold text-gray-700 text-hover-primary'>
                            {new Date(payment.created_at).toLocaleDateString('en-GB')}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column fw-bold text-gray-700'>
                            {payment.order.order_code}
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div
                            className={`d-flex justify-content-start flex-column fw-bold ${
                              payment.deleted_at
                                ? 'badge badge-light-danger'
                                : 'badge badge-light-success'
                            } `}
                          >
                            {payment?.account_type?.type}
                          </div>
                        </div>
                      </td>
                      <td className='text-start text-gray-700 fw-semibold'>
                        <span className=''>{payment.amount}</span>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column fw-semibold text-gray-700'>
                            {payment.note}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column fw-semibold text-gray-700'>
                            {payment.deleted_by ? payment.deleted_by.name : payment.created_by.name}
                          </div>
                        </div>
                      </td>
                      <td>
                        {!payment.deleted_at && (
                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm ms-2'
                            onClick={() => handleDeletePayment(payment)}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className='d-flex justify-content-center'>{renderPaginationControls()}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReturnPayments
