import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import AddNewUser from './AddNewUser'
import EditUser from './EditUser'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {KTIcon} from '../../../_metronic/helpers'
import {ToastContainer} from 'react-toastify'
import {User, UserRoles, UsersType} from '../../models/user_models'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import UserDetails from './UserDetails'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import {Button} from 'react-bootstrap'

const Users = () => {
  const [users, setUsers] = useState<UsersType>([])
  const [userRoles, setUserRoles] = useState<any>([])
  const [salesMen, setSalesMen] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [showEditUser, setShowEditUser] = useState<boolean>(false)
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false)
  const [editUser, setEditUser] = useState<any>()

  // useEffect(() => {
  //   // if (users.length === 0) {
  //   getRoles()
  //   getUsers()
  //   getSalesMen()
  //   // }
  // }, [])

  useEffect(() => {
    // Fetch user roles
    getRoles()
    getSalesMen()
  }, [])

  useEffect(() => {
    // Process users when userRoles state is updated
    if (userRoles.length > 0) {
      getUsers()
    }
  }, [userRoles])

  const getRoles = async () => {
    const data: UserRoles[] | undefined = await supabaseData.getUserRoles()

    setUserRoles(data)
    setIsLoading(false)
  }

  const getUsers = async () => {
    const data: UsersType | null = await supabaseData.getAllUsers()
    // console.log('all user data from user wrapper ->', data)

    if (data && userRoles) {
      const usersWithRoles = data.map((user: User) => {
        const user_role = JSON.parse(user.role)
        const roles = user_role.map((userRoleId: string) => {
          const role = userRoles.find((role: UserRoles) => role.value === userRoleId)
          return role
        })

        return {
          ...user,
          role: roles,
        }
      })

      if (usersWithRoles) setUsers(usersWithRoles)
    }
  }

  const getSalesMen = async () => {
    const data: any = await supabaseData.get_users_by_role('Sales man')
    if (data) setSalesMen(data)
  }

  // const handleUpdateUser = (updatedUser: any) => {
  //   // console.log('user ->', updatedUser)
  //   const updatedUsers = users.map((user: any) => {
  //     if (user.id === updatedUser.id) {
  //       return updatedUser
  //     }
  //     return user
  //   })
  //   setUsers(updatedUsers)

  //   // supabaseData.updateUser(updatedUser);
  // }

  // const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchQuery(event.target.value)
  // }

  const filteredUsers = users.filter((user: any) => {
    console.log('in filter')
    const status = user.status ? 'ACTIVE' : 'DEACTIVATE'
    return (
      user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
      user.role.toLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
      status.toLowerCase().includes(searchQuery.toLocaleLowerCase())
    )
  })

  const handleEditUser = (user: any) => {
    setEditUser(user)
    setShowEditUser(true)
  }

  const handleUserDetails = (user: any) => {
    setEditUser(user)
    setShowUserDetails(true)
  }

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className='card card-xxl-stretch mt-0 mb-5 mb-xl-8'>
        <div className='card-header border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Users List</span>
          </h3>
          <div className='d-flex align-items-center'>
            <AddNewUser userRoles={userRoles} />

            {/* <div className='d-flex m-5' style={{justifyContent: 'space-between'}}>
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid w-250px ps-14'
                  placeholder='Search...'
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
            </div> */}
          </div>
        </div>
        {isLoading ? (
          <div className={loader.custom_loader_container}>
            {' '}
            <p className={loader.custom_loader}></p>
          </div>
        ) : (
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px'>Name</th>
                    <th className='min-w-150px'>Email</th>
                    <th className='min-w-100px '>Role</th>
                    <th className='min-w-100px '>Status</th>
                    <th className='min-w-100px '>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user: any, index: number) => {
                    const id = index + 1
                    return (
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold'>
                              {user.name}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold'>
                              {user.email}
                            </div>
                          </div>
                        </td>
                        <td>
                          {user.role.map((role: any) => (
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column fw-bold'>
                                {role.label},
                              </div>
                            </div>
                          ))}
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold'>
                              {user.status === true ? (
                                <div className='badge badge-light-success fs-7 fw-bold'>ACTIVE</div>
                              ) : (
                                <div className='badge badge-light-danger fs-7 fw-bold'>
                                  DEACTIVATE
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex'>
                            {/* <UserDetails user={user} /> */}
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={() => handleUserDetails(user)}
                            >
                              <KTIcon iconName='eye' className='fs-1' />
                            </button>
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={() => handleEditUser(user)}
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                              {/* <EditUser user={user} userRoles={userRoles} salesMen={salesMen} /> */}
                            </button>
                            <a
                              href='#'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </a>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {showEditUser && (
        <EditUser
          user={editUser}
          userRoles={userRoles}
          salesMen={salesMen}
          showEditUser={showEditUser}
          setShowEditUser={setShowEditUser}
        />
      )}
      {showUserDetails && (
        <UserDetails
          user={editUser}
          setShowUserDetails={setShowUserDetails}
          showUserDetails={showUserDetails}
        />
      )}
    </>
  )
}

// const UsersWrapper = () => {
// const intl = useIntl()
// return (
;<>
  {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Users'})}</PageTitle> */}
  {/* <Users /> */}
</>
// )
// }

export default Users
