import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {BiSolidDownArrowCircle} from 'react-icons/bi'
import {sample_file_path} from '../../../utils'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {ToastContainer} from 'react-toastify'

const Dispatch = ({title}: any) => {
  const [show, setShow] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null)
  const [formErrors, setFormError] = useState<boolean>(false)
  const [b_title, setTitle] = useState<string>('')
  const [isLoader, setIsLoader] = useState<boolean>(false)

  const handleShow = () => setShow(true)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (file) {
      setFile(file)
    }
  }

  const handleFormSubmit = async (e: any) => {
    e.preventDefault()
    if (!file) {
      setFormError(true)
      return
    }
    const sheetData = new FormData()
    if (file) {
      // console.log('file ->', file)
      sheetData.append('file', file)
    }
    if (title === 'Return Orders') {
      const user: string = localStorage.getItem('user_data') as string
      const user_data: any = JSON.parse(user)
      sheetData.append('created_by', user_data.id)
    }

    setIsLoader(true)

    const data: boolean = await supabaseData.sheetUpload(sheetData, setShow)
    if (data) window.location.reload()
  }

  const clearForm = () => {
    setFormError(false)
    setFile(null)
    setShow(false)
    setIsLoader(false)
  }

  return (
    <>
      <div className='btn btn-sm btn-light-primary me-3 ' onClick={handleShow}>
        {title}
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title> {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div className='row mb-3 d-flex align-items-center'>
              <div className='col-4 '>
                <label className='form-label pt-2 fs-5'>
                  {title}
                  <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-8'>
                <input
                  type='file'
                  className={`${
                    formErrors
                      ? 'form-control form-control-sm border border-2 border-danger rounded'
                      : 'form-control form-control-sm'
                  }`}
                  accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  onChange={handleFileChange}
                />
              </div>
            </div>

            <div className='row mb-5'>
              <div className='col-4'>
                <label className='form-label pt-2 fs-5'>Sample Sheet</label>
              </div>
              <div className='col-8'>
                {title === 'Dispatch' && (
                  <a
                    href={`${sample_file_path}/uploads/dispatch_sheet.xlsx`}
                    className='btn btn-light-primary'
                    download
                  >
                    <BiSolidDownArrowCircle className='fs-1' /> Download
                  </a>
                )}
                {title === 'Pincode Sheet' && (
                  <a
                    href={`${sample_file_path}/uploads/pincode_sheet.xlsx`}
                    className='btn btn-light-primary'
                    download
                  >
                    <BiSolidDownArrowCircle className='fs-1' /> Download
                  </a>
                )}
                {title === 'Return Orders' && (
                  <a
                    href={`${sample_file_path}/uploads/return_orders_sheet.xlsx`}
                    className='btn btn-light-primary'
                    download
                  >
                    <BiSolidDownArrowCircle className='fs-1' /> Download
                  </a>
                )}
              </div>
            </div>

            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button variant='secondary' size='sm' className='me-3' onClick={clearForm}>
                  Close
                </Button>
                {isLoader ? (
                  <Button type='button' variant='primary' size='sm' className='me-3' disabled>
                    Wait...
                  </Button>
                ) : (
                  <Button
                    type='button'
                    variant='primary'
                    size='sm'
                    className='me-3'
                    onClick={handleFormSubmit}
                  >
                    Save
                  </Button>
                )}
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Dispatch
