import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import clsx from 'clsx'
import {AiFillEdit} from 'react-icons/ai'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {AddCompany, Company} from '../../models/setting_models'

const AddCompanyDetails = ({setCompanyDetails, companyDetails}: any) => {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const [name, setName] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [mobile, setMobile] = useState<string>('')
  const [gst, setGst] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  // console.log('companyDetails', companyDetails)

  const handleSubmitData = async (e: any) => {
    setIsLoading(true)
    e.preventDefault()
    const newDetails: AddCompany = {
      name: name !== '' ? name : companyDetails.name,
      address: address !== '' ? address : companyDetails.address,
      mobile: mobile !== '' ? mobile : companyDetails?.mobile,
      gst: gst !== '' ? gst : companyDetails.gst,
      email: email !== '' ? email : companyDetails?.email,
      file,
    }
    // console.log('New Object', newDetails)

    const data: Company | undefined = await supabaseData.addCompany(newDetails)
    if (data !== undefined) {
      setCompanyDetails(newDetails)
      // @ts-ignore
      // setAccounts([...accounts, newAccount])
      setShow(false)
      setIsLoading(false)
      window.location.reload()
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0]
    if (selectedFile) {
      setFile(selectedFile)
    }
  }
  return (
    <>
      <div className='d-flex align-items-center btn btn-sm btn-light-primary' onClick={handleShow}>
        {companyDetails.name === '' ? (
          <>
            <KTIcon iconName='plus' className='fs-3' />
            Add
          </>
        ) : (
          <>
            <AiFillEdit className='fs-3' />
            Edit
          </>
        )}
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>Add New Account Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={handleSubmitData}>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Name <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='text'
                  className={clsx('form-control form-control-solid col-9 ', {
                    'is-valid': name?.length >= 4,
                  })}
                  name='name'
                  value={name !== '' ? name : companyDetails.name}
                  onChange={(e: any) => setName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Address <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-10'>
                <textarea
                  className={clsx('form-control form-control-solid col-9 ', {
                    'is-valid': address?.length >= 15,
                  })}
                  name='name'
                  value={address !== '' ? address : companyDetails.address}
                  onChange={(e: any) => setAddress(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Mobile <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='number'
                  className={clsx('form-control form-control-solid col-9 ', {
                    'is-valid': mobile?.length >= 10,
                  })}
                  name='name'
                  value={mobile !== '' ? mobile : companyDetails?.mobile}
                  onChange={(e: any) => setMobile(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  GST <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='text'
                  className={clsx('form-control form-control-solid col-9 ', {
                    'is-valid': gst?.length >= 15,
                  })}
                  name='name'
                  value={gst !== '' ? gst : companyDetails.gst}
                  onChange={(e: any) => setGst(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Email <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='email'
                  className={clsx('form-control form-control-solid col-9 ', {
                    'is-valid': email?.length >= 15,
                  })}
                  name='name'
                  value={email !== '' ? email : companyDetails?.email}
                  onChange={(e: any) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Signature <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='file'
                  className={clsx('form-control form-control-solid col-9 ', {
                    'is-valid': !!file,
                  })}
                  name='signature'
                  onChange={handleFileChange}
                  required
                />
              </div>
            </div>

            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
                {isLoading ? (
                  <Button>
                    <p className=''></p>Wait...
                  </Button>
                ) : (
                  <Button type='submit' variant='primary' size='sm'>
                    Save
                  </Button>
                )}
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddCompanyDetails
