import React from 'react'
import {Link} from 'react-router-dom'
// @ts-ignore
const TabView = ({tabs, activeTab, onTabClick}) => {
  return (
    <div>
      <ul className='nav nav-tabs  '>
        {tabs.map((tab: any) => (
          <li
            key={tab.path}
            className={`nav-item mr-2 text-gray-600 text-primary-hover ${
              activeTab === tab.path ? 'active' : ''
            }`}
            onClick={() => onTabClick(tab.path)}
          >
            <p
              style={
                activeTab === tab.path
                  ? {
                      fontWeight: 'bold',
                      borderBottom: '2px solid #42a1f5',
                      color: '#42a1f5',
                      // backgroundColor: '#42a1f5',
                      padding: '0.5rem 1rem',
                      borderRadius: '0.25rem',
                      cursor: 'pointer',
                    }
                  : {padding: '0.5rem 1rem', cursor: 'pointer', fontWeight: 'bold'}
              }
            >
              <span className='me-1 fs-3 fw-bold'>{tab.icon}</span>
              {tab.label}
            </p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TabView
