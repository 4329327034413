import React, {useEffect, useState} from 'react'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import CustomerCallDetail from './CustomerAllDetails'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {MdKeyboardArrowLeft, MdOutlineNavigateNext} from 'react-icons/md'

const DNDCustomers = ({orders, returnOrders, complete_followup_calls, leaves}: any) => {
  const [isDNDLoading, setIsDNDLoading] = useState(true)
  const [dndCustomers, setDndCustomers] = useState<any>([])
  const [totalDNDCount, setTotalDNDCount] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemsPerPage: number = 20

  useEffect(() => {
    setDNDTotalCustomers()
  }, [])

  useEffect(() => {
    getAllDNDCustomers()
  }, [currentPage])

  const renderPaginationControls = () => {
    const totalPages = Math.ceil(totalDNDCount / itemsPerPage)

    const goToNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1)
      }
    }

    const goToPrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1)
      }
    }
    const goToPage = (pageNumber: any) => {
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber)
      }
    }

    const renderPageNumbers = () => {
      const pageNumbers = []
      const numToShow = 3
      let start = Math.max(1, currentPage - Math.floor(numToShow / 2))
      let end = Math.min(totalPages, start + numToShow - 1)

      if (end - start + 1 < numToShow) {
        start = Math.max(1, end - numToShow + 1)
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i)
      }

      return pageNumbers.map((pageNumber, index) => (
        <button
          key={index}
          onClick={() => goToPage(pageNumber)}
          className={`btn btn-number btn-sm   ${
            pageNumber === currentPage ? 'bg-primary text-light' : 'bg-light text-dark'
          }`}
        >
          {pageNumber}
        </button>
      ))
    }

    return (
      totalDNDCount >= 20 && (
        <div className='pagination-controls mx-auto'>
          <div className='d-flex'>
            <button
              className='btn btn-primary btn-sm me-2'
              onClick={goToPrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft /> Prev
            </button>
            <div className='page-numbers'>
              {currentPage > 2 && (
                <button
                  onClick={() => goToPage(1)}
                  className={`btn btn-number btn-sm  ${1 === currentPage ? 'active' : ''}`}
                >
                  1
                </button>
              )}
              {currentPage > 2 && <span>...</span>}
              <span> {renderPageNumbers()}</span>
              {currentPage < totalPages - 1 && <span>...</span>}
              {currentPage < totalPages - 1 && (
                <button
                  onClick={() => goToPage(totalPages)}
                  className={`btn btn-number btn-sm ${totalPages === currentPage ? 'active' : ''}`}
                >
                  {totalPages}
                </button>
              )}
            </div>
            <button
              className='btn btn-primary btn-sm ms-2'
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              Next <MdOutlineNavigateNext />
            </button>
          </div>
        </div>
      )
    )
  }

  const setDNDTotalCustomers = async () => {
    const count: any = await supabaseData.getTotalDNDCustomersCount()
    if (count) setTotalDNDCount(count)
  }

  const getAllDNDCustomers = async () => {
    const offset: number = (currentPage - 1) * 20
    let display: number = 20
    if (offset > 0) display = offset + 19
    else display = itemsPerPage - 1
    const data: any = await supabaseData.getDNDAllCustomers(offset, itemsPerPage)
    // console.log('DND CUSTOMERS ->', data)
    if (data) {
      setDndCustomers(data)
      setIsDNDLoading(false)
    }
  }

  return (
    <div className=''>
      {isDNDLoading ? (
        <div className={` ${loader.custom_loader_container}`}>
          {' '}
          <p className={loader.custom_loader}></p>
        </div>
      ) : (
        <div className='card-body pt-2'>
          <h3 className='mt-5 mb-10'>DND Activate</h3>
          <table
            className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4 '
            style={{maxWidth: '600px'}}
          >
            <thead>
              <tr className='border-0'>
                <th className='p-0 w-10px'></th>
                <th className='p-0 w-30px'></th>
                <th className='p-0 max-w-50px'></th>
                <th className='p-0 min-w-110px'></th>
                <th className='p-0 min-w-50px'></th>
              </tr>
            </thead>
            <tbody>
              {/* {dndCustomers.slice(0, sliceEnd)?.map((customer: any, i: number) => { */}
              {dndCustomers.map((customer: any, i: number) => {
                const currentDate = new Date()
                const followUpDate = new Date(customer.schedule_date)
                const isPastDate = followUpDate < currentDate
                const isCurrentDate = followUpDate.toDateString() === currentDate.toDateString()
                return (
                  <tr
                    key={i}
                    className={`${
                      (isCurrentDate && 'bg-danger-subtle ') || (isPastDate && 'bg-danger-subtle ')
                    }`}
                  >
                    <td>
                      <span
                        className={`bullet bullet-vertical h-30px bg-success ${
                          (isCurrentDate && 'bg-danger ') || (isPastDate && 'bg-danger ')
                        }`}
                      ></span>
                    </td>
                    <td>
                      <div className='form-check form-check-custom form-check-solid p-0 m-0'>
                        {/* Commented out for clarity */}
                        {/* <CallCompletion customer={customer} /> */}
                      </div>
                    </td>
                    <td>
                      <div
                        className='flex-grow-1 text-gray-800 text-hover-primary fw-bold fs-6'
                        style={{cursor: 'pointer'}}
                      >
                        <CustomerCallDetail
                          orders={orders}
                          returnOrders={returnOrders}
                          customer={customer}
                          complete_followup_calls={complete_followup_calls}
                          leaves={leaves}
                          style={{cursor: 'pointer'}}
                        />
                        <span className='text-muted fw-semibold d-block'>Customer</span>
                      </div>
                    </td>
                    <td>
                      <p className='text-gray-600 fs-6 fw-bold'>{customer.mobile}</p>
                    </td>
                    <td>
                      <p className={`text-gray-600 fs-6 fw-bold`}>
                        {new Date(customer.dnd_on_at).toLocaleDateString('en-GB')}
                      </p>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
      <div className='d-flex justify-content-center'>{renderPaginationControls()}</div>
    </div>
  )
}

export default DNDCustomers
