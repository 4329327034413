import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {RiCloseFill} from 'react-icons/ri'
import {FaUserAlt} from 'react-icons/fa'
import {MdCall, MdEmail, MdLocationPin} from 'react-icons/md'
import {BsFillCalendar2CheckFill} from 'react-icons/bs'
import style from '../../../_metronic/assets/sass/customer.module.css'

const CustomerDetails = ({customer}: any) => {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)

  // console.log('customer from customer view ->', customer)

  return (
    <>
      <div className='d-flex align-items-center' onClick={handleShow}>
        <Link to='' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
          <KTIcon iconName='eye' className='fs-1' />
        </Link>
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>Customer Details</Modal.Title>
          <button
            className='border border-none fs-3 text-hover-primary rounded-circle'
            onClick={() => setShow(false)}
          >
            <RiCloseFill />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-5'>
            <p className='d-flex align-items-center fs-1'>
              <FaUserAlt className='text-gray-600 ' />
              {/* <span className='fw-bold fs-5 ms-2'> Name: </span> */}
              <span className='fw-bold ms-2 text-gray-700'>{customer.label}</span>
            </p>
            <div className='ms-10 d-flex'>
              <div className='d-flex align-items-center ms-5'>
                <span>
                  <MdCall className='text-success fs-3 ' />
                </span>
                <span className='fw-bold fs-7 text-gray-700 ms-1'> {customer.mobile}</span>
              </div>
              <div className='d-flex align-items-center ms-5'>
                <span>
                  <BsFillCalendar2CheckFill className='text-danger-emphasis fs-3 ' />
                </span>
                <span className='fw-bold fs-7 ms-2 text-gray-700 '>
                  {new Date(customer.created_at).toLocaleDateString('en-GB')}
                </span>
              </div>
            </div>
            {customer.mobile_2 && (
              <div className='ms-10 d-flex mt-2'>
                <div className='d-flex align-items-center ms-5'>
                  <span>
                    <MdCall className='text-success fs-3 ' />
                  </span>
                  <span className='fw-bold fs-7 text-gray-700 ms-1'> {customer.mobile_2}</span>
                </div>
              </div>
            )}
            {customer.mobile_3 && (
              <div className='ms-10 d-flex mt-2'>
                <div className='d-flex align-items-center ms-5'>
                  <span>
                    <MdCall className='text-success fs-3 ' />
                  </span>
                  <span className='fw-bold fs-7 text-gray-700 ms-1'> {customer.mobile_3}</span>
                </div>
              </div>
            )}
            <h5 className={`text-gray-700 mt-5 ${style.address_header}`}>Address</h5>
            {customer.customer_address.map((address: any) => (
              <>
                <div className='ms-10 mt-3 d-flex'>
                  <p className='mb-0'>
                    <span>
                      <MdLocationPin className='text-gray-500 fs-3 ' />
                    </span>
                    <span className='fw-bold fs-7 text-gray-600'>{address.address}</span>
                    {address.city && (
                      <span className='fw-bold fs-7 text-gray-600'>, {address.city}</span>
                    )}
                    {address.village && (
                      <span className='fw-bold fs-7 text-gray-600'>, {address.village}</span>
                    )}
                    <span className='fw-bold fs-7 text-gray-600'>, {address.district}</span>
                    <span className='fw-bold fs-7 text-gray-600'>, {address.state}</span>
                  </p>
                </div>
                <span className='fw-bold fs-7 text-gray-600 ms-15'>Pincode: {address.pincode}</span>
              </>
            ))}
          </div>
          <div className={`border-top pt-5 ${style.salesman_header}`}>
            <h4 className={`text-gray-700 mt-5 `}>Sales Man Details</h4>
            <p className='ps-12 mt-5'>
              <span className='fs-6 fw-bold text-gray-700'>name: </span>
              <span className='fs-6 fw-semibold text-gray-700'> {customer.users.label}</span>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CustomerDetails
