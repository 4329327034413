import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import Select from 'react-select'
// import {returnProductsData} from '../AppDataDefine'
import style from '../../../_metronic/assets/sass/returnOrder.module.css'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'

const EditReturnOrder = ({editOrder, courierCompanies, accounts, show, closeModel}: any) => {
  // const [show, setShow] = useState(false)
  const [returnDate, setReturnDate] = useState<string>('')
  // const [selectedProduct, setSelectedProduct] = useState<any>(null)
  const [refundAmount, setRefundAmount] = useState<number>(+editOrder.refund_amount)
  const [returnItems, setReturnItems] = useState<any[]>([])
  const [inputErrors, setInputErrors] = useState<boolean[]>([])
  const [inputErrors2, setInputErrors2] = useState<boolean[]>([])
  const [isRTO, setIsRTO] = useState<boolean>()
  const [referenceId, setReferenceId] = useState(null)
  const [courierCode, setCourierCode] = useState('')
  const [amount, setAmount] = useState<number>(0)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  // const [editOrder,  // seteditOrder] = useState<any>(null)
  const [selectedReturnProduct, setSelectedReturnProduct] = useState<any>([])
  // const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedPaymentMethodObj, setSelectedPaymentMethodObj] = useState<any>(null)
  const [order_manager, setOrderManager] = useState<string>('')
  const [selectedCourierObj, setSelectedCourierObj] = useState<any>({})
  const formatDate = (dateString: string) => {
    console.log('date string ->')
    let date: string = ''
    if (dateString) {
      const parts: string[] = dateString.split('/')
      if (parts.length === 3) {
        const [day, month, year] = parts
        const formattedDate: string = `${year}-${month}-${day}`
        date = formattedDate
      }
    }
    console.log('date ->', date)
    if (date !== '') return date
    return ''
  }

  useEffect(() => {
    console.log('Edit Order ->', editOrder)

    const updatedReturnItems = editOrder.order_items?.map((item: any) => ({
      id: item.id,
      return_unit: item.return_unit,
      damage_unit: item.damage_unit,
      refund_amount: item.refund_amount,
    }))
    setReturnItems(updatedReturnItems)
    setIsRTO(editOrder.is_rto)
    const totalAmount: any = editOrder.items?.reduce(
      (total: number, item: any) => total + item.refund_amount || 0,
      0
    )
    setTotalAmount(totalAmount)
    setInputErrors(new Array(editOrder.items?.length).fill(false))
    setInputErrors2(new Array(editOrder.items?.length).fill(false))
  }, [])

  useEffect(() => {
    const user: any = localStorage.getItem('user_data')
    const user_data: any = JSON.parse(user)
    if (
      user_data.role.includes('Admin') ||
      user_data.role.includes('Sub Admin') ||
      user_data.role.includes('Order Manager') ||
      user_data.role.includes('Order Inventory Manager')
    ) {
      // @ts-ignore

      setOrderManager(user_data.id)
    }
  }, [])

  useEffect(() => {
    const selectedCourier = () => {
      courierCompanies.map((courier: any) => {
        if (courier.value === editOrder?.return_courier_provider_id) {
          setSelectedCourierObj({
            value: courier.value,
            label: courier.label,
          })
        }
        return {
          value: courier.value,
          label: courier.label,
        }
      })
    }

    const selectedAccountType = () => {
      accounts.map((account: any) => {
        if (account.value === editOrder?.return_payment_method) {
          setSelectedPaymentMethodObj({
            value: account.value,
            label: account.label,
          })
        }
        return {
          value: account.id,
          label: account.type,
        }
      })
    }

    selectedCourier()
    if (editOrder.return_payment_method) selectedAccountType()
  }, [])

  const handleChange = (e: any, i: number, type: string) => {
    const {value} = e.target
    const newItems = [...returnItems]

    if (type === 'return_unit') {
      setInputErrors((prevErrors) => {
        console.log('prevErrors ->', prevErrors)

        const newErrors = [...prevErrors]
        console.log('newErrors ->', newErrors, i)
        newErrors[i] = parseInt(value, 10) > editOrder.order_items[i].units
        return newErrors
      })

      newItems[i] = {
        ...newItems[i],
        return_unit: +value,
      }

      const price = editOrder.order_items[i].price
      const newAmount = parseInt(value, 10) * price

      console.log('New Amount', newAmount)
      setAmount(newAmount)

      newItems[i] = {
        ...newItems[i],
        refund_amount: newAmount,
      }

      const totalAmount = newItems.reduce((total, item) => total + item.refund_amount || 0, 0)

      console.log('TOTAL AMOUNT', totalAmount)

      setTotalAmount(totalAmount)
      console.log('Amount --->', totalAmount)

      setReturnItems(newItems)
    } else if (type === 'damage_unit') {
      setInputErrors2((prevErrors) => {
        const newErrors = [...prevErrors]
        newErrors[i] = parseInt(value, 10) > newItems[i].return_unit
        return newErrors
      })

      newItems[i] = {
        ...newItems[i],
        damage_unit: +value,
      }

      setReturnItems(newItems)
    } else if (type === 'damage_items') {
      console.log('Change data', newItems)

      newItems[i] = {
        ...newItems[i],
        refund_amount: +value,
      }

      setReturnItems(newItems)
    }
    console.log('RETURN ITEMS', returnItems)
  }

  useEffect(() => {
    // Calculate the total refund amount whenever returnItems changes
    const totalRefundAmount = returnItems.reduce(
      (total, item) => total + (item.refund_amount || 0),
      0
    )
    setTotalAmount(totalRefundAmount)
  }, [returnItems])

  const handleReturnOrder = async (e: any) => {
    e.preventDefault()
    console.log('RETURN ITEMS', returnItems)
    const hasErrors = inputErrors.some((error) => error) || inputErrors2.some((error) => error)
    if (!hasErrors) {
      let newReturnObj = {}
      if (isRTO) {
        const obj = {
          return_date: returnDate ? returnDate : editOrder.return_date,
          order_id: editOrder.value,
          is_rto: isRTO,
          // order_items: editOrder.items.map((item: any) => {
          //   item.refund_amount = null
          //   return item
          // }),
          order_items: returnItems,
          return_payment_id: null,
          return_payment_mode: null,
          courier_company: null,
          tracking_id: null,
          refund_amount: null,
          order_manager,
        }
        newReturnObj = obj
      } else {
        const obj = {
          order_id: editOrder.id,
          return_date: returnDate ? returnDate : editOrder.return_date,
          // is_rto: isRTO,
          payment: {
            id: editOrder.return_payment ? editOrder.return_payment.id : null,
            transaction_id:
              referenceId !== '' ? referenceId : editOrder.return_payment?.transaction_id,
            payment_method: selectedPaymentMethodObj ? selectedPaymentMethodObj.value : null,
            amount: refundAmount !== null ? +refundAmount : amount,
          },
          // order_items: editOrder.items,
          order_items: returnItems,
          return_payment_method: selectedPaymentMethodObj ? selectedPaymentMethodObj.value : null,
          courier_company: selectedCourierObj ? selectedCourierObj.value : selectedCourierObj,
          tracking_id: courierCode !== '' ? courierCode : editOrder.return_tracking_id,
          refund_amount: refundAmount !== 0 ? +refundAmount : 0,
          payment_status: refundAmount === totalAmount ? 'Refund' : 'Pending',
          order_manager,
        }
        newReturnObj = obj
      }

      console.log('UPDATE RETURN OBJ => ', newReturnObj)

      const data: boolean = await supabaseData.editReturnOrder(newReturnObj)

      if (data) window.location.reload()
    }
  }

  return (
    <>
      <Modal show={show} className={`modal-xl `} style={{maxHeight: '850px', overflowY: 'scroll'}}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>Edit Return Order </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleReturnOrder}>
            <div className='px-5' style={{maxHeight: '650px', overflowY: 'scroll'}}>
              <div className='d-flex border-b border-gray-200 dark:border-gray-700 border-bottom pb-5'>
                <div className='col d-flex align-items-center'>
                  <label className='form-label pt-2 fs-5 ' style={{width: '100px'}}>
                    Order :
                  </label>
                  <div className='col-8 pt-1'>
                    <Select
                      // @ts-ignore
                      styles={{backgroundColor: 'red'}}
                      id='SelExample'
                      defaultValue={{
                        label: `${editOrder.tracking_id} - ${
                          editOrder.customers.mobile
                        } - ${new Date(editOrder.order_date).toLocaleDateString('en-GB')} - ${
                          editOrder.order_courier_provider.name
                        } - ${editOrder.total_amount}`,
                        value: editOrder.id,
                      }}
                      isDisabled={true}
                    />
                  </div>
                </div>
                <div className='col d-flex'>
                  <label className='form-label pt-2 fs-5 me-3 pt-3 ' style={{width: '115px'}}>
                    Return Date <span className='text-danger'>*</span>
                  </label>
                  <div className='col-5 pt-1'>
                    <input
                      type='date'
                      className='form-control form-control-sm form-control-solid '
                      value={
                        returnDate
                          ? returnDate
                          : `${new Date(editOrder.return_date).getFullYear()}-${
                              new Date(editOrder.return_date).getMonth() + 1
                            }-${new Date(editOrder.return_date).getDate()}`
                      }
                      onChange={(e: any) => setReturnDate(e.target.value)}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className='border-bottom'>
                {editOrder && (
                  <>
                    <div className='border-bottom row mt-5'>
                      <div className='col-6'>
                        {/* <p>Return Order Code: RTNORD{editOrder.code}</p> */}
                        <p>Customer Name: {editOrder.customers.name}</p>
                        <p>Mobile: {editOrder.customers.mobile}</p>
                        <p>Order Code: {editOrder.order_code}</p>
                      </div>
                      <div className='col-6'>
                        <div className='form-check form-check-custom form-check-solid mx-5'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            checked={isRTO}
                            onChange={(e) => setIsRTO(e.target.checked)}
                            disabled={true}
                          />
                          <label className='form-check-label me-2 fw-bold fs-5'>RTO</label>
                        </div>
                      </div>
                    </div>
                    <table className='table  table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          {/* <th className='min-w-50px'></th> */}
                          <th className='min-w-100px'></th>
                          <th className='min-w-140px'>Units</th>
                          {!isRTO && <th className=''>Price</th>}
                          <th className='min-w-120px'>Return Units</th>
                          <th className='min-w-100px '>Damage Units</th>
                          {!isRTO && <th>Amount</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {editOrder?.order_items.map((item: any, i: number) => {
                          // console.log('item -->', item)
                          return (
                            <tr key={i}>
                              <td className='d-flex me-4'>
                                <div>
                                  {/* <img
                                    className={style.set_product_image}
                                    src={`${image_path}${item?.products.image}`}
                                  /> */}
                                </div>
                                <div className='ms-5 fw-semi-bold'>
                                  <p className='mb-0 mt-2 fs-6 fw-bold text-gray-700'>
                                    {item.label}
                                  </p>
                                  <p className='mb-0 mt-1 fs-6 fw-bold text-gray-700'>
                                    CODE : {item.code}
                                  </p>
                                </div>
                              </td>
                              <td className='mb-0 position-relative'>
                                <p className='border border-primary-subtle border-2 rounded py-2 px-10 me-5 mb-0 fw-semibold text-gray-600 '>
                                  {item.units}
                                </p>
                              </td>
                              {!isRTO && (
                                <td className='mb-0 position-relative'>
                                  <p className='border border-primary-subtle border-2 rounded py-2 px-10 me-5 mb-0 fw-semibold text-gray-600'>
                                    {item.price}
                                  </p>
                                </td>
                              )}

                              <td className='me-4 position-relative'>
                                <input
                                  type='number'
                                  name='return_unit'
                                  className='form-control form-control-solid'
                                  value={returnItems[i]?.return_unit}
                                  onChange={(e) => handleChange(e, i, 'return_unit')}
                                  required
                                  // readOnly
                                />
                                {inputErrors[i] && (
                                  <p className='text-danger position-absolute'>Enter valid Units</p>
                                )}
                              </td>
                              <td className='position-relative'>
                                <input
                                  type='number'
                                  name='damage_unit'
                                  className='form-control form-control-solid'
                                  value={returnItems[i]?.damage_unit}
                                  onChange={(e) => handleChange(e, i, 'damage_unit')}
                                  required
                                  // readOnly
                                />
                                {inputErrors2[i] && (
                                  <p className='text-danger position-absolute'>Enter valid Units</p>
                                )}
                              </td>
                              {!isRTO && (
                                <td>
                                  <input
                                    className=' form-control form-control-solid'
                                    type='number'
                                    name='amount'
                                    defaultValue={returnItems[i]?.refund_amount}
                                    onChange={(e) => handleChange(e, i, 'damage_items')}
                                  />
                                </td>
                              )}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    {!isRTO && (
                      <p className='border-top pt-3 d-flex justify-content-end'>
                        <span className='fs-5 fw-bold text-gray-700'>
                          Total Amount : {!totalAmount ? editOrder.refund_amount : totalAmount}
                        </span>
                      </p>
                    )}
                  </>
                )}
              </div>
              {!isRTO && (
                <>
                  <div className='mt-4 border-b border-gray-200 dark:border-gray-700  pb-5'>
                    <div className='col d-flex'>
                      <label className='form-label pt-2 fs-5 me-3' style={{width: '133px'}}>
                        Courier Company
                      </label>
                      <div className='col-5 pt-1'>
                        <Select
                          options={courierCompanies}
                          value={isRTO ? null : selectedCourierObj}
                          onChange={(selectedOption: any) => {
                            // setSelectedCourier(selectedOption.label)
                            setSelectedCourierObj(selectedOption)
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='ms-20 col-5'>
                    <input
                      className='form-control form-control-solid form-control-sm mt-2 ms-19'
                      type='text'
                      value={courierCode !== '' ? courierCode : editOrder?.return_tracking_id}
                      onChange={(e: any) => setCourierCode(e.target.value)}
                      placeholder='Enter tracking code '
                    />
                  </div>

                  <div className='mt-8  border-b border-gray-200 dark:border-gray-700 pb-5'>
                    <div className='col d-flex'>
                      <label className='form-label pt-2 fs-5 me-3' style={{width: '133px'}}>
                        Account
                      </label>
                      <div className='col-5 pt-1'>
                        <Select
                          options={accounts}
                          value={selectedPaymentMethodObj}
                          onChange={(selectedOption: any) => {
                            // setSelectedAccount(selectedOption.label)
                            setSelectedPaymentMethodObj(selectedOption)
                          }}
                        />
                      </div>
                    </div>

                    <div className='ms-20 col-5'>
                      <input
                        className='form-control form-control-solid form-control-sm mt-5 ms-20'
                        type='text'
                        value={
                          referenceId !== ''
                            ? referenceId
                            : editOrder?.return_payment?.transaction_id
                        }
                        onChange={(e: any) => setReferenceId(e.target.value)}
                        placeholder='Enter reference id'
                      />
                    </div>
                  </div>
                  <div className='mt-8  border-b border-gray-200 dark:border-gray-700 border-bottom pb-5'>
                    <div className='col d-flex'>
                      <label className='form-label pt-2 fs-5 ' style={{width: '125px'}}>
                        Refund Amount
                      </label>
                      <div className='col-5'>
                        <input
                          className='form-control form-control-sm form-control-solid'
                          type='number'
                          value={refundAmount}
                          onChange={(e: any) => setRefundAmount(+e.target.value)}
                          placeholder='Enter Amount'
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <Modal.Footer className='p-0'>
              <div className='mt-5 d-flex justify-content-end'>
                <Button variant='secondary' size='sm' className='me-3' onClick={closeModel}>
                  Close
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditReturnOrder
