import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {CallHistory} from '../../models/calls_models'
import clsx from 'clsx'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import OrderDetails from '../orders/OrderDetails'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import {MdKeyboardArrowLeft, MdOutlineNavigateNext} from 'react-icons/md'

const CallHistoryWrapper = () => {
  const [customers, setCustomers] = useState<CallHistory[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemsPerPage: number = 20
  const [showOrder, setShowOrder] = useState<boolean>(false)
  const [order, setOrder] = useState<any>(null)
  const [totalCallsHistory, setTotalCallsHistory] = useState<number>(0)
  useEffect(() => {
    getTotalCalls('')
  }, [])

  useEffect(() => {
    getAllCallsHistory('')
  }, [currentPage])

  const getTotalCalls = async (searchQuery: string) => {
    const count: any = await supabaseData.getTotalCallsHistory(searchQuery)
    setTotalCallsHistory(count)
  }

  const renderPaginationControls = () => {
    const totalPages = Math.ceil(totalCallsHistory / itemsPerPage)

    const goToNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1)
      }
    }

    const goToPrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1)
      }
    }
    const goToPage = (pageNumber: any) => {
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber)
      }
    }

    const renderPageNumbers = () => {
      const pageNumbers = []
      const numToShow = 5
      let start = Math.max(1, currentPage - Math.floor(numToShow / 2))
      let end = Math.min(totalPages, start + numToShow - 1)

      if (end - start + 1 < numToShow) {
        start = Math.max(1, end - numToShow + 1)
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i)
      }

      return pageNumbers.map((pageNumber, index) => (
        <button
          key={index}
          onClick={() => goToPage(pageNumber)}
          className={`btn btn-number btn-sm   ${
            pageNumber === currentPage ? 'bg-primary text-light' : 'bg-light text-dark'
          }`}
        >
          {pageNumber}
        </button>
      ))
    }

    return (
      totalCallsHistory >= 20 && (
        <div className='pagination-controls mx-auto'>
          <div className='d-flex'>
            <button
              className='btn btn-primary btn-sm me-2'
              onClick={goToPrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft /> Prev
            </button>
            <div className='page-numbers'>
              {currentPage > 5 && (
                <button
                  onClick={() => goToPage(1)}
                  className={`btn btn-number btn-sm  ${1 === currentPage ? 'active' : ''}`}
                >
                  1
                </button>
              )}
              {currentPage > 5 && <span>...</span>}
              <span> {renderPageNumbers()}</span>
              {currentPage < totalPages - 3 && <span>...</span>}
              {currentPage < totalPages - 3 && (
                <button
                  onClick={() => goToPage(totalPages)}
                  className={`btn btn-number btn-sm ${totalPages === currentPage ? 'active' : ''}`}
                >
                  {totalPages}
                </button>
              )}
            </div>
            <button
              className='btn btn-primary btn-sm ms-2'
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              Next <MdOutlineNavigateNext />
            </button>
          </div>
        </div>
      )
    )
  }

  const getAllCallsHistory = async (searchQuery: any) => {
    const data: CallHistory[] | undefined = await supabaseData.getAllCallsHistory(
      searchQuery,
      currentPage
    )
    // console.log(data)
    if (data) setCustomers(data)
    setIsLoading(false)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setIsLoading(true)
      getTotalCalls(searchValue)
      getAllCallsHistory(searchValue)
    }
  }

  const handleOrderShow = (order: any) => {
    setOrder(order)
    setShowOrder(true)
  }

  const handleOrderClose = () => {
    setShowOrder(false)
    setOrder(null)
  }

  return (
    <>
      <div className='card'>
        <div
          className='d-flex justify-content-space-between'
          style={{justifyContent: 'space-between'}}
        >
          <div className='card-header border-0'>
            <h3 className='card-title fw-bold text-dark'>Call History List</h3>
          </div>
          <div className='d-flex m-5' style={{justifyContent: 'space-between'}}>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                className='form-control form-control-sm form-control-solid w-250px ps-14'
                placeholder='Search...'
                // value={searchQuery}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className={loader.custom_loader_container}>
            {' '}
            <p className={loader.custom_loader}></p>
          </div>
        ) : (
          <div className='card-body pt-2'>
            <table
              className='table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4 '
              style={{maxWidth: '600px'}}
            >
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Follow Up</th>
                  <th className='min-w-150px'>Call Date</th>
                  <th className='min-w-300px'>Name</th>
                  <th className='min-w-150px'>Mobile</th>
                  <th className='min-w-150px'>Order Code</th>
                  <th className='min-w-150px'>Caller Name</th>
                  <th className='min-w-150px'>Status</th>
                  <th className='min-w-150px'>Note</th>
                  <th className='min-w-150px'>Action</th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer: any, i: number) => {
                  const s_Date = new Date(customer.schedule_date)
                  const c_date = new Date(customer.call_date)

                  return (
                    <tr key={i}>
                      <td>
                        <div className='d-flex justify-content-start flex-column fw-bold'>
                          <span className='text-gray-600 fs-6 fw-bold'>
                            {`${s_Date.getDate()}/${s_Date.getMonth() + 1}/${s_Date.getFullYear()}`}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-start flex-column fw-bold'>
                          <span className='text-gray-600 fs-6 fw-bold'>
                            {/* {new Date(customer.call_date).toLocaleDateString('en-GB')} */}
                            {`${c_date.getDate()}/${c_date.getMonth() + 1}/${c_date.getFullYear()}`}
                          </span>
                        </div>
                      </td>
                      <td>
                        <p className='text-gray-600 fs-6 fw-bold'>{customer.customers.name}</p>
                      </td>
                      <td>
                        <p className='text-gray-600 fs-6 fw-bold'>{customer.customers.mobile}</p>
                      </td>
                      <td>
                        <span className='text-gray-600 fs-6 fw-bold'>{customer.order_code}</span>
                      </td>
                      <td>
                        <span className='text-gray-600 fs-6 fw-bold'>{customer.caller.name}</span>
                      </td>
                      <td>
                        <span
                          className={clsx({
                            'badge badge-light-success': customer.call_status === 'Accepted',
                            'badge badge-light-danger': customer.call_status === 'Rejected',
                            'badge badge-light-warning': customer.call_status === 'Not Response',
                          })}
                        >
                          {customer.call_status}
                        </span>
                      </td>
                      <td>
                        <span className='text-gray-600 fs-6 fw-bold'>{customer.note}</span>
                      </td>
                      <td>
                        <button
                          className='btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm me-1'
                          onClick={() => handleOrderShow(customer)}
                        >
                          <KTIcon iconName='eye' className='fs-1' />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className='d-flex justify-content-center'>{renderPaginationControls()}</div>
          </div>
        )}
      </div>
      {showOrder && (
        <OrderDetails
          order={order}
          // customer={order.customer}
          status={'order'}
          showOrder={showOrder}
          handleOrderClose={handleOrderClose}
        />
      )}
    </>
  )
}

export default CallHistoryWrapper
