import React, {useEffect, useState, ChangeEvent} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {FaUserAlt} from 'react-icons/fa'
import {PiPhoneCallFill} from 'react-icons/pi'
// import {customerDetails} from '../AppDataDefine'
import {MdLocationOn} from 'react-icons/md'
import clsx from 'clsx'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {Address} from '../../models/customer_models'
import {Payments} from '../../models/payment_model'
import loader from '../../../_metronic/assets/sass/loader.module.css'

const CustomerOrderDetail = ({className}: any) => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null)
  const [matchingMobileNumbers, setMatchingMobileNumbers] = useState<string[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [receivePayments, setReceivePayments] = useState<Payments[]>([])
  const [returnPayments, setReturnPayments] = useState<Payments[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    setSearchQuery(inputValue)

    if (inputValue.length === 10) {
      setShowModal(true)
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }

    if (inputValue === '') {
      setMatchingMobileNumbers([])
    } else if (inputValue.length >= 3) {
      setTimeout(() => {
        handleSearch(inputValue)
      }, 1000)
    }
  }

  const handleSearch = async (query: string) => {
    const data: any = await supabaseData.getCustomerAllDetail(query)

    if (query.length === 10) {
      if (data.length !== 0) {
        setSelectedCustomer(data[0])

        let receivePayments: Payments[] = []
        let returnPayments: Payments[] = []
        data[0].payment_history.map((payment: Payments) => {
          if (payment.is_return) returnPayments.push(payment)
          else receivePayments.push(payment)
        })
        setReceivePayments(receivePayments)
        setReturnPayments(returnPayments)
        setShowModal(true)
        setMatchingMobileNumbers([searchQuery])
      } else {
        setShowModal(true)
      }
    } else {
      const filteredNumbers = data
        .filter((customer: any) => customer.mobile.includes(searchQuery))
        .map((customer: any) => customer.mobile)

      setMatchingMobileNumbers(filteredNumbers)
    }
  }

  return (
    <>
      <div className={`card card-flush ${className}`}>
        <div className='card-body p-5 '>
          <div className='row '>
            <div className='col-6'>
              <span className='fs-3 fw-bolder text-gray-700 d-block pt-2'>Customer Details</span>
            </div>
            <div className='col-6'>
              <div className='d-flex justify-content-end'>
                <div className='d-flex align-items-center position-relative my-1'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <div>
                    <input
                      className='form-control form-control-sm form-control-solid ps-14'
                      list='search'
                      placeholder='Search mobile number...'
                      value={searchQuery}
                      onChange={handleInputChange}
                    />
                  </div>

                  <datalist id='search'>
                    {matchingMobileNumbers?.map((number, index) => {
                      return <option key={index} value={number} />
                    })}
                  </datalist>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className='modal fade show ms-15' role='dialog' style={{display: 'block'}}>
          <div className='modal-dialog  modal-xl' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Customer Details</h5>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => {
                    setShowModal(false)
                    setSelectedCustomer(null)
                  }}
                />
              </div>
              <div className='modal-body' style={{overflowY: 'scroll', height: '780px'}}>
                <div className={`card card-flush ${className}`}>
                  {loading ? (
                    <div className='d-flex align-items-center justify-content-center'>
                      <span className={loader.loader}>Loading</span>
                    </div>
                  ) : (
                    <div className='card-body p-5 '>
                      {
                        selectedCustomer ? (
                          <div>
                            <div className=''>
                              <div className='d-flex justify-content-between'>
                                <p className='mb-1'>
                                  <FaUserAlt className='text-gray-500 text-hover-primary fs-4' />
                                  <span className='fs-4 fw-bold ms-2 text-gray-700'>
                                    {selectedCustomer.name}
                                  </span>
                                </p>
                                <p className='fs-6 fw-bold text-gray-700'>
                                  Sales Man: {selectedCustomer.sales_man.name}
                                </p>
                              </div>
                              <div className='d-flex justify-content-between'>
                                <div>
                                  <p className='mb-1'>
                                    <PiPhoneCallFill className='text-gray-500 text-hover-primary' />
                                    <span className='fw-bold ms-2 text-gray-700'>
                                      {selectedCustomer.mobile}
                                    </span>
                                    {selectedCustomer.mobile_2 && (
                                      <span className='fw-bold ms-2 text-gray-700'>
                                        , {selectedCustomer.mobile_2}
                                      </span>
                                    )}
                                    {selectedCustomer.mobile_3 && (
                                      <span className='fw-bold ms-2 text-gray-700'>
                                        , {selectedCustomer.mobile_3}
                                      </span>
                                    )}
                                  </p>
                                  <div className='d-flex gap-10'>
                                    {selectedCustomer.customer_address.map(
                                      (customer_address: Address) => (
                                        <div className='mb-0 '>
                                          <p className='fw-bold text-gray-700 mb-0'>
                                            <MdLocationOn className='text-gray-500 text-hover-primary fs-5 me-2' />
                                            Address : {customer_address.address}
                                            {customer_address.city && (
                                              <p className='mb-0'>
                                                <span className='ms-7'>
                                                  <span className='me-9'>City</span> :
                                                  {customer_address.city}
                                                </span>
                                              </p>
                                            )}
                                            {customer_address.village && (
                                              <p className='mb-0'>
                                                <span className='ms-7'>
                                                  <span className='me-4'>Village</span> :
                                                  {customer_address.village}
                                                </span>
                                              </p>
                                            )}
                                            <p className='mb-0'>
                                              <span className='ms-7'>
                                                <span className='me-3'>District</span> :
                                                {customer_address.district}
                                              </span>
                                            </p>
                                            <p className='mb-0'>
                                              <span className='ms-7'>
                                                <span className='me-7'>State</span> :
                                                {customer_address.state}
                                              </span>
                                            </p>
                                            <p className='mb-0'>
                                              <span className='ms-7'>
                                                <span className='me-2'> Pincode</span> :
                                                {customer_address.pincode}
                                              </span>
                                            </p>
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className='table-responsive'
                              style={{maxHeight: '300px', overflowY: 'scroll'}}
                            >
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                  <tr className='fw-bold text-muted'>
                                    <th className='min-w-100px'>Order Id</th>
                                    <th className='min-w-100px'>Date</th>
                                    <th className='min-w-100px'>Courier Company</th>
                                    <th className='min-w-100px'>Payment Mode</th>
                                    <th className='min-w-100px'>amount</th>
                                    <th className='min-w-100px'>Order Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedCustomer.orders?.map((data: any, i: number) => {
                                    return (
                                      <tr key={i}>
                                        <td>
                                          <p className='mb-0 text-gray-700 fw-bold'>
                                            {data.order_code}
                                          </p>
                                        </td>
                                        <td>
                                          <p className='mb-0 text-success fw-bold'>
                                            {new Date(data.order_date).toLocaleDateString('en-GB')}
                                          </p>
                                        </td>
                                        <td>
                                          <p className='mb-0 text-gray-600 fw-bold'>
                                            {data.courier_provider_id.name}
                                          </p>
                                        </td>
                                        <td>
                                          <p className='mb-0 text-gray-600 fw-bold'>
                                            {data.payment_mode}
                                          </p>
                                        </td>
                                        <td>
                                          <p className='mb-0 text-gray-600 fw-bold'>
                                            {data.total_amount}
                                          </p>
                                        </td>
                                        {data.return_date === null || data.return_date === '' ? (
                                          <td>
                                            <span
                                              className={clsx(
                                                'badge ',
                                                {
                                                  'badge-light-primary':
                                                    data.order_status === 'Pending',
                                                },
                                                {
                                                  'badge-light-success':
                                                    data.order_status === 'Deliver',
                                                },
                                                {
                                                  'badge-light-warning':
                                                    data.order_status === 'On the way',
                                                }
                                              )}
                                            >
                                              {data.order_status}
                                            </span>
                                          </td>
                                        ) : (
                                          <td>
                                            <span className={clsx('badge badge-light-danger')}>
                                              Return
                                            </span>
                                          </td>
                                        )}
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                            <div className='row row-cols-2 border-top pt-5'>
                              <div className='col-6 border-end'>
                                <h5 className='fw-bolder text-gray-600'>Payment History</h5>
                                <div
                                  className='table-responsive'
                                  style={{maxHeight: '300px', overflowY: 'scroll'}}
                                >
                                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                      <tr className='fw-bold text-muted'>
                                        <th className='min-w-100px'>Payment Mode</th>
                                        <th className='min-w-100px'>Order Id</th>
                                        <th className='min-w-100px'>Date</th>
                                        <th className='min-w-100px'>amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {receivePayments?.map((data: any, i: number) => {
                                        return (
                                          <tr key={i}>
                                            <td>
                                              <p className='mb-0 text-gray-600 fw-bold'>
                                                {data.payment_method
                                                  ? data.payment_method.type
                                                  : 'COD'}
                                              </p>
                                            </td>
                                            <td>
                                              <p className='mb-0 text-gray-700 fw-bold'>
                                                {data.order.order_code}
                                              </p>
                                            </td>
                                            <td>
                                              <p className='mb-0 text-success fw-bold'>
                                                {new Date(data.created_at).toLocaleDateString(
                                                  'en-GB'
                                                )}
                                              </p>
                                            </td>
                                            <td>
                                              <p className='mb-0 text-gray-600 fw-bold'>
                                                {data.amount}
                                              </p>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className='col-6'>
                                <h5 className='fw-bolder text-danger'>Return Payment </h5>
                                <div
                                  className='table-responsive'
                                  style={{maxHeight: '300px', overflowY: 'scroll'}}
                                >
                                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                      <tr className='fw-bold text-muted'>
                                        <th className='min-w-100px'>Payment Mode</th>
                                        <th className='min-w-100px'>Order Id</th>
                                        <th className='min-w-100px'>Date</th>
                                        <th className='min-w-100px'>amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {returnPayments?.map((data: any, i: number) => {
                                        return (
                                          <tr key={i}>
                                            <td>
                                              <p className='mb-0 text-gray-600 fw-bold'>
                                                {data.payment_method
                                                  ? data.payment_method.type
                                                  : null}
                                              </p>
                                            </td>
                                            <td>
                                              <p className='mb-0 text-gray-700 fw-bold'>
                                                {data.order.order_code}
                                              </p>
                                            </td>
                                            <td>
                                              <p className='mb-0 text-success fw-bold'>
                                                {new Date(data.created_at).toLocaleDateString(
                                                  'en-GB'
                                                )}
                                              </p>
                                            </td>
                                            <td>
                                              <p className='mb-0 text-gray-600 fw-bold'>
                                                {data.amount}
                                              </p>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          //  searchQuery.length == 10 ?
                          <div className=''>
                            <p className='fs-4 fw-semibold mb-0 mt-5 text-center text-gray-600'>
                              No customers found.
                            </p>
                          </div>
                        )
                        // : (
                        //   ''
                        // )
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomerOrderDetail
