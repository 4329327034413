import React, {useEffect, useMemo, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {ToastContainer} from 'react-toastify'
import {RxAvatar} from 'react-icons/rx'
import TabView from './TabView'
import {CustomerCallHistory} from './CustomerCallsHistory'
import {MdLocationPin} from 'react-icons/md'
import {RiCloseFill} from 'react-icons/ri'
import {VscCallOutgoing} from 'react-icons/vsc'
import {FcCallback} from 'react-icons/fc'
import {CustomerDetailOrders} from './CustomerDetailOrders'
import style from '../../../_metronic/assets/sass/call.module.css'
import {BiBasket} from 'react-icons/bi'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import CustomerDetails from './CustomerDetails'
import CustomerCallCompletion from './CustomerCallCompletion'

const CustomerCallDetail = ({
  orders,
  returnOrders,
  customer,
  complete_followup_calls,
  leaves,
}: any) => {
  const [activeTab, setActiveTab] = useState('orders')
  const [show, setShow] = useState<boolean>(false)
  const [isDND, setIsDND] = useState<boolean>(customer.is_dnd)
  const tabs = [
    {label: 'Orders', path: 'orders', icon: <BiBasket />},
    {label: 'History', path: 'history', icon: <VscCallOutgoing />},
  ]
  const handleShow = () => setShow(true)

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
  }
  const [followupShow, setFollowupShow] = useState(false)

  const handleClose = () => setFollowupShow(false)

  const completeFollowUp = async () => {
    console.log('in handle save', customer)
    const data: boolean = await supabaseData.complete_call(customer.id)
    if (data) window.location.reload()
  }

  // console.log('customer data ->', customerData)

  return (
    <>
      {/* <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <div className='d-flex align-items-center' onClick={handleShow}>
        {customer.name}
      </div>
      <Modal show={show} className={`modal-lg ${style.set_modal}`}>
        <Modal.Header className='d-flex justify-content-space-between'>
          <Modal.Title>Customer detail</Modal.Title>
          <button
            className='border border-none fs-3 text-hover-primary'
            onClick={() => setShow(false)}
          >
            <RiCloseFill />
          </button>
        </Modal.Header>
        <Modal.Body>
          <CustomerDetails customer={customer} setIsDND={setIsDND} isDND={isDND} />
          {!customer.order_id && (
            <div className='d-flex gap-6'>
              <div className='d-flex align-items-center justify-content-end'>
                <button
                  className='btn btn-sm btn-light-primary fw-bold mt-6'
                  onClick={completeFollowUp}
                >
                  Complete Followup
                </button>
              </div>
              <div className='d-flex align-items-center justify-content-end'>
                <button
                  className='btn btn-sm btn-light-primary fw-bold mt-6'
                  onClick={() => setFollowupShow(true)}
                >
                  Add Follow Up Call
                </button>
              </div>
              <CustomerCallCompletion
                customer={customer}
                leaves={leaves}
                show={followupShow}
                handleClose={handleClose}
              />
            </div>
          )}

          <div
            className='border-gray-200 dark:border-gray-700 d-flex '
            style={{
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid ',
            }}
          >
            <TabView tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
            <p className='fs-4 fw-bold text-gray-600'>Sales Man : {customer.sales_man}</p>
          </div>

          <div className='mt-6'>
            {activeTab === 'orders' && (
              <div>
                <CustomerDetailOrders orders={orders} returnOrders={returnOrders} leaves={leaves} />
              </div>
            )}

            {activeTab === 'history' && (
              <CustomerCallHistory
                isDND={isDND}
                complete_followup_calls={complete_followup_calls}
                className='m-0 p-0'
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CustomerCallDetail
