import React, {useState} from 'react'
import {FcCallback} from 'react-icons/fc'
import {RxAvatar} from 'react-icons/rx'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {Customer} from '../../models/customer_models'

const CustomerDetails = ({customer, isDND, setIsDND}: any) => {
  const [localIsDND, setLocalIsDND] = useState(isDND)

  const handleDNDChange = async (e: any) => {
    let schedule_date = new Date().toLocaleDateString()

    if (window.confirm('Sure ?')) {
      let is_dnd = !localIsDND
      setLocalIsDND(is_dnd)

      console.log('customer from customerDetails ->', customer)

      const data: Customer | null = await supabaseData.setDNDStatus(
        customer.customer_id,
        is_dnd,
        schedule_date
      )

      if (data) {
        setIsDND(is_dnd)
        window.location.reload()
      }
    }
  }

  return (
    <div>
      <div className='d-flex justify-content-between '>
        <div className='d-flex'>
          <p className='fs-1 text-primary-emphasis'>
            <RxAvatar style={{width: '50px', height: '50px'}} />
          </p>
          <div>
            <h5 className=' ms-3'>
              <span>
                <b>Name: </b>
              </span>
              {customer.name}
              {/* <span>{customer.is_dnd && <span>DND</span>}</span> */}
            </h5>
            <div className='d-flex'>
              <p className='ms-3 mb-0'>
                <FcCallback className='me-1' />
                <span className='fw-semibold me-2'>
                  <b>Mobile:</b>
                </span>
                <span className='text-gray-800 fw-semibold'>{customer.mobile}</span>
              </p>
            </div>
            {customer.mobile_2 && (
              <div className='d-flex'>
                <p className='ms-3 mb-0'>
                  <FcCallback className='me-1' />
                  <span className='fw-semibold me-2'>
                    <b>Mobile 2:</b>
                  </span>
                  <span className='text-gray-800 fw-semibold'>{customer.mobile_2}</span>
                </p>
              </div>
            )}
            {customer.mobile_3 && (
              <div className='d-flex'>
                <p className='ms-3 mb-0'>
                  <FcCallback className='me-1' />
                  <span className='fw-semibold me-2'>
                    <b>Mobile 3:</b>
                  </span>
                  <span className='text-gray-800 fw-semibold'>{customer.mobile_3}</span>
                </p>
              </div>
            )}
          </div>
        </div>
        <div className='form-check form-switch me-20'>
          <input
            className='form-check-input'
            type='checkbox'
            role='switch'
            id='flexSwitchCheckChecked'
            value={localIsDND}
            onChange={handleDNDChange}
            checked={localIsDND}
          />
          <label className='form-check-label fw-bold fs-5' htmlFor='flexSwitchCheckChecked'>
            DND
          </label>
        </div>
      </div>
    </div>
  )
}

export default React.memo(CustomerDetails)
