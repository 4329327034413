import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
// import {allOrders} from '../AppDataDefine'
import AddReturnOrderDetails from './AddReturnOrderDetails'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
// import {ReturnOrderType} from '../../models/order_models'
import clsx from 'clsx'
import {KTIcon} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import ReturnOrderDetails from './ReturnOrderDetails'
import {ToastContainer} from 'react-toastify'
import {AccountType, CourierCompany} from '../../models/setting_models'
import EditReturnOrder from './EditReturnOrder'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import {MdKeyboardArrowLeft, MdOutlineNavigateNext} from 'react-icons/md'
import Dispatch from '../orders/Dispatch'
import ReturnSheetHistory from './ReturnSheetHistory'

const ReturnOrdersWrapper = () => {
  const [totalReturnOrders, setTotalReturnOrders] = useState<number>(0)
  const [returnOrders, setReturnOrders] = useState<any>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [courier_companies, setCourierCompany] = useState<CourierCompany[] | undefined>()
  const [accounts, setAccounts] = useState<AccountType[] | undefined>()
  const [editOrder, setEditOrder] = useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemsPerPage: number = 50
  const [showReturnSheet, setShowReturnSheet] = useState<boolean>(false)
  const handleSheetModal = () => setShowReturnSheet(!showReturnSheet)

  useEffect(() => {
    getCourierCompanies()
    getAllAccounts()
    getAllReturnOrders(searchQuery)
  }, [])

  const getAllReturnOrders = async (value: string) => {
    const all_orders: any = await supabaseData.getOrders(value, 'return', currentPage)

    if (all_orders) {
      setTotalReturnOrders(all_orders.total_orders)
      setReturnOrders(all_orders.orders)
      setIsLoading(false)
    }
  }

  const getCourierCompanies = async () => {
    const data: CourierCompany[] | undefined = await supabaseData.getAllCourierCompany()
    if (data) setCourierCompany(data)
  }

  const getAllAccounts = async () => {
    const data: AccountType[] | undefined = await supabaseData.getAllAccountTypes()
    if (data) setAccounts(data)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    if (event.target.value.length >= 2 || event.target.value.length === 0) {
      setCurrentPage(1)
      getAllReturnOrders(event.target.value)
      setIsLoading(true)
    }
  }

  const renderPaginationControls = () => {
    const totalPages = Math.ceil(totalReturnOrders / itemsPerPage)

    const goToNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1)
      }
    }

    const goToPrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1)
      }
    }
    const goToPage = (pageNumber: any) => {
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber)
        // Update the URL with the current page number
        // history.push(`/customers?page=${pageNumber}`)
      }
    }

    const renderPageNumbers = () => {
      const pageNumbers = []
      const numToShow = 5
      let start = Math.max(1, currentPage - Math.floor(numToShow / 2))
      let end = Math.min(totalPages, start + numToShow - 1)

      if (end - start + 1 < numToShow) {
        start = Math.max(1, end - numToShow + 1)
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i)
      }

      return pageNumbers.map((pageNumber, index) => (
        <button
          key={index}
          onClick={() => goToPage(pageNumber)}
          className={`btn btn-number btn-sm   ${
            pageNumber === currentPage ? 'bg-primary text-light' : 'bg-light text-dark'
          }`}
        >
          {pageNumber}
        </button>
      ))
    }
    // console.log('TOTAL PAGE -->', totalPages)
    // console.log('CURRENT PAGE -->', currentPage)

    return (
      totalReturnOrders >= 50 && (
        <div className='pagination-controls mx-auto'>
          <div className='d-flex'>
            <button
              className='btn btn-primary btn-sm me-2'
              onClick={goToPrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft /> Prev
            </button>
            <div className='page-numbers'>
              {currentPage > 5 && (
                <button
                  onClick={() => goToPage(1)}
                  className={`btn btn-number btn-sm  ${1 === currentPage ? 'active' : ''}`}
                >
                  1
                </button>
              )}
              {currentPage > 5 && <span>...</span>}
              <span> {renderPageNumbers()}</span>

              {currentPage < totalPages - 3 && <span>...</span>}
              {currentPage < totalPages - 3 && (
                <button
                  onClick={() => goToPage(totalPages)}
                  className={`btn btn-number btn-sm ${totalPages === currentPage ? 'active' : ''}`}
                >
                  {totalPages}
                </button>
              )}
            </div>
            <button
              className='btn btn-primary btn-sm ms-2'
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              Next <MdOutlineNavigateNext />
            </button>
          </div>
        </div>
      )
    )
  }

  const [show, setShow] = useState(false)

  useEffect(() => {
    if (currentPage > 1) {
      setIsLoading(true)
      getAllReturnOrders(searchQuery)
      console.log('from page change')
    }
  }, [currentPage])

  const handleEditOrder = async (order: any) => {
    const payment: any = await supabaseData.getOrderPayment(order.id, 'return')
    // console.log('payment ->', payment)
    if (payment.length !== 0) order.return_payment = payment
    else order.return_payment = null

    setShow(true)
    setEditOrder(order)
  }

  const closeModel = () => {
    setShow(false)
  }
  // console.log('selected Order -->', editOrder)

  const deleteReturnOrder = async (order: any) => {
    if (window.confirm('Are you sure , you want delete order?')) {
      const deleteOrder = {
        return_payment_id: order.return_payment?.id,
        order_id: order.id,
        return_tracking_id: null,
        return_date: null,
        return_courier_provider_id: null,
        return_payment_method: null,
        refund_amount: null,
        is_rto: null,
        items: order.order_items,
      }
      console.log('Order -->', order)
      console.log('DELETE ORDER -->', deleteOrder)

      const data: any = await supabaseData.deleteReturnOrder(deleteOrder)
      if (data) {
        window.location.reload()
      }
    }
  }

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* {isLoading ? (
        <div className={loader.custom_loader_container}>
          {' '}
          <p className={loader.custom_loader}></p>
        </div>
      ) : ( */}
      <div className='card card-xxl-stretch mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>All Return Orders</span>
          </h3>
          <div className='d-flex align-items-center'>
            <div
              className='btn btn-sm btn-light-primary me-3 '
              onClick={() => setShowReturnSheet(true)}
            >
              File History
            </div>

            <Dispatch title={'Return Orders'} />
            <AddReturnOrderDetails courierCompanies={courier_companies} accounts={accounts} />

            <div className='d-flex m-5' style={{justifyContent: 'space-between'}}>
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid w-250px ps-14'
                  placeholder='Search...'
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className={loader.custom_loader_container}>
            <p className={loader.custom_loader}></p>
          </div>
        ) : (
          <>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table table-hover table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-100px'>Order Code</th>
                      <th className='min-w-100px'>Tracking Id</th>
                      <th className='min-w-100px '>Return Type</th>
                      <th className='min-w-140px'>Return Date</th>
                      <th className='min-w-140px'>Order Date</th>
                      <th className='min-w-120px'>Status</th>
                      <th className='min-w-100px '>Customer</th>
                      <th className='min-w-100px '>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {returnOrders.map((order: any) => {
                      return (
                        <tr key={order.id}>
                          <td>
                            <div className='d-flex justify-content-start flex-column text-gray-700 fw-bold'>
                              {order.order_code}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-start flex-column text-gray-700 fw-bold'>
                              {order.tracking_id}
                            </div>
                          </td>
                          <td>
                            <p className='mb-0 text-gray-700 fw-bold'>
                              {order.is_rto ? 'RTO' : 'CUSTOMER'}
                            </p>
                          </td>

                          <td>
                            <div className='d-flex justify-content-start flex-column fw-bold'>
                              {new Date(order.return_date).toLocaleDateString('en-GB')}
                              <span className='text-muted fw-semibold d-block fs-8'>Returned</span>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-start flex-column fw-bold'>
                              {new Date(order.order_date).toLocaleDateString('en-GB')}
                              <span className='text-muted fw-semibold d-block fs-8'>Ordered</span>
                            </div>
                          </td>
                          <td className='text-start'>
                            <span
                              className={clsx(
                                'badge ',
                                {'badge-light-success': order.payment_status === 'Paid'},
                                {
                                  'badge-light-primary':
                                    order.payment_status === 'Pending' || 'RTO Done',
                                }
                              )}
                            >
                              {order.payment_status}
                            </span>
                          </td>
                          <td>
                            <div className='d-flex justify-content-start flex-column fw-bold'>
                              <p className='mb-0'>{order.customers.name}</p>
                              <p className='mb-0 text-gray-600 text-8'>{order.customers.mobile}</p>
                            </div>
                          </td>

                          <td>
                            <div className='d-flex'>
                              {/* <div onClick={() => handleEditOrder(order)}>
                                <EditReturnOrder
                                  editOrder={editOrder}
                                  courierCompanies={courier_companies}
                                  accounts={accounts}
                                />
                              </div> */}
                              <div
                                className='btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm me-1'
                                onClick={() => handleEditOrder(order)}
                              >
                                <KTIcon iconName='pencil' className='fs-3' />
                              </div>
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2'
                                onClick={() => deleteReturnOrder(order)}
                              >
                                <KTIcon iconName='trash' className='fs-3' />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            {renderPaginationControls()}
          </>
        )}
      </div>
      {show && (
        <EditReturnOrder
          editOrder={editOrder}
          courierCompanies={courier_companies}
          accounts={accounts}
          show={show}
          closeModel={closeModel}
        />
      )}
      {showReturnSheet && (
        <ReturnSheetHistory
          showReturnSheet={showReturnSheet}
          setShowReturnSheet={setShowReturnSheet}
        />
      )}
    </>
  )
}

export default ReturnOrdersWrapper
