import React, {useEffect, useState} from 'react'
import {BsEyeFill} from 'react-icons/bs'
import {Modal} from 'react-bootstrap'
import {CgCloseO} from 'react-icons/cg'
import {format} from 'date-fns'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import {RiCloseFill} from 'react-icons/ri'

export const ShowPendingAmountDetails = ({show, handleShow}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [courierCompanyList, setCourierCompanyList] = useState([])
  const prev25Date = new Date()
  prev25Date.setDate(prev25Date.getDate() - 25)
  const prevDate = format(new Date(prev25Date), 'yyyy-MM-dd')

  useEffect(() => {
    getListOfPendingPaymentCourierCompany()
  }, [])

  const getListOfPendingPaymentCourierCompany = async () => {
    const data = await supabaseData.getPendingPaymentsCourierCompany(prevDate)

    if (data) setIsLoading(false)

    setCourierCompanyList(data)
  }

  return (
    <>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='bg-primary-subtle d-flex justify-content-between w-full'>
          <Modal.Title>
            <div>Pending Payments Details</div>
          </Modal.Title>
          <button
            className='border border-none fs-3 text-hover-primary rounded-circle'
            onClick={handleShow}
          >
            <RiCloseFill />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='pt-5'>
            <div className='table-responsive'>
              {isLoading ? (
                <div
                  className='d-flex align-items-center justify-content-center'
                  style={{height: '400px'}}
                >
                  {' '}
                  <p className={loader.custom_loader}></p>
                </div>
              ) : (
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-100px'>Courier Company</th>
                      <th className='min-w-100px'>Total Pending Payments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courierCompanyList.map((data: any, i: number) => {
                      const courierName = Object.keys(data)[0]
                      const {orders, return_orders, cancel_orders} = data[courierName]
                      return (
                        <tr key={i}>
                          <td>
                            <p className='mb-0 text-gray-700 fw-bold'>{data.name}</p>
                          </td>
                          <td>
                            <p className='mb-0 gray-600 fw-bold'>{data.total}</p>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
