import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {User, UserRoles} from '../../models/user_models'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import Select from 'react-select'

interface EditUserProps {
  user: any
  userRoles: any
  salesMen: any
  showEditUser: any
  setShowEditUser: any
  // onUpdateUser: (updatedUser: any) => void
}

const EditUser: React.FC<EditUserProps> = ({
  user,
  userRoles,
  salesMen,
  showEditUser,
  setShowEditUser,
}) => {
  // const [show, setShow] = useState(false)
  // const handleShow = () => setShow(true)
  const [name, setName] = useState(user.name)
  const [email, setEmail] = useState(user.email)
  const [password, setPassword] = useState(user.password)

  const [selectedRole, setSelectedRole] = useState<any>(user.role)
  const [active, setActive] = useState<boolean>(user.status)
  const [role_id, setRoleId] = useState<string>('')
  const [selectedSalesman, setSelectedSalesman] = useState<string>('')

  const [user_roles, setUserRoles] = useState<any>([])
  const [formErrors, setFormErrors] = useState<any>({
    nameField: '',
    emailField: '',
    roleField: '',
  })
  useEffect(() => {
    userSelectedRoles()
  }, [])

  // console.log('USERS --->', user)

  const userSelectedRoles = () => {
    let selectedRoles: any = []
    user.role.forEach((role: any) => {
      selectedRoles.push(role.label)
    })
    setUserRoles(selectedRoles)
  }

  const handleRole = (selectedRole: any) => {
    // const selectedValues = selectedRole.map((option: any) => option.value)
    setSelectedRole(selectedRole)
    setFormErrors({roleField: false})
  }

  const isValidEmail = (email: any) => {
    // Regular expression for a valid email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleRoleType = (e: any) => {
    const selectedRoleValue = e.target.value

    const selectedRoleObj: UserRoles = userRoles.find(
      (role: any) => role.label === selectedRoleValue
    ) as UserRoles

    setSelectedRole(selectedRoleObj?.label)
    setRoleId(selectedRoleObj?.value)
  }

  const handleSubmitData = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!name || !email || !selectedRole) {
      setFormErrors({
        nameField: !name,
        // emailField: !email || !isValidEmail(email),
        emailField: !isValidEmail(email),
        roleField: !selectedRole,
      })
      return
    }
    const updatedUser = {
      // ...user,
      id: user.id,
      name: name,
      email: email,
      password: password,
      role: selectedRole,
      sales_person_id: selectedSalesman ? selectedSalesman : user.role_id,
      user_id: user.user_id,
      status: active,
    }
    // console.log('updatedUser ->', updatedUser)
    const data: boolean = await supabaseData.editUser(updatedUser)
    if (data) {
      // setShowEditUser(false)
      window.location.reload()
    }
  }

  return (
    <>
      {/* <div className='d-flex align-items-center' onClick={handleShow}>
        <KTIcon iconName='pencil' className='fs-3' />
      </div> */}
      <Modal show={showEditUser} className={`modal-md `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={handleSubmitData}>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Name <span className='text-danger'>*</span>{' '}
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='text'
                  className={`
                  ${
                    formErrors.nameField
                      ? 'form-control form-control-solid col-9 border border-2 border-danger rounded'
                      : // : name.length >= 3
                        // ? 'form-control form-control-solid col-9 border border-2 border-success rounded'
                        'form-control form-control-solid col-9'
                  }`}
                  name='name'
                  value={name}
                  onChange={(e: any) => {
                    setName(e.target.value)
                    setFormErrors({nameField: false})
                  }}
                />
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Email <span className='text-danger'>*</span>{' '}
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='email'
                  className={`
                  ${
                    formErrors.emailField
                      ? 'form-control form-control-solid col-9 border border-2 border-danger rounded'
                      : 'form-control form-control-solid col-9'
                  }`}
                  name='email'
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                  placeholder='Enter user email'
                />
                {!isValidEmail(email) && <span className='text-danger'>Enter Valid Emile </span>}
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>Password: </label>
              </div>
              <div className='col-10'>
                <input
                  type='password'
                  className='form-control form-control-solid col-9'
                  name='password'
                  value={password}
                  onChange={(e: any) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Role <span className='text-danger'>*</span>{' '}
                </label>
              </div>
              <div className='col-10'>
                <Select
                  className={`${formErrors.roleField && 'border border-2 border-danger rounded'}`}
                  isMulti
                  options={userRoles}
                  value={selectedRole}
                  onChange={handleRole}
                />
              </div>
            </div>

            <div className='col-12 d-flex mb-3 align-items-center'>
              <div className='col-2'>
                <label className='form-label mt-3'>Status: </label>
              </div>
              <div className='form-check form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  name='status'
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
                <label className='form-check-label fw-bold'>
                  {active ? 'Active' : 'De-active'}
                </label>
              </div>
            </div>
            {user_roles.includes('Sales man') && !active && (
              <div className='col-12 d-flex mb-3 align-items-center'>
                <div className='col-2'>
                  <label className='form-label mt-3'>Sales Man: </label>
                </div>
                <div className='form-check form-switch'>
                  <Select
                    options={salesMen}
                    onChange={(sales_men: any) => setSelectedSalesman(sales_men.value)}
                  />
                </div>
              </div>
            )}

            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setShowEditUser(false)}
                >
                  Close
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditUser
