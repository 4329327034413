export const customersData = [
  {
    value: '1',
    label: 'Ana Simmons',
    mobile: '7885698745',
    date: '2023-08-15',
    email: 'ana@test.com',
    sales_man: 'John Doe',
    is_dnd: true,
    addresses: [
      {
        id: 101,
        address: 'C-20, Singanpur Market, Ved Road',
        city: 'Surat',
        district: 'Surat',
        state: 'Gujarat',
        pincode: '101010',
      },
      {
        id: 102,
        address: 'C-55, Shreeji Market, Harni Road',
        village: 'Lakhnau ',
        district: 'Lakhnau',
        state: 'Uttar Pradesh',
        pincode: '303030',
      },
    ],
  },
  {
    value: '2',
    label: 'Lebron Wayde',
    mobile: '7485968745',
    date: '2023-09-15',
    email: 'Lebron@test.com',
    sales_man: 'Jane Smith',
    is_dnd: false,
    addresses: [
      {
        id: 201,
        address: '212/216, Rang Mahal, Samuel Street',
        city: 'Bhavanager',
        district: 'Bhavanager',
        state: 'Gujarat',
        pincode: '404040',
      },
      {
        id: 202,
        address: 'C-55, Shreeji Market, Harni Road',
        city: 'Lakhnau ',
        district: 'Lakhnau',
        state: 'Uttar Pradesh',
        pincode: '303030',
      },
      {
        id: 203,
        address: '28/1538, Nai Walan',
        city: 'Nashik',
        district: 'Nashik',
        state: 'Maharashtra ',
        pincode: '707070',
      },
    ],
  },
  {
    value: '3',
    label: 'Jessie Clarcson',
    mobile: '8965320014',
    date: '2023-09-20',
    email: 'Jessie@test.com',
    sales_man: 'John Doe',
    is_dnd: true,
    addresses: [
      {
        id: 301,
        address: '28/1538, Nai Walan',
        city: 'Nashik',
        district: 'Nashik',
        state: 'Maharashtra ',
        pincode: '707070',
      },
      {
        id: 302,
        address: '212/216, Rang Mahal, Samuel Street',
        city: 'Bhavanager',
        district: 'Bhavanager',
        state: 'Gujarat',
        pincode: '404040',
      },
    ],
  },
  {
    value: '4',
    label: 'Natali Goodwin',
    mobile: '9898523265',
    date: '2023-09-12',
    email: 'Natali@test.com',
    sales_man: 'John Doe',
    is_dnd: false,
    addresses: [
      {
        id: 401,
        address: '28/1538, Nai Walan Nashik',
        city: 'Mumbai',
        district: 'Nashik',
        state: 'Maharashtra ',
        pincode: '505050',
      },
      {
        id: 402,
        address: 'C-55, Shreeji Market, Harni Road',
        city: 'Lakhnau ',
        district: 'Lakhnau',
        state: 'Uttar Pradesh',
        pincode: '303030',
      },
    ],
  },
  {
    value: '5',
    label: 'Kevin Leonard',
    mobile: '8745120215',
    date: '2023-09-004',
    email: 'Kevin@test.com',
    sales_man: 'Jane Smith',
    is_dnd: true,
    addresses: [
      {
        id: 501,
        address: '28/1538, Nai Walan Nashik',
        city: 'Mumbai',
        district: 'Nashik',
        state: 'Maharashtra ',
        pincode: '505050',
      },
    ],
  },
  {
    value: '6',
    label: 'Emma Smith',
    mobile: '8565478952',
    date: '2023-09-18',
    email: 'Emma@test.com',
    sales_man: 'Jane Smith',
    is_dnd: false,
    addresses: [
      {
        id: 601,
        address: '212/216, Rang Mahal, Samuel Street',
        city: 'Bhavanager',
        district: 'Bhavanager',
        state: 'Gujarat',
        pincode: '404040',
      },
      {
        id: 602,
        address: 'C-55, Shreeji Market, Harni Road',
        city: 'Lakhnau ',
        district: 'Lakhnau',
        state: 'Uttar Pradesh',
        pincode: '303030',
      },
      {
        id: 603,
        address: '28/1538, Nai Walan',
        city: 'Nashik',
        district: 'Nashik',
        state: 'Maharashtra ',
        pincode: '707070',
      },
    ],
  },
  {
    value: '7',
    label: 'Brian Cox',
    mobile: '9696989852',
    date: '2023-08-11',
    email: 'Brian@test.com',
    sales_man: 'John Doe',
    is_dnd: true,
    addresses: [
      {
        id: 701,
        address: 'C-20, Singanpur Market, Ved Road',
        city: 'Surat',
        district: 'Surat',
        state: 'Gujarat',
        pincode: '101010',
      },
      {
        id: 702,
        address: 'C-55, Shreeji Market, Harni Road',
        city: 'Lakhnau ',
        district: 'Lakhnau',
        state: 'Uttar Pradesh',
        pincode: '303030',
      },
    ],
  },
]

//   export const allProductsData =[
//     {
//       value: 1,
//       image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
//       label: 'Dylotop',
//       code:1010,
//       price: 340,
//       // units:1,
//       // total:340
//     },
//     {
//       value: 2,
//       image:
//         'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
//       label: 'Glutone-MD',
//       code:2020,
//       price: 260,
//       // units:1,
//       // total:260
//     },
//     {
//       value: 3,
//       image:
//         'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
//       label: 'Prazivac-600',
//       code:3030,
//       price: 380,
//       // units:1,
//       // total:380
//     },
//   ]

export const returnProductsData = [
  {
    value: 1,
    client: 'Kevin Leonard',
    mobile: '9696989852',
    label: 'Dylotop',
    items: [
      {
        id: 111,
        image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
        name: 'Dylotop',
        code: 1010,
        price: 340,
        units: 8,
        total: 2720,
      },
      {
        id: 222,
        image:
          'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
        name: 'Prazivac-600',
        code: 3030,
        price: 160,
        units: 7,
        total: 1120,
      },
    ],
    total: 3840,
  },
  {
    value: 2,
    client: 'Lebron Wayde',
    mobile: '8965320014',
    label: 'Glutone-MD',
    items: [
      {
        id: 333,
        image:
          'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
        name: 'Glutone-MD',
        code: 2020,
        price: 260,
        units: 6,
        total: 1560,
      },
    ],
    total: 1560,
  },
  {
    value: 3,
    client: 'Brian Cox',
    mobile: '7895024535',
    label: 'Prazivac-600',
    items: [
      {
        id: 444,
        image:
          'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
        name: 'Prazivac-600',
        code: 3030,
        price: 160,
        units: 7,
        total: 1120,
      },
    ],
    total: 1120,
  },
]

export const allOrders = [
  {
    id: '100256sasjls66+6safsaf',
    return_date: '',
    date: '12/02/2023',
    follow_up_date: '2023-09-04',
    status: 'Paid',
    call_status: 'Accepted',
    payment_date: '2023-09-05',
    note: 'Notes...',
    order_status: 'Pending',
    courier_weight: '150 gm',
    dimension: '1 x 2 x 3',
    shipping_charge: 50,
    paid_amount: 0,
    amount: 2720,
    payment_method: '',
    tracking_id: 'EZ726093746IN',
    value: 'EZ726093746IN',
    label: 'EZ726093746IN',
    payment: {amount: '', payment_method: '', transaction_id: ''},
    address_id: 101,
    address: {
      address: 'C-20, Singanpur Market, Ved Road',
      at_post: 'Dabholi',
      village: 'navsari',
      taluka: 'Surat',
      city: null,
      district: 'Surat',
      state: 'Gujarat',
      pincode: '101010',
    },
    addresses: [
      {
        id: 101,
        address: 'C-20, Singanpur Market, Ved Road',
        city: 'Surat',
        district: 'Surat',
        state: 'Gujarat',
        PIN: '101010',
      },
      {
        id: 102,
        address: 'C-55, Shreeji Market, Harni Road',
        city: 'Lakhnau ',
        district: 'Lakhnau',
        state: 'Uttar Pradesh',
        PIN: '303030',
      },
    ],
    customer: {
      id: '1',
      name: 'Ana Simmons',
      mobile: '7885698745',
      mobile2: '8989787800',
      date: '02/07/2023',
      email: 'ana@test.com',
      address: {
        address: 'C-20, Shreeji Market, Harni Road, ',
        city: 'Surat',
        district: 'Surat',
        state: 'Gujarat',
        pincode: '101010',
      },
    },

    items: [
      {
        image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
        name: 'Glutone-MD',
        id: 121212,
        description: 'Dylotop description here',
        gst: 15,
        code: 1010,
        price: 340,
        units: 8,
        total: 2720,
        return_items: 0,
        damage_items: 0,
        refund_amount: 0,
      },
    ],
    product: 'Dylotop',
    orders: '5',
    return: 3,
    damage: 1,
    courier: 'India Post',
    payment_mode: 'Cash On Delivery',
    sales_person: 'Jane Smith',
    barcode: 'CODFH536225',
    created_by: 'Jane Smith',
    updated_by: 'John Deo',
    cancel: 'Ana Smith',
    created_at: '2023-09-13T12:15:34+0000',
    updated_at: '2023-09-13T12:00:23+0000',
    cancel_at: '2023-09-14T12:13:59+0000',
  },

  {
    id: 132060,
    date: '15/05/2023',
    status: 'Paid',
    payment_date: '2023-09-15',
    note: 'Notes...',
    order_status: 'Deliver',
    call_status: 'Rejected',
    follow_up_date: '2023-09-10',
    return_date: '',
    courier_weight: '180 gm',
    dimension: '2 x 3 x 4',
    shipping_charge: 80,
    payment_method: 'Bank Transfer',
    tracking_id: 'BD220012002AD',
    label: 'BD220012002AD',
    value: 'BD220012002AD',
    paid_amount: 2500,
    address_id: 202,
    address: {
      address: '212/216, Rang Mahal, Samuel Street',
      at_post: 'dared',
      taluka: 'Vallbhipur',
      city: 'Vallbhipur',
      village: null,
      district: 'Bhavanager',
      state: 'Gujarat',
      pincode: '364760',
    },
    payment: {amount: '', payment_method: 'Bank Transfer', transaction_id: 'BT12450011'},
    addresses: [
      {
        id: 201,
        address: '212/216, Rang Mahal, Samuel Street',
        city: 'Bhavanager',
        district: 'Bhavanager',
        state: 'Gujarat',
        PIN: '404040',
      },
      {
        id: 202,
        address: 'C-55, Shreeji Market, Harni Road',
        city: 'Lakhnau ',
        district: 'Lakhnau',
        state: 'Uttar Pradesh',
        PIN: '303030',
      },
      {
        id: 203,
        address: '28/1538, Nai Walan',
        city: 'Nashik',
        district: 'Nashik',
        state: 'Maharashtra ',
        PIN: '707070',
      },
    ],
    customer: {
      id: '2',
      name: 'Lebron Wayde',
      mobile: '7485968745',
      date: '05/05/2023',
      email: 'Lebron@test.com',
      address: {
        address: '212/216, Rang Mahal, Samuel Street, ',
        city: 'Ahmedabad',
        district: 'Ahmedabad',
        state: 'Gujarat',
        pincode: '202020',
      },
    },
    items: [
      {
        image:
          'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
        name: 'Glutone-MD',
        description: 'Glutone-MD description here',
        id: 212121,
        gst: 12,
        code: 2020,
        price: 260,
        units: 6,
        total: 1560,
        return_units: 0,
        damage_units: 0,
      },
      {
        image:
          'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
        name: 'Prazivac-600',
        description: 'Prazivac-600 description here',
        id: 222222,
        gst: 12,
        code: 3030,
        price: 160,
        units: 7,
        total: 1120,
        return_units: 2,
        damage_units: 0,
      },
      {
        image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
        name: 'Dylotop',
        description: 'Dylotop description here',
        id: 232323,
        gst: 10,
        code: 1010,
        price: 340,
        units: 8,
        total: 2720,
        return_units: 0,
        damage_units: 0,
        refund_amount: 0,
      },
    ],
    product: 'Glutone-MD',
    orders: '3',
    return: 1,
    damage: 0,
    amount: 5300,
    return_amount: 1360,
    courier: 'Blue Dart',
    payment_mode: 'Other',
    return_payment: 'Pay To Credit Card',
    sales_person: 'Jane Smith',
    barcode: 'ARTFH536535',
    return_items: [
      {
        image:
          'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
        name: 'Glutone-MD',
        description: 'Glutone-MD description here',
        code: 2020,
        price: 260,
        return_units: 2,
        damage_units: 1,
        total: 520,
      },
      {
        image:
          'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
        name: 'Prazivac-600',
        description: 'Prazivac-600 description here',
        code: 3030,
        price: 160,
        return_units: 1,
        damage_units: 1,
        total: 160,
      },
      {
        image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
        name: 'Dylotop',
        description: 'Dylotop description here',
        code: 1010,
        price: 340,
        return_units: 2,
        damage_units: 0,
        total: 680,
      },
    ],
    created_by: 'Ana Smith',
    updated_by: 'John Deo',
    cancel: 'Jane Smith',
    created_at: '2023-09-15T12:52:05+0000',
    updated_at: '2023-09-15T12:47:16+0000',
    cancel_at: '2023-09-16T12:34:52+0000',
  },
  {
    id: '400606sasjls-66+6safsaf-3647',
    date: '23/06/2023',
    status: 'Pending',
    order_status: 'Deliver',
    return_date: '',
    call_status: 'Not Response',
    follow_up_date: '2023-09-15',
    courier_weight: '200 gm',
    dimension: '3 x 4 x 5',
    shipping_charge: 40,
    paid_amount: 3500,
    amount: 340,
    payment_method: 'Pay To Credit Card',
    // tracking_id:"BD220012001UI",
    label: 'BD220012001UI',
    value: 'BD220012001UI',
    payment: {amount: '', payment_method: 'Pay To Credit Card', transaction_id: 'PC12450011'},
    address_id: 501,
    address: {
      address: '28/1538, Nai Walan Nashik',
      at_post: null,
      village: null,
      taluka: 'Mumbai',
      city: 'Mumbai',
      district: 'Nashik',
      state: 'Maharashtra',
      pincode: '505050',
    },
    addresses: [
      {
        id: 501,
        address: '28/1538, Nai Walan Nashik',
        city: 'Mumbai',
        district: 'Nashik',
        state: 'Maharashtra ',
        PIN: '505050',
      },
    ],
    customer: {
      id: '3',
      name: 'Natali Goodwin',
      mobile: '9898523265',
      date: '02/02/2023',
      email: 'Natali@test.com',
      address: {
        address: '212/216, Rang Mahal, Samuel , ',
        city: 'Bhavanager',
        district: 'Bhavanager',
        state: 'Gujarat',
        pincode: '404040',
      },
    },
    items: [
      {
        image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
        name: 'Dylotop',
        description: 'Dylotop description here',
        id: 313131,
        code: 1010,
        price: 340,
        units: 8,
        total: 2720,
        return_items: 0,
        damage_items: 0,
      },
      {
        image:
          'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
        name: 'Prazivac-600',
        description: 'Prazivac-600 description here',
        id: 323232,
        code: 3030,
        price: 160,
        units: 7,
        total: 1120,
        return_units: 0,
        damage_units: 0,
        refund_amount: 0,
      },
    ],
    product: 'Dylotop',
    orders: '5',
    return: 0,
    damage: 0,
    courier: 'E-Cart',
    payment_mode: 'Prepaid',
    sales_person: 'John Doe',
    barcode: 'DF)FH536500',
  },
  {
    id: 152500,
    date: '17/06/2023',
    status: 'Paid',
    payment_date: '2023-09-08',
    note: 'Notes...',
    call_status: 'Accepted',
    follow_up_date: '2023-08-20',
    order_status: 'Return',
    return_date: '2023-08-20',
    courier_weight: '250 gm',
    dimension: '4 x 5 x 6',
    shipping_charge: 60,
    payment_method: 'Pay To Debit Card',
    customer: {
      id: '4',
      name: 'Kevin Leonard',
      mobile: '8745120215',
      date: '15/06/2023',
      email: 'Kevin@test.com',
      address: {
        address: '28/1538, Nai Walan ',
        city: 'Mumbai',
        district: 'Mumbai',
        state: 'Maharashtra ',
        pincode: '505050',
      },
    },
    payment: {amount: '', payment_method: 'Pay To Debit Card', transaction_id: 'BT52452200'},
    return_payment: {
      id: 'BTJ78451200',
      payment_method: 'G Pay',
      amount: 500,
      transaction_id: 'RTNBT45127800',
    },
    items: [
      {
        id: 414141,
        image:
          'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
        name: 'Prazivac-600',
        description: 'Prazivac-600 description here',
        code: 3030,
        price: 160,
        units: 7,
        total: 1120,
        return_units: 2,
        damage_units: 1,
        refund_amount: 520,
      },
      {
        id: 424242,
        image:
          'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
        name: 'Prazivac-600',
        description: 'Prazivac-600 description here',
        code: 3030,
        price: 160,
        units: 5,
        total: 1120,
        return_units: 1,
        damage_units: 0,
        refund_amount: 160,
      },
    ],
    product: 'Prazivac-600',
    orders: '8',
    return: 3,
    damage: 2,
    amount: 1120,
    courier: 'Local Post',
    payment_mode: 'Debit Card',
    sales_person: 'Jane Smith',
    barcode: 'LPOFH536225',
    return_tracking_id: 'RTN18787200ID',
    tracking_id: 'DC1256561200',
    label: 'DC1256561200',
    value: 'DC1256561200',
    is_rto: true,
  },

  {
    id: 120070,
    date: '21/02/2023',
    status: 'Pending',
    order_status: 'Deliver',
    follow_up_date: '2023-08-15',
    return_date: '',
    call_status: 'Accepted',
    courier_weight: '100 gm',
    dimension: '5 x 6 x 7',
    shipping_charge: 40,
    amount: 2760,
    paid_amount: null,
    payment_method: 'Pay To Credit Card',
    customer: {
      id: '5',
      name: 'Brian Cox',
      mobile: '9696989852',
      date: '10/05/2023',
      email: 'Brian@test.com',
      address: {
        address: '28/1538, Nai Walan ',
        city: 'Nashik',
        district: 'Nashik',
        state: 'Maharashtra ',
        pincode: '707070',
      },
    },
    items: [
      {
        image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
        name: 'Dylotop',
        description: 'Dylotop description here',
        id: 515151,
        code: 1010,
        price: 340,
        units: 8,
        total: 2720,
        return_units: 0,
        damage_units: 0,
      },
    ],
    product: 'Dylotop',
    orders: '4',
    return: 1,
    damage: 1,

    courier: 'Blue Dart',
    payment_mode: 'Cash On Delivery',
    sales_person: 'Jane Smith',
    barcode: 'CODFH536225',
    tracking_id: 'COD56561200WQ',
    label: 'COD56561200WQ',
    value: 'COD56561200WQ',
  },
  {
    id: 100256,
    date: '12/02/2023',
    status: 'Pending',
    payment_date: '2023-09-12',
    note: 'Notes...',
    follow_up_date: '2023-08-17',
    call_status: 'Rejected',
    order_status: 'Return',
    return_date: '2023-08-24',
    courier_weight: '120 gm',
    dimension: '6 x 7 x 8',
    shipping_charge: 50,
    payment_method: 'Bank Transfer',
    return_tracking_id: 'RTN12121200ID',
    tracking_id: 'BDH12121200WE',
    label: 'BDH12121200WE',
    value: 'BDH12121200WE',
    payment: {amount: '', payment_method: 'Pay To Credit Card', transaction_id: 'BT45127800'},
    return_payment: {
      id: 'QWE212120200',
      payment_method: 'Pay To Debit Card',
      amount: 400,
      transaction_id: 'RTNBT45127800',
    },
    customer: {
      id: '6',
      name: 'Ana Simmons',
      mobile: '7885698745',
      date: '02/07/2023',
      email: 'ana@test.com',
      address: {
        address: 'C-20, Shreeji Market, Harni Road, ',
        city: 'Surat',
        district: 'Surat',
        state: 'Gujarat',
        pincode: '101010',
      },
    },

    items: [
      {
        id: 616161,
        image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
        name: 'Dylotop',
        description: 'Dylotop description here',
        code: 1010,
        price: 340,
        units: 8,
        total: 2720,
        return_units: 2,
        damage_units: 1,
        refund_amount: 680,
      },
    ],
    product: 'Dylotop',
    orders: '5',
    return: 3,
    damage: 1,
    amount: 1500,
    courier: 'Blue Dart',
    payment_mode: 'Cash On Delivery',
    sales_person: 'John Doe',
    barcode: 'CODFH536225',
    is_rto: false,
  },
]

export const allReturnOrders = [
  // {
  //   id: 'JHBJK152500',
  //   date: '17/006/23',
  //   status: 'Paid',
  //   order_status: "Return",
  //   return_type:'RTO',
  //   customer: {
  //     name: 'Kevin Leonard',
  //     mobile: '8745120215',
  //     date: '15/06/2023',
  //     email: 'Kevin@test.com',
  //     address:{address:"28/1538, Nai Walan ",
  //     city: 'Mumbai',
  //     district:"Mumbai",
  //     state: 'Maharashtra ',
  //     pincode: '505050'}
  //   },
  //   items:[{
  //     image:'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
  //     name:'Prazivac-600',
  //     description:"Prazivac-600 description here",
  //     code:3030,
  //     price: 160,
  //     units:7,
  //     total:1120
  //   }],
  //   return_items:[
  //     {
  //       image:
  //         'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
  //       name:'Glutone-MD',
  //       description:"Glutone-MD description here",
  //       code:2020,
  //       price: 260,
  //       return_items:2,
  //       damage_items:1,
  //       total:520,
  //     }
  //   ],
  //   product: 'Prazivac-600',
  //   orders: '8',
  //   return:3,
  //   damage:2,
  //   amount:1120,
  //   courier:"Local Post",
  //   payment_mode:"Debit Card",
  //   sales_person:"Jane Smith",
  //   barcode:"LPOFH536225" ,
  //   return_amount:1550,
  //   tracking_id:'EZ726093746IN',
  //   value:"EZ726093746IN",
  //   label:'EZ726093746IN',
  // },
  // {
  //   id: 'RFGGF132060',
  //   date: '15/05/2023',
  //   status: 'Paid',
  //   order_status: "Return",
  //   return_type:'Customer',
  //   customer: {
  //     name: 'Lebron Wayde',
  //     mobile: '7485968745',
  //     date: '05/05/2023',
  //     email: 'Lebron@test.com',
  //     address:{address:"212/216, Rang Mahal, Samuel Street, ",
  //     city: 'Ahmedabad',
  //     district:"Ahmedabad",
  //     state: 'Gujarat',
  //     pincode: '202020'},
  //   },
  //     items:[{
  //       image:
  //         'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
  //       name:'Glutone-MD',
  //       description:"Glutone-MD description here",
  //       code:2020,
  //       price: 260,
  //       units:6,
  //       total:1560,
  //       return_items:3,
  //       damage_items:1,
  //     },
  //     {
  //       id: 152500,
  //       date: '17/06/2023',
  //       status: 'Paid',
  //       payment_date:'2023-09-08',
  //       note:'Notes...',
  //       follow_up_date:'2023-08-20',
  //       order_status: "Return",
  //       return_date:'2023-08-20',
  //       courier_weight:'250 gm',
  //       dimension:'4 x 5 x 6',
  //       shipping_charge:60,
  //       payment_method:'Pay To Debit Card',
  //       customer: {
  //         id:'4',
  //         name: 'Kevin Leonard',
  //         mobile: '8745120215',
  //         date: '15/06/2023',
  //         email: 'Kevin@test.com',
  //         address:{address:"28/1538, Nai Walan ",
  //         city: 'Mumbai',
  //         district:"Mumbai",
  //         state: 'Maharashtra ',
  //         pincode: '505050'}
  //       },
  //       payment:{amount:"",payment_method:"Pay To Debit Card", transaction_id:"BT52452200"},
  //       return_payment:{id:'BTJ78451200',payment_method:'G Pay', amount:500, transaction_id:"RTNBT45127800"},
  //       items:[{
  //         id:414141,
  //         image:'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
  //         name:'Prazivac-600',
  //         description:"Prazivac-600 description here",
  //         code:3030,
  //         price: 160,
  //         units:7,
  //         total:1120,
  //         return_units:2,
  //         damage_units:1,
  //         refund_amount:520,
  //       },
  //       {
  //         id:424242,
  //         image:'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
  //         name:'Prazivac-600',
  //         description:"Prazivac-600 description here",
  //         code:3030,
  //         price: 160,
  //         units:5,
  //         total:1120,
  //         return_units:1,
  //         damage_units:0,
  //         refund_amount:160,
  //       }],
  //       product: 'Prazivac-600',
  //       orders: '8',
  //       return:3,
  //       damage:2,
  //       amount:1120,
  //       courier:"Local Post",
  //       payment_mode:"Debit Card",
  //       sales_person:"Jane Smith",
  //       barcode:"LPOFH536225",
  //       return_tracking_id:"RTN18787200ID",
  //       tracking_id:"DC1256561200",
  //       label:"DC1256561200",
  //       value:"DC1256561200",
  //       is_rto:true
  //     },
  //     {
  //       id: 120070,
  //       date: '21/02/2023',
  //       status: 'Pending',
  //       order_status: "Deliver",
  //       follow_up_date:'2023-08-15',
  //       return_date:'',
  //       courier_weight:'100 gm',
  //       dimension:'5 x 6 x 7',
  //       shipping_charge:40,
  //       amount:2760,
  //       paid_amount:null,
  //       payment_method:'Pay To Credit Card',
  //       customer: {
  //         id:'5',
  //         name: 'Brian Cox',
  //         mobile: '9696989852',
  //         date: '10/05/2023',
  //         email: 'Brian@test.com',
  //         address:{address:"28/1538, Nai Walan ",
  //         city: 'Nashik',
  //         district:"Nashik",
  //         state: 'Maharashtra ',
  //         pincode: '707070'}
  //       },
  //         items:[{
  //           image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
  //           name:'Dylotop',
  //           description:"Dylotop description here",
  //           id:515151,
  //           code:1010,
  //           price: 340,
  //           units:8,
  //           total:2720,
  //           return_units:0,
  //           damage_units:0,
  //         }],
  //       product: 'Dylotop',
  //       orders: '4',
  //       return:1,
  //       damage:1,
  //       courier:"Blue Dart",
  //       payment_mode:"Cash On Delivery",
  //       sales_person:"Jane Smith",
  //       barcode:"CODFH536225" ,
  //       tracking_id:"COD56561200WQ",
  //       label:"COD56561200WQ",
  //       value:"COD56561200WQ",
  //     },
  //     {
  //       id: 100256,
  //       date: '12/02/2023',
  //       status: 'Paid',
  //       payment_date:'2023-09-12',
  //       note:'Notes...',
  //       follow_up_date:'2023-08-17',
  //       order_status: "Return",
  //       return_date:'2023-08-24',
  //       courier_weight:'120 gm',
  //       dimension:'6 x 7 x 8',
  //       shipping_charge:50,
  //       payment_method:'Bank Transfer',
  //       return_tracking_id:"RTN12121200ID",
  //       tracking_id:"BDH12121200WE",
  //       label:"BDH12121200WE",
  //       value:"BDH12121200WE",
  //       payment:{amount:"",payment_method:"Pay To Credit Card", transaction_id:"BT45127800"},
  //       return_payment:{id:'QWE212120200',payment_method:'Pay To Debit Card', amount:400, transaction_id:'RTNBT45127800' },
  //       customer: {
  //         id:'6',
  //         name: 'Ana Simmons',
  //         mobile: '7885698745',
  //         date: '02/07/2023',
  //         email: 'ana@test.com',
  //         address:{address:"C-20, Shreeji Market, Harni Road, ",
  //         city: 'Surat',
  //         district:'Surat',
  //         state: 'Gujarat',
  //         pincode: '101010'}
  //       },
  //       items:[{
  //         id:616161,
  //         image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
  //         name:'Dylotop',
  //         description:"Dylotop description here",
  //         code:1010,
  //         price: 340,
  //         units:8,
  //         total:2720,
  //         return_units:2,
  //         damage_units:1,
  //         refund_amount:680,
  //       }],
  //       product: 'Dylotop',
  //       orders: '5',
  //       return:3,
  //       damage:1,
  //       amount:1500,
  //       courier:"Blue Dart",
  //       payment_mode:"Cash On Delivery",
  //       sales_person:"John Doe",
  //       barcode:"CODFH536225",
  //       is_rto:false
  //     },
  //   ]
  //   export const allReturnOrders = [
  //     // {
  //     //   id: 'JHBJK152500',
  //     //   date: '17/006/23',
  //     //   status: 'Paid',
  //     //   order_status: "Return",
  //     //   return_type:'RTO',
  //     //   customer: {
  //     //     name: 'Kevin Leonard',
  //     //     mobile: '8745120215',
  //     //     date: '15/06/2023',
  //     //     email: 'Kevin@test.com',
  //     //     address:{address:"28/1538, Nai Walan ",
  //     //     city: 'Mumbai',
  //     //     district:"Mumbai",
  //     //     state: 'Maharashtra ',
  //     //     pincode: '505050'}
  //     //   },
  //     //   items:[{
  //     //     image:'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
  //     //     name:'Prazivac-600',
  //     //     description:"Prazivac-600 description here",
  //     //     code:3030,
  //     //     price: 160,
  //     //     units:7,
  //     //     total:1120
  //     //   }],
  //     //   return_items:[
  //     //     {
  //     //       image:
  //     //         'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
  //     //       name:'Glutone-MD',
  //     //       description:"Glutone-MD description here",
  //     //       code:2020,
  //     //       price: 260,
  //     //       return_items:2,
  //     //       damage_items:1,
  //     //       total:520,
  //     //     }
  //     //   ],
  //     //   product: 'Prazivac-600',
  //     //   orders: '8',
  //     //   return:3,
  //     //   damage:2,
  //     //   amount:1120,
  //     //   courier:"Local Post",
  //     //   payment_mode:"Debit Card",
  //     //   sales_person:"Jane Smith",
  //     //   barcode:"LPOFH536225" ,
  //     //   return_amount:1550,
  //     //   tracking_id:'EZ726093746IN',
  //     //   value:"EZ726093746IN",
  //     //   label:'EZ726093746IN',
  //     // },
  //     // {
  //     //   id: 'RFGGF132060',
  //     //   date: '15/05/2023',
  //     //   status: 'Paid',
  //     //   order_status: "Return",
  //     //   return_type:'Customer',
  //     //   customer: {
  //     //     name: 'Lebron Wayde',
  //     //     mobile: '7485968745',
  //     //     date: '05/05/2023',
  //     //     email: 'Lebron@test.com',
  //     //     address:{address:"212/216, Rang Mahal, Samuel Street, ",
  //     //     city: 'Ahmedabad',
  //     //     district:"Ahmedabad",
  //     //     state: 'Gujarat',
  //     //     pincode: '202020'},
  //     //   },
  //     //     items:[{
  //     //       image:
  //     //         'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
  //     //       name:'Glutone-MD',
  //     //       description:"Glutone-MD description here",
  //     //       code:2020,
  //     //       price: 260,
  //     //       units:6,
  //     //       total:1560,
  //     //       return_items:3,
  //     //       damage_items:1,
  //     //     },
  //     //     {
  //     //       image:'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
  //     //       name:'Prazivac-600',
  //     //       description:"Prazivac-600 description here",
  //     //       code:3030,
  //     //       price: 160,
  //     //       units:7,
  //     //       total:1120,
  //     //       return_items:2,
  //     //     damage_items:0,
  //     //     },
  //     //     {
  //     //       image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
  //     //       name:'Dylotop',
  //     //       description:"Dylotop description here",
  //     //       code:1010,
  //     //       price: 340,
  //     //       units:8,
  //     //       total:2720,
  //     //       return_items:2,
  //     //     damage_items:1,
  //     //     }
  //     //   ],
  //     //   tracking_id:"BD220012001UI",
  //     //   label:"BD220012001UI",
  //     //   value:"BD220012001UI",
  //     //   product: 'Glutone-MD',
  //     //   orders: '3',
  //     //   return:1,
  //     //   damage:0,
  //     //   amount:5300,
  //     //   return_amount:1360,
  //     //   courier:"Blue Dart",
  //     //   payment_mode:"Credit Card",
  //     //   return_payment:"Pay To Credit Card",
  //     //   sales_person:"Jane Smith",
  //     //   barcode:"ARTFH536535",
  //     //   return_items:[
  //     //     {
  //     //       image:
  //     //         'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
  //     //       name:'Glutone-MD',
  //     //       description:"Glutone-MD description here",
  //     //       code:2020,
  //     //       price: 260,
  //     //       return_items:2,
  //     //       damage_items:1,
  //     //       total:520,
  //     //     },
  //     //     {
  //     //       image:'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
  //     //       name:'Prazivac-600',
  //     //       description:"Prazivac-600 description here",
  //     //       code:3030,
  //     //       price: 160,
  //     //       return_items:1,
  //     //       damage_items:1,
  //     //       total:160,
  //     //     },
  //     //     {
  //     //       image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
  //     //       name:'Dylotop',
  //     //       description:"Dylotop description here",
  //     //       code:1010,
  //     //       price: 340,
  //     //       return_items:2,
  //     //       damage_items:0,
  //     //       total:680,
  //     //     }
  //     //   ]
  //     // },
  //     // {
  //     //   id: 'UIJKN152500',
  //     //   date: '17/006/23',
  //     //   status: 'Paid',
  //     //   order_status: "Return",
  //     //   return_type:'RTO',
  //     //   customer: {
  //     //     name: 'Kevin Leonard',
  //     //     mobile: '8745120215',
  //     //     date: '15/06/2023',
  //     //     email: 'Kevin@test.com',
  //     //     address:{address:"28/1538, Nai Walan ",
  //     //     city: 'Mumbai',
  //     //     district:"Mumbai",
  //     //     state: 'Maharashtra ',
  //     //     pincode: '505050'}
  //     //   },
  //     //   items:[{
  //     //     image:'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
  //     //     name:'Prazivac-600',
  //     //     description:"Prazivac-600 description here",
  //     //     code:3030,
  //     //     price: 160,
  //     //     units:7,
  //     //     total:1120
  //     //   }],
  //     //   return_items:[
  //     //     {
  //     //       image:
  //     //         'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
  //     //       name:'Glutone-MD',
  //     //       description:"Glutone-MD description here",
  //     //       code:2020,
  //     //       price: 260,
  //     //       return_items:2,
  //     //       damage_items:1,
  //     //       total:520,
  //     //     }
  //     //   ],
  //     //   product: 'Prazivac-600',
  //     //   orders: '8',
  //     //   return:3,
  //     //   damage:2,
  //     //   amount:1120,
  //     //   courier:"Local Post",
  //     //   payment_mode:"Debit Card",
  //     //   sales_person:"Jane Smith",
  //     //   barcode:"LPOFH536225" ,
  //     //   return_amount:1700,
  //     //   tracking_id:"DC1256561200",
  //     //   label:"DC1256561200",
  //     //   value:"DC1256561200",
  // },
  // {
  //   id: 'POJKL132060',
  //   date: '15/05/23',
  //   status: 'Paid',
  //   order_status: "Return",
  //   return_type:'Customer',
  //   tracking_id:"BD220012002AD",
  //   label:"BD220012002AD",
  //   value:"BD220012002AD",
  //   customer: {
  //     name: 'Lebron Wayde',
  //     mobile: '7485968745',
  //     date: '05/05/2023',
  //     email: 'Lebron@test.com',
  //     address:{address:"212/216, Rang Mahal, Samuel Street, ",
  //     city: 'Ahmedabad',
  //     district:"Ahmedabad",
  //     state: 'Gujarat',
  //     pincode: '202020'},
  //   },
  //     items:[{
  //       image:
  //         'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
  //       name:'Glutone-MD',
  //       description:"Glutone-MD description here",
  //       code:2020,
  //       price: 260,
  //       units:6,
  //       total:1560,
  //       return_items:3,
  //       damage_items:1,
  //     },
  //     {
  //       image:'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
  //       name:'Prazivac-600',
  //       description:"Prazivac-600 description here",
  //       code:3030,
  //       price: 160,
  //       units:7,
  //       total:1120,
  //       return_items:2,
  //     damage_items:0,
  //     },
  //     {
  //       image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
  //       name:'Dylotop',
  //       description:"Dylotop description here",
  //       code:1010,
  //       price: 340,
  //       units:8,
  //       total:2720,
  //       return_items:2,
  //     damage_items:1,
  //     }
  //   ],
  //   product: 'Glutone-MD',
  //   orders: '3',
  //   return:1,
  //   damage:0,
  //   amount:5300,
  //   return_amount:1220,
  //   courier:"Blue Dart",
  //   payment_mode:"Credit Card",
  //   return_payment:"Pay To Credit Card",
  //   sales_person:"Jane Smith",
  //   barcode:"ARTFH536535",
  //   return_items:[
  //     {
  //       image:
  //         'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
  //       name:'Glutone-MD',
  //       description:"Glutone-MD description here",
  //       code:2020,
  //       price: 260,
  //       return_items:2,
  //       damage_items:1,
  //       total:520,
  //     },
  //     {
  //       image:'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
  //       name:'Prazivac-600',
  //       description:"Prazivac-600 description here",
  //       code:3030,
  //       price: 160,
  //       return_items:1,
  //       damage_items:1,
  //       total:160,
  //     },
  //     {
  //       image: 'https://4.imimg.com/data4/GV/YY/MY-10586305/dylotop.jpg',
  //       name:'Dylotop',
  //       description:"Dylotop description here",
  //       code:1010,
  //       price: 340,
  //       return_items:2,
  //       damage_items:0,
  //       total:680,
  //     }
  //   ]
  // }
]

export const allProducts = [
  {
    id: '1',
    image: 'https://mydrxm.com/cdn/shop/products/uvodn_580x.jpg?v=1582484645',
    name: 'Gamma-Trimethyl-Beta-Acetylbutyrobetaine',
    description: 'Dylotop description here',
    price: '245',
    unit: '150 gm',
    current_available_stock: 50,
    code: 'GTBA12457802',
    stock: 50,
    GST: 10,
    is_combo: false,
    unit_type_id: 'gm',
  },
  {
    id: '2',
    image:
      'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
    name: 'L-beta-hydroxy-γ-trimethyl-amino-butyric acid',
    description: 'Prazivac-600 description here',
    unit: '30 Pills',
    price: '285',
    current_available_stock: 28,
    code: 'GMDH12457805',
    stock: 28,
    GST: 12,
    is_combo: false,
    unit_type_id: 'kg',
  },
  {
    id: '3',
    image:
      'https://www.glutone.in/cdn/shop/products/Glutone-MD---Orally-Dissolving-Mini-Effervescent-Glutathione-Tablets-Glutone-1654109104.jpg?v=1657653532',
    name: 'Trimethyl-amino-butyric acid',
    description: 'Glutone-MD description here',
    unit: '10 Pills',
    price: '255',
    current_available_stock: 10,
    code: 'TAMC12457805',
    stock: 48,
    GST: 12,
    is_combo: false,
    unit_type_id: 'kg',
  },
  {
    id: '4',
    image:
      'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
    name: 'N-Acetyl-Carnitine Hydrochloride',
    description: 'Prazivac-600 description here',
    unit: '15',
    price: '350',
    code: 'NACS12457808',
    stock: 58,
    GST: 15,
    products: [
      {id: '2', name: 'L-beta-hydroxy-γ-trimethyl-amino-butyric acid', current_available_stock: 28},
      {id: '1', name: 'Gamma-Trimethyl-Beta-Acetylbutyrobetaine', current_available_stock: 50},
    ],
    is_combo: true,
    unit_type_id: 'pills',
  },
  {
    id: '5',
    image:
      'https://5.imimg.com/data5/SELLER/Default/2022/5/BK/AP/XL/25167998/praziquantel-600-mg.jpg',
    name: 'Anti-Inhibitor Coagulant Complex (FEIBA)',
    description: 'Prazivac-600 description here',
    unit: '10',
    price: '120',
    code: 'AICCF12457808',
    stock: 58,
    GST: 15,
    products: [
      {id: '2', name: 'L-beta-hydroxy-γ-trimethyl-amino-butyric acid', current_available_stock: 28},
      {id: '1', name: 'Gamma-Trimethyl-Beta-Acetylbutyrobetaine', current_available_stock: 50},
      {id: '3', name: 'Trimethyl-amino-butyric acid', current_available_stock: 10},
    ],
    is_combo: true,
    unit_type_id: 'gm',
  },
]

export const users = [
  {
    id: '8d12a303-f22e-4a8d-8f54-a89ebaca4a2b',
    name: 'abc',
    email: 'abc@test.com',
    status: true,
    role: '084f1752-bb43-496f-81bb-051ffc7eb352',
    user_id: 'd1a773ed-ef91-47d7-95f5-1f28554f4862',
    created_at: '2023-08-05T05:53:22.874572+00:00',
    updated_at: '2023-08-05T05:53:22.874572+00:00',
    role_type: {
      name: 'Admin',
    },
  },
  {
    id: 'ce22ee1e-26be-40f0-a679-04ad50901d95',
    name: 'darshan',
    email: 'darshan@test.com',
    status: true,
    role: '084f1752-bb43-496f-81bb-051ffc7eb352',
    user_id: '50e0c22a-7b00-4304-8585-e9921ea97d9b',
    created_at: '2023-08-05T06:00:12.44034+00:00',
    updated_at: '2023-08-05T06:00:12.44034+00:00',
    role_type: {
      name: 'Sub Admin',
    },
  },
  {
    id: '3c13991a-86a5-4f12-91bb-92d698d3e6ad',
    name: 'xyz',
    email: 'xyz@test.com',
    status: true,
    role: 'b777a300-34e4-4678-b52d-aac974af9d7d',
    user_id: '015b407e-cdcf-4ee5-a347-1b794588e7e1',
    created_at: '2023-08-07T05:27:23.657345+00:00',
    updated_at: '2023-08-07T05:27:23.657345+00:00',
    role_type: {
      name: 'caller',
    },
  },
  {
    id: 'dee3b2e7-f553-49ef-a85c-1edef83a6275',
    name: 'pqr',
    email: 'pqr@test.com',
    status: true,
    role: 'c284b82e-8d9f-488b-9df2-f13d5679b953',
    user_id: '594b4e93-dc35-42b9-a3d4-d0704ce6752c',
    created_at: '2023-08-07T05:27:49.755188+00:00',
    updated_at: '2023-08-07T05:27:49.755188+00:00',
    role_type: {
      name: 'Order Manager',
    },
  },
]

export const allUsers = [
  {
    id: '8d12a303-f22e-4a8d-8f54-a89ebaca4a2b',
    name: 'abc',
    email: 'abc@test.com',
    status: true,
    role: '084f1752-bb43-496f-81bb-051ffc7eb352',
    user_id: 'd1a773ed-ef91-47d7-95f5-1f28554f4862',
    created_at: '2023-08-05T05:53:22.874572+00:00',
    updated_at: '2023-08-05T05:53:22.874572+00:00',
    role_type: {
      name: 'order_manager',
    },
  },
  {
    id: 'ce22ee1e-26be-40f0-a679-04ad50901d95',
    name: 'darshan',
    email: '',
    status: true,
    role: '084f1752-bb43-496f-81bb-051ffc7eb352',
    user_id: '50e0c22a-7b00-4304-8585-e9921ea97d9b',
    created_at: '2023-08-05T06:00:12.44034+00:00',
    updated_at: '2023-08-05T06:00:12.44034+00:00',
    role_type: {
      name: 'order_manager',
    },
  },
  {
    id: '3c13991a-86a5-4f12-91bb-92d698d3e6ad',
    name: 'xyz',
    email: 'xyz@test.com',
    status: true,
    role: 'b777a300-34e4-4678-b52d-aac974af9d7d',
    user_id: '015b407e-cdcf-4ee5-a347-1b794588e7e1',
    created_at: '2023-08-07T05:27:23.657345+00:00',
    updated_at: '2023-08-07T05:27:23.657345+00:00',
    role_type: {
      name: 'Sales Man',
    },
  },
  {
    id: 'dee3b2e7-f553-49ef-a85c-1edef83a6275',
    name: 'pqr',
    email: 'pqr@test.com',
    status: true,
    role: 'c284b82e-8d9f-488b-9df2-f13d5679b953',
    user_id: '594b4e93-dc35-42b9-a3d4-d0704ce6752c',
    created_at: '2023-08-07T05:27:49.755188+00:00',
    updated_at: '2023-08-07T05:27:49.755188+00:00',
    role_type: {
      name: 'Sales Man',
    },
  },
]

export const allPayments = [
  {
    order_id: 'IJK123456',
    payment: 'G-Pay',
    amount: 1200,
    note: 'Payment Contrary to popular belief, Lorem Ipsum is not simply random text.',
    date: '10/07/23',
  },
  {
    order_id: 'ABC123456',
    payment: 'Bank Transfer',
    amount: 900,
    note: 'Payment Contrary to popular belief, Lorem Ipsum is not simply random text.',
    date: '15/05/23',
  },
  {
    order_id: 'XYZ123456',
    payment: 'G-Pay',
    amount: 1200,
    note: 'Payment Contrary to popular belief, Lorem Ipsum is not simply random text.',
    date: '1/07/23',
  },
  {
    order_id: 'PQR123456',
    payment: 'Pay To Credit Card',
    amount: 1400,
    note: 'Contrary to popular belief, Lorem Ipsum is not simply random text.',
    date: '5/06/23',
  },
  {
    order_id: 'ASD123456',
    payment: 'Pay To Credit Card',
    amount: 200,
    note: 'Payment Contrary to popular belief, Lorem Ipsum is not simply random text.',
    date: '19/04/23',
  },
  {
    order_id: 'QWD123456',
    payment: 'Bank Transfer',
    amount: 1900,
    note: 'Payment Contrary to popular belief, Lorem Ipsum is not simply random text.',
    date: '1/08/23',
  },
  {
    order_id: 'ASR123456',
    payment: 'Pay To Debit Card',
    amount: 1500,
    note: 'Contrary to popular belief, Lorem Ipsum is not simply random text.',
    date: '8/08/23',
  },
]

export const allLeaves = ['2023-08-21', '2023-08-25', '2023-08-28']

export const callHistory = [
  {
    note: 'This is my note content.',
    call_Status: 'Accepted Call',
    created_at: '10/08/2023',
    order_id: 'ABC13254646',
    users: {id: '1', name: 'Yogesh Diyora'},
  },
  {
    note: 'This is my note content.',
    call_Status: 'Accepted Call',
    created_at: '12/08/2023',
    order_id: 'ABC13254646',
    users: {id: '2', name: 'Darshan Sangani'},
  },
  {
    note: 'This is my note content.',
    call_Status: 'Rejected Call',
    created_at: '14/08/2023',
    order_id: 'ABC13254646',
    users: {id: '1', name: 'Yogesh Diyora'},
  },
  {
    note: 'This is my note content.',
    call_Status: 'Accepted Call',
    created_at: '16/08/2023',
    order_id: 'ABC13254646',
    users: {id: '2', name: 'Darshan Sangani'},
  },
  {
    note: 'This is my note content.',
    call_Status: 'Not Response Call',
    created_at: '18/08/2023',
    order_id: 'ABC13254646',
    users: {id: '1', name: 'Yogesh Diyora'},
  },
]

export const dimention = [
  {value: '1 x 2 x 3', label: '1 x 2 x 3'},
  {value: '2 x 3 x 4', label: '2 x 3 x 4'},
  {value: '3 x 4 x 5', label: '3 x 4 x 5'},
  {value: '4 x 5 x 6', label: '4 x 5 x 6'},
  {value: '5 x 6 x 7', label: '5 x 6 x 7'},
  {value: '6 x 7 x 8', label: '6 x 7 x 8'},
]

export const leaves = [
  {
    start: new Date(2023, 8, 14),
    end: new Date(2023, 8, 11),
    title: 'Vacation',
  },
  {
    start: new Date(2023, 8, 15),
    end: new Date(2023, 8, 15),
    title: 'New Event',
  },
  {
    start: new Date(2023, 8, 16),
    end: new Date(2023, 8, 17),
    title: 'Hello',
  },
]

export const couriers = [
  {
    date: '2023-09-05',
    couriersOrder: [
      {courier_company: 'India Post', order: 25, return: 5},
      {courier_company: 'Blue Dart', order: 35, return: 7},
      {courier_company: 'E-Cart', order: 22, return: 3},
      {courier_company: 'Local Post', order: 15, return: 2},
    ],
  },
  {
    date: '2023-09-13',
    couriersOrder: [
      {courier_company: 'India Post', order: 25, return: 5},
      {courier_company: 'Blue Dart', order: 35, return: 7},
    ],
  },
]

export const paymentCouriers = [
  {
    date: '2023-09-13',
    couriersOrder: [
      {courier_company: 'India Post', cod: 2500, pripaid: 5000},
      {courier_company: 'Blue Dart', order: 3500, return: 7000},
      {courier_company: 'E-Cart', order: 2200, return: 300},
      {courier_company: 'Local Post', order: 1500, return: 2000},
    ],
  },
  {
    date: '2023-09-01',
    couriersOrder: [
      {courier_company: 'India Post', order: 2500, return: 5000},
      {courier_company: 'Blue Dart', order: 3500, return: 7000},
    ],
  },
]

export const customerDetails = [
  {
    customer: {name: 'Olivia Parker', mobile: '9898989898'},
    address: {
      address: 'C-20, Singanpur Market, Ved Road',
      city: 'Surat',
      district: 'Surat',
      state: 'Gujarat',
      PIN: '101010',
    },
    sales_man: 'Jane Smith',
    orders: [
      {
        order_id: 'ABC4543344343',
        date: '25/08/2023',
        courier: 'India Post',
        payment_mode: 'Cash On Delivery',
        amount: 1800,
        order_status: 'Pending',
      },
      {
        order_id: 'SAF4543879343',
        date: '30/08/2023',
        courier: 'Blue Dart',
        payment_mode: 'Prepaid',
        amount: 2300,
        order_status: 'Deliver',
      },
      {
        order_id: 'IUT4852044343',
        date: '05/09/2023',
        courier: 'Local Post',
        payment_mode: 'Cash On Delivery',
        amount: 2800,
        order_status: 'Return',
      },
      {
        order_id: 'LKJ4852044343',
        date: '10/09/2023',
        courier: 'E-Cart',
        payment_mode: 'Prepaid',
        amount: 2000,
        order_status: 'On the way',
      },
    ],
    payment_history: [
      {
        payment_mode: 'Cash On Delivery',
        order_id: 'ABC4543344343',
        date: '25/08/2023',
        amount: 1800,
      },
      {payment_mode: 'Prepaid', order_id: 'SAF4543879343', date: '30/08/20023', amount: 2300},
      {
        payment_mode: 'Cash On Delivery',
        order_id: 'IUT4852044343',
        date: '05/09/2023',
        amount: 2800,
      },
      {payment_mode: 'Prepaid', order_id: 'LKJ4852044343', date: '10/09/2023', amount: 2000},
    ],
    return_payment: [
      {payment_mode: 'Prepaid', order_id: 'LKJ4852044343', date: '10/09/2023', amount: 2000},
    ],
  },
  {
    customer: {name: 'Ana Parker', mobile: '9898909090'},
    address: {
      address: 'C-55, Shreeji Market, Harni Road',
      city: 'Lakhnau ',
      district: 'Lakhnau',
      state: 'Uttar Pradesh',
      PIN: '303030',
    },
    sales_man: 'John Deo',
    orders: [
      {
        order_id: 'ABC4543344343',
        date: '25/08/2023',
        courier: 'India Post',
        payment_mode: 'Cash On Delivery',
        amount: 1800,
        order_status: 'Pending',
      },
      {
        order_id: 'SAF4543879343',
        date: '30/08/2023',
        courier: 'Blue Dart',
        payment_mode: 'Prepaid',
        amount: 2300,
        order_status: 'Deliver',
      },
      {
        order_id: 'IUT4852044343',
        date: '05/09/2023',
        courier: 'Local Post',
        payment_mode: 'Cash On Delivery',
        amount: 2800,
        order_status: 'Return',
      },
      {
        order_id: 'LKJ4852044343',
        date: '10/09/2023',
        courier: 'E-Cart',
        payment_mode: 'Prepaid',
        amount: 2000,
        order_status: 'On the way',
      },
    ],
    payment_history: [
      {
        payment_mode: 'Cash On Delivery',
        order_id: 'ABC4543344343',
        date: '25/08/2023',
        amount: 1800,
      },
      {payment_mode: 'Prepaid', order_id: 'SAF4543879343', date: '30/08/20023', amount: 2300},
      {
        payment_mode: 'Cash On Delivery',
        order_id: 'IUT4852044343',
        date: '05/09/2023',
        amount: 2800,
      },
      {payment_mode: 'Prepaid', order_id: 'LKJ4852044343', date: '10/09/2023', amount: 2000},
    ],
    return_payment: [
      {payment_mode: 'Prepaid', order_id: 'LKJ4852044343', date: '10/09/2023', amount: 2000},
    ],
  },
]

export const allExpanse = [
  {tracking_id: 'ADS1245780AZ', type: 'COD', amount:120, re_mark:'CD'},
  {tracking_id: 'WER25458200A', type: 'Booking', amount:50, re_mark:''},
  {tracking_id: 'BDF4561230ZX', type: 'Other', amount:100, re_mark:'CD'},
  {tracking_id: 'ADS1245780AZ', type: 'COD', amount:20, re_mark:'CD'},
  {tracking_id: 'WER25458200A', type:'Booking', amount:150, re_mark:''},
  {tracking_id: 'BDF4561230ZX', type:'Other', amount:100, re_mark:'CD'},
  {tracking_id: 'ADS1245780AZ', type:'COD', amount:20, re_mark:'CD'},
  {tracking_id: 'WER25458200A', type: 'Booking', amount:150, re_mark:''},
  {tracking_id: 'BDF4561230ZX', type:'Other', amount:100, re_mark:'CD'},
 
]
