import React, {useEffect, useState} from 'react'

import style from '../../../_metronic/assets/sass/call.module.css'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import clsx from 'clsx'
import OrderDetails from '../orders/OrderDetails'
import {OrderItems} from '../../models/order_models'
import {KTIcon} from '../../../_metronic/helpers/components/KTIcon'
import {Button} from 'react-bootstrap'
// import {allReturnOrders} from '../AppDataDefine'

export const CustomerDetailOrders = ({orders, returnOrders, customerData, leaves}: any) => {
  const [showOrder, setShowOrder] = useState<boolean>(false)
  const [order, setOrder] = useState<any>(null)
  const [call_id, setCallId] = useState<string>('')
  const [isDisable, setIsDisable] = useState<boolean>(true)

  const handleOrderShow = (order: any) => {
    setOrder(order)
    setShowOrder(true)
  }

  const handleOrderClose = () => {
    setShowOrder(false)
    setOrder(null)
  }

  const handleCheck = (order_id: string, e: any) => {
    const isChecked: boolean = e.target.checked
    isChecked ? setCallId(order_id) : setCallId('')
  }

  const handleSave = async () => {
    const data: boolean = await supabaseData.complete_followup_call(call_id)
    if (data) window.location.reload()
  }

  return (
    <>
      <div className={`${style.order_card_wrapper}`}>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <tbody>
            {orders.map((order: any) => {
              const currentDate = new Date()
              const followUpDate = new Date(order.followup_date)
              const isPastDate = followUpDate < currentDate
              const isCurrentDate = followUpDate.toDateString() === currentDate.toDateString()
              const textColor = isCurrentDate || isPastDate ? 'text-danger' : 'text-gray-600'
              // console.log('NEW DATE', new Date().toDateString)
              // console.log('order ->', order)
              return (
                <tr key={order.id}>
                  <td>
                    <div className='form-check '>
                      <input
                        className={`form-check-input border border-dark-subtle`}
                        type='checkbox'
                        checked={order.isChecked}
                        onChange={(e) => handleCheck(order.id, e)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start text-gray-600 flex-column fw-bolder'>
                        {order.order_code}
                        <span className='text-muted fw-semibold d-block fs-8'>Order Id</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div
                        className={`d-flex justify-content-start flex-column fw-bolder ${textColor}`}
                      >
                        {order.followup_date
                          ? new Date(order.followup_date).toLocaleDateString('en-GB')
                          : 'Completed'}
                        <span className='text-muted fw-semibold d-block fs-8'>Follow Up Date</span>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex text-gray-700 justify-content-start flex-column fw-bold'>
                        {new Date(order.order_date).toLocaleDateString('en-GB')}
                        <span className='text-muted fw-semibold d-block fs-8'>Order Date</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className='badge badge-light-success'>{order.payment_mode}</span>
                    <span className='text-muted fw-semibold d-block fs-8'>Payment Mode</span>
                  </td>

                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column fw-bold'>
                        {order.total_amount}
                        <span className='text-muted fw-semibold d-block fs-8'>Amount</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex  '>
                      <button
                        className='btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm me-1'
                        onClick={() => handleOrderShow(order)}
                      >
                        <KTIcon iconName='eye' className='fs-1' />
                      </button>
                    </div>
                  </td>
                  <td>
                    <Button
                      type='submit'
                      variant='primary'
                      size='sm'
                      className='me-3'
                      disabled={call_id === order.id ? false : true}
                      onClick={handleSave}
                    >
                      OK
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <h5 className='text-danger pt-5'>
        <u>Return Orders</u>
      </h5>
      <div className={`table-responsive ${style.order_card_wrapper}`}>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <tbody>
            {returnOrders.map((order: any) => {
              return (
                <tr key={order.id}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start text-gray-600 flex-column fw-bolder'>
                        {order.order_code}
                        <span className='text-muted fw-semibold d-block fs-8'>Order Id</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start text-gray-700 flex-column fw-bold'>
                        {new Date(order.order_date).toLocaleDateString('en-GB')}
                        <span className='text-muted fw-semibold d-block fs-8'>Order Date</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start text-gray-700 flex-column fw-bold'>
                        {order.is_rto ? 'RTO' : 'CUSTOMER'}
                        <span className='text-muted fw-semibold d-block fs-8'>Return</span>
                      </div>
                    </div>
                  </td>
                  {order.return_date && (
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column fw-bold text-danger'>
                          {new Date(order.return_date).toLocaleDateString('en-GB')}
                          <span className='text-muted fw-semibold d-block fs-8'>Return Date</span>
                        </div>
                      </div>
                    </td>
                  )}
                  {order.cancel_at && (
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column fw-bold text-danger'>
                          {new Date(order.cancel_at).toLocaleDateString('en-GB')}
                          <span className='text-muted fw-semibold d-block fs-8'>Cancel Date</span>
                        </div>
                      </div>
                    </td>
                  )}
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column fw-bold'>
                        {order.refund_amount}
                        <span className='text-muted fw-semibold d-block fs-8'>Amount</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex  '>
                      <button
                        className='btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm me-1'
                        onClick={() => handleOrderShow(order)}
                      >
                        <KTIcon iconName='eye' className='fs-1' />
                      </button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {showOrder && (
        <OrderDetails
          order={order}
          status={'followup'}
          showOrder={showOrder}
          handleOrderClose={handleOrderClose}
          leaves={leaves}
        />
      )}
    </>
  )
}
