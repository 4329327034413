/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
// import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import CourierCompany from './CourierCompany'
import CustomerOrderDetail from './CustomerOrderDetail'
import PaymentHistory from './PaymentHistory'
import PincodeDetails from './PincodeDetails'
import BookingFund from './BookingFund'
import {PendingPayments} from './PendingPayments'

const DashboardPage: FC = () => {
  const user: any = localStorage.getItem('user_data')
  const user_data = JSON.parse(user)
  const userRole = user_data.role
  return (
    <>
      <div className='mb-5'>
        <CustomerOrderDetail />
      </div>
      <div className='row row-cols-2  d-flex col-12 m-0 p-0'>
        {(userRole.includes('Admin') ||
          userRole.includes('Sub Admin') ||
          userRole.includes('Order Manager') ||
          userRole.includes('Order Inventory Manager')) && (
          <div className='col-sm-12 col-md-6 mb-5 p-0 pe-3'>
            <CourierCompany />
          </div>
        )}
        {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && (
          <div className='col-sm-12 col-md-6 mb-5 p-0 ps-3'>
            <PaymentHistory />
          </div>
        )}
        {/* <div className='col-sm-12 col-md-6 mb-5 p-0 ps-0 pe-3'>
          <BookingFund />
        </div> */}
        <div className='col-sm-12 col-md-6 mb-5 p-0 pe-3'>
          <PincodeDetails />
        </div>
        <div className='col-sm-12 col-md-6 mb-5 p-0'>
          <PendingPayments />
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
