import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import clsx from 'clsx'
import Select from 'react-select'
import {Address, Customer, EditCustomerDetail} from '../../models/customer_models'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import PincodeAddress from './PincodeAddress'

const EditCustomer = ({customer, sales_men, show, closeModel}: any) => {
  const formTitle = 'Edit Customer'
  const [showAddress, setShowAddress] = useState(false)
  const [pincodeValue, setPincodeValue] = useState<any>(null)
  const [pincodeError, setPincodeError] = useState<boolean>(false)
  const [office, setOffice] = useState()
  const [manually, setManually] = useState(false)

  const [editAddress, setEditAddress] = useState<Address>({
    id: '',
    address: '',
    district: '',
    taluka: '',
    state: '',
    pincode: '',
    at_post: '',
    city: '',
    village: '',
  })

  const [selectedAddress, setSelectedAddress] = useState<any>({})
  const [customerInfo, setCustomerInfo] = useState<any>({
    value: customer.value,
    label: customer.label,
    mobile: customer.mobile,
    mobile_2: customer.mobile_2,
    mobile_3: customer.mobile_3,
    sales_man_id: customer.users.value,
    address: '',
    city: '',
    village: '',
    district: '',
    state: '',
    pincode: '',
    at_post: '',
    taluka: '',
  })
  const [formErrors, setFormErrors] = useState<any>({
    nameField: '',
    mobileField: '',
    addressField: '',
    pincodeField: '',
    placeTitleField: '',
    placeField: '',
    talukaField: '',
    districtField: '',
    stateField: '',
    salesmanField: '',
  })
  const [selectedSalesManObj, setSelectedSalesManObj] = useState<{value: ''; label: ''}>({
    ...customer.users,
  })
  const [selectedPlaceValue, setSelectedPlaceValue] = useState<any>({})
  const [place, setPlace] = useState<string>('')
  const [selectPlace, setSelectPlace] = useState<any>([
    {value: 'Village', label: 'Village'},
    {value: 'City', label: 'City'},
  ])
  const handlePincodeRelatedValue = async (value: any) => {
    const data: any = await supabaseData.setPincodeRelatedValue(value)
    // console.log('PNCODE DATA ->', data)

    if (data.message) {
      setPincodeError(true)
    } else {
      console.log('PINCODE VALUE DATA =>', data)

      setPincodeValue(data)
      setEditAddress((prevEditAddress) => ({
        ...prevEditAddress,
        taluka: data.taluka,
        district: data.district,
        state: data.state,
      }))
    }
  }

  const handleInput = async (e: any) => {
    const {name, value} = e.target

    if (name in editAddress) {
      setEditAddress((prevEditAddress) => ({...prevEditAddress, [name]: value}))
      setPincodeError(false)

      if (name === 'pincode') {
        setPincodeValue(null)

        if (value.length === 6) {
          setEditAddress((prevEditAddress) => ({
            ...prevEditAddress,
            taluka: '',
            district: '',
            state: '',
          }))
          handlePincodeRelatedValue(value)

          setPincodeError(false)
          setFormErrors({
            ...formErrors,
            talukaField: false,
            districtField: false,
            stateField: false,
          })
        }
      }
      // }
    } else {
      setCustomerInfo((prevCustomerInfo: any) => ({...prevCustomerInfo, [name]: value}))
    }
    setFormErrors({
      nameField: '',
      mobileField: '',
      addressField: '',
      pincodeField: '',
      placeTitleField: '',
      placeField: '',
      talukaField: '',
      districtField: '',
      stateField: '',
      salesmanField: '',
    })
  }

  const handleSelectAddress = async (selectedOption: any) => {
    // await setSelectedAddress(selectedOption)

    await customer.customer_address.map((address: any) => {
      if (address.id === selectedOption.value) {
        // console.log('ID', selectedAddress.value)

        console.log('selected adfdress ->', address)

        setEditAddress({
          id: address.id,
          address: address.address,
          district: address.district,
          state: address.state,
          pincode: address.pincode,
          at_post: address.at_post,
          taluka: address?.taluka,
          city: address.city,
          village: address.village,
        })
        if (address.city) {
          setSelectedPlaceValue({
            value: 'City',
            label: 'City',
          })
        } else {
          setSelectedPlaceValue({value: 'Village', label: 'Village'})
        }
      }
    })
    // handlePincodeRelatedValue(selectedOption.pincode)
    await setShowAddress(true)
  }

  // useEffect(() => {
  //   customer.customer_address.map((address: any) => {
  //     if (address.id === selectedAddress.value) {
  //       // console.log('ID', selectedAddress.value)

  //       console.log('selected adfdress ->', address)

  //       setEditAddress({
  //         id: address.id,
  //         address: address.address,
  //         district: address.district,
  //         state: address.state,
  //         pincode: address.pincode,
  //         at_post: address.at_post,
  //         taluka: address.taluka,
  //         city: address.city,
  //         village: address.village,
  //       })
  //       if (address.city) {
  //         setSelectedPlaceValue({
  //           value: 'City',
  //           label: 'City',
  //         })
  //       } else {
  //         setSelectedPlaceValue({value: 'Village', label: 'Village'})
  //       }
  //     }
  //   })
  // }, [selectedAddress])

  const updatedCustomers = async (e: any) => {
    // console.log('SELECTED PLACE ==>', selectedPlaceValue)
    e.preventDefault()
    if (
      customerInfo.label.length <= 2 ||
      customerInfo.mobile.length <= 9 ||
      customerInfo.mobile.length > 10 ||
      !selectedSalesManObj
    ) {
      // console.log('IN IF CONDITION')

      setFormErrors({
        nameField: customerInfo.label.length >= 3 ? false : true,
        mobileField: customerInfo.mobile.length === 10 ? false : true,
        salesmanField: selectedSalesManObj ? false : true,
      })
      return
    }
    console.log('CITY ==>', editAddress.city)
    console.log('VILLAGE ==>', editAddress.village)

    if (
      editAddress.id !== '' &&
      (editAddress.address === '' ||
        editAddress.pincode.length !== 6 ||
        !editAddress.taluka ||
        editAddress.taluka.length <= 2 ||
        !editAddress.district ||
        editAddress.district.length <= 2 ||
        !editAddress.state ||
        editAddress.state.length <= 2 ||
        (editAddress.city === null && editAddress.village === null))
    ) {
      console.log('if address is select ')

      setFormErrors({
        addressField: editAddress.address ? false : true,
        pincodeField: editAddress.pincode?.length === 6 ? false : true,
        placeField: editAddress.city || editAddress.village ? false : true,
        talukaField:
          editAddress.taluka?.length >= 3 || customerInfo.taluka.length >= 3 ? false : true,
        districtField:
          editAddress.district?.length >= 3 || customerInfo.district.length >= 3 ? false : true,
        stateField: editAddress.state?.length >= 3 || customerInfo.state.length >= 3 ? false : true,
      })
      return
    }

    const updatedCustomer = {
      id: customer.value,
      name: customerInfo.label !== '' ? customerInfo.label : customer.label,
      mobile: customerInfo.mobile !== '' ? customerInfo.mobile : customer.mobile,
      mobile_2: customerInfo.mobile_2 !== '' ? customerInfo.mobile_2 : customer.mobile_2,
      mobile_3: customerInfo.mobile_3 !== '' ? customerInfo.mobile_3 : customer.mobile_3,
      // address_detail: selectedAddress.value && {
      //   id: selectedAddress.value,
      //   address: customerInfo.address !== '' ? customerInfo.address : editAddress.address,
      //   district: customerInfo.district !== '' ? customerInfo.district : editAddress.district,
      //   city:
      //     selectedPlaceValue.value === 'City' ? (place !== '' ? place : editAddress.city) : null,
      //   village:
      //     selectedPlaceValue.value === 'Village'
      //       ? place !== ''
      //         ? place
      //         : editAddress.village
      //       : null,
      //   state: customerInfo.state !== '' ? customerInfo.state : editAddress.state,
      //   pincode: customerInfo.pincode !== '' ? customerInfo.pincode : editAddress.pincode,
      //   at_post: customerInfo.at_post !== '' ? customerInfo.at_post : editAddress.at_post,
      //   taluka: customerInfo.taluka !== '' ? customerInfo.taluka : editAddress.taluka,
      // },
      address_detail: editAddress,
      sales_person_id: selectedSalesManObj.value,
    }

    const data: any = await supabaseData.editCustomerDetails(updatedCustomer)

    if (data !== undefined || null) {
      window.location.reload()
    }
  }

  return (
    <>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>Edit Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pb-4'>
          <form className='row' onSubmit={updatedCustomers}>
            <div style={{maxHeight: '715px', overflowY: 'scroll'}}>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>
                    Name <span className='text-danger'>*</span>{' '}
                  </label>
                </div>
                <div className='col-10'>
                  <input
                    type='text'
                    className={`col-9 ${
                      formErrors.nameField
                        ? 'form-control form-control-solid border border-2 border-danger rounded'
                        : 'form-control form-control-solid '
                    } `}
                    name='label'
                    value={customerInfo.label}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>
                    Mobile <span className='text-danger'>*</span>{' '}
                  </label>
                </div>
                <div className='col-10'>
                  <input
                    type='number'
                    className={`col-9 ${
                      formErrors.mobileField
                        ? 'form-control form-control-solid border border-2 border-danger rounded'
                        : 'form-control form-control-solid '
                    } `}
                    name='mobile'
                    value={customerInfo.mobile}
                    onChange={handleInput}
                  />
                  {formErrors.mobileField && (
                    <span className='text-danger'>
                      Mobile number is not valid. It should have exactly 10 digits.
                    </span>
                  )}
                </div>
              </div>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>Mobile 2: </label>
                </div>
                <div className='col-10'>
                  <input
                    type='number'
                    className={clsx('form-control form-control-solid col-9 ', {
                      'is-valid': customerInfo.mobile_2 && customerInfo.mobile_2.length == 10,
                    })}
                    name='mobile_2'
                    value={customerInfo.mobile_2}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>Mobile 3: </label>
                </div>
                <div className='col-10'>
                  <input
                    type='number'
                    className={clsx('form-control form-control-solid col-9 ', {
                      'is-valid': customerInfo.mobile_3 && customerInfo.mobile_3.length == 10,
                    })}
                    name='mobile_3'
                    value={customerInfo.mobile_3}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className='col-12 d-flex mb-3 '>
                <label className='col form-label pt-3 fs-5  fw-bold'>Address</label>
                <div className='col-10'>
                  <Select
                    className='z-index-1'
                    options={customer?.customer_address?.map((address: Address) => ({
                      label: `${address.address} ${address.city} ${address.district} ${address.state} Pincode: ${address.pincode}`,
                      value: address.id,
                      pincode: address.pincode,
                    }))}
                    onChange={handleSelectAddress}
                    // value={selectedAddressId}
                    // onChange={(selectedOption: any) => {
                    //   // console.log('ADDRESS VALUE v-->', selectedOption)
                    //   setSelectedAddress(selectedOption)
                    //   setShowAddress(true)
                    //   handlePincodeRelatedValue(selectedOption.pincode)
                    // }}
                  />
                </div>
              </div>
              {showAddress && (
                <>
                  <div className='col-12 d-flex mb-3'>
                    <div className='col-2'>
                      <label className='form-label mt-3'>
                        Address <span className='text-danger'>*</span>
                      </label>
                    </div>
                    <div className='col-10'>
                      <textarea
                        // type='text'
                        maxLength={120}
                        className={`col-9 ${
                          formErrors.addressField
                            ? 'form-control form-control-solid border border-2 border-danger rounded'
                            : 'form-control form-control-solid '
                        } `}
                        name='address'
                        value={editAddress.address}
                        onChange={handleInput}
                      />
                      {formErrors.addressField && (
                        <span className='text-danger'>
                          Address is not valid. It shows more then 35 digits.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='col-12 d-flex mb-3'>
                    <div className='col-2'>
                      <label className='form-label mt-3'>At & Post</label>
                    </div>
                    <div className='col-10'>
                      <input
                        type='text'
                        className={clsx('form-control form-control-solid col-9 ', {
                          'is-valid': editAddress.at_post?.length >= 3,
                        })}
                        name='at_post'
                        value={editAddress.at_post}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <PincodeAddress
                    formTitle={formTitle}
                    formErrors={formErrors}
                    customerInfo={editAddress}
                    setCustomerInfo={setEditAddress}
                    setFormErrors={setFormErrors}
                    setOffice={setOffice}
                    manually={manually}
                    setManually={setManually}
                  />
                  {/* <div className='col-12 d-flex mb-3'>
                    <div className='col-2'>
                      <label className='form-label mt-3'>
                        Pincode <span className='text-danger'>*</span>{' '}
                      </label>
                    </div>
                    <div className='col-10'>
                      <input
                        type='text'
                        className={`col-9 ${
                          formErrors.pincodeField
                            ? 'form-control form-control-solid border border-2 border-danger rounded'
                            : 'form-control form-control-solid '
                        } `}
                        name='pincode'
                        value={!editAddress.pincode ? customerInfo.pincode : editAddress.pincode}
                        onChange={handleInput}
                      />
                      {(formErrors.pincodeFiled || pincodeError) && (
                        <span className='text-danger'>Pincode number is not valid.</span>
                      )}
                    </div>
                  </div>

                  <div className='col-12 d-flex align-items-center mb-3'>
                    <div className='col-3'>
                      <label className='form-label mt-3 d-flex'>
                        <Select
                          options={selectPlace}
                          value={selectedPlaceValue}
                          onChange={(selectedOption: any) => {
                            setSelectedPlaceValue(selectedOption)
                            setPlace(editAddress.city || editAddress.village)
                          }}
                        />
                        <span className='text-danger ps-1'>*</span>
                      </label>
                    </div>
                    <div className='col-9'>
                      <input
                        type='text'
                        className={`col-9 ${
                          formErrors.placeField
                            ? 'form-control form-control-solid border border-2 border-danger rounded'
                            : 'form-control form-control-solid '
                        } `}
                        placeholder='Enter name '
                        value={
                          place === ''
                            ? editAddress.city
                              ? editAddress.city
                              : editAddress.village
                            : place
                        }
                        onChange={(e: any) => {
                          setPlace(e.target.value)
                          setFormErrors({placeField: false})
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-12 d-flex mb-3'>
                    <div className='col-2'>
                      <label className='form-label mt-3'>
                        Taluka <span className='text-danger'>*</span>
                      </label>
                    </div>
                    <div className='col-10'>
                      <input
                        type='text'
                        className={`col-9 ${
                          formErrors.talukaField
                            ? 'form-control form-control-solid border border-2 border-danger rounded'
                            : 'form-control form-control-solid '
                        } `}
                        name='taluka'
                        value={!editAddress.taluka ? customerInfo.taluka : editAddress.taluka}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className='col-12 d-flex mb-3'>
                    <div className='col-2'>
                      <label className='form-label mt-3'>
                        district <span className='text-danger'>*</span>{' '}
                      </label>
                    </div>
                    <div className='col-10'>
                      <input
                        type='text'
                        className={`col-9 ${
                          formErrors.districtField
                            ? 'form-control form-control-solid border border-2 border-danger rounded'
                            : 'form-control form-control-solid '
                        } `}
                        name='district'
                        value={!editAddress.district ? customerInfo.district : editAddress.district}
                        onChange={handleInput}
                      />
                    </div>
                  </div>

                  <div className='col-12 d-flex mb-3'>
                    <div className='col-2'>
                      <label className='form-label mt-3'>
                        State <span className='text-danger'>*</span>{' '}
                      </label>
                    </div>
                    <div className='col-10'>
                      <input
                        type='text'
                        className={`col-9 ${
                          formErrors.stateField
                            ? 'form-control form-control-solid border border-2 border-danger rounded'
                            : 'form-control form-control-solid '
                        } `}
                        name='state'
                        value={!editAddress.state ? customerInfo.state : editAddress.state}
                        onChange={handleInput}
                      />
                    </div>
                  </div> */}
                </>
              )}
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>
                    SalesMan <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-10'>
                  <Select
                    className={`col-9 ${
                      formErrors.salesmanField ? ' border border-2 border-danger rounded' : ' '
                    } `}
                    options={sales_men}
                    value={selectedSalesManObj}
                    onChange={(selectedOption: any) => {
                      console.log('VALUE', selectedOption)
                      setSelectedSalesManObj(selectedOption)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className=' d-flex justify-content-between border-top pt-4'>
              <div>
                {office && !manually && (
                  <div className=' fs-3 fw-semibold text-gray-700'>
                    <span> Office :</span> <span className=''>{office}</span>
                  </div>
                )}
              </div>
              <div>
                <Button variant='secondary' size='sm' className='me-3' onClick={closeModel}>
                  Close
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  Save
                </Button>
              </div>
            </div>
          </form>
          {/* <div className='mt-8  border-b border-gray-200 dark:border-gray-700'></div> */}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditCustomer
