import React, {useEffect, useState} from 'react'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import {Button} from 'react-bootstrap'
import {DateRangePicker} from 'react-dates'
import axios from 'axios'
import {api_url} from '../../../utils'
import {ProductsType} from '../../models/product_models'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import Select from 'react-select'
import {RoleWiseUsers} from '../../models/user_models'
import {CourierCompany} from '../../models/setting_models'
import {ToastContainer} from 'react-toastify'

const Reports = () => {
  const [products, setProducts] = useState<ProductsType>([])
  const [report, setReport] = useState<string | null>(null)
  const [sub_report, setSubReport] = useState<string | null>(null)
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState<any>([])
  const [selectedProductsIds, setSelectedProductsIds] = useState<any>([])
  const [isLoader, setLoader] = useState<boolean>(false)
  const [sales_men, selAllSalesMen] = useState<RoleWiseUsers[]>([])
  const [courierCompanies, setCourierCompanies] = useState<CourierCompany[]>([])
  const [selectedCourier, setSelectedCourier] = useState<any>([])
  const [selectedSalesMen, setSelectedSalesMen] = useState<any>([])
  const [selectedCourierIds, setSelectedCourierIds] = useState<any>([])
  const [selectedSalesMenIds, setSelectedSalesMenIds] = useState<any>([])
  const [is_payment_pending, setIsPaymentPending] = useState<boolean | null>(false)
  const [payment_report, setPaymentReport] = useState<string>('')

  useEffect(() => {
    get_all_products()
    get_all_sales_man()
    getAllCourierCompany()
  }, [])

  const onDatesChange = ({startDate, endDate}: any) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const onFocusChange = (focusedInput: any) => {
    setFocusedInput(focusedInput)
  }

  const handleSubmitData = async (e: any) => {
    setLoader(true)
    const formattedStartDate = startDate ? startDate.format('yyyy-MM-DD') : ''
    const formattedEndDate = endDate ? endDate.format('yyyy-MM-DD') : ''

    e.preventDefault()

    await supabaseData.reports(
      formattedStartDate,
      formattedEndDate,
      sub_report,
      selectedProductsIds,
      selectedSalesMenIds,
      selectedCourierIds,
      setLoader
    )
    setStartDate('')
    setEndDate('')
    setSelectedProducts([])
    setSelectedSalesMen([])
    setSelectedCourier([])
  }

  const handleProductField = (selectedOption: any) => {
    const selectedValue = selectedOption.map((product: any) => {
      const obj = {value: product.value, label: product.label}
      return obj
    })

    setSelectedProducts(selectedValue)

    const product_ids = selectedOption.map((product: any) => product.value)

    setSelectedProductsIds(product_ids)
  }

  const handleSalesManField = (selectedOption: any) => {
    const selectedValue = selectedOption.map((sales_man: any) => {
      const obj = {value: sales_man.value, label: sales_man.label}
      return obj
    })

    setSelectedSalesMen(selectedValue)

    const sales_men_ids = selectedOption.map((sales_man: any) => sales_man.value)
    setSelectedSalesMenIds(sales_men_ids)
  }

  const handleCourierField = (selectedOption: any) => {
    const selectedValue = selectedOption.map((courier: any) => {
      const obj = {value: courier.value, label: courier.label}
      return obj
    })

    setSelectedCourier(selectedValue)

    const courier_ids = selectedOption.map((courier: any) => courier.value)
    setSelectedCourierIds(courier_ids)
  }

  const get_all_products = async () => {
    const all_products: ProductsType | null = await supabaseData.getAllProducts()

    if (all_products) setProducts(all_products)
  }

  const get_all_sales_man = async () => {
    const all_sales_man: RoleWiseUsers[] | undefined = await supabaseData.get_users_by_role(
      'Sales man'
    )

    if (all_sales_man) selAllSalesMen(all_sales_man)
  }

  const getAllCourierCompany = async () => {
    const data: CourierCompany[] | undefined = await supabaseData.getAllCourierCompany()

    if (data) setCourierCompanies(data)
  }

  const selectReport = (type: string) => {
    setReport(type)
    setSubReport(null)
  }

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className='card card-xxl-stretch mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h2 className='card-title align-items-start flex-column'>
            <p className='card-label fw-bold fs-3 mb-1'>Reports</p>
          </h2>
          {/* <Export /> */}
        </div>
        <div className='d-flex justify-content-start fs-4'>
          <div className='px-2 py-3 ms-10 me-5'>
            <div className='list-group'>
              <button
                type='button'
                className={`list-group-item list-group-item-action ${
                  report === 'orders' ? 'active' : ''
                }`}
                onClick={() => selectReport('orders')}
              >
                Orders
              </button>
              <button
                type='button'
                className={`list-group-item list-group-item-action ${
                  report === 'products' ? 'active' : ''
                }`}
                onClick={() => {
                  setReport('products')
                  setSubReport('products')
                }}
              >
                Products
              </button>
              <button
                type='button'
                className={`list-group-item list-group-item-action ${
                  report === 'salesMan' ? 'active' : ''
                }`}
                onClick={() => selectReport('salesMan')}
              >
                Sales Men
              </button>
              <button
                type='button'
                className={`list-group-item list-group-item-action ${
                  report === 'payments' ? 'active' : ''
                }`}
                onClick={() => selectReport('payments')}
              >
                Payments
              </button>
              <button
                type='button'
                className={`list-group-item list-group-item-action ${
                  report === 'print' ? 'active' : ''
                }`}
                onClick={() => {
                  setReport('print')
                  setSubReport('print')
                }}
              >
                Prints
              </button>
            </div>
          </div>
          <div className='align-self-start px-2 py-3 me-5'>
            {report === 'orders' && (
              <div className='list-group'>
                <button
                  type='button'
                  className={`list-group-item list-group-item-action ${
                    sub_report === 'return' ? 'active' : ''
                  }`}
                  onClick={() => setSubReport('return')}
                >
                  Returns
                </button>
                <button
                  type='button'
                  className={`list-group-item list-group-item-action ${
                    sub_report === 'cancel' ? 'active' : ''
                  }`}
                  onClick={() => setSubReport('cancel')}
                >
                  Cancels
                </button>
              </div>
            )}
            {report === 'salesMan' && (
              <div className='list-group'>
                <button
                  type='button'
                  className={`list-group-item list-group-item-action ${
                    sub_report === 'sales_man' ? 'active' : ''
                  }`}
                  onClick={() => setSubReport('sales_man')}
                >
                  Orders
                </button>
                <button
                  type='button'
                  className={`list-group-item list-group-item-action ${
                    sub_report === 'sales_man_return' ? 'active' : ''
                  }`}
                  onClick={() => setSubReport('sales_man_return')}
                >
                  Return
                </button>
              </div>
            )}
            {report === 'payments' && (
              <div className='list-group'>
                <button
                  type='button'
                  className={`list-group-item list-group-item-action ${
                    sub_report === 'all_payment' ? 'active' : ''
                  }`}
                  onClick={() => {
                    setSubReport('all_payment')
                    // setPaymentReport('all')
                    // setIsPaymentPending(null)
                  }}
                >
                  All
                </button>
                <button
                  type='button'
                  className={`list-group-item list-group-item-action ${
                    sub_report === 'pending_payment' ? 'active' : ''
                  }`}
                  onClick={() => {
                    setSubReport('pending_payment')
                    // setPaymentReport('pending')
                    // setIsPaymentPending(true)
                  }}
                >
                  Pending
                </button>
                <button
                  type='button'
                  className={`list-group-item list-group-item-action ${
                    sub_report === 'receive_payment' ? 'active' : ''
                  }`}
                  onClick={() => {
                    // setPaymentReport('receive')
                    setSubReport('receive_payment')
                  }}
                >
                  Receive
                </button>
              </div>
            )}
          </div>

          {sub_report && (
            <div
              className='card border border-secondary card-custom mt-3 align-self-start mb-5'
              style={{width: '35rem'}}
            >
              <div className='card-title px-5 pt-5 pb-2 border-bottom border-secondary bg-primary-subtle'>
                <h3 className='card-label '>{`${report?.toLocaleUpperCase()}`}</h3>
              </div>
              <div className='p-5'>
                <div className='d-flex mb-5'>
                  <p className='mb-0 me-5 fs-4 fw-bold'>Date</p>
                  <div className=''>
                    <DateRangePicker
                      startDate={startDate}
                      startDateId='your_unique_start_date_id'
                      endDate={endDate}
                      displayFormat='DD/MM/yyyy'
                      endDateId='your_unique_end_date_id'
                      onDatesChange={onDatesChange}
                      focusedInput={focusedInput}
                      small
                      onFocusChange={onFocusChange}
                      calendarInfoPosition='top'
                      // @ts-ignore
                      className='DateRangePicker '
                      dayPickerWrapperClassName='DayPicker'
                      calendarMonthClassName='CalendarMonth'
                      navNextClassName='DayPickerNavigation_button'
                      navPrevClassName='DayPickerNavigation_button'
                      style={{width: '400px'}}
                      isOutsideRange={() => false}
                    />
                  </div>
                </div>
                {sub_report === 'products' && (
                  <div className='row my-3 d-flex align-items-center'>
                    <div className='col-2 me-2'>
                      <label className='fw-bold'>Product</label>
                    </div>
                    <div className='col-9 '>
                      <Select
                        isMulti
                        className='col-12 fs-6'
                        placeholder='Search...'
                        options={products}
                        value={selectedProducts}
                        onChange={handleProductField}
                      />
                    </div>
                  </div>
                )}
                {(sub_report === 'sales_man' || sub_report === 'sales_man_return') && (
                  <div className='row my-3 d-flex align-items-center'>
                    <div className='col-2 me-2'>
                      <label className='fw-bold'>Sales man</label>
                    </div>
                    <div className='col-9 '>
                      <Select
                        isMulti
                        className='col-12 fs-6'
                        placeholder='Search...'
                        options={sales_men}
                        value={selectedSalesMen}
                        onChange={handleSalesManField}
                      />
                    </div>
                  </div>
                )}
                {(sub_report === 'all_payment' ||
                  sub_report === 'pending_payment' ||
                  sub_report === 'receive_payment') && (
                  <div className='row my-3 d-flex align-items-center'>
                    <div className='col-2 me-2'>
                      <label className='fw-bold'>Courier</label>
                    </div>
                    <div className='col-9 '>
                      <Select
                        isMulti
                        className='col-12 fs-6'
                        placeholder='Search...'
                        options={courierCompanies}
                        value={selectedCourier}
                        onChange={handleCourierField}
                      />
                    </div>
                  </div>
                )}
                <div className='d-flex justify-content-end'>
                  {isLoader ? (
                    <Button type='button' variant='primary' size='sm' className='me-3' disabled>
                      Wait...
                    </Button>
                  ) : (
                    <Button
                      type='button'
                      variant='primary'
                      size='sm'
                      onClick={handleSubmitData}
                      className='me-3'
                    >
                      Apply
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Reports
