import React, {ChangeEvent, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import AddNewCustomer from './AddNewCustomer'
import Select, {Options} from 'react-select'
// import {allLeaves, allProductsData, dimention} from '../AppDataDefine'
import style from '../../../_metronic/assets/sass/order.module.css'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {AiTwotonePrinter} from 'react-icons/ai'
import {CgClose, CgCloseR} from 'react-icons/cg'
import barcodeStyle from '../../../_metronic/assets/sass/barcodepdf.module.css'

import clsx from 'clsx'
import GenerateBarcodePdf from './GenerateBarcodePdf'
import {image_path} from '../../../utils'
import {Customer} from '../../models/customer_models'
import {Leave} from '../../models/setting_models'
import {FilterOptionOption} from 'react-select/dist/declarations/src/filters'
import PincodeAddress from '../customers/PincodeAddress'

const AddOrdersDetails = ({
  products,
  sales_men,
  courierCompanies,
  accounts,
  leaves,
  companyDetails,
}: any) => {
  const [show, setShow] = useState(false)
  const [manually, setManually] = useState(false)
  const formTitle = 'Add Shipping Address'
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null)
  const [customersList, setCustomersList] = useState<any[]>([])
  const [showCustomerDetail, setShowCustomerDetail] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<any>(null)
  const [selectedProductsList, setSelectedProductsList] = useState<any[]>([])
  const [selectedSalesman, setSelectedSalesman] = useState<any>(null)
  const [office, setOffice] = useState()
  const [courierWeight, setCourierWeight] = useState<string>('')
  const [courierCode, setCourierCode] = useState<string>('')
  const [amount, setAmount] = useState<number>(0)
  const [paymentId, setPaymentId] = useState<string>('')
  // const [customer, setCustomer] = useState<any>({})
  const [orders, setOrders] = useState<any>([])

  const [shippingCharge, setShippingCharge] = useState<number>(0)
  const [selectedAddress, setSelectedAddress] = useState<{value: string; label: string} | null>(
    null
  )
  const [showAddress, setShowAddress] = useState(false)
  const [allLeaves, setAllLeaves] = useState<any[]>([])
  const [reMark, setReMAark] = useState('CB')
  const [date, setDate] = useState('')
  const [orderDate, setOrderDate] = useState<any>('')
  const [dimension, setDimension] = useState<any[]>([])
  const [selectedDimension, setSelectDimension] = useState('')
  const [isPrinting, setIsPrinting] = useState(false)
  const [newOrder, setNewOrder] = useState<any>(null)
  const [formErrors, setFormErrors] = useState<any>({
    paymentModeField: '',
    clientField: '',
    salesmanField: '',
    courierField: '',
    trackingIdField: '',
    addressField: '',
    productField: '',
    CourierWeightField: '',
  })
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [shippingAddress, setShippingAddress] = useState<any>({
    address: '',
    city: '',
    district: '',
    state: '',
    pincode: '',
    village: '',
    taluka: '',
    at_post: '',
  })

  const [selectedCourier, setSelectedCourier] = useState<{
    value: string
    label: string
    followup_days: number
  } | null>(null)
  const [selectedPaymentMode, setSelectedPaymentMode] = useState<string | null>('Cash On Delivery')
  const [paymentModes, setPaymentModes] = useState([
    {value: 'Prepaid', label: 'Prepaid'},
    {value: 'Cash On Delivery', label: 'Cash On Delivery'},
  ])
  const [selectedAccount, setSelectedAccount] = useState<{value: string; label: string} | null>(
    null
  )

  const [previous_courier, setPreviousCourier] = useState<string | null>(null)

  useEffect(() => {
    const currentDate = new Date()
    const formattedDate = currentDate.toISOString().split('T')[0]
    setOrderDate(formattedDate)
  }, [])

  // useEffect(() => {
  //   if (dimention) setDimension(dimention)
  // }, [])

  const handleAddress = () => {
    setSelectedAddress(null)
    setShowAddress(true)
  }

  const handleInputChange = (inputValue: string): any => {
    console.log('number ->', inputValue)

    // const inputValue = e.target.value

    // setSearchQuery(inputValue)

    if (inputValue.length >= 3) {
      getCustomers(inputValue)
    }
    return inputValue
    // if (inputValue.length === 10) {
    //   setShowModal(true)
    //   setLoading(true)
    //   setTimeout(() => {
    //     setLoading(false)
    //   }, 2000)
    // }

    // if (inputValue === '') {
    //   setMatchingMobileNumbers([])
    // } else if (inputValue.length >= 3) {
    //   setTimeout(() => {
    //     handleSearch(inputValue)
    //   }, 1000)
    // }
  }

  const getCustomers = async (number: string) => {
    console.log('entered number ->', number)
    const data: any = await supabaseData.getSearchCustomers(number)

    if (data) setCustomersList(data)
  }

  const handleChangeInputAddress = (e: any) => {
    setShippingAddress({...shippingAddress, [e.target.name]: e.target.value})
  }

  const handleShow = () => setShow(true)

  const handleCustomerSelect = async (selectedOption: any) => {
    console.log('selectedOption', selectedOption)

    if (selectedOption) {
      const customerWithMobile = customersList.find(
        (customer: any) =>
          customer.id === selectedOption.value || customer.mobile === selectedOption.mobile
      )

      console.log('customerWithMobile', customerWithMobile)

      setSelectedSalesman({
        value: customerWithMobile.users.id,
        label: customerWithMobile.users.name,
      })
      setSelectedCustomer(customerWithMobile)
      setShowCustomerDetail(true)
      setFormErrors({clientField: false})
    }
    setOrders([])
    const data = await supabaseData.getCustomerOrders(selectedOption.value, 'orders')

    if (data.length !== 0) {
      setOrders(data)
      const last_address = data[0].customer_address
      setSelectedAddress({
        value: last_address.id,
        label: `${last_address.address} ${last_address.city} ${last_address.village} ${last_address.district} ${last_address.state} Pincode: ${last_address.pincode}`,
      })

      let courier_company: string = ''
      if (selectedPaymentMode === 'Cash On Delivery') {
        for (let order of data) {
          if (order.payment_mode === 'Cash On Delivery') {
            console.log('previous COD courier ->', order.orders_courier_provider?.name)
            setPreviousCourier(order.orders_courier_provider.name)
            break
          }
        }
      }
    }
  }

  const handleSalesmanSelect = (selectedOption: any) => {
    console.log('selected sales man ->', selectedOption)
    setSelectedSalesman(selectedOption)
    setFormErrors({salesmanField: false})
  }

  const handleCourierSelect = async (selectedOption: any) => {
    console.log('selected courier  ->', selectedOption)
    setSelectedCourier(selectedOption)

    const tracking_id: string = await supabaseData.getTrackingId(selectedOption.value)

    setCourierCode(tracking_id)
  }

  const handleProductSelect = (selectedOption: any) => {
    // console.log('selected product ==>', selectedOption)

    let product = selectedProductsList.filter((product: any) => {
      return product.value === selectedOption.value
    })

    setSelectedProduct(selectedOption)
    setSelectedProductsList([...selectedProductsList, selectedOption])
    setFormErrors({productField: false})
  }

  //-------------------Yogesh bhai------------------

  // useEffect(() => {
  //   const defaultCustomer = customersList.find((customer) => customer.value === '1')
  //   setSelectedCustomer(defaultCustomer)

  //   const defaultSalesman = salesmen.find((salesman) => salesman.value === '1')
  //   setSelectedSalesman(defaultSalesman)
  // }, [])

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const {name, value} = e.target
    const updatedProducts = [...selectedProductsList]
    const updatedProduct = {
      ...updatedProducts[index],
      [name]: parseInt(value),
    }

    if (name === 'price' || name === 'units') {
      updatedProduct.total = (updatedProduct.price || 0) * updatedProduct.units
    } else if (name === 'total' && updatedProduct.units) {
      updatedProduct.price = updatedProduct.total / updatedProduct.units
    }

    updatedProducts[index] = updatedProduct

    setSelectedProductsList(updatedProducts) // Set the updated array
  }

  const calculateTotalOfTotals = () => {
    const totalProductAmount = selectedProductsList.reduce(
      (sum, product) => sum + (product.total || 0),
      0
    )

    // const totalAmount = data + shippingCharge
    const totalAmount = totalProductAmount + shippingCharge
    return totalAmount
  }

  const handleSaveAndPrint = async (e: any) => {
    const data: any = await addNewOrder(e)

    if (data) {
      data.order_items.map((item: any) => {
        item.label = item.products.name
        item.code = item.products.code
        item.image = item.products.image
        item.gst = item.products.gst
        delete item.products
        return item
      })
      setNewOrder(data)
      setIsPrinting(true)
      setTimeout(() => {
        window.print()
        setIsPrinting(false)
      }, 1000)
      clearForm()
    }
  }

  const handleSaveAndNext = async (e: any) => {
    const data: any = await addNewOrder(e)

    if (data) {
      clearForm()
    }
  }

  const handleSave = async (e: any) => {
    const data: any = await addNewOrder(e)

    if (data) {
      clearForm()
      window.location.reload()
    }
  }

  const clearForm = () => {
    setCourierCode('')
    setCustomersList([])
    setSelectedSalesman(null)
    setSelectedCustomer(null)
    setShowCustomerDetail(false)
    setSelectedProduct(null)
    setSelectedCourier(null)
    setSelectedProductsList([])
    setSelectedAddress(null)
    setShippingAddress({
      address: '',
      city: '',
      district: '',
      state: '',
      pincode: '',
      village: '',
      taluka: '',
      at_post: '',
    })
    setShowAddress(false)
    setSelectedProduct(null)
    setCourierWeight('')
    setFormErrors({
      paymentModeField: '',
      clientField: '',
    })
    setPaymentId('')
    setAmount(0)
    setSelectedAccount(null)
    setPreviousCourier(null)
    setSelectedPaymentMode('Cash On Delivery')
    setShippingCharge(0)
    clearShippingAddress()
  }

  const clearShippingAddress = () => {
    setShippingAddress({
      address: '',
      city: '',
      district: '',
      state: '',
      pincode: '',
      village: '',
      taluka: '',
      at_post: '',
    })
  }

  const handleClose = () => {
    clearForm()
    setShow(false)
    window.location.reload()
  }

  const addNewOrder = async (e: any) => {
    e.preventDefault()
    // const selectedAddress = customer.addresses?.find(
    //   (address: any) => address.id === selectedAddress
    // )

    console.log('IN ADD NEW ORDER')

    const user: string = localStorage.getItem('user_data') as string
    const user_data = JSON.parse(user)

    // setIsSave(true)
    // const selectedAddress = customer.addresses?.find(
    //   (address: any) => address.id === selectedAddress
    // )
    // if (!selectedAddress && !shippingAddress.address) {
    //   setFormErrors({
    //     addressField: selectedAddress || shippingAddress.address ? false : true,
    //   })
    //   return
    // }
    if (
      !selectedPaymentMode ||
      !selectedCustomer ||
      !selectedSalesman ||
      courierCode.length === 0 ||
      selectedProductsList.length === 0 ||
      !courierWeight ||
      (!selectedAddress && !shippingAddress.address)
    ) {
      setFormErrors({
        paymentModeField: selectedPaymentMode ? false : true,
        clientField: selectedCustomer ? false : true,
        salesmanField: selectedSalesman ? false : true,
        trackingIdField: courierCode.length !== 0 ? false : true,
        addressField: selectedAddress || shippingAddress.address ? false : true,
        productField: selectedProductsList.length !== 0 ? false : true,
        CourierWeightField: courierWeight ? false : true,
      })
      return
    }

    const newOrder = {
      customer_id: selectedCustomer.id,
      sales_person_id: selectedSalesman.value,
      payment_mode: selectedPaymentMode,
      payment_method: selectedAccount ? selectedAccount.value : null,
      address_id: selectedAddress && selectedAddress.value,
      tracking_id: courierCode !== '' ? courierCode.trim().replace(/\s+/g, '') : null,
      order_date: orderDate,
      // dimension: selectedDimension,
      shipping_charge: shippingCharge,
      address: shippingAddress.address !== '' ? shippingAddress.address : null,
      city: shippingAddress.city !== '' ? shippingAddress.city : null,
      district: shippingAddress.district !== '' ? shippingAddress.district : null,
      state: shippingAddress.state !== '' ? shippingAddress.state : null,
      pincode: shippingAddress.pincode !== '' ? shippingAddress.pincode : null,
      taluka: shippingAddress.taluka !== '' ? shippingAddress.taluka : null,
      village: shippingAddress.village !== '' ? shippingAddress.village : null,
      at_post: shippingAddress.at_post !== '' ? shippingAddress.at_post : null,
      total_amount: calculateTotalOfTotals(),
      paid_amount: amount ? amount : 0,
      payment_status: amount === calculateTotalOfTotals() ? 'Paid' : 'Pending',
      courier_provider_id: selectedCourier ? selectedCourier.value : null,
      order_items: selectedProductsList,
      courier_weight: courierWeight,
      shipping_charges: shippingCharge,
      order_status: 'Pending',
      payment: {
        amount: amount ? amount : null,
        transaction_id: paymentId ? paymentId : null,
        payment_method: selectedAccount ? selectedAccount.value : null,
        created_by: amount !== 0 ? user_data.id : null,
      },
      follow_up_call: date,
      remark: reMark,
      created_by: user_data.id,
    }
    // console.log('new order ->', newOrder)
    const data = await supabaseData.addNewOrder(newOrder)
    return data.data
  }

  const deleteProduct = (value: any) => {
    if (window.confirm('Are You sure, you want delete product?')) {
      const filterProducts = selectedProductsList.filter((x: any) => x.value !== value)
      setSelectedProductsList(filterProducts)
    }
  }

  useEffect(() => {
    const calculateBusinessDaysFromToday = (days: number) => {
      // console.log('ORDERDATE ===>', orderDate)

      const today = orderDate === '' ? new Date() : orderDate
      let currentDay = new Date(today)
      // console.log('CURRENt DATE', currentDay)

      const isWeekday = (date: any) => {
        const day = date?.getDay()
        return day !== 0
      }

      const isLeave = (date: any) => leaves?.includes(date.toISOString().split('T')[0])

      for (let i = 0; i < days; ) {
        currentDay.setDate(currentDay.getDate() + 1)

        if (isWeekday(currentDay) && !isLeave(currentDay)) {
          i++
        }
      }

      const formattedDate = currentDay.toISOString().split('T')[0]
      return formattedDate
    }

    const days =
      selectedCourier && selectedCourier.followup_days ? selectedCourier.followup_days : 7
    const calculatedDate = calculateBusinessDaysFromToday(days)
    setDate(calculatedDate)
  }, [orderDate, leaves, selectedCourier])

  const handlePaymentMode = (selectedOption: any) => {
    console.log('selected payment mode ->', selectedOption)

    setSelectedPaymentMode(selectedOption.value)
    setFormErrors({paymentModeField: false})

    // console.log('customers orders ->', orders)
    setPreviousCourier(null)

    if (selectedOption.value === 'Cash On Delivery') {
      for (let order of orders) {
        if (order.payment_mode === 'Cash On Delivery') {
          console.log('order from COD->', order)
          setPreviousCourier(order.orders_courier_provider.name)
          break
        }
      }
    }
    if (selectedOption.value === 'Prepaid') {
      for (let order of orders) {
        if (order.payment_mode === 'Prepaid') {
          console.log('order from prepaid ->', order)
          setPreviousCourier(order.orders_courier_provider.name)
          break
        }
      }
    }
  }

  return (
    <>
      <div className='btn btn-sm btn-light-primary' onClick={handleShow}>
        <KTIcon iconName='plus' className='fs-3' />
        New Order
      </div>
      <Modal show={show} className={`modal-xl `}>
        <Modal.Header>
          <Modal.Title>Add Order Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <form onSubmit={addNewOrder}> */}
          <form>
            <div style={{maxHeight: '650px', overflowY: 'scroll', overflowX: 'hidden'}}>
              <div className={`row row-cols-4 mb-3 ${selectedCourier && 'row row-cols-4'}`}>
                <div className='col-3 d-flex mt-1  '>
                  <label className='col-4 form-label d-flex pt-2 me-2'>
                    <span> Order Date</span> <span className='text-danger'>*</span>
                  </label>
                  <div className='col-8'>
                    <input
                      type='date'
                      style={{height: '38px'}}
                      className=' form-control form-control-sm form-control-solid mx-0'
                      name='date'
                      value={orderDate}
                      onChange={(e: any) => setOrderDate(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className='col-3'>
                  <div className='d-flex'>
                    {/* <div className=''> */}
                    <label className=' col-3 form-label pt-2 fs-5 '>
                      Courier
                      {selectedPaymentMode === 'Cash On Delivery' && (
                        <span className='text-danger'>*</span>
                      )}
                    </label>
                    {/* </div> */}
                    <div className='pt-1 col-9'>
                      <Select
                        className={`
                          ${
                            !selectedCourier && selectedPaymentMode === 'Cash On Delivery'
                              ? 'border border-2 border-danger rounded'
                              : selectedCourier
                              ? ''
                              : ''
                          }`}
                        required={selectedPaymentMode === 'Cash On Delivery'}
                        options={courierCompanies}
                        value={
                          selectedCourier && {
                            label: selectedCourier.label,
                            value: selectedCourier.value,
                          }
                        }
                        onChange={handleCourierSelect}
                      />
                    </div>
                  </div>
                </div>
                {/* {selectedCourier && ( */}
                <div className='col-3 '>
                  <input
                    style={{height: '38px'}}
                    className={`form-control form-control-solid mt-1 ${
                      formErrors.trackingIdField && 'border border-2 border-danger rounded'
                    }`}
                    type='text'
                    value={courierCode}
                    onChange={(e: any) => {
                      setCourierCode(e.target.value)
                      setFormErrors({trackingIdField: false})
                    }}
                    placeholder='Enter tracking code '
                  />
                </div>
                {/* )} */}
                <div className='col-3 d-flex mt-1'>
                  <label className='form-label pt-2 me-2'>
                    <span> Follow Up Call</span> <span className='text-danger'>*</span>
                  </label>
                  <div>
                    <input
                      type='date'
                      style={{height: '38px'}}
                      className='form-control form-control-sm form-control-solid mx-0'
                      name='date'
                      value={date}
                      onChange={(e: any) => setDate(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className='border-bottom pb-5'>
                <div className='row row-cols-3 align-items-center'>
                  <div className='col-5 d-flex  position-relative mt-0'>
                    <label className='form-label pt-2 fs-5 '>
                      Customer Name <span className='text-danger me-2'>*</span>
                    </label>
                    <div className='col-6 pt-1'>
                      <Select
                        id='SelExample'
                        isSearchable={true}
                        className={`${
                          formErrors.clientField && 'border border-2 border-danger rounded'
                        } `}
                        required
                        onInputChange={handleInputChange}
                        options={customersList.map((customer: any) => ({
                          label: `${customer.name} - ${customer.mobile}`,
                          value: customer.id,
                        }))}
                        value={
                          selectedCustomer && {
                            label: `${selectedCustomer.name} - ${selectedCustomer.mobile}`,
                            value: selectedCustomer.id,
                          }
                        }
                        onChange={handleCustomerSelect}
                      />
                    </div>
                    {/* <button
                      type='button'
                      className='btn btn-primary btn-sm mt-1 position-absolute'
                      style={{left: '61.5%', height: '37px'}}
                    >
                      <i className='fas fa-search '></i>
                    </button> */}
                    <div className='mt-1 '>
                      <AddNewCustomer sales_men={sales_men} from={'order'} />
                    </div>
                  </div>

                  <div className='col-3 d-flex '>
                    <label className=' form-label pt-3 fs-6 me-1'>
                      Sales Man <span className='text-danger'>*</span>
                    </label>
                    <div className='col-8'>
                      <Select
                        className={
                          formErrors.salesmanField && 'border border-2 border-danger rounded'
                        }
                        options={sales_men}
                        value={selectedSalesman}
                        required
                        // options={salesmen}
                        // // value={selectedSalesman}
                        onChange={handleSalesmanSelect}
                      />
                    </div>
                  </div>
                  <div className='col-4 d-flex'>
                    <label className=' col-4 form-label pt-2 fs-5 me-2 d-flex'>
                      Payment Mode <span className='text-danger'>*</span>
                    </label>
                    <div className='col-7 h-20px m-0 p-0 '>
                      <Select
                        className={`${
                          formErrors.paymentModeField && 'border border-2 border-danger rounded'
                        }`}
                        options={paymentModes}
                        onChange={(e) => handlePaymentMode(e)}
                        defaultValue={paymentModes[1]}
                      />
                      {/* {formErrors.paymentModeField && (
                      <span className='text-danger'>Please fill in this field.</span>
                    )} */}
                    </div>
                    <br />
                  </div>

                  <div className='col-12 d-flex row mt-5 '>
                    <label className='col-1 form-label pt-3 fs-5  fw-bold'>Address</label>
                    <div className='col-7 ms-4'>
                      <Select
                        className={
                          formErrors.addressField && 'border border-2 border-danger rounded'
                        }
                        options={selectedCustomer?.customer_address?.map((address: any) => ({
                          label: `${address.address} ${address.city} ${address.district} ${address.state} Pincode: ${address.pincode}`,
                          value: address.id,
                        }))}
                        value={selectedAddress}
                        onChange={(selectedOption: any) => {
                          // console.log('VALUE', selectedOption)
                          setSelectedAddress(selectedOption)
                          setFormErrors({addressField: false})
                        }}
                      />
                      {formErrors.addressField && (
                        <p className='text-danger'>Select address or Add Shipping address</p>
                      )}
                    </div>
                    <button
                      type='button'
                      onClick={handleAddress}
                      className='btn btn-danger col-1 mt-2 pt-0 ps-2'
                      style={{height: '25px', width: '25px'}}
                    >
                      <CgClose />
                    </button>
                    {previous_courier && (
                      <p className='col-3 pt-2 text-end fs-6 ms-8 '>
                        Courier Company : {previous_courier}
                      </p>
                    )}
                  </div>
                  {/* {showCustomerDetail && ( */}
                  {/* <div className='fs-5 mt-3 ms-3'>
                    <b>Addresses: </b>
                    <div className='row mt-5'>
                      {customer.customer_address.map((address: any) => {
                        return (
                          <div key={address.id} className='col d-flex me-10 '>
                            <div className='me-3'>
                              <input
                                    className='form-check-input'
                                    type='radio'
                                    name='selectedAddress'
                                    value={address.id}
                                    checked={selectedAddress === address.id}
                                    onChange={() => setSelectedAddress(address.id)}
                                  />
                            </div>
                            <div>
                              <p className='fw-semibold text-gray-700'>
                                <span> {address.address},</span> <span> {address.city},</span>
                                <span> {address.district},</span>
                                <span> {address.state},</span>
                                <span className='text-dark'> Pincode: </span>
                                {address.pincode}
                              </p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div> */}
                  {/* // )} */}
                </div>
              </div>
              <div className=''>
                <p className='text-gray-800 fw-bold fs-5 my-5'>
                  <KTIcon iconName='geolocation' className='fs-5 me-1 text-gray-700' />
                  Shipping Address:
                </p>
                {showAddress ? (
                  <div className='d-flex border-bottom'>
                    <PincodeAddress
                      formTitle={formTitle}
                      formErrors={formErrors}
                      setFormErrors={setFormErrors}
                      customerInfo={shippingAddress}
                      setCustomerInfo={setShippingAddress}
                      setOffice={setOffice}
                      manually={manually}
                      setManually={setManually}
                    />
                    {/* <div className='mb-5 row'>
                      <div className='col-8'>
                        <input
                          className='form-control form-control-solid'
                          placeholder='Enter address details'
                          name='address'
                          type='text'
                          value={shippingAddress.address}
                          onChange={handleChangeInputAddress}
                        />
                      </div>
                      <div className='col-4'>
                        <input
                          className=' form-control form-control-solid'
                          placeholder='Enter pincode'
                          name='pincode'
                          type='number'
                          value={shippingAddress.pincode}
                          onChange={handleChangeInputAddress}
                        />
                      </div>

                      <div className='row mt-5'>
                        <div className='col-4'>
                          <input
                            className='form-control form-control-solid'
                            placeholder='Enter city'
                            name='city'
                            type='text'
                            value={shippingAddress.city}
                            onChange={handleChangeInputAddress}
                          />
                        </div>
                        <div className='col-4'>
                          <input
                            className='form-control form-control-solid'
                            placeholder='Enter village'
                            name='village'
                            type='text'
                            value={shippingAddress.village}
                            onChange={handleChangeInputAddress}
                          />
                        </div>
                        <div className='col-4'>
                          <input
                            className='form-control form-control-solid ms-4'
                            placeholder='Enter At-Post'
                            name='at_post'
                            type='text'
                            value={shippingAddress.at_post}
                            onChange={handleChangeInputAddress}
                          />
                        </div>
                      </div>
                      <div className='row mt-5'>
                        <div className='col-4'>
                          <input
                            className=' form-control form-control-solid '
                            placeholder='Enter taluka'
                            name='taluka'
                            type='text'
                            value={shippingAddress.taluka}
                            onChange={handleChangeInputAddress}
                          />
                        </div>
                        <div className='col-4'>
                          <input
                            className='form-control form-control-solid'
                            placeholder='Enter district'
                            name='district'
                            type='text'
                            value={shippingAddress.district}
                            onChange={handleChangeInputAddress}
                          />
                        </div>
                        <div className='col-4'>
                          <input
                            className='form-control form-control-solid ms-4'
                            placeholder='Enter state'
                            name='state'
                            type='text'
                            value={shippingAddress.state}
                            onChange={handleChangeInputAddress}
                          />
                        </div>
                      </div>
                    </div> */}
                    <CgCloseR
                      onClick={() => {
                        setShowAddress(false)
                        clearShippingAddress()
                      }}
                      className='ms-10 me-3  fw-bolder text-gray-500 text-hover-primary'
                      style={{fontSize: '40px', marginTop: '-40px'}}
                    />
                  </div>
                ) : (
                  <span
                    className='fw-bold text-gray-600 text-hover-primary mt-4  align-items-center'
                    style={{cursor: 'pointer'}}
                    onClick={() => setShowAddress(true)}
                  >
                    <span
                      className='pt-2 d-flex align-items-center ps-20'
                      // style={{border: '1px solid black'}}
                    >
                      <KTIcon iconName='plus' className='fs-3 me-1 ' />
                      <span className='fs-7 '>Add Shipping Address</span>
                    </span>
                  </span>
                )}
              </div>
              <div className='mt-8  border-b border-gray-200 dark:border-gray-700 border-bottom pb-5'>
                <div className='col d-flex'>
                  <label className='form-label pt-2 fs-5 me-3' style={{width: '110px'}}>
                    Product <span className='text-danger'>*</span>
                  </label>
                  <div className='col-5 pt-1'>
                    <Select
                      className={formErrors.productField && 'border border-2 border-danger rounded'}
                      id='SelExample'
                      options={products}
                      value={selectedProduct}
                      onChange={handleProductSelect}
                    />
                  </div>
                </div>
              </div>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-200px'>Name</th>
                    <th className='min-w-100px'>Price</th>
                    <th className='min-w-100px'>Units</th>
                    <th className='min-w-150px '>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProductsList.length <= 0 ? (
                    <div
                      className='d-flex justify-content-end mx-auto '
                      style={{width: '500px', justifyContent: 'center'}}
                    >
                      <p className='fs-5 fw-semibold text-gray-600 '>No product selected</p>
                    </div>
                  ) : (
                    selectedProductsList.map((product, index) => {
                      return (
                        <tr key={product.value}>
                          <td>
                            <div className='d-flex cursor-pointer'>
                              <div>
                                <img
                                  className={style.set_product_image}
                                  src={`${image_path}${product.image}`}
                                />
                              </div>
                              <div className='ms-5 fw-semi-bold'>
                                <p className='mb-0 fs-6 fw-bold'>{product.label}</p>
                                <p className='mb-0  fs-8 fw-bold text-gray-600'>
                                  CODE : {product.code}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className=' me-4'>
                              <input
                                style={{width: '100px'}}
                                type='number'
                                className='form-control form-control-solid form-control-sm'
                                name='price'
                                value={product.price ? product.price : 0}
                                onChange={(e) => handleChangeInput(e, index)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className=' me-4'>
                              <input
                                style={{width: '100px'}}
                                type='number'
                                name='units'
                                className='form-control form-control-solid form-control-sm '
                                value={product.units ? product.units : 0}
                                onChange={(e) => handleChangeInput(e, index)}
                              />
                            </div>
                          </td>
                          <td>
                            <div>
                              <input
                                style={{width: '150px'}}
                                type='number'
                                name='total'
                                className='form-control form-control-solid form-control-sm'
                                value={product.total ? product.total : 0}
                                onChange={(e) => handleChangeInput(e, index)}
                              />
                            </div>
                          </td>
                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={() => deleteProduct(product.value)}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
              {selectedProductsList.length > 0 && (
                <div className='text-end'>
                  <div className='d-flex align-items-center justify-content-end border-bottom border-top py-5 row'>
                    <div className='col-2 ps-50'>
                      <label className='col fw-bold'>Shipping Charge:</label>
                    </div>
                    <div className='col-2'>
                      <input
                        className='form-control form-control-sm  form-control-solid col-2 fw-semibold'
                        type='number'
                        value={shippingCharge}
                        onChange={(e) => setShippingCharge(parseFloat(e.target.value))}
                      />
                    </div>
                    <div className='col-2'></div>
                  </div>
                  <div className='mt-3 d-flex align-items-center justify-content-end row'>
                    <div className='col-2'>
                      <h5>Totals: </h5>
                    </div>
                    <div className='col-1 text-gray-700 fw-bold'>{calculateTotalOfTotals()}</div>
                    <div className='col-3'></div>
                  </div>
                </div>
              )}
              <div className='row border-top pt-5'>
                <div className='col-6'>
                  {/* <div className='mt-4 border-b border-gray-200 dark:border-gray-700  pb-5'>
                    <div className='col d-flex'>
                      <label className='form-label pt-2 fs-5 me-2' style={{width: '122px'}}>
                        Dimension <span className='text-danger'>*</span>
                      </label>
                      <div className='col-8 pt-1'>
                        <Select
                          options={dimension}
                          onChange={(selectedOption: any) => {
                            console.log('VALUE', selectedOption)
                            setSelectDimension(selectedOption.value)
                          }}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className='mt-4 border-b border-gray-200 dark:border-gray-700  pb-5'>
                    <div className='col d-flex'>
                      <label className='form-label pt-2 fs-5 me-3 mt-2' style={{width: '120px'}}>
                        Courier Weight <span className='text-danger'>*</span>
                      </label>
                      <div className='col-8 pt-1 '>
                        <input
                          className={`form-control form-control-solid form-control-sm mt-2 py-3 ${
                            formErrors.CourierWeightField && 'border border-2 border-danger rounded'
                          }`}
                          type='text'
                          value={courierWeight}
                          placeholder='Enter Courier Weight'
                          // required
                          onChange={(e: any) => {
                            setCourierWeight(e.target.value)
                            setFormErrors({CourierWeightField: false})
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='pb-5'>
                    {selectedPaymentMode !== 'Cash On Delivery' && (
                      <>
                        <div className='col d-flex'>
                          <label className='form-label pt-2 fs-5 me-3' style={{width: '120px'}}>
                            Account
                          </label>
                          <div className='col-8 pt-1'>
                            <Select
                              options={accounts}
                              onChange={(selectedOption: any) => {
                                setSelectedAccount(selectedOption)
                              }}
                              value={selectedAccount}
                            />
                          </div>
                        </div>
                        {selectedAccount && (
                          <div className='ms-20 col-9 d-flex'>
                            <span className='text-danger mt-5 ms-15'></span>
                            <input
                              className='form-control form-control-solid form-control-sm mt-5 ms-2'
                              // className={`${
                              //   selectedAccount
                              //     ? paymentId.length >= 10
                              //       ? 'form-control form-control-solid form-control-sm mt-5 border-success ms-2'
                              //       : 'form-control form-control-solid form-control-sm mt-5 border-danger ms-2'
                              //     : 'form-control form-control-solid form-control-sm mt-5 ms-2 '
                              // }`}
                              type='text'
                              value={paymentId}
                              onChange={(e: any) => setPaymentId(e.target.value)}
                              placeholder='Enter Payment id'
                              // required
                            />
                          </div>
                        )}
                        <div className='mt-4 border-b border-gray-200 dark:border-gray-700 '>
                          <div className='col d-flex'>
                            <label
                              className='form-label pt-2 fs-5 me-3 mt-2'
                              style={{width: '113px'}}
                            >
                              Amount
                            </label>
                            <div className='col-8 pt-1 '>
                              <input
                                className='form-control form-control-solid form-control-sm mt-2 py-3'
                                type='number'
                                value={amount}
                                onChange={(e: any) => setAmount(+e.target.value)}
                                placeholder='Enter amount'
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className='mt-4 border-b border-gray-200 dark:border-gray-700  pb-5'>
                      <div className='col d-flex'>
                        <label className='form-label pt-2 fs-5 me-3 mt-2' style={{width: '113px'}}>
                          Re Mark
                        </label>
                        <div className='col-8 pt-1 '>
                          <input
                            className='form-control form-control-solid form-control-sm mt-2 py-3'
                            type='text'
                            value={reMark}
                            onChange={(e: any) => setReMAark(e.target.value)}
                            placeholder='Enter re mark'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {showCustomerDetail && (
                  <div className='border-start my-5 col-6'>
                    <h5 className='text-gray-700 text-center '>Last Five Orders Details</h5>
                    <div className='accordion accordion-flush' id='accordionFlushExample'>
                      {orders.map((order: any) => {
                        return (
                          <div className='accordion-item' key={order.order_code}>
                            <h2 className='accordion-header'>
                              <button
                                className='accordion-button collapsed text-hover-primary p-3 text-hover-border-primary-subtle border-bottom '
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target={`#collapse-${order.order_code}`}
                                aria-expanded='false'
                                aria-controls={`collapse-${order.order_code}`}
                              >
                                <span>
                                  <span className='text-gray-700 fs-6 fw-bold'>
                                    Order ID: {order.order_code}
                                    {order.return_date === null || order.return_date === '' ? (
                                      <span
                                        className={clsx(
                                          'badge ',
                                          {
                                            'badge-light-primary': order.order_status === 'Pending',
                                          },
                                          {
                                            'badge-light-success': order.order_status === 'Deliver',
                                          },
                                          {
                                            'badge-light-warning':
                                              order.order_status === 'On the way',
                                          }
                                        )}
                                      >
                                        {order.order_status}
                                      </span>
                                    ) : (
                                      <span className='ms-8 badge badge-light-danger'>Return</span>
                                    )}
                                  </span>
                                  <br />
                                  <span className='fw-semibold fs-7'>
                                    <span className='text-gray-600'>
                                      Order date:
                                      {new Date(order.order_date).toLocaleDateString('en-GB')}
                                    </span>
                                    {order.return_date && (
                                      <span className='text-danger ms-15'>
                                        Return Date:
                                        {new Date(order.return_date).toLocaleDateString('en-GB')}
                                      </span>
                                    )}
                                  </span>
                                </span>
                              </button>
                            </h2>
                            <div
                              id={`collapse-${order.order_code}`}
                              className='accordion-collapse collapse'
                              data-bs-parent='#accordionFlushExample'
                            >
                              <div className='accordion-body'>
                                <div className='row row-cols-2 border-bottom'>
                                  {/* <div className='col-6'>
                                    <div className='row'>
                                      <div className='col-9'>
                                        <p className=' fs-6 fw-bold m-0'>
                                          <span>Order Id: </span>
                                          <span className='text-gray-700'>{order.order_code}</span>
                                        </p>
                                      </div>
                                      <span
                                        className={clsx(
                                          'badge-sm fw-bold px-2  col-3',
                                          {'badge-light-primary': order.order_status === 'Pending'},
                                          {'badge-light-danger': order.order_status === 'Return'},
                                          {'badge-light-success': order.order_status === 'Deliver'},
                                          {
                                            'badge-light-warning':
                                              order.order_status === 'On the way',
                                          }
                                        )}
                                        style={{height: '20px'}}
                                      >
                                        {order.order_status}
                                      </span>
                                    </div>
                                    <p className='fw-bold fs-8 text-gray-600'>
                                      <span>Order Date:</span>
                                      {new Date(order.created_at).toLocaleDateString()}
                                    </p>
                                  </div> */}
                                  <div className='col-6 mb-3 fs-8'>
                                    <div className='row'>
                                      <p className='col-6 mb-0 fw-bold text-gray-700 pe-0 me-0'>
                                        Courier Company:
                                      </p>
                                      <p
                                        className='col-6 mb-0 fw-semibold ps-0'
                                        style={{marginLeft: '-15px'}}
                                      >
                                        {order.orders_courier_provider &&
                                          order.orders_courier_provider.name}
                                      </p>
                                    </div>
                                    <div className=''>
                                      <span className='fw-bold text-gray-700'>Total Amount: </span>
                                      <span className='fw-bolder ms-1'>₹{order.total_amount}</span>
                                      <p
                                        className='fw-bold text-gray-700'
                                        // style={{width: 'auto', maxWidth: '160px'}}
                                      >
                                        Payment: {order.payment_mode}
                                      </p>
                                    </div>
                                  </div>
                                  <div className='col-6'>Sales man : {order.sales_man.name}</div>
                                </div>
                                <div className='border-bottom pb-5'>
                                  {order.order_items.map((item: any, i: number) => {
                                    return (
                                      <div key={i}>
                                        <div className='d-flex row mt-1'>
                                          <div className='col-1'>
                                            <img
                                              src={`${image_path}${item.products.image}`}
                                              style={{
                                                width: '25px',
                                                height: '25px',
                                                borderRadius: '20%',
                                              }}
                                            />
                                          </div>
                                          <div className='ms-1 col-5'>
                                            <p className='fs-7 fw-bold mb-0'>
                                              {item.products.name}
                                            </p>
                                          </div>
                                          <div className='col-2'>
                                            <p className='fs-8 fw-bold text-gray-700 ms-5'>
                                              ₹{item.price}
                                            </p>
                                          </div>
                                          <div className='col-2'>
                                            <p className='fs-8 fw-bold text-gray-700 ms-5'>
                                              {item.units} Units
                                            </p>
                                          </div>
                                          <div className='col-1'>
                                            <p className='fs-8 fw-bold text-gray-700 ms-5'>
                                              ₹{item.sub_total}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>

                                {order.return_date !== null && (
                                  <>
                                    <div>
                                      <h6 className='text-gray-700 text-center mb-2'>
                                        Return Items
                                      </h6>
                                      <div className='d-flex justify-content-between'>
                                        <span className={`badge-sm fw-bold px-2 badge-light-info `}>
                                          Return Payment: {order.return_payment}
                                        </span>
                                        <span
                                          className='col-4 text-end fs-5 fw-bold'
                                          style={{justifyContent: 'space-between'}}
                                        >
                                          <span className='text-gray-600 me-3'>Refund Total: </span>
                                          <span className='badge badge-light-danger fs-8'>
                                            ₹{order.return_amount}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0'>
                                      <thead>
                                        <tr className='fw-bold text-muted'>
                                          <th className='min-w-100'></th>
                                          <th className='min-w-250'></th>
                                          <th className='min-w-50 fs-8'>Price</th>
                                          <th className='min-w-50 fs-9'>Return Items</th>
                                          <th className='min-w-100 fs-9'>Damage Items</th>
                                          <th className='min-w-50'></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {order.order_items.map((item: any, i: number) => {
                                          return (
                                            <tr key={i} className='m-0'>
                                              {/* <div className='d-flex row mt-8'> */}
                                              <td>
                                                <div className='col-1'>
                                                  <img
                                                    src={`${image_path}${item.products.image}`}
                                                    style={{
                                                      width: '25px',
                                                      height: '25px',
                                                      borderRadius: '20%',
                                                    }}
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <p className='fs-7 fw-bold mb-0'>
                                                  {item.products.name}
                                                </p>
                                                <p className='fs-8 fw-semibold text-gray-600'>
                                                  {item.products.description}
                                                </p>
                                              </td>
                                              <td>
                                                <div className='col-1'>
                                                  <p className='fs-8 fw-bold text-gray-700 '>
                                                    ₹{item.price}
                                                  </p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className='col-2'>
                                                  <p className='fs-8 fw-bold text-gray-700 '>
                                                    {item.return_item}
                                                  </p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className='col-2'>
                                                  <p className='fs-8 fw-bold text-gray-700 '>
                                                    {item.damage_item}
                                                  </p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className='col-1'>
                                                  <p className='fs-8 fw-bold text-gray-700 '>
                                                    ₹{item.sub_total}
                                                  </p>
                                                </div>
                                              </td>
                                              {/* </div> */}
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end '>
                <button
                  type='button'
                  className='text-light fw-bold focus-ring   px-4 py-2 text-decoration-none border rounded-2 me-2'
                  style={{backgroundColor: 'rgba(var(--bs-primary-rgb))'}}
                  onClick={handleSaveAndPrint}
                >
                  Print & Next
                </button>
                <button
                  className='text-light fw-bold focus-ring  py-1 px-2 text-decoration-none border rounded-2 me-2'
                  style={{backgroundColor: 'rgba(var(--bs-primary-rgb))'}}
                  type='button'
                  onClick={handleSaveAndNext}
                >
                  Save & Next
                </button>
                <button
                  className='text-light fw-bold focus-ring  py-1 px-2 text-decoration-none border rounded-2 me-2'
                  style={{backgroundColor: 'rgba(var(--bs-primary-rgb))'}}
                  type='button'
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  type='button'
                  className='me-3  focus-ring  px-4 py-2 text-decoration-none border rounded-2 me-2'
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <div className={barcodeStyle.print_visible}>
        {isPrinting && newOrder && (
          <GenerateBarcodePdf order={[newOrder]} companyDetails={companyDetails} />
        )}
      </div>
    </>
  )
}

export default React.memo(AddOrdersDetails)
