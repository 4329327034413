import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {BsInboxFill, BsInboxesFill} from 'react-icons/bs'
import {ToastContainer} from 'react-toastify'
import {KTIcon} from '../../../_metronic/helpers'
import TabView from '../calls/TabView'
import {RiCloseFill} from 'react-icons/ri'
import EditSingleProduct from './EditSingleProduct'

const EditProduct = ({
  product,
  products,
  onUpdateProduct,
  setShowEdit,
  showEdit,
  userRole,
}: any) => {
  const [activeTab, setActiveTab] = useState<string>(product.is_combo === true ? 'combo' : 'single')
  // const [show, setShow] = useState(false)
  const tabs = [
    {label: 'Single ', path: 'single', icon: <BsInboxFill />},
    {label: 'Combo', path: 'combo', icon: <BsInboxesFill />},
  ]
  // const handleShow = () => setShow(true)

  useEffect(() => {}, [])
  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <>
      {/* <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      {/* <div className='d-flex align-items-center' onClick={handleShow}>
        <KTIcon iconName='pencil' className='fs-3' />
      </div> */}
      <Modal show={showEdit} className={`modal-md `}>
        <Modal.Header className='d-flex justify-content-space-between'>
          <Modal.Title>Edit Products</Modal.Title>
          {/* <button
            className='border border-none fs-3 text-hover-primary'
            onClick={() => setShow(false)}
          >
            <RiCloseFill />
          </button> */}
        </Modal.Header>
        <Modal.Body>
          <div className=' border-b border-gray-200 dark:border-gray-700'>
            <TabView tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
          </div>

          <div className='mt-6'>
            {activeTab === 'single' && (
              <div>
                <EditSingleProduct
                  product={product}
                  onUpdateProduct={onUpdateProduct}
                  setShow={setShowEdit}
                  tab={activeTab}
                  userRole={userRole}
                />
              </div>
            )}

            {activeTab === 'combo' && (
              <EditSingleProduct
                product={product}
                products={products}
                onUpdateProduct={onUpdateProduct}
                setShow={setShowEdit}
                tab={activeTab}
                userRole={userRole}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditProduct
