import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {BsInboxFill, BsInboxesFill} from 'react-icons/bs'
import {ToastContainer} from 'react-toastify'
import {KTIcon} from '../../../_metronic/helpers'
import TabView from '../calls/TabView'
import AddNewProduct from './AddNewSingleProduct'
import {RiCloseFill} from 'react-icons/ri'

const AddProducts = ({products, setProduct, userRole}: any) => {
  const [activeTab, setActiveTab] = useState('single')
  const [show, setShow] = useState(false)
  const tabs = [
    {label: 'Single ', path: 'single', icon: <BsInboxFill />},
    {label: 'Combo', path: 'combo', icon: <BsInboxesFill />},
  ]
  const handleShow = () => setShow(true)

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <>
      <div className='btn btn-sm btn-light-primary' onClick={handleShow}>
        <KTIcon iconName='plus' className='fs-3' />
        New Product
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='d-flex justify-content-space-between'>
          <Modal.Title>Add Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=' border-b border-gray-200 dark:border-gray-700'>
            <TabView tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
          </div>

          <div className='mt-6'>
            {activeTab === 'single' && (
              <div>
                <AddNewProduct
                  products={products}
                  setProduct={setProduct}
                  setShow={setShow}
                  tab={activeTab}
                  userRole={userRole}
                />
              </div>
            )}

            {activeTab === 'combo' && (
              <AddNewProduct
                products={products}
                setProduct={setProduct}
                setShow={setShow}
                tab={activeTab}
                userRole={userRole}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddProducts
