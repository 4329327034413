import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import Select from 'react-select'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {Product} from '../../models/product_models'

const EditSingleProduct = ({setShow, products, product, tab, userRole}: any) => {
  const [image, setImage] = useState<File | string>(product.image)
  const [name, setName] = useState<string>(product.label)
  const [price, setPrice] = useState<number>(product.price)
  const [description, setDescription] = useState(product.description)
  const [unit, setUnit] = useState<number>(product.unit)
  const [code, setCode] = useState(product.code)
  const [GST, setGST] = useState<number>(product.gst)
  const [selectedProducts, setSelectedProducts] = useState<any[]>([])
  const [error, setError] = useState(false)
  const [formErrors, setFormErrors] = useState<any>({
    nameField: '',
    priceField: '',
    gstField: '',
    codeField: '',
  })
  const [selectedUnitTYpeObject, setSelectedUnitTYpeObject] = useState<any>(null)
  const [productIds, setProductIds] = useState<string[]>([])
  const [selectedUnitType, setSelectedUnitType] = useState(null)
  const [manufacturingCharge, setManufacturingCharge] = useState<number>(
    product.manufacturing_charge
  )
  const [unitTypes, setUnitTypes] = useState([
    {value: 'gm', label: 'gm'},
    {value: 'kg', label: 'kg'},
    {value: 'pills', label: 'pills'},
  ])

  useEffect(() => {
    if (tab === 'combo') selectedAllProducts()
  }, [])

  const selectedAllProducts = () => {
    let finalProduct: any[] = []
    products.forEach((prod: any) => {
      if (JSON.parse(product.product_ids))
        if (JSON.parse(product.product_ids).indexOf(prod.value) !== -1) {
          finalProduct.push({
            value: prod.value,
            label: prod.label,
          })
        }
    })

    setSelectedProducts(finalProduct)
  }
  useEffect(() => {
    const selectedUnitType = () => {
      unitTypes.map((type) => {
        if (type.value === product.unit_type) {
          setSelectedUnitTYpeObject({
            value: type.value,
            label: type.label,
          })
        }
      })
    }
    selectedUnitType()
  }, [])

  const handleProductSelected = (selectedOptions: any) => {
    const product_ids = selectedOptions.map((opt: any) => {
      console.log(opt.value)
      return opt.value
    })
    console.log('select product ->', product_ids)
    setProductIds(product_ids)
    setSelectedProducts(selectedOptions)
  }
  const handleChangeUnitType = (selectedOptions: any) => {
    setSelectedUnitType(selectedOptions.value)
    setSelectedUnitTYpeObject(selectedOptions)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!name || !code || manufacturingCharge === 0) {
      setFormErrors({
        nameField: name.length >= 3 ? false : true,
        // gstField: GST ? false : true,
        codeField: code.length >= 4 ? false : true,
        manufacturingField: manufacturingCharge === 0 ? true : false,
      })
      return
    }
    if (selectedProducts.length < 2 && tab === 'combo') {
      setError(true)
      return
    }

    if (tab === 'combo' && selectedProducts.length < 2) {
      setError(true)
    } else {
      setError(false)
      const updateProductData = {
        // ...product,
        name,
        price,
        gst: GST,
        description,
        unit,
        code,
        image,
        current_stock: tab === 'combo' ? 0 : product.current_stock,
        product_ids: productIds.length >= 2 ? JSON.stringify(productIds) : product.product_ids,
        is_combo: tab === 'combo' ? true : false,
        unit_type: selectedUnitTYpeObject.value,
        manufacturing_charge: manufacturingCharge,
      }
      console.log('edit product ->', updateProductData)
      const data: Product | null = await supabaseData.editProductDetails(
        updateProductData,
        product.value
      )
      if (data !== null) {
        // console.log('after update product ->', data)
        setShow(false)
        window.location.reload()
      }
    }

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const file: any = e.target.files[0]
        setImage(file)
      }
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} className='row'>
        <div className='col-12 d-flex mb-3'>
          <div className='col-2'>
            <label className='form-label mt-3'>
              Name <span className='text-danger'>*</span>
            </label>
          </div>
          <div className='col-10'>
            <input
              type='text'
              className={`${
                formErrors.nameField
                  ? 'form-control form-control-solid col-9 border border-2 border-danger rounded'
                  : // : name.length >= 3
                    // ? 'form-control form-control-solid col-9 border border-2 border-success rounded'
                    'form-control form-control-solid col-9'
              } `}
              name='name'
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                setFormErrors({nameField: false})
              }}
            />
          </div>
        </div>
        {tab === 'combo' && (
          <div className='col-12 d-flex mb-3'>
            <div className='col-2'>
              <label className='form-label mt-3'>
                Products <span className='text-danger'>*</span>
              </label>
            </div>
            <div className='col-10'>
              <Select
                options={products}
                isMulti
                onChange={handleProductSelected}
                value={selectedProducts}
                name='product_ids'
              />
              {error && <p className='text-danger'>At least two Product selections are required</p>}
            </div>
          </div>
        )}
        <div className='col-12 d-flex mb-3'>
          <div className='col-2'>
            <label className='form-label mt-3'>Price</label>
          </div>
          <div className='col-10'>
            <input
              type='number'
              className='form-control form-control-solid col-9'
              name='price'
              value={price}
              onChange={(e) => {
                setPrice(parseFloat(e.target.value))
                setFormErrors({priceField: false})
              }}
            />
          </div>
        </div>
        <div className='col-12 d-flex mb-3'>
          <div className='col-2'>
            <label className='form-label mt-3'>
              GST <span className='text-danger'>*</span>
            </label>
          </div>
          <div className='col-10'>
            <input
              type='number'
              className={`${
                formErrors.gstField
                  ? 'form-control form-control-solid col-9 border border-2 border-danger rounded'
                  : 'form-control form-control-solid col-9'
              } `}
              name='GST'
              value={GST}
              onChange={(e) => {
                setGST(parseFloat(e.target.value))
                setFormErrors({gstField: false})
                setError(false)
              }}
            />
          </div>
        </div>
        {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && (
          <div className='col-12 d-flex mb-3'>
            <div className='col-3'>
              <label className='form-label mt-3'>Manufacturing</label>
            </div>
            <div className='col-9'>
              <input
                type='number'
                className={`form-control form-control-solid col-9 ${
                  formErrors.manufacturingField &&
                  'form-control form-control-solid col-9 border border-2 border-danger rounded'
                } `}
                name='manufacturingCharge'
                value={price}
                onChange={(e: any) => {
                  setManufacturingCharge(+e.target.value)
                  setFormErrors({manufacturingField: false})
                }}
              />
            </div>
          </div>
        )}
        <div className='col-12 d-flex mb-3'>
          <div className='col-2'>
            <label className='form-label mt-3'>Description: </label>
          </div>
          <div className='col-10'>
            <textarea
              className='form-control form-control-solid col-9'
              name='description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
        <div className='col-12 d-flex align-items-center mb-3 '>
          <div className='d-flex row col-8'>
            <div className='col-3'>
              <label className='form-label mt-3 d-flex'>Weight</label>
            </div>
            <div className='col-9'>
              <input
                type='number'
                className={clsx('form-control form-control-solid col-9 ', {
                  // @ts-ignore
                  'is-valid': unit >= 1,
                })}
                name='unit'
                value={unit}
                onChange={(e: any) => setUnit(+e.target.value)}
              />
            </div>
          </div>
          <div className='col-4'>
            <Select
              options={unitTypes}
              onChange={handleChangeUnitType}
              value={selectedUnitTYpeObject}
              // name='product_ids'
            />
          </div>
        </div>
        <div className='col-12 d-flex mb-3'>
          <div className='col-2'>
            <label className='form-label mt-3'>
              Code <span className='text-danger'>*</span>
            </label>
          </div>
          <div className='col-10'>
            <input
              type='text'
              className={`${
                formErrors.codeField
                  ? 'form-control form-control-solid col-9 border border-2 border-danger rounded'
                  : 'form-control form-control-solid col-9'
              } `}
              name='code'
              value={code}
              onChange={(e) => {
                setCode(e.target.value)

                setFormErrors({codeField: false})
              }}
            />
          </div>
        </div>
        {/* <div className='col-12 d-flex mb-3'>
          <div className='col-2'>
            <label className='form-label mt-3'>Image: </label>
          </div>
          <div className='col-10'>
            <input
              type='file'
              name='image'
              className='form-control col-9'
              onChange={handleImageChange}
            />
          </div>
        </div> */}
        <div className='mt-5 d-flex justify-content-end'>
          <Button variant='secondary' size='sm' className='me-3' onClick={() => setShow(false)}>
            Close
          </Button>
          <Button type='submit' variant='primary' size='sm'>
            Save
          </Button>
        </div>
      </form>
    </>
  )
}

export default EditSingleProduct
