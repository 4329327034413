import React, {useEffect, useState} from 'react'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import {DateRangePicker} from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/initialize'
import '../../../_metronic/assets/sass/datePicker.css'
import moment from 'moment'
// import {couriers} from '../AppDataDefine'

const CourierCompany = () => {
  const [courierOrders, setCourierOrders] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [startDate, setStartDate] = useState<any>(moment().subtract(0, 'year'))
  const [endDate, setEndDate] = useState<any>('')
  const [focusedInput, setFocusedInput] = useState<any>(null)

  useEffect(() => {
    getCouriers()
  }, [])
  // console.log('COUNTRY PAYMENT ->', courierOrders)

  const getCouriers = async () => {
    // console.log("START DATE ->", );

    setIsLoading(true)
    const formattedStartDate = startDate?.toISOString().split('T')[0]

    const formattedEndDate = endDate !== '' ? endDate?.toISOString().split('T')[0] : null

    const data = await supabaseData.getCouriers(formattedStartDate, formattedEndDate)
    if (data) {
      setIsLoading(false)
      setCourierOrders(data)
    }
  }

  const onDatesChange = ({startDate, endDate}: any) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const onFocusChange = (focusedInput: any) => {
    setFocusedInput(focusedInput)
  }

  const totalOrders = () => {
    return courierOrders.reduce((total: number, data: any) => {
      const courierName = Object.keys(data)[0]
      return total + data[courierName].orders
    }, 0)
  }

  const totalReturnOrders = () => {
    return courierOrders.reduce((total: number, data: any) => {
      const courierName = Object.keys(data)[0]
      return total + data[courierName].return_orders
    }, 0)
  }
  const totalCancelOrders = () => {
    return courierOrders.reduce((total: number, data: any) => {
      const courierName = Object.keys(data)[0]
      return total + data[courierName].cancel_orders
    }, 0)
  }

  return (
    <>
      <div className='card'>
        <div className='card-body '>
          <div className='row '>
            <div className='col-6'>
              <span className='fs-3 fw-bolder text-gray-700 d-block pt-2'>Courier Company</span>
            </div>
            <div className='col-6'>
              <DateRangePicker
                startDate={startDate}
                startDateId='your_unique_start_date_id'
                endDate={endDate}
                displayFormat='DD/MM/yyyy'
                endDateId='your_unique_end_date_id'
                onDatesChange={onDatesChange}
                focusedInput={focusedInput}
                small
                onFocusChange={onFocusChange}
                calendarInfoPosition='top'
                // @ts-ignore
                className='DateRangePicker'
                dayPickerWrapperClassName='DayPicker'
                calendarMonthClassName='CalendarMonth'
                navNextClassName='DayPickerNavigation_button'
                navPrevClassName='DayPickerNavigation_button'
                style={{width: '400px'}}
                isOutsideRange={() => false}
              />
              <button className='btn btn-primary btn-sm ms-2' onClick={getCouriers}>
                Apply
              </button>
            </div>
          </div>
          <div className='pt-5'>
            <div className='table-responsive'>
              {isLoading ? (
                <div
                  className='d-flex align-items-center justify-content-center'
                  style={{height: '400px'}}
                >
                  {' '}
                  <p className={loader.custom_loader}></p>
                </div>
              ) : (
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-100px'>Courier Company</th>
                      <th className='min-w-100px'>Orders</th>
                      <th className='min-w-100px'>Returns</th>
                      <th className='min-w-100px'>Cancels</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courierOrders.map((data: any, i: number) => {
                      const courierName = Object.keys(data)[0]
                      const {orders, return_orders, cancel_orders} = data[courierName]
                      return (
                        <tr key={i}>
                          <td>
                            <p className='mb-0 text-gray-700 fw-bold'>{courierName}</p>
                          </td>
                          <td>
                            <p className='mb-0 text-success fw-bold'>{orders}</p>
                          </td>
                          <td>
                            <p className='mb-0 text-danger fw-bold'>{return_orders}</p>
                          </td>
                          <td>
                            <p className='mb-0 text-danger fw-bold'>{cancel_orders}</p>
                          </td>
                        </tr>
                      )
                    })}
                    <tr className='border-top border-top-5'>
                      <td>
                        <p className='mb-0 text-gray-700 fw-bold'>Total</p>
                      </td>
                      <td>
                        <p className='mb-0 text-gray-700 fw-bold'>{totalOrders()}</p>
                      </td>
                      <td>
                        <p className='mb-0 text-gray-700 fw-bold'>{totalReturnOrders()}</p>
                      </td>
                      <td>
                        <p className='mb-0 text-gray-700 fw-bold'>{totalCancelOrders()}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CourierCompany
