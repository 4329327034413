import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {AllOrders} from '../pages/orders/AllOrders'
import SettingWrapper from '../pages/settings/SettingWrapper'
import ReturnOrdersWrapper from '../pages/returns/ReturnOrdersWrapper'
import CustomerWrapper from '../pages/customers/CustomerWrapper'
import ProductsWrapper from '../pages/Products/ProductsWrapper'
import CallHistoryWrapper from '../pages/callHistory/CallHistoryWrapper'
import CallsWrapper from '../pages/calls/CallsWrapper'
// import UsersWrapper from '../pages/users/UsersWrapper'
import Users from '../pages/users/UsersWrapper'
import Payment from '../pages/payment/Payment'
import GenerateBarcodePdf from '../pages/orders/GenerateBarcodePdf'
import ExpanseWrapper from '../pages/expanse/ExpanseWrapper'
import Reports from '../pages/reports/Reports'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* <Route path='auth/*' element={<Navigate to='/orders' />} /> */}

        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='orders' element={<AllOrders />} />
        <Route path='setting' element={<SettingWrapper />} />
        <Route path='return-orders' element={<ReturnOrdersWrapper />} />
        <Route path='customers' element={<CustomerWrapper />} />
        <Route path='products' element={<ProductsWrapper />} />
        <Route path='call-history' element={<CallHistoryWrapper />} />
        <Route path='calls' element={<CallsWrapper />} />
        <Route path='users' element={<Users />} />
        <Route path='payment' element={<Payment />} />
        <Route path='expanse' element={<ExpanseWrapper />} />
        <Route path='reports' element={<Reports />} />
        <Route path='pdf' element={<GenerateBarcodePdf />} />

        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        /> */}

        {/* <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
