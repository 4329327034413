import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {RiCloseFill} from 'react-icons/ri'
import {FaEye, FaUserAlt} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md'
import {FaEyeSlash} from 'react-icons/fa'

const UserDetails = ({user, showUserDetails, setShowUserDetails}: any) => {
  // const [show, setShow] = useState(false)
  // const handleShow = () => setShow(true)
  const [showPassword, setShowPassword] = useState(false)
  console.log('USER -> ', user)

  return (
    <>
      {/* <div className='d-flex align-items-center' onClick={handleShow}>
        <Link to='' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
          <KTIcon iconName='eye' className='fs-1' />
        </Link>
      </div> */}
      <Modal show={showUserDetails} className={`modal-lg `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>User Details</Modal.Title>
          <button
            className='border border-none fs-3 text-hover-primary rounded-circle'
            onClick={() => setShowUserDetails(false)}
          >
            <RiCloseFill />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className='d-flex align-items-center fs-1'>
              <FaUserAlt className='text-gray-600 ' />
              <span className='fw-bold ms-2 text-gray-700'>
                {user.name}
                <span className=''>
                  {user.status === true ? (
                    <span className='badge badge-light-success fs-7 fw-bold'>ACTIVE</span>
                  ) : (
                    <span className='badge badge-light-danger fs-7 fw-bold'>DEACTIVATE</span>
                  )}
                </span>
              </span>
            </p>
            <div className='ms-10 '>
              <div
              // className='d-flex align-items-center text-hover-primary'
              >
                <span className='me-1'>
                  <MdEmail className='text-primary fs-3 ' />
                </span>
                <span className='fw-bold fs-4 text-gray-700'> {user.email},</span>
              </div>
              <div className='d-flex align-items-center ms-5'>
                <p className='mb-0 fw-bold fs-4 ms-1 text-gray-700 ps-2'>
                  Role:{' '}
                  {user.role.map((role: any) => {
                    return <span> {role.label}, </span>
                  })}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UserDetails
