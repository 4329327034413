import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import Select from 'react-select'
// import {returnProductsData} from '../AppDataDefine'
import style from '../../../_metronic/assets/sass/order.module.css'
import {image_path} from '../../../utils'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {Payments} from '../../models/payment_model'
import {toast} from 'react-toastify'

const AddPayment = ({accounts, title, user}: any) => {
  const [show, setShow] = useState<boolean>(false)
  const [selectedOrder, setSelectedOrder] = useState<any>(null)
  const [selectedProductsList, setSelectedProductsList] = useState<any>([])
  const [amount, setAmount] = useState<string>('')
  const [transaction_id, setTransactionId] = useState<string>('')
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null)
  const [note, setNote] = useState<string>('')
  const [date, setDate] = useState()
  const [orders, setOrders] = useState<any>([])
  const [orderError, setOrderError] = useState<boolean>(false)
  const [paymentItems, setPaymentItems] = useState<any>([])

  useEffect(() => {
    if (selectedProductsList) {
      console.log('item ==>', selectedProductsList)
      const updatedReturnItems = selectedProductsList.map((item: any) => ({
        id: item.id,
        price: item.price,
        units: item.units,
        damage_unit: item.damage_unit,
        return_unit: item.return_unit,
        sub_total: item.sub_total,
        refund_amount: item.refund_amount,
        // product_id: item.product_id,
      }))
      setPaymentItems(updatedReturnItems)
    }
  }, [selectedOrder])
  const [formErrors, setFormErrors] = useState<any>({
    idField: '',
    dateField: '',
    accountField: '',
    amountField: '',
    transaction_idField: '',
  })

  const handleShow = () => setShow(true)

  const clearForm = () => {
    setSelectedOrder(null)
    setSelectedProductsList([])
    setAmount('')
    setTransactionId('')
    setSelectedAccount(null)
    setNote('')
    setOrders([])
    setOrderError(false)
    setPaymentItems([])
    setShow(false)
  }

  const handleOrderSelect = (selectedOption: any) => {
    console.log('order ->', selectedOption)
    setSelectedOrder(selectedOption)
    setSelectedProductsList(selectedOption.order_items)
    setFormErrors({idField: false})
  }

  const handlePaymentSubmit = async (e: any) => {
    e.preventDefault()
    if (!selectedOrder || !selectedAccount || !amount || amount > selectedOrder.total_amount) {
      setFormErrors({
        idField: selectedOrder ? false : true,
        // dateField: date ? false : true,
        accountField: selectedAccount ? false : true,
        amountField: !amount || amount > selectedOrder.total_amount ? true : false,
      })
      return
    }
    const paymentObj = {
      order_id: selectedOrder?.id,
      payment_method: selectedAccount,
      transaction_id,
      amount: +amount,
      customer_id: selectedOrder?.customer_id,
      // payment_date: date,
      created_by: user.id,
      order_items: paymentItems,
      note,
      is_return: title === 'Return Payment Details' ? true : false,
    }

    console.log('payment obj =>', paymentObj)
    const data: Payments | boolean = await supabaseData.addPayment(paymentObj)
    if (data) {
      setShow(false)
      setSelectedOrder(null)
      setSelectedAccount(null)
      setTransactionId('')
      setNote('')
      setAmount('')
      setOrderError(false)
      window.location.reload()
    }
  }

  const getOrders = async (searchValue: string) => {
    const type = title === 'Return Payment Details' ? 'return' : 'orders'

    const data: any = await supabaseData.getOrders(searchValue, type, 1)

    if (data.orders.length === 0) {
      setOrderError(true)
    } else {
      console.log('orders from add return order ->', data)
      setOrderError(false)
      setOrders(data.orders)
    }
  }

  const handleInputChange = (inputValue: string) => {
    if (inputValue.length >= 3) {
      getOrders(inputValue)
    }
  }

  const handleChangeInput = (e: any, index: number, type: string) => {
    console.log('function call')

    const {value} = e.target
    const newItem = [...paymentItems]
    if (title === 'Payment Sheet') {
      if (type === 'damage_unit') {
        newItem[index] = {
          ...newItem[index],
          sub_total: +value,
        }

        console.log('NEW ITEM', newItem)
        setPaymentItems(newItem)
      }
    } else {
      if (type === 'damage_unit') {
        newItem[index] = {
          ...newItem[index],
          refund_amount: +value,
        }

        console.log('NEW ITEM', newItem)
        setPaymentItems(newItem)
      }
    }
  }

  return (
    <>
      <div className='btn btn-sm btn-light-primary me-5' onClick={handleShow}>
        <KTIcon iconName='plus' className='fs-3' />
        New Payment
      </div>
      <Modal show={show} className={`modal-xl `} style={{maxHeight: '850px', overflowY: 'scroll'}}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handlePaymentSubmit}>
            <div className='px-5' style={{maxHeight: '650px', overflowY: 'scroll'}}>
              <div className=' d-flex border-b border-gray-200 dark:border-gray-700 border-bottom pb-5'>
                <div className='col-6 d-flex'>
                  <label className='col-3 form-label pt-2 fs-5 me-3' style={{width: '115px'}}>
                    Order Id <span className='text-danger'>*</span>
                  </label>
                  <div className='col-10 pt-1'>
                    {orderError && <p className='mb-1 text-danger'>Return order not found.</p>}
                    <Select
                      className={` ${orderError && ' border border-2 border-danger rounded'}`}
                      id='SelExample'
                      onInputChange={handleInputChange}
                      options={orders.map((order: any) => ({
                        ...order,
                        label: `${order.tracking_id} - ${order.customers.mobile} - ${new Date(
                          order.order_date
                        ).toLocaleDateString('en-GB')} - ${order.order_courier_provider.name} - ${
                          order.total_amount
                        }`,
                        value: order.id,
                      }))}
                      onChange={handleOrderSelect}
                      placeholder='Search...'
                    />
                  </div>
                </div>
                {/* <div className='col-5 d-flex ms-20'>
                  <label className='form-label pt-2 fs-5 me-3 pt-3 ' style={{width: '115px'}}>
                    Date <span className='text-danger'>*</span>
                  </label>
                  <div className='col-8 pt-1'>
                    <input
                      type='date'
                      className={` ${
                        formErrors.dateField
                          ? 'form-control form-control-sm form-control-solid border border-2 border-danger rounded'
                          : date
                          ? 'form-control form-control-sm form-control-solid border border-2 border-success rounded'
                          : 'form-control form-control-sm form-control-solid'
                      }`}
                      value={date}
                      onChange={(e: any) => {
                        setDate(e.target.value)
                        setFormErrors({dateField: false})
                      }}
                    />
                  </div>
                </div> */}
              </div>
              <div className='border-bottom'>
                {selectedOrder && (
                  <>
                    <div className='border-bottom row mt-5'>
                      <div className='col-6'>
                        <p>Customer Name: {selectedOrder?.customers?.name}</p>
                        <p>Mobile: {selectedOrder?.customers?.mobile}</p>
                      </div>
                    </div>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-200px'>Name</th>
                          <th className='min-w-100px'>Price</th>
                          <th className='min-w-100px'>Units</th>
                          {title === 'Return Payment Details' && (
                            <th className='min-w-100px'>Return Units</th>
                          )}
                          {title === 'Return Payment Details' && (
                            <th className='min-w-100px'>Damage Units</th>
                          )}
                          {title === 'Return Payment Details' ? (
                            <th className='min-w-150px '>Refund Amount</th>
                          ) : (
                            <th className='min-w-150px '>Total</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProductsList?.map((item: any, index: number) => {
                          return (
                            <tr key={item.value}>
                              <td>
                                <div className='d-flex cursor-pointer'>
                                  <div>
                                    {/* <img
                                      className={style.set_product_image}
                                      src={`${image_path}${item.products.image}`}
                                    /> */}
                                  </div>
                                  <div className='ms-5 fw-semi-bold'>
                                    <p className='mb-0 fs-6 fw-bold'>{item.label}</p>
                                    <p className='mb-0  fs-8 fw-bold text-gray-600'>
                                      CODE : {item.code}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className=' me-4'>
                                  <input
                                    style={{width: '100px'}}
                                    type='number'
                                    className='form-control form-control-solid form-control-sm'
                                    name='price'
                                    value={paymentItems[index]?.price}
                                    readOnly
                                    // onChange={(e) => handleChangeInput(e, index, 'price')}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className=' me-4'>
                                  <input
                                    style={{width: '100px'}}
                                    type='number'
                                    name='units'
                                    className='form-control form-control-solid form-control-sm '
                                    value={paymentItems[index]?.units}
                                    readOnly
                                    // onChange={(e) => handleChangeInput(e, index, 'units')}
                                  />
                                </div>
                              </td>
                              {title === 'Return Payment Details' && (
                                <td className='me-4 position-relative'>
                                  <input
                                    type='number'
                                    name='return_unit'
                                    className='form-control form-control-solid form-control-sm'
                                    value={paymentItems[index]?.return_unit}
                                    //  onChange={(e) => handleChange(e, i, 'return_unit')}
                                    readOnly
                                  />
                                  {/* {inputErrors[i] && (
                                   <p className='text-danger position-absolute'>Enter valid Units</p>
                                 )} */}
                                </td>
                              )}
                              {title === 'Return Payment Details' && (
                                <td className='position-relative'>
                                  <input
                                    type='number'
                                    name='damage_unit'
                                    className='form-control form-control-solid form-control-sm'
                                    value={paymentItems[index]?.damage_unit}
                                    //  onChange={(e) => handleChange(e, i, 'damage_unit')}
                                    readOnly
                                  />
                                </td>
                              )}
                              {title === 'Return Payment Details' ? (
                                <td>
                                  <div>
                                    <input
                                      style={{width: '150px'}}
                                      type='number'
                                      name='total'
                                      className='form-control form-control-solid form-control-sm'
                                      value={paymentItems[index]?.refund_amount}
                                      onChange={(e) => handleChangeInput(e, index, 'damage_unit')}
                                    />
                                  </div>
                                </td>
                              ) : (
                                <td>
                                  <div>
                                    <input
                                      style={{width: '150px'}}
                                      type='number'
                                      name='total'
                                      className='form-control form-control-solid form-control-sm'
                                      value={paymentItems[index]?.sub_total}
                                      onChange={(e) => handleChangeInput(e, index, 'damage_unit')}
                                    />
                                  </div>
                                </td>
                              )}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <div className='pe-20 me-20'>
                      <div className='pe-20'>
                        {title === 'Payment Details' && (
                          <p className='border-top pt-3 d-flex justify-content-end fs-5 fw-bold text-gray-700'>
                            <span style={{width: '150px'}}>Shipping Charges : </span>
                            <span> {selectedOrder.shipping_charges}</span>
                          </p>
                        )}
                        {/* {title === 'Payment Details' ? (
                          <p className='border-top pt-3 d-flex justify-content-end fs-5 fw-bold text-gray-700'>
                            <span className='fs-5 fw-bold text-gray-700'>
                              <span style={{width: '150px'}}>Paid Amount : </span>
                              <span>
                                {!selectedOrder.paid_amount ? 0 : selectedOrder.paid_amount}
                              </span>
                            </span>
                          </p>
                        ) : (
                          <p className='border-top pt-3 d-flex justify-content-end fs-5 fw-bold text-gray-700'>
                            <span className='fs-5 fw-bold text-gray-700'>
                              <span style={{width: '150px'}}>Refund Amount : </span>
                              <span>{}</span>
                            </span>
                          </p>
                        )} */}
                        <p className='border-top pt-3 d-flex justify-content-end fs-5 fw-bold text-gray-700'>
                          <span className='fs-5 fw-bold text-gray-700'>
                            <span style={{width: '150px'}}>Total Amount : </span>
                            <span>{selectedOrder.total_amount}</span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className='mt-8  border-b border-gray-200 dark:border-gray-700 pb-5'>
                <div className='col d-flex'>
                  <label className='form-label pt-2 fs-5 me-3' style={{width: '115px'}}>
                    Account <span className='text-danger'>*</span>
                  </label>
                  <div className='col-5 pt-1'>
                    <Select
                      className={` ${
                        formErrors.accountField && ' border border-2 border-danger rounded'
                      }`}
                      options={accounts}
                      onChange={(selectedOption: any) => {
                        console.log('VALUE', selectedOption)
                        setSelectedAccount(selectedOption.value)
                        setFormErrors({accountField: false})
                      }}
                    />
                  </div>
                </div>
                {selectedAccount && (
                  <div className='ms-20 col-5'>
                    <input
                      className={`form-control form-control-solid form-control-sm mt-5 ms-19 ${
                        formErrors.transaction_idField &&
                        'form-control form-control-solid form-control-sm mt-5 ms-19 border border-2 border-danger rounded'
                      }`}
                      type='text'
                      value={transaction_id}
                      onChange={(e: any) => {
                        setTransactionId(e.target.value)
                        setFormErrors({transaction_idField: false})
                      }}
                      placeholder='Enter reference id'
                    />
                  </div>
                )}
              </div>
              <div className='mt-8  border-b border-gray-200 dark:border-gray-700 border-bottom pb-5'>
                <div className='col d-flex'>
                  <label className='form-label pt-2 fs-5 ' style={{width: '125px'}}>
                    Amount <span className='text-danger'>*</span>
                  </label>
                  <div className='col-5'>
                    <input
                      className={`form-control form-control-sm form-control-solid ${
                        formErrors.amountField &&
                        'form-control form-control-sm form-control-solid border border-2 border-danger rounded'
                      }`}
                      type='number'
                      value={amount}
                      onChange={(e: any) => {
                        setAmount(e.target.value)
                        setFormErrors({amountField: false})
                      }}
                      placeholder='Enter Amount'
                    />
                    {formErrors.amountField && (
                      <span className='text-danger'>Please Enter Valid Amount *</span>
                    )}
                  </div>
                </div>
              </div>
              <div className='mt-8  border-b border-gray-200 dark:border-gray-700 border-bottom pb-5'>
                <div className='col d-flex'>
                  <label className='form-label pt-2 fs-5 ' style={{width: '125px'}}>
                    Note
                  </label>
                  <div className='col-5'>
                    <input
                      className='form-control form-control-sm form-control-solid'
                      type='text'
                      value={note}
                      onChange={(e: any) => setNote(e.target.value)}
                      placeholder='Enter note'
                    />
                  </div>
                </div>
              </div>
            </div>
            <Modal.Footer className='p-0'>
              <div className='mt-5 d-flex justify-content-end'>
                <Button variant='secondary' size='sm' className='me-3' onClick={clearForm}>
                  Close
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddPayment
