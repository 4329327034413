import React, {ChangeEvent, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'

const PincodeDetails = () => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [pincodeData, setPincodeData] = useState<any>([])

  // const [showtable, setShowTable] = useState<boolean>(false)
  // const [isLoader, setIsLoader] = useState(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    setSearchQuery(inputValue)
    if (inputValue.length === 6) {
      // setIsLoader(true)
      // setShowTable(true)

      setIsLoading(true)

      // console.log('functon called', inputValue)
      const data = await supabaseData.setPincodeRelatedValue(inputValue)

      if (!data.message) {
        setPincodeData(data)

        // setIsLoader(false)

        setIsLoading(false)
      } else {
        console.log('Pincode error -->', data.message)
      }
    } else setPincodeData([])
    // console.log('PINCODE DATA', pincodeData)
  }
  return (
    <div className='card ps-5 '>
      <div className='card-body p-5 '>
        <div className='row '>
          <div className='col-6'>
            <span className='fs-3 fw-bolder text-gray-700 d-block pt-2'>Pincode Details</span>
          </div>
          <div className='col-6'>
            <div className='d-flex justify-content-end'>
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <div>
                  <input
                    className='form-control form-control-sm form-control-solid ps-14'
                    list='search'
                    placeholder='Search Pincode details...'
                    value={searchQuery}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{maxHeight: '450px', overflowY: 'scroll'}}>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-100px'>Pincode</th>
                <th className='min-w-100px'>Office name</th>
                <th className='min-w-100px'>Taluka</th>
                <th className='min-w-100px'>District</th>
                <th className='min-w-100px'>State</th>
                <th className='min-w-100px'>Village / City</th>
              </tr>
            </thead>
            {showtable &&
              (isLoader ? (
                <div
                  className='d-flex align-items-center justify-content-center'
                  style={{height: '400px'}}
                >
                  {' '}
                  <p className={loader.custom_loader}></p>
                </div>
              ) : (
                <tbody>
                  {pincodeData.map((data: any, i: number) => {
                    return (
                      <tr key={i}>
                        <td>
                          <p className='mb-0 text-gray-700 fw-bold'>{data.pincode}</p>
                        </td>
                        <td>
                          <p className='mb-0 text-gray-700 fw-bold'>{data.office_name}</p>
                        </td>
                        <td>
                          <p className='mb-0 text-gray-700 fw-bold'>{data.taluka}</p>
                        </td>
                        <td>
                          <p className='mb-0 text-gray-700 fw-bold'>{data.district}</p>
                        </td>
                        <td>
                          <p className='mb-0 text-gray-700 fw-bold'>{data.state}</p>
                        </td>
                        <td>
                          <p className='mb-0 text-gray-700 fw-bold'>{data.village_locality_name}</p>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              ))}
          </table>
        </div> */}

        {isLoading ? (
          <div className={loader.custom_pincode_loader_container}>
            <p className={loader.custom_loader}></p>
          </div>
        ) : (
          <div style={{maxHeight: '450px', overflowY: 'scroll'}}>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-100px'>Pincode</th>
                  <th className='min-w-100px'>Village / City</th>
                  <th className='min-w-100px'>Office name</th>
                  <th className='min-w-100px'>Taluka</th>
                  <th className='min-w-100px'>District</th>
                  <th className='min-w-100px'>State</th>
                </tr>
              </thead>
              <tbody>
                {pincodeData.map((data: any, i: number) => {
                  return (
                    <tr key={i}>
                      <td>
                        <p className='mb-0 text-gray-700 fw-bold'>{data.pincode}</p>
                      </td>
                      <td>
                        <p className='mb-0 text-gray-700 fw-bold'>{data.village_locality_name}</p>
                      </td>
                      <td>
                        <p className='mb-0 text-gray-700 fw-bold'>{data.office_name}</p>
                      </td>
                      <td>
                        <p className='mb-0 text-gray-700 fw-bold'>{data.taluka}</p>
                      </td>
                      <td>
                        <p className='mb-0 text-gray-700 fw-bold'>{data.district}</p>
                      </td>
                      <td>
                        <p className='mb-0 text-gray-700 fw-bold'>{data.state}</p>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default PincodeDetails
