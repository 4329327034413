import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import classes from '../Page.module.css'
import {toast} from 'react-toastify'
import {AddUser, UserRoles, User, UsersType} from '../../models/user_models'
import Select from 'react-select'
import clsx from 'clsx'

interface AddNewUserProps {
  userRoles: UserRoles[]
}

const AddNewUser: React.FC<AddNewUserProps> = ({userRoles}) => {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState([])
  const [selectedRole, setSelectedRole] = useState('')
  const [active, setActive] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [formErrors, setFormErrors] = useState<any>({
    nameField: '',
    emailField: '',
    passwordField: '',
    roleField: '',
  })

  const handleRole = (selectedRole: any) => {
    const selectedValues = selectedRole.map((option: any) => option.value)
    setSelectedRole(selectedValues)
    setFormErrors({roleField: false})
  }
  console.log('ROLE ==>', selectedRole)

  const isValidEmail = (email: any) => {
    // Regular expression for a valid email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleSubmitData = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    e.preventDefault()
    if (!name || !email || !password || !selectedRole) {
      setFormErrors({
        nameField: !name,
        emailField: !isValidEmail(email),
        passwordField: !password,
        roleField: !selectedRole,
      })
      return
    }
    const userData: AddUser = {
      name: name,
      email: email,
      password,
      role: JSON.stringify(selectedRole),
      status: active,
    }
    console.log('DATA', userData)

    const new_user: User | undefined = await supabaseData.addNewUser(userData, setIsLoading)
    // if (new_user) setUsers([...users, new_user])
    if (new_user !== undefined) {
      setIsLoading(false)
      setShow(false)
      window.location.reload()
    }
  }

  return (
    <>
      <div className='btn btn-sm btn-light-primary' onClick={handleShow}>
        <KTIcon iconName='plus' className='fs-3' />
        New User
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={handleSubmitData}>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Name <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='text'
                  className={` form-control form-control-solid col-9
                     ${
                       formErrors.nameField &&
                       'form-control form-control-solid col-9 border border-2 border-danger rounded'
                     }`}
                  name='name'
                  placeholder='Enter name'
                  value={name}
                  onChange={(e: any) => {
                    setName(e.target.value)
                    setFormErrors({nameField: false})
                  }}
                />
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Email <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='email'
                  className={` form-control form-control-solid col-9 ${
                    formErrors.emailField &&
                    'form-control form-control-solid col-9 border border-2 border-danger rounded'
                  }`}
                  name='email'
                  value={email}
                  placeholder='Enter email'
                  onChange={(e: any) => {
                    setEmail(e.target.value)
                    setFormErrors({emailField: false})
                  }}
                />
                {formErrors.emailField && <span className='text-danger'>Enter Valid Emile </span>}
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Password <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='password'
                  className={` 
                  ${
                    formErrors.passwordField
                      ? 'form-control form-control-solid col-9 border border-2 border-danger rounded'
                      : password.length >= 6
                      ? 'form-control form-control-solid col-9 border border-2 border-success rounded'
                      : 'form-control form-control-solid col-9'
                  }`}
                  name='password'
                  value={password}
                  placeholder='Enter password'
                  onChange={(e: any) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Role <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-10'>
                <Select
                  className={`${formErrors.roleField && 'border border-2 border-danger rounded'}`}
                  isMulti
                  options={userRoles}
                  onChange={handleRole}
                  // onChange={(selectedRole: any) => {
                  //   setSelectedRole(selectedRole.value)
                  //   setFormErrors({roleField: false})
                  // }}
                />
              </div>
            </div>

            <div className='col-12 d-flex mb-3 align-items-center'>
              <div className='col-2'>
                <label className='form-label mt-3'>Status</label>
              </div>
              <div className='form-check form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  name='status'
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
                <label className='form-check-label fw-bold'>
                  {active ? 'Active' : 'De-active'}
                </label>
              </div>
            </div>

            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
                {/* {isLoading ? (
                  <Button>
                    <p className='mb-0'>Wait...</p>
                  </Button>
                ) : ( */}
                <Button type='submit' variant='primary' size='sm'>
                  Save
                </Button>
                {/* )} */}
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddNewUser
