import {useEffect, useState} from 'react'
import {ShowPendingAmountDetails} from './ShowPendingAmountDetails'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {KTIcon} from '../../../_metronic/helpers'

export const PendingPayments = () => {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(!show)
  const [totalPendingPayments, setTotalPendingPayments] = useState(0)

  useEffect(() => {
    getPendingPayments()
  }, [])

  const getPendingPayments = async () => {
    const data: any = await supabaseData.getLast25DaysPayments()

    const totalSum = data.reduce((accumulator: number, currentValue: any) => {
      return accumulator + currentValue.total_amount
    }, 0)

    setTotalPendingPayments(totalSum)
  }

  return (
    <div className='card p-4 ms-3'>
      <div className='d-flex align-items-center justify-content-between'>
        <h5 className='fs-2 text-gray-700'>
          <span>Pending Amounts :</span>
          <span className='ps-6'>{totalPendingPayments}</span>
        </h5>
        <button
          className='btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm me-1'
          onClick={() => setShow(true)}
        >
          <KTIcon iconName='eye' className='fs-1' />
        </button>
        {show && <ShowPendingAmountDetails show={show} handleShow={handleShow} />}
      </div>
    </div>
  )
}
