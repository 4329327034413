import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import Select from 'react-select'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {BiSolidDownArrowCircle} from 'react-icons/bi'
import {sample_file_path} from '../../../utils'
// import {SheetModel} from '../../models/setting_models'

const ImportSheet = ({sheetTitle, user, courierCompanies}: any) => {
  const [show, setShow] = useState<boolean>(false)
  const [selectedCourier, setSelectedCourier] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)
  const [payment_reference, setPaymentReference] = useState<string>('')
  const [amount, setAmount] = useState<string>('')
  const [date, setDate] = useState<string>('')
  const [formErrors, setFormError] = useState<any>({
    courierField: '',
    fileField: '',
    paymentField: '',
    amountFiled: '',
    dateField: '',
  })
  const handleShow = () => setShow(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (file) {
      setFile(file)
    }
  }

  const isDisable = selectedCourier.length === 0

  const handleFormSubmit = async (e: any) => {
    e.preventDefault()
    if (sheetTitle === 'Barcode Sheet') {
      if (!selectedCourier || !file) {
        setFormError({
          courierField: selectedCourier ? false : true,
          fileField: file ? false : true,
        })
        return
      }
    }
    if (sheetTitle === 'Payment Sheet') {
      if (!selectedCourier || !file || payment_reference === '' || amount === '' || date === '') {
        setFormError({
          courierField: selectedCourier ? false : true,
          fileField: file ? false : true,
          paymentField: payment_reference !== '' ? false : true,
          amountFiled: amount !== '' ? false : true,
          dateField: date !== '' ? false : true,
        })
        return
      }
    }

    if (!file) return

    // console.log('file ->', file)
    // console.log('courier ->', selectedCourier)

    const sheetData = new FormData()
    if (file) {
      // console.log('file ->', file)
      sheetData.append('file', file)
    }
    sheetData.append('courier_provider_id', selectedCourier)

    if (sheetTitle === 'Payment Sheet') {
      sheetData.append('payment_reference', payment_reference)
      sheetData.append('amount', amount)
      sheetData.append('date', date)
      sheetData.append('created_by', user.id)
    }

    console.log('sheet_obj ->', sheetData)

    setIsLoading(true)

    const data: boolean = await supabaseData.sheetUpload(sheetData, setShow)
    if (data) {
      setIsLoading(false)
      window.location.reload()
    }
  }

  return (
    <>
      <div className='btn btn-sm btn-light-primary me-3 ' onClick={handleShow}>
        <KTIcon iconName='plus' className='fs-3' />
        {sheetTitle}
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title> {sheetTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div className=''>
              <div className='row mb-5'>
                <div className='col-4'>
                  <label className='form-label pt-2 fs-5'>
                    Courier Company <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-8 pt-1'>
                  <Select
                    options={courierCompanies}
                    onChange={(selectedOption: any) => {
                      // console.log('VALUE', selectedOption)
                      setSelectedCourier(selectedOption.value)
                    }}
                  />
                </div>
              </div>
            </div>
            {sheetTitle === 'Payment Sheet' && (
              <div className='row mb-3'>
                <div className='col-4 '>
                  <label className='form-label pt-2 fs-5'>
                    Date <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-8'>
                  <input
                    type='date'
                    className={`${
                      formErrors.dateField
                        ? 'form-control form-control-sm form-control-solid border border-2 border-danger rounded'
                        : 'form-control form-control-sm form-control-solid'
                    }`}
                    name='paymentSheetName'
                    value={date}
                    placeholder='Enter amount'
                    onChange={(e: any) => {
                      setDate(e.target.value)
                      setFormError({dateField: false})
                    }}
                  />
                </div>
              </div>
            )}

            <div className='row mb-5'>
              <div className='col-4'>
                <label className='form-label pt-2 fs-5'>
                  Import File
                  <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-8'>
                <input
                  type='file'
                  className={`${
                    formErrors.fileField
                      ? 'form-control form-control-sm border border-2 border-danger rounded'
                      : 'form-control form-control-sm'
                  }`}
                  // accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  onChange={handleFileChange}
                />
              </div>
            </div>

            {sheetTitle === 'Payment Sheet' && (
              <>
                <div className='row mb-5'>
                  <div className='col-4'>
                    <label className='form-label pt-2 fs-5'>
                      Payment Reference <span className='text-danger'>*</span>
                    </label>
                  </div>
                  <div className='col-8 pt-1'>
                    <input
                      className={`${
                        formErrors.paymentField
                          ? 'form-control form-control-sm border border-2 border-danger rounded'
                          : 'form-control form-control-sm'
                      }`}
                      type='text'
                      value={payment_reference}
                      placeholder='Enter Payment Reference'
                      required
                      onChange={(e: any) => {
                        setPaymentReference(e.target.value)
                        setFormError({paymentField: false})
                      }}
                    />
                  </div>
                </div>

                <div className='row mb-5'>
                  <div className='col-4'>
                    <label className='form-label pt-2 fs-5'>
                      Amount<span className='text-danger'>*</span>
                    </label>
                  </div>
                  <div className='col-8 pt-1'>
                    <input
                      className={`${
                        formErrors.amountFiled
                          ? 'form-control form-control-sm border border-2 border-danger rounded'
                          : 'form-control form-control-sm'
                      }`}
                      type='text'
                      value={amount}
                      placeholder='Enter Amount'
                      required
                      onChange={(e: any) => {
                        setAmount(e.target.value)
                        setFormError({amountFiled: false})
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <div className='row mb-5'>
              <div className='col-4'>
                <label className='form-label pt-2 fs-5'>Sample Sheet</label>
              </div>

              {sheetTitle === 'Barcode Sheet' ? (
                <div className='col-8'>
                  <a
                    href={`${sample_file_path}/uploads/barcode_sheet.xlsx`}
                    className='btn btn-light-primary'
                    download
                  >
                    <BiSolidDownArrowCircle className='fs-1' /> Download
                  </a>
                </div>
              ) : (
                <div className='col-8'>
                  <a
                    href={`${sample_file_path}/uploads/payment_sheet.xlsx`}
                    className='btn btn-light-primary'
                    download
                  >
                    <BiSolidDownArrowCircle className='fs-1' /> Download
                  </a>
                </div>
              )}
            </div>
            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
                <Button type='submit' variant='primary' size='sm' disabled={isDisable}>
                  {isLoading ? 'Wait...' : 'Save'}
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ImportSheet
