import React, {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import clsx from 'clsx'
import classes from '../Page.module.css'
import {supabase} from '../../supabase/SupabaseConnect'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {AccountType} from '../../models/setting_models'
const AddAccount = ({accounts, setAccounts}: any) => {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const [account, setAccount] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmitData = async (e: any) => {
    setIsLoading(true)
    e.preventDefault()
    // const newAccount = {
    //   name: account,
    // }

    const data: AccountType | undefined = await supabaseData.addAccountType(account)
    // @ts-ignore

    if (data) {
      setAccounts([...accounts, data])

      setShow(false)
      setIsLoading(false)
      setAccount('')
    }
  }
  return (
    <>
      <div className='d-flex align-items-center btn btn-sm btn-light-primary' onClick={handleShow}>
        <KTIcon iconName='plus' className='fs-3' />
        Add
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>Add New Account Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={handleSubmitData}>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Name <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='text'
                  className={clsx('form-control form-control-solid col-9 ', {
                    'is-valid': account.length >= 4,
                  })}
                  name='name'
                  value={account}
                  onChange={(e: any) => setAccount(e.target.value)}
                  required
                />
              </div>
            </div>

            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
                {isLoading ? (
                  <Button>
                    <p className={classes.loader}></p>Wait...
                  </Button>
                ) : (
                  <Button type='submit' variant='primary' size='sm'>
                    Save
                  </Button>
                )}
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddAccount
