import React, {useEffect, useState} from 'react'
import ReturnPayments from './ReturnPayments'
// import {allOrders} from '../AppDataDefine'
import OrderExpanse from './OrderExpanse'
import {ToastContainer} from 'react-toastify'

const ExpanseWrapper = () => {
  const [returnPayment, setReturnPayment] = useState([])
  useEffect(() => {
    // if (allOrders) {
    //   const payments: any = []
    //   allOrders.map((order: any) => {
    //     if (order.order_status === 'Return') {
    //       payments.push(order)
    //     }
    //   })
    //   setReturnPayment(payments)
    // }
  }, [])
  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className='row'>
        <div className='col-5'>
          <OrderExpanse />
        </div>
        <div className='col-7'>
          <ReturnPayments />
        </div>
      </div>
    </>
  )
}

export default ExpanseWrapper
