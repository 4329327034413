import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import Select from 'react-select'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {RoleWiseUsers} from '../../models/user_models'
import {AddCustomer, Customer, CustomersType} from '../../models/customer_models'
import PincodeAddress from '../customers/PincodeAddress'

const AddNewCustomer = ({title, sales_men, from}: any) => {
  const formTitle = 'Add Customer'
  const user: string = localStorage.getItem('user_data') as string
  const user_data = JSON.parse(user)
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const [selectedSalesMan, setSelectedSalesMan] = useState<any>('')
  const [selectedPlaceValue, setSelectedPlaceValue] = useState<string>('City')
  const [place, setPlace] = useState<string>('')
  const [office, setOffice] = useState<string>('')
  const [manually, setManually] = useState(false)

  const [selectPlace, setSelectPlace] = useState<any>([
    {value: 'Village', label: 'Village'},
    {value: 'City', label: 'City'},
  ])
  // const [addressObj, setAddressObj] = useState({
  //   pincode: '',
  //   city: '',
  //   village: '',
  //   district: '',
  //   taluka: '',
  //   state: '',
  // })
  const [isOffice, setIsOffice] = useState<boolean>(false)
  const [pincodeValue, setPincodeValue] = useState<any>()
  const [pincodeError, setPincodeError] = useState<boolean>(false)
  const [customerInfo, setCustomerInfo] = useState<any>({
    name: '',
    mobile: '',
    mobile_2: '',
    mobile_3: '',
    address: '',
    district: '',
    taluka: '',
    state: '',
    pincode: '',
    sales_man_id: '',
    at_post: '',
    city: '',
    village: '',
    created_by: '',
  })
  const [formErrors, setFormErrors] = useState<any>({
    nameFiled: '',
    mobileFiled: '',
    addressFiled: '',
    pincodeFiled: '',
    placeTitleField: '',
    placeField: '',
    talukaField: '',
    districtField: '',
    stateField: '',
    salesmanField: '',
  })

  const handleInput = async (e: any) => {
    setCustomerInfo({...customerInfo, [e.target.name]: e.target.value})
    setFormErrors({
      nameFiled: '',
      mobileFiled: false,
      addressFiled: false,
      pincodeFiled: false,
      placeField: false,
      talukaField: false,
      districtField: false,
      stateField: false,
    })
    // if (e.target.name === 'pincode') {
    //   setPincodeValue(null)
    //   const updatedValue = e.target.value
    //   setPincodeError(false)
    //   if (updatedValue.length === 6) {
    //     setIsOffice(true)
    //     const data: any = await supabaseData.setPincodeRelatedValue(updatedValue)
    //     console.log(' PNCODE DATA ->', data)
    //     if (data.message || data.length === 0) {
    //       setPincodeError(true)
    //     } else {
    //       setPincodeValue(data)
    //       setPincodeError(false)
    //       setFormErrors({
    //         ...formErrors,
    //         talukaField: false,
    //         districtField: false,
    //         stateField: false,
    //       })
    //     }
    //   }
    // }
  }

  const handleSubmitData = async (e: any) => {
    console.log('Pincode value =>', customerInfo.pincode)

    e.preventDefault()
    if (
      customerInfo.name.length <= 2 ||
      customerInfo.mobile.length <= 9 ||
      customerInfo.mobile?.length > 10 ||
      customerInfo.address === '' ||
      customerInfo.pincode.length !== 6 ||
      // customerInfo.pincode?.length > 6 ||
      // (pincodeValue ? pincodeValue.taluka?.length <= 2 : customerInfo.taluka?.length <= 2) ||
      // (pincodeValue ? pincodeValue.district.length <= 2 : customerInfo.district.length <= 2) ||
      // (pincodeValue ? pincodeValue.state.length <= 2 : customerInfo.state.length <= 2) ||
      // (customerInfo.city === null && customerInfo.village === null) ||

      !selectedSalesMan
    ) {
      console.log('if In')
      setFormErrors({
        nameFiled: customerInfo.name.length >= 3 ? false : true,
        mobileFiled: customerInfo.mobile.length === 10 ? false : true,
        addressFiled: customerInfo.address !== '' ? false : true,
        pincodeFiled: customerInfo.pincode.length === 6 ? false : true,
        placeField: customerInfo.city || customerInfo.village ? false : true,
        talukaField:
          customerInfo?.taluka?.length >= 2 || pincodeValue?.taluka?.length >= 2 ? false : true,
        // talukaField: customerInfo.taluka.length >= 3 ? false : true,
        districtField:
          customerInfo.district?.length >= 3 || pincodeValue?.district?.length >= 2 ? false : true,
        stateField:
          customerInfo?.state?.length >= 3 || pincodeValue?.state?.length >= 2 ? false : true,
        salesmanField: selectedSalesMan ? false : true,
      })
      return
    }
    const newObj = {
      ...customerInfo,
      sales_man_id: selectedSalesMan,
      created_by: user_data.id,
    }
    console.log('NEW OBJECT ->', newObj)

    const data: any = await supabaseData.addNewCustomer(newObj)
    if (data !== undefined) {
      setCustomerInfo({
        name: '',
        mobile: '',
        mobile_2: '',
        mobile_3: '',
        address: '',
        district: '',
        taluka: '',
        state: '',
        pincode: '',
        at_post: '',
        sales_man_id: '',
        created_by: '',
        city: '',
        village: '',
      })
      setShow(false)
      setPincodeValue(null)
      setIsOffice(false)
      // setCustomers([...customers, data])
      if (from === 'customer') window.location.reload()
    }
  }

  return (
    <>
      <div
        className='btn btn-light-primary ms-5 '
        style={{
          height: `${from === 'order' ? '38px' : '34px'}`,
          paddingTop: `${from === 'order' ? '7px' : '4px'}`,
        }}
        onClick={handleShow}
      >
        {title ? (
          <span className='d-flex align-items-center'>
            <KTIcon iconName='plus' className='fs-3' /> {title}
          </span>
        ) : (
          'Add'
        )}
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='px-auto' style={{backgroundColor: '#afc6ac'}}>
          <Modal.Title>Add New Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={handleSubmitData}>
            <div style={{maxHeight: '700px', overflowY: 'scroll'}}>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>
                    Name <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-10'>
                  <input
                    type='text'
                    className={`form-control form-control-solid col-9${
                      formErrors.nameFiled &&
                      'form-control form-control-solid col-9 border border-2 border-danger rounded'
                    } `}
                    placeholder='Enter name'
                    name='name'
                    value={customerInfo.name}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>
                    Mobile <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-10'>
                  <input
                    type='number'
                    className={` form-control form-control-solid col-9
                     ${
                       (formErrors.mobileFiled || customerInfo.mobile.length >= 11) &&
                       'form-control form-control-solid col-9 border border-2 border-danger rounded'
                     } `}
                    placeholder='Enter mobile number'
                    name='mobile'
                    value={customerInfo.mobile}
                    onChange={handleInput}
                    min={0}
                    step={1}
                  />
                  {formErrors.mobileFiled && (
                    <span className='text-danger'>
                      Mobile number is not valid. It should have exactly 10 digits.
                    </span>
                  )}
                </div>
              </div>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>Mobile 2</label>
                </div>
                <div className='col-10'>
                  <input
                    type='number'
                    className={clsx('form-control form-control-solid col-9 ', {
                      'is-valid': customerInfo.mobile_2.length >= 10,
                    })}
                    placeholder='Enter mobile number'
                    name='mobile_2'
                    value={customerInfo.mobile_2}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>Mobile 3</label>
                </div>
                <div className='col-10'>
                  <input
                    type='number'
                    className={clsx('form-control form-control-solid col-9 ', {
                      'is-valid': customerInfo.mobile_3.length >= 10,
                    })}
                    placeholder='Enter mobile number'
                    name='mobile_3'
                    value={customerInfo.mobile_3}
                    onChange={handleInput}
                  />
                </div>
              </div>

              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>
                    Address <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-10'>
                  <textarea
                    maxLength={120}
                    className={`form-control form-control-solid col-9 ${
                      formErrors.addressFiled &&
                      'form-control form-control-solid col-9 border border-2 border-danger rounded'
                    }`}
                    placeholder='Enter address '
                    name='address'
                    value={customerInfo.address}
                    onChange={handleInput}
                  />
                  {/* {formErrors.addressFiled && (
                    <span className='text-danger'>
                      Address is not valid. It shows more then 35 digits.
                    </span>
                  )} */}
                </div>
              </div>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>At & Post</label>
                </div>
                <div className='col-10'>
                  <input
                    type='text'
                    className={clsx('form-control form-control-solid col-9 ', {
                      'is-valid': customerInfo?.at_post.length >= 5,
                    })}
                    placeholder='Enter At & Post '
                    name='at_post'
                    value={customerInfo?.at_post}
                    onChange={handleInput}
                  />
                </div>
              </div>

              <PincodeAddress
                formTitle={formTitle}
                formErrors={formErrors}
                customerInfo={customerInfo}
                setCustomerInfo={setCustomerInfo}
                setFormErrors={setFormErrors}
                setOffice={setOffice}
                manually={manually}
                setManually={setManually}
              />

              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>
                    SalesMan <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-10'>
                  <Select
                    className={`${
                      formErrors.salesmanField ? 'border border-2 border-danger rounded' : ''
                    }`}
                    options={sales_men}
                    onChange={(selectedOption: any) => {
                      setSelectedSalesMan(selectedOption.value)
                      setFormErrors({salesmanField: false})
                    }}
                  />
                </div>
              </div>
            </div>
            <Modal.Footer className='d-flex justify-content-between'>
              <div>
                {office && !manually && (
                  <div className=' fs-3 fw-semibold text-gray-700'>
                    <span> Office :</span> <span className=''>{office}</span>
                  </div>
                )}
              </div>
              <div className='mb-0 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddNewCustomer
