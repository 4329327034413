import React, {useEffect, useState} from 'react'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {Leave} from '../../models/setting_models'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {LuDelete} from 'react-icons/lu'
import {leaves} from '../AppDataDefine'
import loader from '../../../_metronic/assets/sass/loader.module.css'

const localizer = momentLocalizer(moment)

const MyCalendar = () => {
  const [events, setEvents] = useState<Leave[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getLeaves()
  }, [])

  const getLeaves = async () => {
    const data: Leave[] | null = await supabaseData.getAllLeaves()
    if (data) setEvents(data)
    setIsLoading(false)
  }

  const handleSelectSlot = async ({start}: any) => {
    const selectedDate = start

    const isSunday = selectedDate.getDay() === 0

    const eventOnSelectedDate = events?.find(
      (event: any) =>
        moment(event.start).format('DD-MM-YYYY') === moment(selectedDate).format('DD-MM-YYYY')
    )

    if (eventOnSelectedDate) {
      window.alert(`An event is already set for this date.`)
      // window.confirm(`Are you sure you want delete An event ?`)
    } else if (isSunday) {
      alert('You cannot set a new event on Sunday.')
    } else {
      const title = window.prompt('Enter event title:')
      if (title) {
        const newEvent: Leave = {
          start: `${selectedDate}`,
          end: `${selectedDate}`,
          title,
        }
        let leaves: string = ''

        const oldLeaves = events === undefined ? [] : events

        oldLeaves?.push(newEvent)

        leaves = JSON.stringify(oldLeaves)

        const data: Leave[] | undefined = await supabaseData.addLeaves(leaves)
        console.log(data)
        data !== undefined && setEvents([...data])
      }
    }
  }

  console.log('LEAVES', events)
  const deleteEvent = async (eventToDelete: any) => {
    console.log('DATE -->', eventToDelete)

    if (window.confirm(`Are you sure you want delete An event ?`)) {
      const updatedEvents: any = events.filter((event: any) => event.start !== eventToDelete.start)
      const data: Leave[] | undefined = await supabaseData.addLeaves(updatedEvents)
      data !== undefined && setEvents([...data])
    }
  }

  // const handleSelectSlot = ({start}: any) => {
  //   const selectedDate = start
  //   const selectedDateFormatted = new Date(selectedDate).toISOString().slice(0, 10)
  //   console.log('Selected Date:', selectedDateFormatted)

  //   const eventOnSelectedDate = events.find((event: any) => {
  //     const eventDateFormatted = new Date(event.start).toISOString().slice(0, 10)
  //     console.log('Event Date:', eventDateFormatted)
  //     return eventDateFormatted === selectedDateFormatted
  //   })

  //   if (eventOnSelectedDate) {
  //     window.alert(`An event is already set for this date.`)
  //   } else if (selectedDate.getDay() === 0) {
  //     alert("You cannot set a new event on Sundays when there's already an event.")
  //   } else {
  //     const title = window.prompt('Enter event title:')
  //     if (title) {
  //       const newEvent = {
  //         start: selectedDateFormatted,
  //         end: eventOnSelectedDate,
  //         title,
  //       }

  //       setEvents([...events, newEvent])
  //     }
  //   }
  // }

  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor='start'
      endAccessor='end'
      views={['month']}
      style={{height: '550px'}}
      selectable={true}
      onSelectSlot={handleSelectSlot}
      components={{
        event: ({event}: any) => (
          <div className='d-flex position-relative'>
            <p className='mb-0'>{event.title}</p>
            <LuDelete
              className='ms-3 fs-2 position-absolute end-0'
              onClick={() => deleteEvent(event)}
            />
          </div>
        ),
      }}
    />
  )
}

export default MyCalendar
