import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
// import {customersData} from '../AppDataDefine'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
// import AddNewCustomer from './AddNewCustomer'
import {PageTitle} from '../../../_metronic/layout/core'
import EditCustomer from './EditCustomer'
import {ToastContainer} from 'react-toastify'
import {Customer, CustomersType} from '../../models/customer_models'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {Link} from 'react-router-dom'
import CustomerDetails from './CustomerDetails'
import AddNewCustomer from '../orders/AddNewCustomer'
import {RoleWiseUsers} from '../../models/user_models'
import {MdKeyboardArrowLeft, MdOutlineNavigateNext} from 'react-icons/md'
import loader from '../../../_metronic/assets/sass/loader.module.css'

const CustomerWrapper = () => {
  const [customers, setCustomers] = useState<CustomersType>([])
  const [addCustomerTitle, setAddCustomerTitle] = useState('New Customer')
  const [sales_men, selAllSalesMen] = useState<RoleWiseUsers[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [show, setShow] = useState(false)
  const [editCustomer, setEditCustomer] = useState<Customer | null>(null)

  const user: any = localStorage.getItem('user_data')
  const user_data = JSON.parse(user)
  const userRole = user_data.role

  // console.log('user role ->', userRole)

  useEffect(() => {
    getTotalCustomer(searchQuery)
    get_all_sales_man()
  }, [])

  const get_all_sales_man = async () => {
    const all_sales_man: RoleWiseUsers[] | undefined = await supabaseData.get_users_by_role(
      'Sales man'
    )
    if (all_sales_man) selAllSalesMen(all_sales_man)
  }

  const [totalCustomers, setTotalCustomers] = useState<number>(0)

  const getTotalCustomer = async (searchValue: string) => {
    const count: number | null = await supabaseData.getTotalCustomers(searchValue)
    if (count) setTotalCustomers(count)
  }

  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemsPerPage: number = 20

  useEffect(() => {
    getCustomers(searchQuery)
  }, [currentPage])

  const renderPaginationControls = () => {
    const totalPages = Math.ceil(totalCustomers / itemsPerPage)

    const goToNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1)
      }
    }

    const goToPrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1)
      }
    }
    const goToPage = (pageNumber: any) => {
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber)
      }
    }

    const renderPageNumbers = () => {
      const pageNumbers = []
      const numToShow = 5
      let start = Math.max(1, currentPage - Math.floor(numToShow / 2))
      let end = Math.min(totalPages, start + numToShow - 1)

      if (end - start + 1 < numToShow) {
        start = Math.max(1, end - numToShow + 1)
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i)
      }

      return pageNumbers.map((pageNumber, index) => (
        <button
          key={index}
          onClick={() => goToPage(pageNumber)}
          className={`btn btn-number btn-sm   ${
            pageNumber === currentPage ? 'bg-primary text-light' : 'bg-light text-dark'
          }`}
        >
          {pageNumber}
        </button>
      ))
    }

    return (
      totalCustomers >= 20 && (
        <div className='pagination-controls mx-auto'>
          <div className='d-flex'>
            <button
              className='btn btn-primary btn-sm me-2'
              onClick={goToPrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft /> Prev
            </button>
            <div className='page-numbers'>
              {currentPage > 5 && (
                <button
                  onClick={() => goToPage(1)}
                  className={`btn btn-number btn-sm  ${1 === currentPage ? 'active' : ''}`}
                >
                  1
                </button>
              )}
              {currentPage > 5 && <span>...</span>}
              <span> {renderPageNumbers()}</span>
              {currentPage < totalPages - 3 && <span>...</span>}
              {currentPage < totalPages - 3 && (
                <button
                  onClick={() => goToPage(totalPages)}
                  className={`btn btn-number btn-sm ${totalPages === currentPage ? 'active' : ''}`}
                >
                  {totalPages}
                </button>
              )}
            </div>
            <button
              className='btn btn-primary btn-sm ms-2'
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              Next <MdOutlineNavigateNext />
            </button>
          </div>
        </div>
      )
    )
  }

  const getCustomers = async (searchValue: string) => {
    const offset: number = (currentPage - 1) * 20
    let display: number = 20
    if (offset > 0) display = offset + 19
    else display = itemsPerPage - 1

    const data: CustomersType | null = await supabaseData.getCustomers(offset, display, searchValue)

    if (data) {
      setCustomers(data)
      setIsLoading(false)
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue: string = event.target.value
    console.log('search value ->', searchValue, searchValue.length)
    setSearchQuery(searchValue)
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setCurrentPage(1)
      setIsLoading(true)
      getTotalCustomer(searchValue)
      getCustomers(searchValue)
    }
  }

  const handleCustomerSelect = (customer: any) => {
    setEditCustomer(customer)
    setShow(true)
  }

  const closeModel = () => {
    setShow(false)
  }

  const handleDNDChange = async (e: any, customer: any) => {
    let schedule_date = new Date().toLocaleDateString()
    console.log('customer =>', customer)
    if (window.confirm('Sure ?')) {
      let is_dnd = !customer.is_dnd
      const data: Customer | null = await supabaseData.setDNDStatus(
        customer.id,
        is_dnd,
        schedule_date
      )
      if (data) {
        window.location.reload()
      }
    }
  }

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className='card card-xxl-stretch mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Customers List</span>
          </h3>
          <div className='d-flex align-items-center'>
            <AddNewCustomer
              title={addCustomerTitle}
              sales_men={sales_men}
              customers={customers}
              setCustomers={setCustomers}
              from={'customer'}
            />

            <div className='d-flex m-5' style={{justifyContent: 'space-between'}}>
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid w-250px ps-14'
                  placeholder='Search...'
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className={loader.custom_loader_container}>
            {' '}
            <p className={loader.custom_loader}></p>
          </div>
        ) : (
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-striped  table-hover table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-300px'>Name</th>
                    <th className='min-w-200px'>Number</th>
                    <th className='min-w-200px '>Date</th>
                    <th className='min-w-150px '>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer: any) => {
                    return (
                      <tr key={customer.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold'>
                              <img
                                src={toAbsoluteUrl('/avatar-icon.png')}
                                alt=''
                                style={{width: '30px', height: '30px'}}
                              />
                            </div>
                            <span className='ms-5 fw-bold text-gray-700 text-hover-primary'>
                              {customer.label}
                            </span>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold'>
                              {customer.mobile}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold'>
                              {new Date(customer.created_at).toLocaleDateString('en-GB')}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            <CustomerDetails customer={customer} />
                            {!userRole.includes('Caller') && (
                              <>
                                <div
                                  className='btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm me-1'
                                  onClick={() => handleCustomerSelect(customer)}
                                >
                                  <KTIcon iconName='pencil' className='fs-3' />
                                </div>
                                <div className='form-check form-switch me-20'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    role='switch'
                                    id='flexSwitchCheckChecked'
                                    // @ts-ignore
                                    value={customer.is_dnd}
                                    onChange={(e) => handleDNDChange(e, customer)}
                                    checked={customer.is_dnd}
                                  />
                                  {/* <label className='form-check-label fw-bold fs-5' htmlFor='flexSwitchCheckChecked'>
                                DND
                              </label> */}
                                </div>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className='d-flex justify-content-center'>{renderPaginationControls()}</div>
          </div>
        )}
      </div>
      {show && (
        <EditCustomer
          customer={editCustomer}
          sales_men={sales_men}
          show={show}
          closeModel={closeModel}
        />
      )}
    </>
  )
}

export default CustomerWrapper
