import React, {useEffect, useState} from 'react'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import {DateRangePicker} from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/initialize'
import '../../../_metronic/assets/sass/datePicker.css'
import moment from 'moment'

// import {paymentCouriers} from '../AppDataDefine'

const PaymentHistory = () => {
  const [courierPayments, setCourierPayments] = useState<any>([])
  const [searchDate, setSearchDate] = useState<string>(new Date().toISOString().split('T')[0])
  const [isLoading, setIsLoading] = useState(true)
  const [startDate, setStartDate] = useState<any>(moment().subtract(0, 'year'))
  const [endDate, setEndDate] = useState<any>('')
  const [focusedInput, setFocusedInput] = useState(null)

  useEffect(() => {
    getAllCourierPayment()
  }, [])

  const getAllCourierPayment = async () => {
    setIsLoading(true)
    const formattedStartDate = startDate?.toISOString().split('T')[0]

    const formattedEndDate = endDate !== '' ? endDate.toISOString().split('T')[0] : null

    const data: any = await supabaseData.getAllCourierPayment(formattedStartDate, formattedEndDate)
    if (data) {
      setCourierPayments(data)
      setIsLoading(false)
    }
  }

  const onDatesChange = ({startDate, endDate}: any) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const onFocusChange = (focusedInput: any) => {
    setFocusedInput(focusedInput)
  }

  const getTotalCOD = () => {
    return courierPayments.reduce((total: number, data: any) => {
      const courierName = Object.keys(data)[0]
      return total + data[courierName].cod
    }, 0)
  }

  const getTotalPrepaid = () => {
    return courierPayments.reduce((total: number, data: any) => {
      const courierName = Object.keys(data)[0]
      return total + data[courierName].prepaid
    }, 0)
  }

  // const totalCOD = getTotalCOD()
  // const totalPrepaid = getTotalPrepaid()

  return (
    <>
      <div className='card ps-5 '>
        <div className='card-body '>
          <div className='row '>
            <div className='col-6'>
              <span className='fs-3 fw-bolder text-gray-700 d-block pt-2'>Payments</span>
            </div>
            <div className='col-6'>
              {/* <input
                type='date'
                className='form-control form-control-solid'
                value={searchDate}
                onChange={(e: any) => setSearchDate(e.target.value)}
              /> */}
              <DateRangePicker
                startDate={startDate}
                startDateId='your_unique_start_date_id'
                endDate={endDate}
                displayFormat='DD/MM/yyyy'
                endDateId='your_unique_end_date_id'
                onDatesChange={onDatesChange}
                focusedInput={focusedInput}
                small
                onFocusChange={onFocusChange}
                calendarInfoPosition='top'
                // @ts-ignore
                className='DateRangePicker'
                dayPickerWrapperClassName='DayPicker'
                calendarMonthClassName='CalendarMonth'
                navNextClassName='DayPickerNavigation_button'
                navPrevClassName='DayPickerNavigation_button'
                style={{width: '400px'}}
                isOutsideRange={() => false}
              />
              <button className='btn btn-primary btn-sm ms-2' onClick={getAllCourierPayment}>
                Apply
              </button>
            </div>
          </div>
          <div className='pt-5'>
            <div className='table-responsive'>
              {isLoading ? (
                <div
                  className='d-flex align-items-center justify-content-center'
                  style={{height: '400px'}}
                >
                  <p className={loader.custom_loader}></p>
                </div>
              ) : (
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-100px'>Courier Company</th>
                      <th className='min-w-100px'>Case On Delivery</th>
                      <th className='min-w-100px'>Prepaid</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courierPayments.map((data: any, i: number) => {
                      const courierName = Object.keys(data)[0]
                      const {cod, prepaid} = data[courierName]
                      return (
                        <tr key={i}>
                          <td>
                            <p className='mb-0 text-gray-700 fw-bold'>{courierName}</p>
                          </td>
                          <td>
                            <p className='mb-0 text-gray-700 fw-bold'>{cod}</p>
                          </td>
                          <td>
                            <p className='mb-0 text-gray-700 fw-bold'>{prepaid}</p>
                          </td>
                        </tr>
                      )
                    })}
                    <tr className='border-top border-top-5'>
                      <td>
                        <p className='mb-0 text-gray-700 fw-bold'>Total</p>
                      </td>
                      <td>
                        <p className='mb-0 text-gray-700 fw-bold'>{getTotalCOD()}</p>
                      </td>
                      <td>
                        <p className='mb-0 text-gray-700 fw-bold'>{getTotalPrepaid()}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentHistory
