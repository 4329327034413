import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import Select from 'react-select'
// import {returnProductsData} from '../AppDataDefine'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import style from '../../../_metronic/assets/sass/returnOrder.module.css'
import {image_path} from '../../../utils'
import {ReturnOrderItems} from '../../models/order_models'
const AddReturnOrderDetails = ({courierCompanies, accounts}: any) => {
  const [show, setShow] = useState(false)
  const [returnDate, setReturnDate] = useState<string>(new Date().toISOString().split('T')[0])
  const [selectedOrder, setSelectedOrder] = useState<any>(null)
  const [selectedProductsList, setSelectedProductsList] = useState<any>()
  const [refundAmount, setRefundAmount] = useState<number>(0)
  const [returnItems, setReturnItems] = useState<ReturnOrderItems[]>([])
  const [inputErrors, setInputErrors] = useState<boolean[]>([])
  const [inputErrors2, setInputErrors2] = useState<boolean[]>([])
  const [isRTO, setIsRTO] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<string>('')
  const [referenceId, setReferenceId] = useState('')
  const [tracking_id, setTrackingId] = useState<string>('')
  const [amount, setAmount] = useState<number>()
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [selectedCourier, setSelectedCourier] = useState<string>('')
  const [order_manager, setOrderManager] = useState<string>('')
  const [orders, setOrders] = useState<any>([])

  useEffect(() => {
    if (selectedProductsList) {
      const updatedReturnItems = selectedProductsList.map((item: any) => ({
        id: item.id,
        return_unit: 0,
        damage_unit: 0,
        refund_amount: amount,
        product_id: item.product_id,
      }))
      setReturnItems(updatedReturnItems)
    }
  }, [selectedOrder])

  const handleOrderSelect = (selectedOption: any) => {
    console.log('selected order ->', selectedOption)
    setSelectedOrder(selectedOption)
    setSelectedProductsList(selectedOption.order_items)
  }

  useEffect(() => {
    if (selectedProductsList) {
      setInputErrors(new Array(selectedProductsList.length).fill(false))
      setInputErrors2(new Array(selectedProductsList.length).fill(false))
    }
  }, [selectedOrder])

  const handleChange = (e: any, i: number, type: string) => {
    const {value} = e.target
    const newItems = [...returnItems]

    if (type === 'return_unit') {
      setInputErrors((prevErrors) => {
        const newErrors = [...prevErrors]
        newErrors[i] = parseInt(value, 10) > selectedProductsList[i].units
        return newErrors
      })

      newItems[i] = {
        ...newItems[i],
        return_unit: +value,
      }

      const price = selectedProductsList[i].price
      const newAmount = parseInt(value, 10) * price

      console.log('New Amount', newAmount)
      setAmount(newAmount)

      newItems[i] = {
        ...newItems[i],
        refund_amount: newAmount,
      }

      const totalAmount: number = newItems.reduce(
        (total, item) => total + item.refund_amount || 0,
        0
      )
      setTotalAmount(totalAmount)
      // console.log('Amount --->', totalAmount)

      setReturnItems(newItems)
    } else if (type === 'damage_unit') {
      setInputErrors2((prevErrors) => {
        const newErrors = [...prevErrors]
        //@ts-ignore
        newErrors[i] = parseInt(value, 10) > newItems[i].return_unit
        return newErrors
      })

      newItems[i] = {
        ...newItems[i],
        damage_unit: +value,
      }

      setReturnItems(newItems)
    } else if (type === 'damage_items') {
      console.log('Change data', newItems)

      newItems[i] = {
        ...newItems[i],
        refund_amount: +value,
      }

      setReturnItems(newItems)
    }
  }

  const handleShow = () => setShow(true)

  const handleReturnOrder = async (e: any) => {
    e.preventDefault()
    const hasErrors = inputErrors.some((error) => error) || inputErrors2.some((error) => error)
    // console.log('RETURN ITEMS', returnItems)
    if (!hasErrors) {
      const newReturnObj = {
        order_id: selectedOrder.id,
        order_status: 'Return',
        return_date: returnDate,
        is_rto: isRTO ? true : false,
        payment_status: totalAmount === refundAmount ? 'Refund' : 'Pending',
        payment: {
          transaction_id: referenceId === '' ? null : referenceId,
          payment_method: selectedAccount === '' ? null : selectedAccount,
          amount: refundAmount,
          created_by: order_manager,
        },
        return_order_manager: order_manager,
        order_items: returnItems,
        courier_provider_id: isRTO
          ? selectedOrder.order_courier_provider.id
          : selectedCourier === ''
          ? null
          : selectedCourier,
        tracking_id: isRTO ? selectedOrder.tracking_id : tracking_id === '' ? null : tracking_id,
        refund_amount: isRTO ? 0 : refundAmount,
      }

      console.log('NEW RETURN OBJ => ', newReturnObj)
      const data: any = await supabaseData.createReturnOrder(newReturnObj)
      if (data) {
        setShow(false)
        setSelectedProductsList([])
        setAmount(0)
        setTotalAmount(0)
        setRefundAmount(0)
        setSelectedAccount('')
        setSelectedCourier('')
        setReferenceId('')
        setTrackingId('')
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    const lsGetItem = localStorage.getItem('user_data')
    const role = localStorage.getItem('role')
    if (role === 'Admin' || 'Sub Admin' || 'Order Manager') {
      // @ts-ignore
      const orderManager: any = JSON.parse(lsGetItem)
      setOrderManager(orderManager?.id)
    }
  }, [])

  const getOrders = async (searchValue: string) => {
    console.log('orders from add return order')
    const data: any = await supabaseData.getOrders(searchValue, 'orders', 1)

    if (data) {
      setOrders(data.orders)
    }
    console.log('orders from add return order ->', data)
  }

  const handleInputChange = (inputValue: string) => {
    if (inputValue.length >= 3) {
      getOrders(inputValue)
    }
  }

  return (
    <>
      <div className='btn btn-sm btn-light-primary' onClick={handleShow}>
        <KTIcon iconName='plus' className='fs-3' />
        New Return Order
      </div>
      <Modal show={show} className={`modal-xl `} style={{maxHeight: '850px', overflowY: 'scroll'}}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>Return Order Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleReturnOrder}>
            <div className='px-5' style={{maxHeight: '610px', overflowY: 'scroll'}}>
              <div className='d-flex border-b border-gray-200 dark:border-gray-700 border-bottom pb-5'>
                <div className='col-8 d-flex'>
                  <label className='form-label pt-2 fs-5 me-3' style={{width: '115px'}}>
                    Order Id <span className='text-danger'>*</span>
                  </label>
                  <div className='col-8 pt-1'>
                    <Select
                      // @ts-ignore
                      styles={{backgroundColor: 'red'}}
                      id='SelExample'
                      onInputChange={handleInputChange}
                      options={orders.map((order: any) => ({
                        ...order,
                        label: `${order.tracking_id} - ${order.customers.mobile} - ${new Date(
                          order.order_date
                        ).toLocaleDateString('en-GB')} - ${order.order_courier_provider.name} - ${
                          order.total_amount
                        }`,
                        value: order.id,
                      }))}
                      value={selectedOrder}
                      onChange={handleOrderSelect}
                      placeholder='Search...'
                      required
                    />
                  </div>
                </div>
                <div className='col-4 d-flex'>
                  <label className='form-label pt-2 fs-5 me-3 pt-3 ' style={{width: '115px'}}>
                    Return Date <span className='text-danger'>*</span>
                  </label>
                  <div className='col-5 pt-1'>
                    <input
                      type='date'
                      className='form-control form-control-sm form-control-solid '
                      value={returnDate}
                      onChange={(e: any) => setReturnDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className='border-bottom'>
                {selectedOrder && (
                  <>
                    <div className='border-bottom row mt-5'>
                      <div className='col-6'>
                        {/* <p>Return Order Code: RTNORD{selectedOrderDetail.code}</p> */}
                        <p>Customer Name: {selectedOrder.customers.name}</p>
                        <p>Mobile: {selectedOrder.customers.mobile}</p>
                      </div>
                      <div className='col-6'>
                        <div className='form-check form-check-custom form-check-solid mx-5'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            checked={isRTO}
                            onChange={(e) => setIsRTO(e.target.checked)}
                          />
                          <label className='form-check-label me-2 fw-bold fs-5'>RTO</label>
                        </div>
                      </div>
                    </div>
                    <table className='table  table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          {/* <th className='min-w-50px'></th> */}
                          <th className='min-w-100px'></th>
                          <th className='min-w-140px'>Units</th>
                          {!isRTO && <th className=''>Price</th>}
                          <th className='min-w-120px'>Return Units</th>
                          <th className='min-w-100px '>Damage Units</th>
                          {!isRTO && <th>Amount</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProductsList?.map((item: any, i: number) => {
                          return (
                            <tr key={i}>
                              <td>
                                <div>
                                  {/* <img
                                    className={style.set_product_image}
                                    src={`${image_path}${item.products.image}`}
                                  /> */}
                                </div>
                                <div className='ms-5 fw-semi-bold'>
                                  <p className='mb-0'>{item.label}</p>
                                  <p className='mb-0 mt-4 fs-6 fw-bold text-gray-700'>
                                    CODE : {item.code}
                                  </p>
                                </div>
                              </td>
                              <td className=' me-4'>
                                <p className='border border-primary-subtle border-2 rounded py-2 px-10 me-5 fw-semibold text-gray-600 mt-4'>
                                  {item.units}
                                </p>
                              </td>
                              {!isRTO && (
                                <td>
                                  <p className='border border-primary-subtle border-2 rounded py-2 px-10 me-5 fw-semibold text-gray-600 mt-4'>
                                    {item.price}
                                  </p>
                                </td>
                              )}

                              <td className='me-4 position-relative'>
                                <input
                                  style={{height: '40px'}}
                                  type='number'
                                  name='return_unit'
                                  className='form-control form-control-sm form-control-solid'
                                  value={returnItems[i]?.return_unit || 0}
                                  onChange={(e) => handleChange(e, i, 'return_unit')}
                                  required
                                />
                                {inputErrors[i] && (
                                  <p className='text-danger position-absolute'>Enter valid Units</p>
                                )}
                              </td>
                              <td className='position-relative'>
                                <input
                                  style={{height: '40px'}}
                                  type='number'
                                  name='damage_unit'
                                  className='form-control form-control-sm form-control-solid'
                                  value={returnItems[i]?.damage_unit || 0}
                                  onChange={(e) => handleChange(e, i, 'damage_unit')}
                                />
                                {inputErrors2[i] && (
                                  <p className='text-danger position-absolute'>Enter valid Units</p>
                                )}
                              </td>
                              {!isRTO && (
                                <td>
                                  <input
                                    className=' form-control border border-primary-subtle border-2 rounded py-2 px-10 me-5 fw-semibold text-gray-600'
                                    type='number'
                                    name='amount'
                                    value={returnItems[i]?.refund_amount}
                                    onChange={(e) => handleChange(e, i, 'damage_items')}
                                  />
                                </td>
                              )}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    {!isRTO && (
                      <p className='border-top pt-3 d-flex justify-content-end'>
                        <span className='fs-5 fw-bold text-gray-700'>
                          Total Amount : {totalAmount}
                        </span>
                      </p>
                    )}
                  </>
                )}
              </div>
              {!isRTO && (
                <>
                  <div className='mt-4 border-b border-gray-200 dark:border-gray-700  pb-5'>
                    <div className='col d-flex'>
                      <label className='form-label pt-2 fs-5 me-3' style={{width: '133px'}}>
                        Courier Company
                      </label>
                      <div className='col-5 pt-1'>
                        <Select
                          options={courierCompanies}
                          onChange={(selectedOption: any) => {
                            console.log('VALUE', selectedOption)
                            setSelectedCourier(selectedOption.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  {selectedCourier && (
                    <div className='ms-20 col-5'>
                      <input
                        className='form-control form-control-solid form-control-sm mt-2 ms-19'
                        type='text'
                        value={tracking_id}
                        onChange={(e: any) => setTrackingId(e.target.value)}
                        placeholder='Enter tracking code '
                      />
                    </div>
                  )}
                  <div className='mt-8  border-b border-gray-200 dark:border-gray-700 pb-5'>
                    <div className='col d-flex'>
                      <label className='form-label pt-2 fs-5 me-3' style={{width: '133px'}}>
                        Account
                      </label>
                      <div className='col-5 pt-1'>
                        <Select
                          options={accounts}
                          onChange={(selectedOption: any) => {
                            console.log('VALUE', selectedOption)
                            setSelectedAccount(selectedOption.value)
                          }}
                        />
                      </div>
                    </div>
                    {selectedAccount && (
                      <div className='ms-20 col-5'>
                        <input
                          className='form-control form-control-solid form-control-sm mt-5 ms-20'
                          type='text'
                          value={referenceId}
                          onChange={(e: any) => setReferenceId(e.target.value)}
                          placeholder='Enter reference id'
                        />
                      </div>
                    )}
                  </div>
                  <div className='mt-8  border-b border-gray-200 dark:border-gray-700 border-bottom pb-5'>
                    <div className='col d-flex'>
                      <label className='form-label pt-2 fs-5 ' style={{width: '125px'}}>
                        Refund Amount
                      </label>
                      <div className='col-5'>
                        <input
                          className='form-control form-control-sm form-control-solid'
                          type='number'
                          value={refundAmount}
                          onChange={(e: any) => setRefundAmount(parseInt(e.target.value))}
                          placeholder='Enter Amount'
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddReturnOrderDetails
