import React, {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import clsx from 'clsx'
import classes from '../Page.module.css'
import {AddCourierCompany, CourierCompany, EditCourierCompany} from '../../models/setting_models'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'

const EditCourier = ({courierData}: any) => {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const [courier, setCourier] = useState<EditCourierCompany>({
    id: courierData.value,
    name: courierData.label,
    e_biller_id: courierData.e_biller_id,
    tracking_url: courierData.tracking_url,
    followup_days: courierData.followup_days,
  })
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmitData = async (e: any) => {
    setIsLoading(true)
    e.preventDefault()

    const data = await supabaseData.editCourierCompany(courier)

    if (data !== undefined) {
      setShow(false)
      setIsLoading(false)
    }
  }

  return (
    <>
      <div
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
        onClick={handleShow}
      >
        <KTIcon iconName='pencil' className='fs-3' />
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>Edit Courier Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={handleSubmitData}>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Name <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='text'
                  className={clsx('form-control form-control-solid col-9 ', {
                    'is-valid': courier.name.length >= 4,
                  })}
                  name='name'
                  value={courier.name}
                  readOnly
                  //   onChange={(e: any) => setCourier({...courier, name: e.target.value})}
                  //   required
                />
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  E-bill Id
                  {/* <span className='text-danger'>*</span> */}
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='text'
                  className={clsx('form-control form-control-solid col-9 ', {
                    'is-valid': courier.e_biller_id.length >= 4,
                  })}
                  name='name'
                  value={courier.e_biller_id}
                  onChange={(e: any) => setCourier({...courier, e_biller_id: e.target.value})}
                />
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Tracking Url
                  {/* <span className='text-danger'>*</span> */}
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='text'
                  className={clsx('form-control form-control-solid col-9 ', {
                    'is-valid': courier.tracking_url.length >= 4,
                  })}
                  name='name'
                  value={courier.tracking_url}
                  onChange={(e: any) => setCourier({...courier, tracking_url: e.target.value})}
                />
              </div>
            </div>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>
                  Days
                  {/* <span className='text-danger'>*</span> */}
                </label>
              </div>
              <div className='col-10'>
                <input
                  type='number'
                  // className={clsx('form-control form-control-solid col-9 ', {
                  //   'is-valid': courier.days > 0,
                  // })}
                  className={'form-control form-control-solid col-9 '}
                  name='followup_days'
                  value={courier.followup_days}
                  onChange={(e: any) => setCourier({...courier, followup_days: +e.target.value})}
                />
              </div>
            </div>

            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
                {isLoading ? (
                  <Button className='d-flex align-items-center btn-sm'>
                    <p className={`mb-0 pb-0 ${classes.loader}`}></p>
                    <span className='ps-3'>Wait...</span>
                  </Button>
                ) : (
                  <Button type='submit' variant='primary' size='sm'>
                    Save
                  </Button>
                )}
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditCourier
