/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import call from '../../../.././helpers/icons-config/icons'
import {HiTemplate} from 'react-icons/hi'
import {Navbar} from '../../header/Navbar'
import {FiLogOut} from 'react-icons/fi'
import {useAuth} from '../../../../../app/modules/auth'
import {useNavigate} from 'react-router-dom'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser, logout} = useAuth()
  const navigate = useNavigate()

  const handleClick = () => {
    localStorage.removeItem('role')
    navigate('/auth/login')
  }
  const user: any = localStorage.getItem('user_data')

  const user_data = JSON.parse(user)
  const userRole = user_data.role
  // console.log('ROLE =>', userRole)

  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '85vh'}}>
      <div style={{flex: '1 0 auto', overflowY: 'scroll'}}>
        <SidebarMenuItem
          to='/dashboard'
          icon='element-11'
          title='Dashboard'
          fontIcon='bi-app-indicator'
        />
        <>
          {(userRole.includes('Admin') ||
            userRole.includes('Sub Admin') ||
            userRole.includes('Caller')) && (
            <SidebarMenuItem
              to='/calls'
              icon='filter-square'
              title='Calls'
              fontIcon='bi-app-indicator'
            />
          )}
          {(userRole.includes('Admin') ||
            userRole.includes('Sub Admin') ||
            userRole.includes('Caller')) && (
            <SidebarMenuItem
              to='/call-history'
              icon='burger-menu-2'
              title='Call History'
              fontIcon='bi-app-indicator'
            />
          )}
          {(userRole.includes('Admin') ||
            userRole.includes('Sub Admin') ||
            userRole.includes('Order Manager') ||
            userRole.includes('Order Inventory Manager') ||
            userRole.includes('Caller')) && (
            <SidebarMenuItem
              to='/orders'
              icon='burger-menu'
              title='Orders'
              fontIcon='bi-app-indicator'
            />
          )}
          {(userRole.includes('Admin') ||
            userRole.includes('Sub Admin') ||
            userRole.includes('Order Manager') ||
            userRole.includes('Order Inventory Manager')) && (
            <SidebarMenuItem
              to='/return-orders'
              icon='information-4'
              title='Return orders'
              fontIcon='bi-app-indicator'
            />
          )}
          {(userRole.includes('Admin') ||
            userRole.includes('Sub Admin') ||
            userRole.includes('Order Manager') ||
            userRole.includes('Order Inventory Manager') ||
            userRole.includes('Caller')) && (
            <SidebarMenuItem
              to='/customers'
              icon='save-2'
              title='Customers'
              fontIcon='bi-app-indicator'
            />
          )}
          {(userRole.includes('Admin') ||
            userRole.includes('Sub Admin') ||
            userRole.includes('Order Inventory Manager')) && (
            <SidebarMenuItem
              to='/products'
              icon='basket-ok'
              title='Products'
              fontIcon='bi-app-indicator'
            />
          )}
          {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && (
            <SidebarMenuItem
              to='/users'
              icon='security-user'
              title='Users'
              fontIcon='bi-app-indicator'
            />
          )}
          {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && (
            <SidebarMenuItem
              to='/payment'
              // icon='finance-calculator'
              icon='paypal'
              title='Payments'
              fontIcon='bi-app-indicator'
            />
          )}
          {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && (
            <SidebarMenuItem
              to='/expanse'
              icon='finance-calculator'
              title='Expanse'
              fontIcon='bi-app-indicator'
            />
          )}
          {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && (
            <SidebarMenuItem
              to='/reports'
              icon='book-square'
              title='Reports'
              fontIcon='bi-app-indicator'
            />
          )}
          {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && (
            <SidebarMenuItem
              to='/setting'
              icon='setting-2'
              title='Settings'
              fontIcon='bi-app-indicator'
            />
          )}
        </>
      </div>
      <div style={{flex: '0 10 auto'}}>
        <p
          onClick={handleClick}
          style={{
            cursor: 'pointer',
          }}
        >
          <span>
            <span className='fw-bolder text-gray-700 text-hover-primary'>
              <FiLogOut className='fw-bold fs-2 ms-5 me-2' /> Log Out
            </span>
          </span>
        </p>
      </div>
    </div>
  )
}

export {SidebarMenuMain}
