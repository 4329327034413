import React, {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {BsBox2Fill} from 'react-icons/bs'
import {Product} from '../../models/product_models'

const StockIn = ({product_id}: any) => {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const [inventory, setInventory] = useState<string>('')

  const handleSubmitData = async (e: any) => {
    // console.log('product id ->', product_id)
    e.preventDefault()
    // setCustomers([...customers, customerInfo])
    const data: Product | undefined = await supabaseData.manageProductsStock(product_id, inventory)
    // console.log(data)
    if (data !== undefined) {
      setShow(false)
      window.location.reload()
    }
  }
  return (
    <>
      <div className='d-flex align-items-center fw-bold' onClick={handleShow}>
        <BsBox2Fill className='text-gray-500 fs-4 text-hover-primary' />
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header>
          <Modal.Title>Manage Stock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={handleSubmitData}>
            <div className='col-12 d-flex mb-3'>
              <div className='col-2'>
                <label className='form-label mt-3'>Stock :</label>
              </div>
              <div className='col-10'>
                <input
                  type='text'
                  className='form-control form-control-solid col-9'
                  name='label'
                  value={inventory}
                  onChange={(e: any) => setInventory(e.target.value)}
                  placeholder='Enter new stock'
                />
              </div>
            </div>
            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default StockIn
