import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {AiFillCloseSquare} from 'react-icons/ai'
import loader from '../../../_metronic/assets/sass/loader.module.css'

const ReturnSheetHistory = ({showReturnSheet, setShowReturnSheet}: any) => {
  const [fileSheet, setFileSheet] = useState<any>([])
  const [isLoader, setIsLoader] = useState<boolean>(true)

  const fetchReturnFileSheet = async () => {
    let sheet = await supabaseData.returnOrderSheets()
    console.log('Sheet ->', sheet)
    setFileSheet(sheet)
    setIsLoader(false)
  }

  useEffect(() => {
    fetchReturnFileSheet()
  }, [])

  return (
    <>
      {isLoader ? (
        <div className={loader.custom_loader_container}>
          <p className={loader.custom_loader}></p>
        </div>
      ) : (
        <Modal show={showReturnSheet} className={`modal-lg`}>
          <Modal.Header className='bg-primary-subtle flex'>
            <Modal.Title> Return File Sheet History</Modal.Title>
            <button className='btn' onClick={() => setShowReturnSheet(false)}>
              <AiFillCloseSquare size={24} />
            </button>
          </Modal.Header>
          <Modal.Body className='' style={{height: '80vh', overflowY: 'auto'}}>
            {isLoader ? (
              <div className={loader.custom_loader_container}>
                <p className={loader.custom_loader}></p>
              </div>
            ) : (
              <>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    <table className='table table-hover table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-100px'>Date</th>
                          <th className='min-w-100px'>File Name</th>
                          <th className='min-w-100px '>Return Orders</th>
                          {/* <th className='min-w-100px '>Return Type</th>
                        <th className='min-w-140px'>Return Date</th>
                        <th className='min-w-140px'>Order Date</th>
                        <th className='min-w-120px'>Status</th>
                        <th className='min-w-100px '>Customer</th>
                        <th className='min-w-100px '>Actions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {fileSheet.map((order: any) => {
                          return (
                            <tr key={order.id}>
                              <td>
                                <div className='d-flex justify-content-start flex-column text-gray-700 fw-bold'>
                                  {new Date(order.created_at).toLocaleDateString('en-GB')}
                                </div>
                              </td>
                              <td>
                                <div className='d-flex justify-content-start flex-column text-gray-700 fw-bold'>
                                  {order.name}
                                </div>
                              </td>
                              <td>
                                <p className='mb-0 text-gray-700 fw-bold'>
                                  {order.orders_count ? order.orders_count : 0}
                                </p>
                              </td>
                              {/* <td>
                              <p className='mb-0 text-gray-700 fw-bold'>
                                {order.is_rto ? 'RTO' : 'CUSTOMER'}
                              </p>
                            </td>

                            <td>
                              <div className='d-flex justify-content-start flex-column fw-bold'>
                                {new Date(order.return_date).toLocaleDateString('en-GB')}
                                <span className='text-muted fw-semibold d-block fs-8'>
                                  Returned
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex justify-content-start flex-column fw-bold'>
                                {new Date(order.order_date).toLocaleDateString('en-GB')}
                                <span className='text-muted fw-semibold d-block fs-8'>Ordered</span>
                              </div>
                            </td>
                            <td className='text-start'>
                              <span
                                className={clsx(
                                  'badge ',
                                  {'badge-light-success': order.payment_status === 'Paid'},
                                  {
                                    'badge-light-primary':
                                      order.payment_status === 'Pending' || 'RTO Done',
                                  }
                                )}
                              >
                                {order.payment_status}
                              </span>
                            </td>
                            <td>
                              <div className='d-flex justify-content-start flex-column fw-bold'>
                                <p className='mb-0'>{order.customers.name}</p>
                                <p className='mb-0 text-gray-600 text-8'>
                                  {order.customers.mobile}
                                </p>
                              </div>
                            </td> */}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* {renderPaginationControls()} */}
              </>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default ReturnSheetHistory
