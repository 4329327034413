import React, {useEffect, useState} from 'react'
import CustomerCallDetail from './CustomerAllDetails'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {KTIcon} from '../../../_metronic/helpers'
import {PendingCall} from '../../models/calls_models'
import {ToastContainer} from 'react-toastify'
import {Leave} from '../../models/setting_models'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import {MdKeyboardArrowLeft, MdOutlineNavigateNext} from 'react-icons/md'
import DNDCustomers from './DNDCustomers'
import {useNavigate, useParams} from 'react-router-dom'

const CallsWrapper = () => {
  const getCurrentDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = `${today.getMonth() + 1}`.padStart(2, '0') // Months are 0-based
    const day = `${today.getDate()}`.padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const [orders, setOrders] = useState<any[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedDate, setSelectedDate] = useState<string>(localStorage.getItem('date') as string)
  const [customerData, setCustomerData] = useState<any>(null)
  const [returnOrders, setReturnOrders] = useState<any>([])
  const [complete_followup_calls, setCompeteFollowupCall] = useState<any>([])
  const [customers, setCustomers] = useState<any[]>([])
  const [leaves, setLeaves] = useState<string[]>([])
  const [dndCustomers, setDndCustomers] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isDNDLoading, setIsDNDLoading] = useState(true)
  const [totalFollowupCall, setTotalFollowupCall] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemsPerPage: number = 20

  const getTotalCalls = async (date: string, searchValue: string) => {
    const count: number | null = await supabaseData.getTotalFollowupCalls(date, searchValue)
    if (count !== null) setTotalFollowupCall(count)
  }

  useEffect(() => {
    const date: string = localStorage.getItem('date') as string

    if (date === '') localStorage.setItem('date', getCurrentDate())
    else setSelectedDate(date)

    getAllLeaves()
  }, [])

  useEffect(() => {
    getTotalCalls(selectedDate, searchQuery)
    getAllPendingFollowupCall(selectedDate, searchQuery)
    localStorage.setItem('date', selectedDate)
  }, [selectedDate])

  useEffect(() => {
    getAllPendingFollowupCall(selectedDate, searchQuery)
  }, [currentPage])

  const renderPaginationControls = () => {
    const totalPages = Math.ceil(totalFollowupCall / itemsPerPage)

    const goToNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1)
      }
    }

    const goToPrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1)
      }
    }
    const goToPage = (pageNumber: any) => {
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber)
        // Update the URL with the current page number
        // history.push(`/customers?page=${pageNumber}`)
      }
    }

    const renderPageNumbers = () => {
      const pageNumbers = []
      const numToShow = 3
      let start = Math.max(1, currentPage - Math.floor(numToShow / 2))
      let end = Math.min(totalPages, start + numToShow - 1)

      if (end - start + 1 < numToShow) {
        start = Math.max(1, end - numToShow + 1)
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i)
      }

      return pageNumbers.map((pageNumber, index) => (
        <button
          key={index}
          onClick={() => goToPage(pageNumber)}
          className={`btn btn-number btn-sm   ${
            pageNumber === currentPage ? 'bg-primary text-light' : 'bg-light text-dark'
          }`}
        >
          {pageNumber}
        </button>
      ))
    }

    return (
      totalFollowupCall >= 20 && (
        <div className='pagination-controls mx-auto'>
          <div className='d-flex'>
            <button
              className='btn btn-primary btn-sm me-2'
              onClick={goToPrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft /> Prev
            </button>
            <div className='page-numbers'>
              {currentPage > 2 && (
                <button
                  onClick={() => goToPage(1)}
                  className={`btn btn-number btn-sm  ${1 === currentPage ? 'active' : ''}`}
                >
                  1
                </button>
              )}
              {currentPage > 2 && <span>...</span>}
              <span> {renderPageNumbers()}</span>
              {currentPage < totalPages - 1 && <span>...</span>}
              {currentPage < totalPages - 1 && (
                <button
                  onClick={() => goToPage(totalPages)}
                  className={`btn btn-number btn-sm ${totalPages === currentPage ? 'active' : ''}`}
                >
                  {totalPages}
                </button>
              )}
            </div>
            <button
              className='btn btn-primary btn-sm ms-2'
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              Next <MdOutlineNavigateNext />
            </button>
          </div>
        </div>
      )
    )
  }

  const getAllPendingFollowupCall = async (date: string, searchValue: string) => {
    const offset: number = (currentPage - 1) * 20
    let display: number = 20
    if (offset > 0) display = offset + 19
    else display = itemsPerPage - 1
    const data: PendingCall[] | undefined = await supabaseData.getAllPendingFollowupCall(
      offset,
      display,
      date,
      searchValue
    )
    if (data) {
      const isDnd: any = []
      const notDnd: any = []
      data.map((call: any) => {
        // console.log('customer-------->', call)

        if (call.is_dnd) {
          console.log('IF condition')

          isDnd.push(call)
        } else {
          notDnd.push(call)
        }
      })
      setDndCustomers(isDnd)
      setCustomers(notDnd)
      setIsLoading(false)
      setIsDNDLoading(false)
    }
  }

  const getAllLeaves = async () => {
    const data: Leave[] | null = await supabaseData.getAllLeaves()
    if (data) {
      const leaves = data?.map((leave: Leave) => {
        return new Date(leave.start).toISOString().split('T')[0]
      })

      // console.log('leaves ->', leaves)
      setLeaves(leaves)
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value
    setSearchQuery(searchValue)
    if (searchValue.length >= 3 || searchValue.length === 0) {
      setCurrentPage(1)
      setIsLoading(true)
      getTotalCalls(selectedDate, searchValue)
      getAllPendingFollowupCall(selectedDate, searchValue)
    }
  }

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   console.log('date ->', event.target.value)
    const selectedDateValue = event.target.value
    setSelectedDate(selectedDateValue)
    //   if (selectedDateValue !== '') filterCustomers(selectedDateValue, searchQuery)
    //   else setCustomers(followup_call)
  }

  const handleCustomerClick = async (customer_detail: any) => {
    // console.log('customer data ->', customerData)
    // console.log('customer detail ->', customer_detail)

    if (customerData === null || customerData.customer_id !== customer_detail.customer_id) {
      setCustomerData(customer_detail)
      const data: any = await supabaseData.getCustomerOrders(
        customer_detail.customer_id,
        'followup'
      )
      if (data && !customer_detail.is_dnd) {
        data.forEach((order: any) => {
          order.order_items = order.order_items.map((item: any) => {
            item.label = item.products.name
            item.code = item.products.code
            item.image = item.products.image
            delete item.products
            return item
          })
        })
        const return_cancel_orders: any = []
        const orders: any = []
        setOrders(data)
        data.map((order: any) => {
          if (order.return_date || order.cancel_at) {
            return_cancel_orders.push(order)
          } else {
            orders.push(order)
          }
        })

        setReturnOrders(return_cancel_orders)
        setOrders(orders)
      }
      const complete_followup_calls: any = await supabaseData.getCustomerCallHistory(
        customer_detail.customer_id
      )

      setCompeteFollowupCall(complete_followup_calls)
    }
  }

  // const handleCheck = (order_id: string, e: any) => {
  //   const isChecked: boolean = e.target.checked
  //   isChecked ? setCallId(order_id) : setCallId('')
  // }

  // const handleSave = async () => {
  //   const data: boolean = await supabaseData.complete_followup_call(call_id)
  //   if (data) window.location.reload()
  // }

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className='d-flex me-5'>
        <div className='card col-7 align-self-start mx-3'>
          <div className='d-flex' style={{justifyContent: 'space-between', alignItems: 'center'}}>
            <div className='card-header border-0'>
              <h3 className='card-title fw-bold text-dark'>Call List</h3>
            </div>
            <div className='d-flex align-items-center'>
              <input
                type='date'
                className='form-control form-control-sm form-control-solid w-200px h-35px'
                value={selectedDate}
                onChange={handleDateChange}
              />
              <div className='d-flex m-5' style={{justifyContent: 'space-between'}}>
                <div className='d-flex align-items-center position-relative my-1'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <input
                    type='text'
                    className='form-control form-control-sm form-control-solid w-200px ps-14'
                    placeholder='Search...'
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className={loader.custom_loader_container}>
              {' '}
              <p className={loader.custom_loader}></p>
            </div>
          ) : (
            <div className='card-body pt-2 '>
              <table
                className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4 '
                style={{maxWidth: '100%'}}
              >
                <thead>
                  <tr className='border-0'>
                    {/* <th className='p-0 w-10px'></th>
                    <th className='p-0 w-30px'></th>
                    <th className='p-0 max-w-50px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th> */}
                    {/* <th className='p-0 min-w-50px'></th> */}
                  </tr>
                </thead>
                <tbody>
                  {customers?.map((customer: any, i: number) => {
                    const currentDate = new Date()
                    const followUpDate = new Date(customer.schedule_date)
                    const isPastDate =
                      followUpDate < currentDate ||
                      (selectedDate !== '' && followUpDate < new Date(selectedDate))
                    const isCurrentDate = followUpDate.toDateString() === currentDate.toDateString()
                    return (
                      <tr
                        key={i}
                        className={`${
                          (isCurrentDate && 'bg-danger-subtle ') ||
                          (isPastDate && 'bg-danger-subtle ')
                        }`}
                      >
                        <td>
                          <span
                            className={`bullet bullet-vertical h-30px bg-success ${
                              (isCurrentDate && 'bg-danger ') || (isPastDate && 'bg-danger ')
                            }`}
                          ></span>
                        </td>
                        <td>
                          <div
                            className='flex-grow-1 text-gray-800 text-hover-primary fw-bold fs-6'
                            onClick={() => handleCustomerClick(customer)}
                          >
                            <CustomerCallDetail
                              orders={orders}
                              returnOrders={returnOrders}
                              customer={customer}
                              complete_followup_calls={complete_followup_calls}
                              leaves={leaves}
                              style={{cursor: 'pointer'}}
                            />
                            <span className='text-muted fw-semibold d-block'>Customer</span>
                          </div>
                        </td>
                        <td>
                          <p className='text-gray-600 fs-6 fw-bold'>{customer.mobile}</p>
                        </td>
                        <td>
                          <p className={`text-gray-600 fs-6 fw-bold`}>
                            {new Date(customer.schedule_date).toLocaleDateString('en-GB')}
                          </p>
                        </td>
                        <td>
                          <p className='text-gray-600 fs-6 fw-bold'>{customer.tracking_id}</p>
                        </td>
                        <td>
                          <p className='text-gray-600 fs-6 fw-bold'>{customer.note}</p>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div className='d-flex justify-content-center'>{renderPaginationControls()}</div>
            </div>
          )}
        </div>
        <div className='card col-5 align-self-start mx-3'>
          <DNDCustomers
            orders={orders}
            returnOrders={returnOrders}
            complete_followup_calls={complete_followup_calls}
            leaves={leaves}
          />
        </div>
      </div>
    </>
  )
}

export default CallsWrapper
