import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {RxAvatar} from 'react-icons/rx'
import 'react-datepicker/dist/react-datepicker.css'
import {FollowupCall} from '../../models/calls_models'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {Link} from 'react-router-dom'

const AddCallCompletion = ({order, leaves, show, handleClose}: any) => {
  // const [show, setShow] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<{value: string; label: string} | null>(null)
  const [note, setNote] = useState<string>('')
  const [date, setDate] = useState<string>('')
  const [leave, setLeaves] = useState<any[]>([])
  const [formErrors, setFormErrors] = useState<any>({
    statusField: '',
    noteField: '',
  })

  // console.log('Followup Order -->', order)
  useEffect(() => {
    if (leaves) {
      // console.log(' in if')
      setLeaves(leaves)
    }
  }, [])

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptionValue = event.target.value
    const selectedOptionLabel = event.target.selectedOptions[0].innerText
    setSelectedStatus({value: selectedOptionValue, label: selectedOptionLabel})
    setFormErrors({selectedStatus: false})
  }

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value
    const wordCount = inputValue.trim().split(/\s+/).length

    if (wordCount <= 100) {
      setNote(inputValue)
    }

    setFormErrors({noteField: false})
  }
  useEffect(() => {
    const calculateBusinessDaysFromToday = (days: number) => {
      const today = new Date()
      let currentDay = new Date(today)

      const isWeekday = (date: any) => {
        const day = date.getDay()
        return day !== 0
      }

      const isLeave = (date: any) => leave?.includes(date.toISOString().split('T')[0])

      for (let i = 0; i < days; ) {
        currentDay.setDate(currentDay.getDate() + 1)

        if (isWeekday(currentDay) && !isLeave(currentDay)) {
          i++
        }
      }

      const formattedDate = currentDay.toISOString().split('T')[0]
      return formattedDate
    }

    const calculatedDate = calculateBusinessDaysFromToday(7)
    setDate(calculatedDate)
  }, [leave])

  const handleSave = async (e: any) => {
    e.preventDefault()
    if (!selectedStatus || note === '') {
      setFormErrors({
        statusField: selectedStatus ? false : true,
        noteField: note !== '' ? false : true,
      })

      return
    }
    const newCallCompletionData = {
      call_status: selectedStatus.label,
      note: note,
      schedule_date: date !== '' ? date : null,
      order_id: order.id,
      customer_id: order.customers.id,
      // id: followup_data.value,
    }
    console.log('NEW OBJ =>', newCallCompletionData)

    const data: FollowupCall | undefined = await supabaseData.addFollowupCall(newCallCompletionData)

    if (data !== undefined) {
      // setShow(false)
      window.location.reload()
    }
  }

  return (
    <>
      {/* <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}

      <Modal show={show} className='modal-lg'>
        <Modal.Header>
          <Modal.Title>Call Completion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSave}>
            <div className='d-flex'>
              <p className='fs-1 text-primary-emphasis'>
                <RxAvatar style={{width: '50px', height: '50px'}} />
              </p>
              <h4 className='mt-4 ms-3'>{order.customers.name}</h4>
            </div>
            <div className='mb-10 col-sm-12'>
              <label htmlFor='floatingSelectGrid'>
                <b>
                  Call Status <span className='text-danger'>*</span>{' '}
                </b>
              </label>
              <div>
                <select
                  className={`form-select form-select-solid ${
                    formErrors.statusField
                      ? 'form-select border border-2 border-danger rounded'
                      : selectedStatus && 'form-select form-select-solid'
                  }`}
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  defaultValue={'0'}
                  onChange={handleStatusChange}
                >
                  <option value='0'>Select</option>
                  <option value='1'>Accepted</option>
                  <option value='2'>Rejected</option>
                  <option value='3'>Not Response</option>
                </select>
              </div>
              {/* <select
              value={selectedStatus}
              onChange={(selectedOption: any) => {
                setSelectedState(selectedOption)
              }}
            /> */}
            </div>
            <div className='mb-10 col-sm-12'>
              <label htmlFor='floatingSelectGrid'>
                <b>
                  Note <span className='text-danger'>*</span>
                </b>
                <textarea
                  className={`form-control form-control-solid${
                    formErrors.noteField
                      ? 'form-select border border-2 border-danger rounded'
                      : note !== '' && 'form-control form-control-solid'
                  }`}
                  placeholder='Leave a comment note'
                  rows={5}
                  cols={77}
                  value={note}
                  onChange={handleNoteChange}
                />
                <p>Word count: {note.trim().split(/\s+/).length}/100</p>
              </label>
            </div>
            {order.cancel_at === null && order.return_date === null && (
              <div className=' mt-8 border-gray-200 dark:border-gray-700  pb-5'>
                <h6>Selected date next call to customer </h6>
                <div className='col d-flex row'>
                  <label className='form-label pt-2 fs-5' style={{width: '50px'}}>
                    Date
                  </label>
                  <div className='col-5'>
                    <input
                      type='date'
                      className='form-control form-control-solid mx-0'
                      name='date'
                      value={date}
                      onChange={(e: any) => setDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            )}
            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button variant='secondary' size='sm' className='me-3' onClick={handleClose}>
                  Close
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddCallCompletion
