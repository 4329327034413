import React, {useEffect, useRef, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import AddOrdersDetails from './AddOrdersDetails'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {OrdersType} from '../../models/order_models'
import clsx from 'clsx'
import {ProductsType} from '../../models/product_models'
import {RoleWiseUsers} from '../../models/user_models'
import OrderDetails from './OrderDetails'
import {AiTwotonePrinter} from 'react-icons/ai'
import ImportSheet from '../payment/ImportSheet'
import GenerateBarcodePdf from './GenerateBarcodePdf'
import style from '../../../_metronic/assets/sass/barcodepdf.module.css'
import {AccountType, Company, CourierCompany, Leave} from '../../models/setting_models'
import {ToastContainer} from 'react-toastify'
import InvoiceFilePdf from './InvoiceFilePdf'
import EditOrder from './EditOrder'
import Export from './Export'
import Bnpl from './Bnpl'
import {MdKeyboardArrowLeft, MdOutlineNavigateNext} from 'react-icons/md'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import Dispatch from './Dispatch'
import {RiCloseFill} from 'react-icons/ri'
import {Modal, Button} from 'react-bootstrap'
import Select from 'react-select'
import {DateRangePicker} from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import '../../../_metronic/assets/sass/datePicker.css'

const AllOrders = () => {
  const [customers, setCustomers] = useState<any[]>()
  const [products, setProducts] = useState<ProductsType>([])
  const [sales_men, selAllSalesMen] = useState<RoleWiseUsers[]>([])
  const [courierCompanies, setCourierCompanies] = useState<CourierCompany[]>([])
  const [accounts, setAccounts] = useState<AccountType[]>([])
  const [orders, setOrders] = useState<any>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [edit_order, setEditOrder] = useState<any>(null)
  const [singleCustomer, setSingleCustomer] = useState()
  const [selectAll, setSelectAll] = useState(false)
  const [sheetTitle, setSheetTitle] = useState<string>('Barcode Sheet')
  const [isPrinting, setIsPrinting] = useState(false)
  const [selectedOrders, setSelectedOrders] = useState<OrdersType[]>([])
  const [leaves, setLeaves] = useState<string[]>([])

  const user: any = localStorage.getItem('user_data')
  const user_data = JSON.parse(user)
  const userRole = user_data.role
  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemsPerPage: number = 50
  const [totalOrders, setTotalOrders] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)

  const [companyDetails, setCompanyDetails] = useState<Company>({
    name: '',
    address: '',
    mobile: '',
    gst: '',
    email: '',
    image_name: '',
  })
  const [showEdit, setShowEdit] = useState<boolean>(false)
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [showOrder, setShowOrder] = useState<boolean>(false)
  const [order, setOrder] = useState<any>(null)

  const [isLoader, setIsLoader] = useState(false)
  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>('')
  const [selectedCourier, setSelectedCourier] = useState<any>([])
  const [selectedSalesMen, setSelectedSalesMen] = useState<any>([])
  const [selectedProducts, setSelectedProducts] = useState<any>([])

  const [selectedCourierIds, setSelectedCourierIds] = useState<any>([])
  const [selectedSalesMenIds, setSelectedSalesMenIds] = useState<any>([])
  const [selectedProductsIds, setSelectedProductsIds] = useState<any>([])
  const [focusedInput, setFocusedInput] = useState(null)

  const handleShow = () => setShowFilter(true)

  const handleEditModel = () => setShowEdit(false)

  const handleProductField = (selectedOption: any) => {
    console.log('SELECTED ==>', selectedOption)

    const selectedValue = selectedOption.map((product: any) => {
      const obj = {value: product.value, label: product.label}
      return obj
    })

    setSelectedProducts(selectedValue)

    const product_ids = selectedOption.map((product: any) => product.value)

    setSelectedProductsIds(product_ids)
  }

  const handleSalesManField = (selectedOption: any) => {
    const selectedValue = selectedOption.map((sales_man: any) => {
      const obj = {value: sales_man.value, label: sales_man.label}
      return obj
    })

    setSelectedSalesMen(selectedValue)

    const sales_men_ids = selectedOption.map((sales_man: any) => sales_man.value)
    setSelectedSalesMenIds(sales_men_ids)
  }
  const handleCourierField = (selectedOption: any) => {
    const selectedValue = selectedOption.map((courier: any) => {
      const obj = {value: courier.value, label: courier.label}
      return obj
    })

    setSelectedCourier(selectedValue)

    const courier_ids = selectedOption.map((courier: any) => courier.value)
    setSelectedCourierIds(courier_ids)
  }

  const searchAndFilterData = async (type: string) => {
    if (currentPage > 1) {
      setCurrentPage(1)
    } else {
      filterOrders(type)
    }
  }

  const filterOrders = async (type: string) => {
    const filterObj = {
      startDate,
      endDate,
      products: selectedProductsIds.length === 0 ? null : selectedProductsIds,
      courier_company: selectedCourierIds.length === 0 ? null : selectedCourierIds,
      sales_men: selectedSalesMenIds.length === 0 ? null : selectedSalesMenIds,
    }
    setIsLoader(true)
    const data = await supabaseData.order_filter(filterObj, currentPage, type)

    if (data) {
      setTotalOrders(data.total_orders)
      setOrders(data.orders)
      setShowFilter(false)
      setIsLoader(false)
    }
  }

  const onDatesChange = ({startDate, endDate}: any) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const onFocusChange = (focusedInput: any) => {
    setFocusedInput(focusedInput)
  }

  const handlePrint = (order: any) => {
    console.log('IN PRINT ORDER --->', order)

    const selectedOrdersArray = Array.isArray(order) ? order : [order]
    setSelectedOrders(selectedOrdersArray)
    setIsPrinting(true)
    setTimeout(() => {
      window.print()
      setIsPrinting(false)
    }, 100)
    // setSelectedOrders([])
  }

  const handleAllPrint = () => {
    if (selectedOrders.length > 1) {
      handlePrint(selectedOrders)
    }
  }

  useEffect(() => {
    get_all_products()
    get_all_sales_man()
    getAllCourierCompany()
    getAllAccountTypes()
    getCompanyDetail()
    getAllLeaves()
  }, [])

  useEffect(() => {
    if (
      startDate !== '' ||
      selectedCourierIds.length > 0 ||
      selectedSalesMenIds.length > 0 ||
      selectedProductsIds.length > 0
    ) {
      // searchAndFilterData('filter')
      filterOrders('filter')
    } else {
      setIsLoading(true)
      getOrders(searchQuery)
    }
  }, [currentPage])

  const renderPaginationControls = () => {
    const totalPages = Math.ceil(totalOrders / itemsPerPage)

    const goToNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1)
      }
    }

    const goToPrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1)
      }
    }
    const goToPage = (pageNumber: any) => {
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber)
        // Update the URL with the current page number
        // history.push(`/customers?page=${pageNumber}`)
      }
    }

    const renderPageNumbers = () => {
      const pageNumbers = []
      const numToShow = 5
      let start = Math.max(1, currentPage - Math.floor(numToShow / 2))
      let end = Math.min(totalPages, start + numToShow - 1)

      if (end - start + 1 < numToShow) {
        start = Math.max(1, end - numToShow + 1)
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i)
      }

      return pageNumbers.map((pageNumber, index) => (
        <button
          key={index}
          onClick={() => goToPage(pageNumber)}
          className={`btn btn-number btn-sm   ${
            pageNumber === currentPage ? 'bg-primary text-light' : 'bg-light text-dark'
          }`}
        >
          {pageNumber}
        </button>
      ))
    }
    // console.log('TOTAL PAGE -->', totalPages)
    // console.log('CURRENT PAGE -->', currentPage)

    return (
      totalOrders >= 50 && (
        <div className='pagination-controls mx-auto'>
          <div className='d-flex'>
            <button
              className='btn btn-primary btn-sm me-2'
              onClick={goToPrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft /> Prev
            </button>
            <div className='page-numbers'>
              {currentPage > 5 && (
                <button
                  onClick={() => goToPage(1)}
                  className={`btn btn-number btn-sm  ${1 === currentPage ? 'active' : ''}`}
                >
                  1
                </button>
              )}
              {currentPage > 5 && <span>...</span>}
              <span> {renderPageNumbers()}</span>

              {currentPage < totalPages - 3 && <span>...</span>}
              {currentPage < totalPages - 3 && (
                <button
                  onClick={() => goToPage(totalPages)}
                  className={`btn btn-number btn-sm ${totalPages === currentPage ? 'active' : ''}`}
                >
                  {totalPages}
                </button>
              )}
            </div>
            <button
              className='btn btn-primary btn-sm ms-2'
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              Next <MdOutlineNavigateNext />
            </button>
          </div>
        </div>
      )
    )
  }

  const getOrders = async (value: string) => {
    const all_orders: any = await supabaseData.getOrders(value, 'orders', currentPage)

    if (all_orders) {
      setTotalOrders(all_orders.total_orders)
      setOrders(all_orders.orders)
      setIsLoading(false)
    }
  }

  const getAllLeaves = async () => {
    const data: Leave[] | null = await supabaseData.getAllLeaves()
    if (data) {
      const leaves = data?.map((leave: Leave) => {
        return new Date(leave.start).toISOString().split('T')[0]
      })

      // console.log('leaves ->', leaves)
      setLeaves(leaves)
    }
  }

  const getCompanyDetail = async () => {
    const data: Company | undefined = await supabaseData.getCompanyDetail()
    if (data) setCompanyDetails(data)
  }

  const get_all_products = async () => {
    const all_products: ProductsType | null = await supabaseData.getAllProducts()
    // console.log('all products ->', all_products)
    if (all_products) setProducts(all_products)
  }

  const get_all_sales_man = async () => {
    const all_sales_man: RoleWiseUsers[] | undefined = await supabaseData.get_users_by_role(
      'Sales man'
    )
    // console.log('all sales_man ->', all_sales_man)
    if (all_sales_man) selAllSalesMen(all_sales_man)
  }
  const getAllCourierCompany = async () => {
    const data: CourierCompany[] | undefined = await supabaseData.getAllCourierCompany()
    // console.log('all courier ->', data)
    if (data) setCourierCompanies(data)
  }

  const getAllAccountTypes = async () => {
    const data: AccountType[] | undefined = await supabaseData.getAllAccountTypes()
    if (data) setAccounts(data)
  }

  const handleCommonCheckboxChange = () => {
    setSelectAll(!selectAll)
    const updatedOrders = orders.map((order: any) => ({
      ...order,
      isChecked: !selectAll,
    }))
    setOrders(updatedOrders)
    if (!selectAll) {
      setSelectedOrders(orders)
    } else {
      setSelectedOrders([])
    }
  }

  const handleRowCheckboxChange = async (clickedOrder: any) => {
    const updatedOrders = orders.map((order: any) =>
      order.id === clickedOrder.id ? {...order, isChecked: !order.isChecked} : order
    )
    setOrders(updatedOrders)

    const allRowCheckboxesChecked = updatedOrders.every((order: any) => order.isChecked)
    setSelectAll(allRowCheckboxesChecked)

    const selectRow = updatedOrders.filter((order: any) => order.isChecked)

    setSelectedOrders(selectRow)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value
    setSearchQuery(event.target.value)
    if (searchValue.length >= 2 || searchValue.length === 0) {
      setCurrentPage(1)
      getOrders(searchValue)
      setIsLoading(true)
    }
  }

  const handleEditOrder = async (order: any) => {
    console.log('ORDER -> ', order)

    // if (edit_order === null || edit_order?.id !== order.id) {
    console.log('In if')

    const address: any = await supabaseData.getAllAddress(order.customers.id)
    if (order.payment_mode === 'Prepaid') {
      const payment: any = await supabaseData.getOrderPayment(order.id, 'order')
      // console.log('payment ->', payment)
      if (payment.length !== 0) order.payment_data = payment[0]
      else order.payment_data = null
    }
    order.order_items =
      order.order_items &&
      order.order_items.map((item: any) => {
        const product = item.products // Get the product object
        // delete item.products // Remove the products property
        return {...item, ...product} // Merge properties
      })
    order.address = address

    setEditOrder(order)
    setShowEdit(true)
    // }
  }

  const clearFilter = () => {
    setStartDate('')
    setEndDate('')
    setSelectedCourier([])
    setSelectedSalesMen([])
    setSelectedProducts([])
    setSelectedCourierIds([])
    setSelectedSalesMenIds([])
    setSelectedProductsIds([])
    setIsLoader(false)
  }

  const handleOrderShow = (order: any) => {
    setOrder(order)
    setShowOrder(true)
  }

  const handleOrderClose = () => {
    setShowOrder(false)
    setOrder(null)
  }

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* {isLoading ? (
        <div className={loader.custom_loader_container}>
          {' '}
          <p className={loader.custom_loader}></p>
        </div>
      ) : ( */}
      <div>
        {isPrinting && (
          <div className={style.print_visible}>
            <GenerateBarcodePdf order={selectedOrders} companyDetails={companyDetails} />
          </div>
        )}
        <div className='card card-xxl-stretch mb-5 mb-xl-8' style={{position: 'relative'}}>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>All Orders</span>
            </h3>

            <div className='d-flex align-items-center'>
              <div className='d-flex m-5' style={{justifyContent: 'space-between'}}>
                <div className='d-flex align-items-center position-relative my-1'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <input
                    type='text'
                    className='form-control form-control-sm form-control-solid w-250px ps-14'
                    placeholder='Search...'
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </div>
              </div>

              {(userRole.includes('Admin') ||
                userRole.includes('Sub Admin') ||
                userRole.includes('Order Manager') ||
                userRole.includes('Order Inventory Manager')) && (
                <>
                  {/* <FilterData
                      products={products}
                      sales_men={sales_men}
                      courierCompanies={courierCompanies}
                      page={currentPage}
                      setTotalOrders={setTotalOrders}
                      setOrders={setOrders}
                    /> */}
                  <button className='btn btn-sm btn-light-primary me-3' onClick={handleShow}>
                    {/* <KTIcon iconName='plus' className='fs-3' /> */}
                    Filter
                  </button>
                  <Modal show={showFilter} className={`modal-md `}>
                    <Modal.Header className='bg-primary-subtle'>
                      <Modal.Title>Filter</Modal.Title>
                      <button
                        className='border border-none fs-3 text-hover-primary rounded-circle'
                        onClick={() => setShowFilter(false)}
                      >
                        <RiCloseFill />
                      </button>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='d-flex align-items-center'>
                        <div className='col-3'>
                          <label className=' fw-bold text-gray-700' style={{width: '60px'}}>
                            Date
                          </label>
                        </div>
                        <DateRangePicker
                          startDate={startDate}
                          startDateId='your_unique_start_date_id'
                          endDate={endDate}
                          displayFormat='DD/MM/yyyy'
                          endDateId='your_unique_end_date_id'
                          onDatesChange={onDatesChange}
                          focusedInput={focusedInput}
                          small
                          onFocusChange={onFocusChange}
                          calendarInfoPosition='top'
                          // @ts-ignore
                          className='DateRangePicker'
                          dayPickerWrapperClassName='DayPicker'
                          calendarMonthClassName='CalendarMonth'
                          navNextClassName='DayPickerNavigation_button'
                          navPrevClassName='DayPickerNavigation_button'
                          style={{width: '400px'}}
                          isOutsideRange={() => false}
                        />
                      </div>
                      <div className='row mt-3 d-flex align-items-center'>
                        <div className='col-3'>
                          <label className='me-2 fw-bold text-gray-700' style={{width: '60px'}}>
                            Product
                          </label>
                        </div>
                        <div className='col-9 '>
                          <Select
                            isMulti
                            className='col-12 '
                            placeholder='Search...'
                            options={products}
                            value={selectedProducts}
                            onChange={handleProductField}
                          />
                        </div>
                      </div>
                      <div className='row mt-3 d-flex align-items-center'>
                        <div className='col-3'>
                          <label className='me-2 fw-bold text-gray-700' style={{width: '80px'}}>
                            Sales Man
                          </label>
                        </div>
                        <div className='col-9 '>
                          <Select
                            isMulti
                            className=' col-12 '
                            placeholder='Search...'
                            options={sales_men}
                            value={selectedSalesMen}
                            onChange={handleSalesManField}
                          />
                        </div>
                      </div>
                      <div className='row mt-3 d-flex align-items-center'>
                        <div className='col-3'>
                          <label className='me-2 fw-bold text-gray-700' style={{width: '160px'}}>
                            Courier Company
                          </label>
                        </div>
                        <div className='col-9 '>
                          <Select
                            isMulti
                            className=' col-12 '
                            placeholder='Search...'
                            options={courierCompanies}
                            value={selectedCourier}
                            onChange={handleCourierField}
                          />
                        </div>
                      </div>
                      <Modal.Footer className='mt-5 p-0'>
                        <div className='d-flex justify-content-end mt-5 '>
                          <Button
                            variant='secondary'
                            size='sm'
                            className='me-3'
                            onClick={clearFilter}
                          >
                            Clear
                          </Button>
                          {isLoader ? (
                            <Button
                              type='button'
                              variant='primary'
                              size='sm'
                              className='me-3'
                              disabled
                            >
                              Wait...
                            </Button>
                          ) : (
                            <Button
                              type='button'
                              variant='primary'
                              size='sm'
                              onClick={() => searchAndFilterData('filter')}
                              className='me-3'
                            >
                              Apply
                            </Button>
                          )}
                          {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && (
                            <Button
                              type='button'
                              variant='primary'
                              size='sm'
                              onClick={() => searchAndFilterData('export')}
                            >
                              Export
                            </Button>
                          )}
                        </div>
                      </Modal.Footer>
                    </Modal.Body>
                  </Modal>
                  <Dispatch title={'Dispatch'} />
                  {(userRole.includes('Admin') ||
                    userRole.includes('Sub Admin') ||
                    userRole.includes('Order Inventory Manager')) && <Bnpl />}
                  {/* {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && <Export />} */}
                  {/* </div> */}
                  {(userRole.includes('Admin') ||
                    userRole.includes('Sub Admin') ||
                    userRole.includes('Order Inventory Manager') ||
                    userRole.includes('Order Manager')) && (
                    <>
                      <ImportSheet sheetTitle={sheetTitle} courierCompanies={courierCompanies} />

                      <AddOrdersDetails
                        customers={customers}
                        setCustomers={setCustomers}
                        products={products}
                        sales_men={sales_men}
                        courierCompanies={courierCompanies}
                        accounts={accounts}
                        leaves={leaves}
                        companyDetails={companyDetails}
                      />
                    </>
                  )}
                </>
              )}
              <button onClick={handleAllPrint} className='btn btn-sm btn-light-primary ms-3'>
                Print
              </button>
            </div>
          </div>

          {isLoading ? (
            <div className={loader.custom_loader_container}>
              {' '}
              <p className={loader.custom_loader}></p>
            </div>
          ) : (
            <>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        {/* CHECK BOXES */}

                        <th>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={selectAll}
                              onChange={handleCommonCheckboxChange}
                            />
                            {selectedOrders.length !== 0 && <span>({selectedOrders.length})</span>}
                          </div>
                        </th>
                        <th className='min-w-100px'>Date</th>
                        <th className='min-w-100px'>Order Code</th>
                        <th className='min-w-100px '>Customer</th>
                        <th className='min-w-100px '>Courier</th>
                        <th className='min-w-100px '>Tracking ID</th>
                        {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && (
                          <th className='min-w-100px '>SalesMan</th>
                        )}
                        <th className='min-w-90px '>Amount</th>
                        <th className='min-w-120px'>Payment Mode</th>
                        <th className='min-w-120px'>Payment Status</th>
                        <th className='min-w-120px'>Order Status</th>
                        <th className='min-w-100px '>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((order: any, i: number) => {
                        return (
                          <tr key={i}>
                            {/* CHECKBOX */}

                            <td>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={order.isChecked}
                                  onChange={() => handleRowCheckboxChange(order)}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column fw-bold'>
                                  {new Date(order.order_date).toLocaleDateString('en-GB')}
                                  <span className='text-muted fw-semibold d-block fs-8'>
                                    Ordered
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start text-gray-600 flex-column fw-bolder'>
                                  {order.order_code}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column text-gray-800 text-hover-success fw-bold'>
                                  {order.customers.name}
                                </div>
                              </div>
                            </td>
                            <td className='text-start'>
                              <span>
                                {order.order_courier_provider && order.order_courier_provider.name}
                              </span>
                            </td>

                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column text-gray-800 text-hover-success fw-bold'>
                                  {order.tracking_id}
                                </div>
                              </div>
                            </td>
                            {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && (
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column text-gray-800 text-hover-success fw-bold'>
                                    {order.sales_man.name}
                                  </div>
                                </div>
                              </td>
                            )}
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column fw-bold'>
                                  {order.total_amount}
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column fw-bold'>
                                  {order.payment_mode}
                                </div>
                              </div>
                            </td>

                            <td className='text-start'>
                              <span
                                className={clsx(
                                  'badge ',
                                  {'badge-light-success': order.payment_status === 'Paid'},
                                  {
                                    'badge-light-primary': order.payment_status === 'Pending',
                                  },
                                  {
                                    'badge-light-danger':
                                      order.payment_status === 'RTO Done' ||
                                      order.payment_status === 'Refund' ||
                                      order.payment_status === 'Cancel' ||
                                      order.payment_status === 'Return',
                                  }
                                )}
                              >
                                {order.payment_status}
                              </span>
                            </td>
                            {order.return_date === null && order.cancel_at === null && (
                              <td>
                                <span
                                  className={clsx(
                                    'badge ',
                                    {'badge-light-primary': order.order_status === 'Pending'},
                                    // {'badge-light-danger': order.order_status === 'Cancel'},
                                    {'badge-light-success': order.order_status === 'Deliver'},
                                    {'badge-light-warning': order.order_status === 'On the way'},
                                    {'badge-light-warning': order.order_status === 'Dispatch'}
                                  )}
                                >
                                  {order.order_status}
                                </span>
                              </td>
                            )}
                            {order.return_date && (
                              <td>
                                <span className={clsx('badge badge-light-danger')}>Return</span>
                              </td>
                            )}
                            {order.cancel_at && (
                              <td>
                                <span className={clsx('badge badge-light-danger')}>Cancel</span>
                              </td>
                            )}
                            <td>
                              <div className='d-flex '>
                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm me-1'
                                  onClick={() => handleOrderShow(order)}
                                >
                                  <KTIcon iconName='eye' className='fs-1' />
                                </button>
                                {(userRole.includes('Admin') ||
                                  userRole.includes('Sub Admin') ||
                                  userRole.includes('Order Inventory Manager') ||
                                  userRole.includes('Order Manager')) && (
                                  <div onClick={() => handleEditOrder(order)}>
                                    <button className='btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm me-1'>
                                      <KTIcon iconName='pencil' className='fs-3' />
                                    </button>
                                  </div>
                                )}
                                <button
                                  onClick={() => handlePrint(order)}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm me-1'
                                >
                                  <AiTwotonePrinter className='text-gray-600 fs-2 text-hover-primary' />
                                </button>
                                {(userRole.includes('Admin') ||
                                  userRole.includes('Sub Admin') ||
                                  userRole.includes('Order Inventory Manager') ||
                                  userRole.includes('Order Manager')) && (
                                  <button
                                    // onClick={() => handleSetPDF(order)}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm me-1'
                                  >
                                    <InvoiceFilePdf order={order} companyDetails={companyDetails} />
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {renderPaginationControls()}
            </>
          )}
        </div>
      </div>
      {showOrder && (
        <OrderDetails
          order={order}
          customer={order.customer}
          status={'order'}
          showOrder={showOrder}
          handleOrderClose={handleOrderClose}
        />
      )}
      {showEdit && (
        <EditOrder
          order={edit_order}
          // customer={singleCustomer}
          // customers={customers}
          sales_men={sales_men}
          products={products}
          courierCompanies={courierCompanies}
          accounts={accounts}
          showEdit={showEdit}
          handleModel={handleEditModel}
          companyDetails={companyDetails}
        />
      )}
    </>
  )
}

export {AllOrders}
