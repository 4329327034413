import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {FaUserAlt} from 'react-icons/fa'
import {RiCloseFill} from 'react-icons/ri'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'

const ExpanseDetailView = ({expenseId, show, handleClose}: any) => {
  const [expanse, setExpense] = useState<any>(null)

  useEffect(() => {
    getExpenseById()
  }, [])

  const getExpenseById = async () => {
    const data: any = await supabaseData.getExpenseById(expenseId)
    setExpense(data)
  }

  return (
    <>
      <div className='d-flex align-items-center'>
        <Link to='' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
          <KTIcon iconName='eye' className='fs-1' />
        </Link>
      </div>
      <Modal show={show} className={`modal-sm `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>Expanse Details</Modal.Title>
          <button
            className='border border-none fs-3 text-hover-primary rounded-circle'
            onClick={handleClose}
          >
            <RiCloseFill />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>Tracking Id : {expenseId}</p>
            <p>COD : {expanse?.cod}</p>
            <p>Booking: {expanse?.booking}</p>
            <p>Other: {expanse?.other}</p>
            <p>Total: {expanse?.total}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ExpanseDetailView
