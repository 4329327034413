import React, {useEffect, useState} from 'react'
import style from '../../../_metronic/assets/sass/call.module.css'
import {FcCallback} from 'react-icons/fc'
import {RxAvatar} from 'react-icons/rx'
import {colorLighten} from '../../../_metronic/assets/ts/_utils'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
// import {callHistory} from '../AppDataDefine'
import clsx from 'clsx'

const CustomerCallHistory = ({className, complete_followup_calls, isDND}: any) => {
  const [calls, setCalls] = useState<any[]>([])

  return (
    <>
      <div className={`card ${className} ${style.calls_detail_wrapper}`}>
        <div className='card-body m-0 p-0'>
          {isDND && complete_followup_calls.length !== 0 && (
            <div className='timeline-label'>
              <div className='timeline-item mb-2 ms-20 ps-17'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless fs-1 text-danger'></i>
                </div>
                <div className='fw-bolder timeline-content ps-3 mb-0'>
                  <p className='fs-2 mb-4 text-gray-700'>Finished</p>
                </div>
              </div>
            </div>
          )}
          <div>
            {complete_followup_calls.length === 0 && (
              <p className='m-0 fs-4 fw-semibold text-gray-700'>No call History Found.</p>
            )}
          </div>
          {complete_followup_calls.map((call: any, i: number) => {
            return (
              <div className='timeline-label' key={i}>
                <div className='timeline-item mb-2'>
                  <div className='timeline-label fw-bold text-gray-800 fs-7'>
                    <span>{new Date(call.created_at).toLocaleDateString('en-GB')}</span>
                    <span className='ms-2'>{`${new Date(call.created_at).getHours()}: ${new Date(
                      call.created_at
                    ).getMinutes()}`}</span>
                  </div>
                  <div className='timeline-badge'>
                    <i
                      className={clsx(
                        'fa fa-genderless fs-1',
                        {'text-success': call.call_status === 'Accepted'},
                        {'text-danger': call.call_status === 'Rejected'},
                        {'text-warning': call.call_status === 'Not Response'}
                      )}
                    ></i>
                  </div>
                  <div className='fw-bold timeline-content ps-3 mb-0'>{call.call_status}</div>
                </div>
                <p className={`pb-0 fw-bold text-gray-700 ${style.seller_div}`}>
                  Order Id: {call.order?.order_code}
                </p>
                <div className={style.note}>
                  <h3 className={style.note_title}>Note</h3>
                  <p className={`mb-0 ${style.note_content}`}>{call.note}</p>
                </div>
                <div className={style.seller_div}>
                  <p className='mb-2 fw-semibold text-gray-700 '>
                    <FcCallback /> by
                    <RxAvatar style={{width: '20px', height: '20px', color: '#0095e8'}} />
                    {call.caller.name}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export {CustomerCallHistory}
