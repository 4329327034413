import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {toast} from 'react-toastify'
import {AddProduct, Product, ProductsType} from '../../models/product_models'
import clsx from 'clsx'
import Select from 'react-select'

interface AddProductProps {
  products: any
  setProduct: Dispatch<SetStateAction<ProductsType>>
  setShow: Dispatch<SetStateAction<boolean>>
  tab: any
  userRole: string[]
}

const AddNewProduct: React.FC<AddProductProps> = ({
  products,
  setProduct,
  setShow,
  tab,
  userRole,
}) => {
  const [image, setImage] = useState<File | null>(null)
  const [name, setName] = useState<string>('')
  const [price, setPrice] = useState<number>(0)
  const [description, setDescription] = useState('')
  const [unit, setUnit] = useState<number | undefined>()
  const [code, setCode] = useState('')
  const [GST, setGST] = useState<number>(0)
  const [manufacturingCharge, setManufacturingCharge] = useState<number>(0)
  const [selectedProducts, setSelectedProducts] = useState<any>([])
  const [error, setError] = useState(false)
  const [formErrors, setFormErrors] = useState<any>({
    nameField: '',
    priceField: '',
    gstField: '',
    codeField: '',
  })

  const [selectedUnitType, setSelectedUnitType] = useState(null)
  const [unitTypes, setUnitTypes] = useState([
    {value: 'gm', label: 'gm'},
    {value: 'kg', label: 'kg'},
    {value: 'pills', label: 'pills'},
    {value: 'ltr', label: 'ltr'},
  ])

  const handleShow = () => {
    setShow(false)
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0])
    }
  }
  const handleProductSelected = (selectedOptions: any) => {
    const selectedValues = selectedOptions.map((option: any) => option.value)
    setSelectedProducts(selectedValues)
    if (selectedProducts.length < 2) {
      setError(false)
    }
  }
  const handleChangeUnitType = (selectedOptions: any) => {
    setSelectedUnitType(selectedOptions.value)
  }

  const handleSubmitData = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log('SUBMITTED')

    if (name.length <= 2 || code.length < 2 || manufacturingCharge === 0) {
      console.log('IN IF')

      setFormErrors({
        nameField: name.length >= 3 ? false : true,
        // priceField: price ? false : true,
        // gstField: GST ? false : true,
        codeField: code.length >= 2 ? false : true,
        manufacturingField: manufacturingCharge === 0 ? true : false,
      })
      return
    }

    if (selectedProducts.length < 2 && tab === 'combo') {
      console.log('SELECT PRODUCT')

      setError(true)
      return
    }
    const newProductObj: AddProduct = {
      name: name,
      price: price,
      description: description,
      unit: unit,
      unit_type: selectedUnitType,
      code: code,
      gst: GST,
      product_ids: JSON.stringify(selectedProducts),
      image,
      is_combo: tab === 'combo' ? true : false,
      manufacturing_charge: manufacturingCharge,
    }
    console.log('NEW OBJ -->', newProductObj)

    // setProduct((prevProducts: any) => [...prevProducts, newProductObj])

    const data: Product | undefined = await supabaseData.addNewProduct(newProductObj)

    // Clear form inputs and close modal after successful data submission
    if (data !== undefined) {
      setImage(null)
      setName('')
      setDescription('')
      setUnit(0)
      setCode('')
      setGST(0)

      // Close the modal
      setShow(false)
      window.location.reload()
    }
  }

  return (
    <>
      <form className='row' onSubmit={handleSubmitData}>
        <div className='col-12 d-flex mb-3'>
          <div className='col-2'>
            <label className='form-label mt-3'>
              Name <span className='text-danger'>*</span>
            </label>
          </div>
          <div className='col-10'>
            <input
              type='text'
              className={`form-control form-control-solid col-9 ${
                formErrors.nameField &&
                'form-control form-control-solid col-9 border border-2 border-danger rounded'
              } `}
              name='mobile'
              value={name}
              onChange={(e: any) => {
                setName(e.target.value)
                setFormErrors({nameField: false})
              }}
            />
          </div>
        </div>
        {tab === 'combo' && (
          <div className='col-12 d-flex mb-3'>
            <div className='col-2'>
              <label className='form-label mt-3'>
                Products <span className='text-danger'>*</span>
              </label>
            </div>
            <div className='col-10'>
              <Select
                className={`${error && 'border border-2 border-danger rounded'} `}
                options={products}
                isMulti
                onChange={handleProductSelected}
                value={products?.filter((product: any) => selectedProducts.includes(product.value))}
              />
              {error && <p className='text-danger'>At least two Product selections are required</p>}
            </div>
          </div>
        )}
        <div className='col-12 d-flex mb-3'>
          <div className='col-2'>
            <label className='form-label mt-3'>Price</label>
          </div>
          <div className='col-10'>
            <input
              type='number'
              className={`form-control form-control-solid col-9 ${
                formErrors.priceField &&
                'form-control form-control-solid col-9 border border-2 border-danger rounded'
              } `}
              name='price'
              value={price}
              onChange={(e: any) => {
                setPrice(+e.target.value)
                setFormErrors({priceField: false})
              }}
            />
          </div>
        </div>
        <div className='col-12 d-flex mb-3'>
          <div className='col-2'>
            <label className='form-label mt-3'>
              GST <span className='text-danger'>*</span>
            </label>
          </div>
          <div className='col-10'>
            <input
              type='number'
              className={`form-control form-control-solid col-9 ${
                formErrors.gstField &&
                'form-control form-control-solid col-9 border border-2 border-danger rounded'
              } `}
              name='GST'
              value={GST}
              onChange={(e: any) => {
                setGST(+e.target.value)
                setFormErrors({gstField: false})
              }}
            />
          </div>
        </div>
        {(userRole.includes('Admin') || userRole.includes('Sub Admin')) && (
          <div className='col-12 d-flex mb-3'>
            <div className='col-3'>
              <label className='form-label mt-3'>Manufacturing</label>
            </div>
            <div className='col-9'>
              <input
                type='number'
                className={`form-control form-control-solid col-9 ${
                  formErrors.manufacturingField &&
                  'form-control form-control-solid col-9 border border-2 border-danger rounded'
                } `}
                name='manufacturingCharge'
                value={price}
                onChange={(e: any) => {
                  setManufacturingCharge(+e.target.value)
                  setFormErrors({manufacturingField: false})
                }}
              />
            </div>
          </div>
        )}
        <div className='col-12 d-flex mb-3'>
          <div className='col-3'>
            <label className='form-label mt-3'>Description</label>
          </div>
          <div className='col-9'>
            <textarea
              className={clsx('form-control form-control-solid col-7 ', {
                'is-valid': description.length >= 15,
              })}
              name='description'
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </div>
        </div>
        <div className='col-12 d-flex align-items-center mb-3 '>
          <div className='d-flex row col-8'>
            <div className='col-3'>
              <label className='form-label mt-3 d-flex'>Weight</label>
            </div>
            <div className='col-9'>
              <input
                type='number'
                className={clsx('form-control form-control-solid col-9 ', {
                  // @ts-ignore
                  'is-valid': unit >= 1,
                })}
                name='unit'
                value={unit}
                onChange={(e: any) => setUnit(+e.target.value)}
              />
            </div>
          </div>
          <div className='col-4'>
            <Select
              options={unitTypes}
              onChange={handleChangeUnitType}
              // value={selectedUnitType}
              // name='product_ids'
            />
          </div>
        </div>
        <div className='col-12 d-flex mb-3'>
          <div className='col-2'>
            <label className='form-label mt-3'>
              Code <span className='text-danger'>*</span>
            </label>
          </div>
          <div className='col-10'>
            <input
              type='text  '
              className={`form-control form-control-solid col-9 ${
                formErrors.codeField &&
                'form-control form-control-solid col-9 border border-2 border-danger rounded'
              } `}
              name='inventory'
              value={code}
              onChange={(e: any) => {
                setCode(e.target.value)
                setFormErrors({codeField: false})
              }}
            />
          </div>
        </div>
        <div className='col-12 d-flex mb-3'>
          <div className='col-2'>
            <label className='form-label mt-3'>Image</label>
          </div>
          <div className='col-10'>
            <input
              type='file'
              name='image'
              className='form-control col-9'
              onChange={handleImageChange}
            />
          </div>
        </div>

        <div className='mt-5 d-flex justify-content-end border-top pt-5'>
          <Button variant='secondary' size='sm' className='me-3' onClick={handleShow}>
            Close
          </Button>
          <Button type='submit' variant='primary' size='sm'>
            Save
          </Button>
        </div>
      </form>
    </>
  )
}

export default AddNewProduct
