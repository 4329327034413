import React, {useState} from 'react'
import style from '../../../_metronic/assets/sass/order.module.css'
// @ts-ignore
import numberToWords from 'number-to-words'
import {Button, Modal} from 'react-bootstrap'
import {FaFileInvoice} from 'react-icons/fa'
import {RiCloseFill} from 'react-icons/ri'
import {image_path} from '../../../utils'

const InvoiceFilePdf = ({order, companyDetails}: any) => {
  const [isGST, setIsGST] = useState(false)
  const [show, setShow] = useState(false)
  const [isPDF, setIsPDF] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const handleShow = () => setShow(true)

  // const itemsTotalUnits = order.items.reduce((total: number, item: any) => total + item.units, 0)
  const numberInWords: any = numberToWords.toWords(order?.total_amount ? order?.total_amount : 0)
  console.log('total amounts ->', order?.total_amount)

  const amountInWords: any = numberInWords.replace(/,/g, '')

  const handleSetGSTPDF = () => {
    setSelectedOrder(order)
    setIsGST(true)
    setShow(false)
    setIsPDF(true)
    setTimeout(() => {
      window.print()
      setIsPDF(false)
    }, 100)
  }
  const handlesetNonGST = () => {
    setIsGST(false)
    setSelectedOrder(order)
    setShow(false)
    setIsPDF(true)
    setTimeout(() => {
      window.print()
      setIsPDF(false)
    }, 100)
  }

  return (
    <>
      <div className='d-flex align-items-center' onClick={handleShow}>
        {/* <KTIcon iconName='plus' className='fs-3' /> */}
        <FaFileInvoice className='text-gray-600 fs-2 text-hover-primary' />
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='bg-primary-subtle d-flex justify-content-space-between'>
          <Modal.Title>Select Print Type</Modal.Title>
          <button
            className='border border-none fs-3 text-hover-primary'
            onClick={() => setShow(false)}
          >
            <RiCloseFill />
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* <Modal.Footer> */}
          <div className='mt-5 d-flex justify-content-center'>
            <Button variant='primary' size='sm' className='me-3' onClick={handleSetGSTPDF}>
              GST
            </Button>

            <Button variant='primary' size='sm' onClick={handlesetNonGST}>
              Non-GST
            </Button>
          </div>
          {/* </Modal.Footer> */}
        </Modal.Body>
      </Modal>
      {isPDF && selectedOrder && (
        <div className={` ${style.print_visible}`}>
          {/* <h6>Bill of Supply</h6> */}
          <div className={`${style.pdf_wrapper} ${style.printable_content}`}>
            <table className={style.tab} style={{width: 'auto', overflow: 'hidden'}}>
              <tr>
                <td colSpan={12}>
                  {isGST && (
                    <>
                      <p className='mb-0 text-start fs-2 ps-3 fw-bold'>
                        GSTIN : {companyDetails.gst}
                      </p>
                      <p className='fs-1 mb-0 fw-bolder'>
                        <u>TAX INVOICE</u>
                      </p>
                    </>
                  )}
                  <div>
                    <p className='fw-bolder mb-0' style={{fontSize: '27px'}}>
                      {companyDetails.name}
                    </p>
                    <p className='mb-0 fs-2' style={{margin: '0 auto', width: '300px'}}>
                      {companyDetails.address}
                    </p>
                    <p className='mb-0 fs-2 fw-bold'>
                      Tel. : {companyDetails.mobile} email : {companyDetails.email}
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={12} className='ps-3 text-start p-0'>
                  <div className='row row cols-2'>
                    <div className='col' style={{borderRight: '1px solid'}}>
                      <p className='mb-0 fs-2 '>
                        <span>Invoice No.</span>
                        <span className='ps-10'>: {order.order_code}</span>
                      </p>
                      <p className='mb-0 fs-2'>
                        <span>Date</span>
                        <span className='ps-20 ms-8'>
                          : {new Date(order.order_date).toLocaleDateString('en-GB')}
                        </span>
                      </p>
                    </div>
                    <div className='col'>
                      <p className='mb-0 fs-2 d-flex '>
                        <span>Courier Company</span>
                        <span className='ps-2'>: {order.order_courier_provider.name}</span>
                      </p>
                      <p className='mb-0 fs-2 '>
                        <span>Tracking Id</span>
                        <span className='ps-20'>: {order.tracking_id} </span>
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={12} className='ps-3 text-start'>
                  <div className='row '>
                    <div className='col-6'>
                      <p className='mb-0 fs-1 fw-bolder'>Billed to : </p>
                      <p className='mb-0 fs-2'>
                        {order.customers.name}- Mo. {order.customers.mobile},
                      </p>
                      <p className='mb-0 fs-2'>{order.customer_address.address} ,</p>
                    </div>
                    <div className='col-6 fs-2 ps-5'>
                      {order.customer_address.city && (
                        <p className='mb-0 row row-cols-2'>
                          <p className='mb-0 col ' style={{width: '100px'}}>
                            City :
                          </p>
                          <p className='mb-0 col'>{order.customer_address.city}</p>
                        </p>
                      )}
                      {order.customer_address.village && (
                        <p className='mb-0 row row-cols-2'>
                          <p className='mb-0 col ' style={{width: '100px'}}>
                            City :
                          </p>
                          <p className='mb-0 col'>{order.customer_address.village}</p>
                        </p>
                      )}
                      <p className='mb-0 row row-cols-2'>
                        <p className='mb-0 col' style={{width: '100px'}}>
                          District :
                        </p>
                        <p className='mb-0 col'> {order.customer_address.district}</p>
                      </p>
                      <p className='mb-0 row row-cols-2'>
                        <p className='mb-0 col' style={{width: '100px'}}>
                          State :
                        </p>
                        <p className='mb-0 col'> {order.customer_address.state}</p>
                      </p>
                      <p className='mb-0 row row-cols-2'>
                        <p className='mb-0 col' style={{width: '100px'}}>
                          Pincode :
                        </p>
                        <p className='mb-0 col'> {order.customer_address.pincode}</p>
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th className='fs-1' style={{width: '40px'}}>
                  No
                </th>
                <th colSpan={isGST ? 3 : 5} className='fs-1 ps-5 text-start'>
                  Item name
                </th>
                <th className=' pe-2 fs-1'>Qty</th>
                {/* <th className=' pe-2 fs-1'>Units</th> */}
                <th colSpan={2} className='pe-2 fs-1'>
                  Price
                </th>
                {isGST && (
                  <>
                    <th className='fs-1'>GST(%)</th>
                    <th className='fs-1'>GST(A)</th>
                  </>
                )}

                <th colSpan={3} className=' pe-2 fs-1'>
                  Amount
                </th>
              </tr>
              {order.order_items.map((item: any, index: any) => {
                let num = index + 1
                const gst =
                  item.gst >= 10 ? parseFloat(`1.${item.gst}`) : parseFloat(`1.0${item.gst}`)
                const calculatedGSTPrice = item.price / gst
                const itemPriceWithoutGST = item.price - calculatedGSTPrice
                return (
                  <tr className='py-1'>
                    <td className='fs-3 py-1' style={{width: '40px'}}>
                      {num}
                    </td>
                    <td colSpan={isGST ? 3 : 5} className='fs-3 ps-5 text-start py-1'>
                      {item.label}
                    </td>
                    <td className='text-end pe-2 fs-3 py-1'>{item.units}</td>
                    {/* <td className='text-end pe-2 fs-3'>₹{item.price}</td> */}
                    <td colSpan={2} className='text-end pe-2 fs-3 py-1'>
                      ₹{isGST ? calculatedGSTPrice.toFixed(2) : item.price}
                    </td>
                    {isGST && (
                      <>
                        <td className='text-end fs-3 pe-3 py-1'>{item.gst} %</td>
                        <td className='text-end fs-3 pe-3 py-1'>
                          {itemPriceWithoutGST.toFixed(2)}
                        </td>
                      </>
                    )}

                    <td colSpan={3} className='text-center fs-2 py-1'>
                      ₹{item.sub_total}.00
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td colSpan={10}>
                  <p className='mb-0 text-start ps-3 fs-2 fw-bold'> {amountInWords} only</p>
                </td>
                <td colSpan={2} className='fs-1 fw-bold ps-10 pe-3'>
                  ₹{order.total_amount}.00
                </td>
              </tr>
              <tr>
                <td colSpan={12} className='ps-3 text-start p-0'>
                  <div className='row row cols-2'>
                    <div className='col' style={{borderRight: '1px solid'}}>
                      <div className='fs-4 ps-3 text-start py-3'>
                        <p className='mb-1 fw-bold'>
                          <u>Terms & Conditions</u>
                        </p>
                        <p className='mb-1'>E.& O.E.</p>
                        <p className='mb-1'>1. Goods once sold will not be taken back.</p>
                        <p className='mb-1'>
                          2. Interest @ 18% p.a. will be charged if the payment is not made with in
                          the stipulated time.
                        </p>
                        <p className='mb-1'>3. Subject to 'Gujarat' Jurisdiction only.</p>
                        <p className='mb-1'>Thanks for doing business with us!</p>
                      </div>
                    </div>
                    <div className='col m-0 p-0'>
                      <div className='fs-2 text-start '>
                        <p className='pb-12 ps-3' style={{borderBottom: '1px solid'}}>
                          Receiver's Signature :
                        </p>
                      </div>
                      <div className='fs-2 text-start fw-bold ps-3'>
                        <p className='mb-5'>For: Amar Ayurveda.</p>
                        <img
                          src={`${image_path}${companyDetails.image_name}`}
                          style={{height: '70px'}}
                        />
                        <p className=' pt-5'>Authorized Signatory</p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      )}
    </>
  )
}

export default InvoiceFilePdf
