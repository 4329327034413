import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import Select from 'react-select'
import {BiSolidDownArrowCircle} from 'react-icons/bi'
import {sample_file_path} from '../../../utils'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'

const AddExpenseFile = () => {
  const [show, setShow] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [selectedFileType, setSelectedFileType] = useState<string>('')
  const [fileTypes, setFileTypes] = useState<any>([
    {value: 'COD', label: 'COD'},
    {value: 'Booking', label: 'Booking'},
    {value: 'Other', label: 'Other'},
  ])

  const handleShow = () => setShow(true)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (file) {
      setSelectedFile(file)
    }
  }

  const handelFormSubmit = async (e: any) => {
    e.preventDefault()
    const sheetData = new FormData()
    if (selectedFile) {
      sheetData.append('file', selectedFile)
      sheetData.append('expense_type', selectedFileType)
    }
    // console.log('sheet data- >', sheetData)
    const data: boolean = await supabaseData.sheetUpload(sheetData, setShow)
    if (data) window.location.reload()
  }

  return (
    <>
      <div className='btn btn-sm btn-light-primary' onClick={handleShow}>
        {/* <KTIcon iconName='plus' className='fs-3' /> */}
        Import
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title> Import Sheet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handelFormSubmit}>
            <div className='row mb-5'>
              <div className='col-4'>
                <label className='form-label pt-2 fs-5'>
                  Expanse File
                  <span className='text-danger'>*</span>
                </label>
              </div>
              <div className='col-8'>
                <input
                  type='file'
                  className='form-control form-control-sm'
                  // accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  onChange={handleFileChange}
                  required
                />
              </div>
            </div>
            <div className=''>
              <div className='row mb-5'>
                <div className='col-4'>
                  <label className='form-label pt-2 fs-5'>
                    Expanse Type <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-8 pt-1'>
                  <Select
                    options={fileTypes}
                    onChange={(selectedOption: any) => {
                      // console.log('VALUE', selectedOption)
                      setSelectedFileType(selectedOption.value)
                    }}
                    required
                  />
                </div>
              </div>
            </div>
            <div className='row mb-5'>
              <div className='col-4'>
                <label className='form-label pt-2 fs-5'>Sample Sheet</label>
              </div>
              <div className='col-8'>
                <a
                  href={`${sample_file_path}/uploads/expense_sheet.xlsx`}
                  className='btn btn-light-primary'
                  download
                >
                  <BiSolidDownArrowCircle className='fs-1' /> Download
                </a>
              </div>
            </div>

            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
                <Button type='submit' variant='primary' size='sm'>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddExpenseFile
