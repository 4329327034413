import React, {useEffect, useState} from 'react'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import clsx from 'clsx'
import {KTIcon} from '../../../_metronic/helpers'
import AddPayment from './AddPayment'
import {Payments} from '../../models/payment_model'
import ImportSheet from './ImportSheet'
import {ToastContainer} from 'react-toastify'
import {AccountType, CourierCompany} from '../../models/setting_models'
import EditPayment from './EditPayment'
import {RiFileDownloadFill} from 'react-icons/ri'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import {MdKeyboardArrowLeft, MdOutlineNavigateNext} from 'react-icons/md'

const Payment = () => {
  const [allPendingOrders, setAllPendingOrders] = useState([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [orders, setOrders] = useState<any>()
  const [accounts, setAccounts] = useState<AccountType[] | undefined>()
  const [sheetTitle, setSheetTitle] = useState<string>('Payment Sheet')
  const [payments, setPayments] = useState<any>([])
  const [totalPayments, setTotalPayments] = useState<number>(0)
  const [title, setTitle] = useState('Payment Details')
  const [paymentSheets, setPaymentSheets] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemsPerPage: number = 50
  const [user, setUser] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [courierCompanies, setCourierCompanies] = useState<CourierCompany[]>([])

  useEffect(() => {
    getAllAccounts()
    const user: any = localStorage.getItem('user_data')
    const user_data: any = JSON.parse(user)
    setUser(user_data)
    getAllCourierCompany()
    getPaymentSheet()
  }, [])

  const getAllPayments = async (searchValue: string) => {
    // const data: Payments[] | undefined = await supabaseData.getAllPayments('receive')
    // if (data) setPayments(data)

    const payments: any = await supabaseData.getAllPayments(searchValue, 'receive', currentPage)

    if (payments) {
      setTotalPayments(payments.total_payment)
      setPayments(payments.payments_data)
      setIsLoading(false)
    }
  }

  const getAllCourierCompany = async () => {
    const data: CourierCompany[] | undefined = await supabaseData.getAllCourierCompany()
    // console.log('all courier ->', data)
    if (data) setCourierCompanies(data)
  }

  const getAllAccounts = async () => {
    const data: AccountType[] | undefined = await supabaseData.getAllAccountTypes()
    if (data) setAccounts(data)
  }

  const downloadPaymentSheet = () => {}

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    if (event.target.value.length >= 3 || event.target.value.length === 0) {
      setCurrentPage(1)
      setIsLoading(true)
      getAllPayments(event.target.value)
    }
  }

  const renderPaginationControls = () => {
    const totalPages = Math.ceil(totalPayments / itemsPerPage)

    const goToNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1)
      }
    }

    const goToPrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1)
      }
    }
    const goToPage = (pageNumber: any) => {
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber)
        // Update the URL with the current page number
        // history.push(`/customers?page=${pageNumber}`)
      }
    }

    const renderPageNumbers = () => {
      const pageNumbers = []
      const numToShow = 5
      let start = Math.max(1, currentPage - Math.floor(numToShow / 2))
      let end = Math.min(totalPages, start + numToShow - 1)

      if (end - start + 1 < numToShow) {
        start = Math.max(1, end - numToShow + 1)
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i)
      }

      return pageNumbers.map((pageNumber, index) => (
        <button
          key={index}
          onClick={() => goToPage(pageNumber)}
          className={`btn btn-number btn-sm   ${
            pageNumber === currentPage ? 'bg-primary text-light' : 'bg-light text-dark'
          }`}
        >
          {pageNumber}
        </button>
      ))
    }
    // console.log('TOTAL PAGE -->', totalPages)
    // console.log('CURRENT PAGE -->', currentPage)

    return (
      totalPayments >= 50 && (
        <div className='pagination-controls mx-auto'>
          <div className='d-flex'>
            <button
              className='btn btn-primary btn-sm me-2'
              onClick={goToPrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft /> Prev
            </button>
            <div className='page-numbers'>
              {currentPage > 5 && (
                <button
                  onClick={() => goToPage(1)}
                  className={`btn btn-number btn-sm  ${1 === currentPage ? 'active' : ''}`}
                >
                  1
                </button>
              )}
              {currentPage > 5 && <span>...</span>}
              <span> {renderPageNumbers()}</span>

              {currentPage < totalPages - 3 && <span>...</span>}
              {currentPage < totalPages - 3 && (
                <button
                  onClick={() => goToPage(totalPages)}
                  className={`btn btn-number btn-sm ${totalPages === currentPage ? 'active' : ''}`}
                >
                  {totalPages}
                </button>
              )}
            </div>
            <button
              className='btn btn-primary btn-sm ms-2'
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              Next <MdOutlineNavigateNext />
            </button>
          </div>
        </div>
      )
    )
  }

  useEffect(() => {
    setIsLoading(true)
    getAllPayments(searchQuery)
  }, [currentPage])

  const handleDeletePayment = async (payment: any) => {
    console.log('delete payment ->', payment)

    if (window.confirm('Are you sure , you want delete order?')) {
      const deletePayment = {
        id: payment.id,
        order_id: payment.order_id,
        amount: payment.amount,
      }

      const data: boolean = await supabaseData.deletePayment(deletePayment, user.id)
      if (data) window.location.reload()
    }
  }

  const getPaymentSheet = async () => {
    const data: any = await supabaseData.getAllPaymentSheets()
    if (data.length > 0) {
      setPaymentSheets(data)
    }
  }

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* {isLoading ? (
        <div className={loader.custom_loader_container}>
          {' '}
          <p className={loader.custom_loader}></p>
        </div>
      ) : ( */}
      <div className='d-flex  gap-7'>
        <div className='card card-xxl-stretch mb-5 mb-xl-8 col-7'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Payments</span>
            </h3>
            <div className='d-flex align-items-center'>
              <ImportSheet
                sheetTitle={sheetTitle}
                user={user}
                courierCompanies={courierCompanies}
              />

              <AddPayment
                accounts={accounts}
                title={title}
                user={user}
                // courierCompanies={courierCompanies}
              />

              <div className='d-flex m-5' style={{justifyContent: 'space-between'}}>
                <div className='d-flex align-items-center position-relative my-1'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <input
                    type='text'
                    className='form-control form-control-sm form-control-solid w-250px ps-14'
                    placeholder='Search...'
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className={loader.custom_loader_container}>
              {' '}
              <p className={loader.custom_loader}></p>
            </div>
          ) : (
            <>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-hover table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-100px '>Date</th>
                        <th className='min-w-100px'>Order Code</th>
                        <th className='min-w-100px'>Courier Company</th>
                        <th className='min-w-100px'>Tracking Id</th>
                        <th className='min-w-100px'>Payment</th>
                        <th className='min-w-50px'>Amount</th>
                        <th className='min-w-100px '>Note</th>
                        <th className='min-w-100px '>Created By</th>
                        <th className='min-w-50px '>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {filteredPaymentData.slice(0, sliceEnd).map((payment: any) => { */}
                      {payments.map((payment: any) => {
                        return (
                          <tr key={payment.id}>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start  flex-column fw-bold text-gray-700 text-hover-primary'>
                                  {new Date(payment.created_at).toLocaleDateString('en-GB')}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column fw-bold text-gray-700'>
                                  {payment.order.order_code}
                                </div>
                              </div>
                            </td>
                            <td className='text-start text-gray-700 fw-semibold'>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column fw-bold text-gray-700'>
                                  {payment.order_courier_provider.name}
                                </div>
                              </div>
                            </td>
                            <td className='text-start text-gray-700 fw-semibold'>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column fw-bold text-gray-700'>
                                  {payment.order.tracking_id}
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className='d-flex align-items-center'>
                                <div
                                  className={clsx(
                                    'badge ',
                                    {'badge-light-success': !payment.deleted_at},
                                    {
                                      'badge-light-danger': payment.deleted_at,
                                    }
                                  )}
                                >
                                  {payment.account_type.type}
                                </div>
                              </div>
                            </td>
                            <td className='text-start text-gray-700 fw-semibold'>
                              <span className=''>{payment.amount}</span>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column fw-semibold text-gray-700'>
                                  {payment.note}
                                </div>
                              </div>
                            </td>
                            <td className='text-start text-gray-700 fw-semibold'>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column fw-bold text-gray-700'>
                                  {payment.deleted_by
                                    ? payment.deleted_by.name
                                    : payment.created_by.name}
                                </div>
                              </div>
                            </td>

                            {!payment.deleted_at && (
                              <td>
                                <div className='d-flex'>
                                  <button
                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm ms-2'
                                    onClick={() => handleDeletePayment(payment)}
                                  >
                                    <KTIcon iconName='trash' className='fs-3' />
                                  </button>
                                </div>
                              </td>
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <div className='d-flex justify-content-center'>{renderPaginationControls()}</div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className='card align-self-start mb-5 mb-xl-8 col-5'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Payments Sheet</span>
            </h3>
          </div>
          <div className='card-body py-3 '>
            <div className='table-responsive'>
              <table className='table table-hover table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-50px'>Date</th>
                    <th className='min-w-140px'>Courier Id</th>
                    <th className='min-w-120px'>Payment Reference</th>
                    <th className='min-w-100px '>Amount</th>
                    <th className='min-w-50px '>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentSheets.map((payment: any, i: number) => {
                    return (
                      <tr key={i}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold text-gray-700'>
                              {new Date(payment.date).toLocaleDateString('en-GB')}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold badge badge-light-success'>
                              {payment.courier_provider_id.name}
                            </div>
                          </div>
                        </td>
                        <td className='text-start text-gray-700 fw-semibold'>
                          <span className=''>{payment.payment_reference}</span>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-semibold text-gray-700'>
                              {payment.amount}
                            </div>
                          </div>
                        </td>

                        <td>
                          {/* <div className='d-flex'>
                            <button
                              className='btn btn-icon btn-bg-light btn-sm ms-2'
                              onClick={() => downloadPaymentSheet()}
                            >
                              <RiFileDownloadFill className='fs-1 text-gray-600 text-hover-primary' />
                            </button>
                          </div> */}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  )
}
export default Payment
