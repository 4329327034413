import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'

const PincodeAddress = ({
  formTitle,
  formErrors,
  customerInfo,
  setCustomerInfo,
  setFormErrors,
  setOffice,
  manually,
  setManually,
}: any) => {
  const [talukaOptions, setTalukaOptions] = useState([])
  const [selectedTaluka, setSelectedTaluka] = useState<any>(null)
  const [districtOptions, setDistrictOptions] = useState([])
  const [selectedDistrict, setSelectedDistrict] = useState<any>(null)
  const [stateOptions, setStateOptions] = useState([])
  const [selectedState, setSelectedState] = useState<any>(null)
  const [villageOptions, setVillageOptions] = useState([])
  const [selectedVillage, setSelectedVillage] = useState<any>(null)
  const [pincodeValue, setPincodeValue] = useState<any>()
  const [pincodeError, setPincodeError] = useState<boolean>(false)
  const [place, setPlace] = useState<string>('')
  const [selectPlace, setSelectPlace] = useState<any>([
    {value: 'Village', label: 'Village'},
    {value: 'City', label: 'City'},
  ])
  const [officeName, setOfficeName] = useState<string>('')
  const [selectedPlaceValue, setSelectedPlaceValue] = useState<string>('City')
  // const [officeName, setOfficeName] = useState<string>('')
  const handleManually = async (e: any) => {
    setManually(e.target.checked)
    if (e.target.checked) {
      setPlace('')
      setCustomerInfo((prevCustomerInfo: any) => ({
        ...prevCustomerInfo,
        taluka: '',
        state: '',
        district: '',
      }))
    }
  }

  useEffect(() => {
    console.log('CUSTOMER INFO ->', customerInfo)
    if (customerInfo.pincode !== '') {
      setSelectedVillage({
        value: customerInfo.city === '' ? customerInfo.village : customerInfo.city,
        label: customerInfo.city === '' ? customerInfo.village : customerInfo.city,
      })
      setSelectedTaluka({
        value: customerInfo.taluka,
        label: customerInfo.taluka,
      })
      setSelectedDistrict({
        value: customerInfo.district,
        label: customerInfo.district,
      })
      setSelectedState({
        value: customerInfo.state,
        label: customerInfo.state,
      })
      setSelectedPlaceValue(customerInfo.city !== '' ? 'City' : 'Village')
    }
    const fetchData = async () => {
      const data: any = await supabaseData.setPincodeRelatedValue(customerInfo.pincode)
      // console.log(' PNCODE DATA ->', data)
      const villageOptions = data
        .map((village: any) => ({
          value: village.village_locality_name,
          label: village.village_locality_name,
        }))
        .filter(
          (village: any, index: number, self: any) =>
            index === self.findIndex((value: any) => value.value === village.value)
        )
      const talukaOptions = data
        .map((taluka: any) => ({
          value: taluka.taluka,
          label: taluka.taluka,
        }))
        .filter(
          (taluka: any, index: number, self: any) =>
            index === self.findIndex((value: any) => value.value === taluka.value)
        )
      const districtOptions = data
        .map((district: any) => ({
          value: district.district,
          label: district.district,
        }))
        .filter(
          (district: any, index: number, self: any) =>
            index === self.findIndex((value: any) => value.value === district.value)
        )
      const stateOptions = data
        .map((state: any) => ({value: state.state, label: state.state}))
        .filter(
          (state: any, index: number, self: any) =>
            index === self.findIndex((value: any) => value.value === state.value)
        )

      // const office = data
      //   .map((office: any) => office.office_name)
      //   .filter(
      //     (office: any, index: number, self: any) =>
      //       index === self.findIndex((value: any) => value.value === office.value)
      //   )

      setVillageOptions(villageOptions)
      setTalukaOptions(talukaOptions)
      setDistrictOptions(districtOptions)
      setStateOptions(stateOptions)
      setPincodeValue(data)
      // setOffice(office)
      // setOfficeName(office)
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!manually) {
      setCustomerInfo((prevCustomerInfo: any) => ({
        ...prevCustomerInfo,
        at_post: customerInfo.at_post,
        city: selectedPlaceValue === 'City' ? selectedVillage?.label : null,
        village: selectedPlaceValue === 'Village' ? selectedVillage?.label : null,
        taluka: selectedTaluka?.label,
        district: selectedDistrict?.label,
        state: selectedState?.label,
      }))
    } else {
      setCustomerInfo((prevCustomerInfo: any) => ({
        ...prevCustomerInfo,
        at_post: customerInfo.at_post,
        city: selectedPlaceValue === 'City' ? (place !== '' ? place : null) : null,
        village: selectedPlaceValue === 'Village' ? (place !== '' ? place : null) : null,
        taluka: customerInfo?.taluka,
        district: customerInfo?.district,
        state: customerInfo?.state,
      }))
    }
  }, [pincodeValue, selectedVillage, selectedPlaceValue, selectPlace, manually, place])

  const handleManuallyInput = (e: any) => {
    setCustomerInfo({...customerInfo, [e.target?.name]: e.target?.value})
  }

  const handleInput = async (e: any) => {
    setCustomerInfo({...customerInfo, [e.target?.name]: e.target?.value})
    setFormErrors({
      nameFiled: '',
      mobileFiled: false,
      addressFiled: false,
      pincodeFiled: false,
      placeField: false,
      talukaField: false,
      districtField: false,
      stateField: false,
    })
    if (e.target?.name === 'pincode') {
      const updatedValue = e.target.value
      setSelectedDistrict(null)
      setSelectedState(null)
      setSelectedTaluka(null)
      setSelectedVillage(null)
      setPincodeError(false)
      if (updatedValue.length === 6) {
        // setIsOffice(true)
        const data: any = await supabaseData.setPincodeRelatedValue(updatedValue)
        // console.log(' PNCODE DATA ->', data)
        if (data.message || data.length === 0) {
          setPincodeError(true)
        } else {
          const villageOptions = data
            .map((village: any) => ({
              value: village.village_locality_name,
              label: village.village_locality_name,
            }))
            .filter(
              (village: any, index: number, self: any) =>
                index === self.findIndex((value: any) => value.value === village.value)
            )
          const talukaOptions = data
            .map((taluka: any) => ({
              value: taluka.taluka,
              label: taluka.taluka,
            }))
            .filter(
              (taluka: any, index: number, self: any) =>
                index === self.findIndex((value: any) => value.value === taluka.value)
            )
          const districtOptions = data
            .map((district: any) => ({
              value: district.district,
              label: district.district,
            }))
            .filter(
              (district: any, index: number, self: any) =>
                index === self.findIndex((value: any) => value.value === district.value)
            )
          const stateOptions = data
            .map((state: any) => ({value: state.state, label: state.state}))
            .filter(
              (state: any, index: number, self: any) =>
                index === self.findIndex((value: any) => value.value === state.value)
            )
          const office = data
            .map((office: any) => office.office_name)
            .filter(
              (office: any, index: number, self: any) =>
                index === self.findIndex((value: any) => value.value === office.value)
            )

          setVillageOptions(villageOptions)
          setTalukaOptions(talukaOptions)
          setDistrictOptions(districtOptions)
          setStateOptions(stateOptions)
          setPincodeValue(data)
          // setOffice(office)
          // setOfficeName(office)
          setPincodeError(false)
          setFormErrors({
            ...formErrors,
            talukaField: false,
            districtField: false,
            stateField: false,
          })
          setPincodeError(false)
        }
      }
    }
  }

  const handleVillageField = (selectedOption: any) => {
    const villageName = selectedOption
    setSelectedVillage(villageName)

    // console.log('selectedVillageData -->', selectedOption)
    // console.log('OPTIONS ->', pincodeValue)
    const selectedVillageData: any = pincodeValue?.find(
      (data: any) => data.village_locality_name === villageName.label
    )
    setSelectedTaluka({
      value: selectedVillageData?.taluka,
      label: selectedVillageData?.taluka,
    })
    setSelectedDistrict({
      value: selectedVillageData?.district,
      label: selectedVillageData?.district,
    })
    setSelectedState({
      value: selectedVillageData?.state,
      label: selectedVillageData?.state,
    })
    setFormErrors({
      ...formErrors,
      placeField: false,
      talukaField: false,
      districtField: false,
      stateField: false,
    })

    console.log(selectedVillageData)

    setOffice(selectedVillageData?.office_name)
    setOfficeName(selectedVillageData?.office_name)
  }

  const handleInputChange = (e: any) => {
    setCustomerInfo({...customerInfo, [e.target.name]: e.target.value})
  }

  return (
    <>
      {(formTitle === 'Add Customer' || formTitle === 'Edit Customer') && (
        <div>
          <div className='col-12 d-flex mb-3'>
            <div className='col-2'>
              <label className='form-label mt-3'>
                Pincode <span className='text-danger'>*</span>
              </label>
            </div>
            <div className='col-10 d-flex justify-content-between '>
              <div className='col-8'>
                <input
                  type='text'
                  className={`form-control form-control-solid  ${
                    (formErrors.pincodeFiled || pincodeError) &&
                    'form-control form-control-solid col-9 border border-2 border-danger rounded'
                  }`}
                  placeholder='Enter pincode '
                  name='pincode'
                  value={!customerInfo.pincode ? pincodeValue?.pincode : customerInfo.pincode}
                  onChange={handleInput}
                />
                {(formErrors.pincodeFiled || pincodeError) && (
                  <span className='text-danger'>Pincode number is not valid.</span>
                )}
              </div>
              <div className='form-check form-switch pt-4'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckChecked'
                  checked={manually}
                  // onChange={(e: any) => setManually(e.target.checked)}
                  onChange={handleManually}
                />
                <label className='form-check-label'>Manually</label>
              </div>
            </div>
          </div>
          {!manually ? (
            <>
              <div className='col-12 d-flex align-items-center mb-3'>
                <div className='col-3 '>
                  <label className='form-label mt-2 d-flex'>
                    <Select
                      options={selectPlace}
                      defaultValue={selectPlace[1]}
                      onChange={(selectedOption: any) => {
                        // console.log('VALUE', selectedOption)
                        setSelectedPlaceValue(selectedOption.label)
                      }}
                    />
                    <span className='text-danger ms-2'>*</span>
                  </label>
                </div>
                <div className='col-9 '>
                  <Select
                    className={` ${
                      formErrors.placeField ? ' border border-2 border-danger rounded' : ''
                    } `}
                    options={villageOptions}
                    onChange={handleVillageField}
                    value={selectedVillage}
                  />
                </div>
              </div>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>
                    Taluka <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-10'>
                  <Select
                    className={` ${
                      formErrors.talukaField ? ' border border-2 border-danger rounded' : ''
                    } `}
                    options={talukaOptions}
                    value={selectedTaluka}
                    onChange={(selectedOption: any) => {
                      setSelectedTaluka(selectedOption)
                    }}
                  />
                </div>
              </div>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>
                    District <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-10'>
                  <Select
                    className={` ${
                      formErrors.districtField ? ' border border-2 border-danger rounded' : ''
                    } `}
                    options={districtOptions}
                    value={selectedDistrict}
                    onChange={(selectedOption: any) => {
                      setSelectedDistrict(selectedOption)
                    }}
                  />
                </div>
              </div>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>
                    State <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-10'>
                  <Select
                    className={` ${
                      formErrors.stateField ? ' border border-2 border-danger rounded' : ''
                    } `}
                    options={stateOptions}
                    value={selectedState}
                    onChange={(selectedOption: any) => {
                      setSelectedState(selectedOption)
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='col-12 d-flex align-items-center mb-3'>
                <div className='col-3 '>
                  <label className='form-label mt-3 d-flex'>
                    <Select
                      options={selectPlace}
                      defaultValue={selectPlace[1]}
                      onChange={(selectedOption: any) => {
                        console.log('VALUE', selectedOption)
                        setSelectedPlaceValue(selectedOption.value)
                      }}
                    />
                    <span className='text-danger ms-2'>*</span>
                  </label>
                </div>
                <div className='col-9 '>
                  <input
                    type='text'
                    className={`form-control form-control-solid col-9 ${
                      formErrors.placeField &&
                      'form-control form-control-solid col-9 border border-2 border-danger rounded'
                    } `}
                    placeholder='Enter name '
                    value={place}
                    onChange={(e: any) => {
                      setPlace(e.target.value)
                      setFormErrors({placeField: false})
                    }}
                  />
                </div>
              </div>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>
                    Taluka <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-10'>
                  <input
                    type='text'
                    className={`form-control form-control-solid col-9 ${
                      formErrors.talukaField &&
                      'form-control form-control-solid col-9 border border-2 border-danger rounded'
                    } `}
                    placeholder='Enter taluka '
                    name='taluka'
                    value={!pincodeValue?.taluka ? customerInfo.taluka : pincodeValue?.taluka}
                    onChange={handleManuallyInput}
                  />
                </div>
              </div>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>
                    District <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-10'>
                  <input
                    type='text'
                    className={`form-control form-control-solid col-9 ${
                      formErrors.districtField &&
                      'form-control form-control-solid col-9 border border-2 border-danger rounded'
                    } `}
                    name='district'
                    value={!pincodeValue ? customerInfo.district : pincodeValue?.district}
                    onChange={handleManuallyInput}
                    placeholder='Enter district name'
                  />
                </div>
              </div>
              <div className='col-12 d-flex mb-3'>
                <div className='col-2'>
                  <label className='form-label mt-3'>
                    State <span className='text-danger'>*</span>
                  </label>
                </div>
                <div className='col-10'>
                  <input
                    type='text'
                    className={`form-control form-control-solid col-9 ${
                      formErrors.stateField &&
                      'form-control form-control-solid col-9 border border-2 border-danger rounded'
                    } `}
                    name='state'
                    value={!pincodeValue ? customerInfo.state : pincodeValue?.state}
                    onChange={handleManuallyInput}
                    placeholder='Enter state'
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {(formTitle === 'Add Shipping Address' || formTitle === 'Edit Order') && (
        <div className='row mb-3'>
          <div className='col-8'>
            <input
              style={{height: '38px'}}
              className='form-control form-control-solid'
              placeholder='Enter address details'
              name='address'
              type='text'
              value={customerInfo.address}
              onChange={handleInputChange}
            />
          </div>
          <div className='d-flex align-items-center col-2 mb-3'>
            <input
              style={{height: '38px'}}
              type='text'
              className={`form-control form-control-solid col-9 ${
                formErrors.pincodeFiled &&
                'form-control form-control-solid col-9 border border-2 border-danger rounded'
              }`}
              placeholder='Enter pincode '
              name='pincode'
              value={customerInfo.pincode}
              onChange={handleInput}
            />
            <div className='form-check form-switch ms-10'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckChecked'
                checked={manually}
                // onChange={(e: any) => setManually(e.target.checked)}
                onChange={handleManually}
              />
              <label className='form-check-label'>Manually</label>
            </div>

            {(formErrors.pincodeFiled || pincodeError) && (
              <span className='text-danger'>Pincode number is not valid.</span>
            )}
          </div>
          <div className='col-3'>
            <input
              style={{height: '38px'}}
              className='form-control form-control-solid'
              placeholder='Enter At-Post'
              name='at_post'
              type='text'
              value={customerInfo.at_post}
              onChange={handleInputChange}
            />
          </div>
          {!manually ? (
            <>
              <div className='col-5 d-flex align-items-center mb-3'>
                <div className='col-3 me-2'>
                  <Select
                    options={selectPlace}
                    defaultValue={selectPlace[1]}
                    onChange={(selectedOption: any) => {
                      // console.log('VALUE', selectedOption)
                      setSelectedPlaceValue(selectedOption.label)
                    }}
                  />
                </div>
                <div className='col-9'>
                  <Select options={villageOptions} onChange={handleVillageField} />
                </div>
              </div>
              <div className='col-4 mb-3'>
                <Select
                  options={talukaOptions}
                  placeholder='Select Taluka...'
                  value={selectedTaluka}
                  onChange={(selectedOption: any) => {
                    setSelectedTaluka(selectedOption)
                  }}
                />
              </div>
              <div className='col-4 mb-3'>
                <Select
                  options={districtOptions}
                  placeholder='Select District...'
                  value={selectedDistrict}
                  onChange={(selectedOption: any) => {
                    setSelectedDistrict(selectedOption)
                  }}
                />
              </div>
              <div className='col-4 mb-3'>
                <Select
                  options={stateOptions}
                  placeholder='Select State...'
                  value={selectedState}
                  onChange={(selectedOption: any) => {
                    setSelectedState(selectedOption)
                  }}
                />
              </div>
              {customerInfo.pincode !== '' && (
                <div className='col-4 mb-3 fs-3 text-gray-600 fw-semibold'>
                  Office : {officeName}{' '}
                </div>
              )}
            </>
          ) : (
            <>
              <div className='col-5 d-flex align-items-center mb-3'>
                <div className='col-4 '>
                  <label className='form-label d-flex'>
                    <Select
                      options={selectPlace}
                      defaultValue={selectPlace[1]}
                      onChange={(selectedOption: any) => {
                        console.log('VALUE', selectedOption)
                        setSelectedPlaceValue(selectedOption.value)
                      }}
                    />
                    <span className='text-danger ms-2'>*</span>
                  </label>
                </div>
                <div className='col-8 '>
                  <input
                    type='text'
                    className={`form-control form-control-solid ${
                      formErrors.placeField &&
                      'form-control form-control-solid border border-2 border-danger rounded'
                    } `}
                    placeholder='Enter name '
                    value={place}
                    onChange={(e: any) => {
                      setPlace(e.target.value)
                      setFormErrors({placeField: false})
                    }}
                  />
                </div>
              </div>
              <div className='col-4 d-flex mb-3'>
                <div className=''>
                  <input
                    type='text'
                    className={`form-control form-control-solid col-9 ${
                      formErrors.talukaField &&
                      'form-control form-control-solid col-9 border border-2 border-danger rounded'
                    } `}
                    placeholder='Enter taluka '
                    name='taluka'
                    value={!pincodeValue?.taluka ? customerInfo.taluka : pincodeValue?.taluka}
                    onChange={handleManuallyInput}
                  />
                </div>
              </div>

              <div className='col-4'>
                <input
                  type='text'
                  className={`form-control form-control-solid col-9 ${
                    formErrors.districtField &&
                    'form-control form-control-solid col-9 border border-2 border-danger rounded'
                  } `}
                  name='district'
                  value={!pincodeValue ? customerInfo.district : pincodeValue?.district}
                  onChange={handleManuallyInput}
                  placeholder='Enter district name'
                />
              </div>

              <div className='col-4'>
                <input
                  type='text'
                  className={`form-control form-control-solid col-9 ${
                    formErrors.stateField &&
                    'form-control form-control-solid col-9 border border-2 border-danger rounded'
                  } `}
                  name='state'
                  value={!pincodeValue ? customerInfo.state : pincodeValue?.state}
                  onChange={handleManuallyInput}
                  placeholder='Enter state'
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default PincodeAddress
