import React, {useEffect, useState} from 'react'
// import {allExpanse} from '../AppDataDefine'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import ExpanseDetailView from './ExpanseDetailView'
import AddExpenseFile from './AddExpenseFile'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {KTIcon} from '../../../_metronic/helpers'
import {MdKeyboardArrowLeft, MdOutlineNavigateNext} from 'react-icons/md'
// import {allExpanse} from '../AppDataDefine'

const OrderExpanse = () => {
  const [allOrdersExpanse, setAllOrdersExpanse] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [show, setShow] = useState<boolean>(false)
  const [expenseId, setExpenseId] = useState<null | string>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemsPerPage: number = 20
  const [totalExpanse, setTotalExpanse] = useState<number>(0)

  const handleClose = () => setShow(false)

  useEffect(() => {
    getTotalExpanse()
  }, [])

  useEffect(() => {
    getAllExpense()
  }, [currentPage])

  const getTotalExpanse = async () => {
    const count: number | null = await supabaseData.getTotalExpanse()
    console.log('EXPANsE COUNT ->', count)

    if (count) setTotalExpanse(count)
  }

  const renderPaginationControls = () => {
    const totalPages = Math.ceil(totalExpanse / itemsPerPage)

    const goToNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1)
      }
    }

    const goToPrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1)
      }
    }
    const goToPage = (pageNumber: any) => {
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber)
      }
    }

    const renderPageNumbers = () => {
      const pageNumbers = []
      const numToShow = 5
      let start = Math.max(1, currentPage - Math.floor(numToShow / 2))
      let end = Math.min(totalPages, start + numToShow - 1)

      if (end - start + 1 < numToShow) {
        start = Math.max(1, end - numToShow + 1)
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i)
      }

      return pageNumbers.map((pageNumber, index) => (
        <button
          key={index}
          onClick={() => goToPage(pageNumber)}
          className={`btn btn-number btn-sm   ${
            pageNumber === currentPage ? 'bg-primary text-light' : 'bg-light text-dark'
          }`}
        >
          {pageNumber}
        </button>
      ))
    }

    return (
      totalExpanse >= 20 && (
        <div className='pagination-controls mx-auto'>
          <div className='d-flex'>
            <button
              className='btn btn-primary btn-sm me-2'
              onClick={goToPrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft /> Prev
            </button>
            <div className='page-numbers'>
              {currentPage > 5 && (
                <button
                  onClick={() => goToPage(1)}
                  className={`btn btn-number btn-sm  ${1 === currentPage ? 'active' : ''}`}
                >
                  1
                </button>
              )}
              {currentPage > 5 && <span>...</span>}
              <span> {renderPageNumbers()}</span>
              {currentPage < totalPages - 3 && <span>...</span>}
              {currentPage < totalPages - 3 && (
                <button
                  onClick={() => goToPage(totalPages)}
                  className={`btn btn-number btn-sm ${totalPages === currentPage ? 'active' : ''}`}
                >
                  {totalPages}
                </button>
              )}
            </div>
            <button
              className='btn btn-primary btn-sm ms-2'
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              Next <MdOutlineNavigateNext />
            </button>
          </div>
        </div>
      )
    )
  }
  const getAllExpense = async () => {
    const offset: number = (currentPage - 1) * 20
    let display: number = 20
    if (offset > 0) display = offset + 19
    else display = itemsPerPage - 1
    const data: any = await supabaseData.getAllExpense(offset, display)
    if (data.length > 0) setAllOrdersExpanse(data)
    setIsLoading(false)
  }

  return (
    <>
      <div className='card card-xxl-stretch mb-5 mb-xl-8'>
        {isLoading ? (
          <div className={loader.custom_loader_container}>
            {' '}
            <p className={loader.custom_loader}></p>
          </div>
        ) : (
          <div className='card-body py-5'>
            <div className='d-flex align-items-center justify-content-between'>
              <h3>Expanse</h3>
              <AddExpenseFile />
            </div>
            <div className='table-responsive'>
              <table className='table table-hover table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-100px'>Tracking Id</th>
                    <th className='min-w-140px'>Expanse type</th>
                    <th>Amount</th>
                    <th>Re Mark</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allOrdersExpanse.map((expanse: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold text-gray-700'>
                              {expanse.tracking_id}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold text-gray-700'>
                              <p className='mb-0'>{expanse.type}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold text-gray-700'>
                              <p className='mb-0'>{expanse.amount}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fw-bold text-gray-700'>
                              <p className='mb-0'>{expanse.remark}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={() => {
                                setShow(true)
                                setExpenseId(expanse.tracking_id)
                              }}
                            >
                              <KTIcon iconName='eye' className='fs-1' />
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div className='d-flex justify-content-center'>{renderPaginationControls()}</div>
            </div>
          </div>
        )}
      </div>
      {show && <ExpanseDetailView expenseId={expenseId} show={show} handleClose={handleClose} />}
    </>
  )
}

export default OrderExpanse
