import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import {RiCloseFill} from 'react-icons/ri'
import style from '../../../_metronic/assets/sass/product.module.css'
import {image_path} from '../../../utils'
import {RxDotFilled} from 'react-icons/rx'

const ProductDetails = ({product, showDetails, setShowDetails}: any) => {
  // const [show, setShow] = useState(false)
  // const handleShow = () => setShow(true)

  return (
    <>
      {/* <div className='d-flex align-items-center' onClick={handleShow}>
        <Link to='' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
          <KTIcon iconName='eye' className='fs-1' />
        </Link>
      </div> */}
      <Modal show={showDetails} className={`modal-lg`}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>Product Details</Modal.Title>
          <button
            className='border border-none fs-3 text-hover-primary rounded-circle'
            onClick={() => setShowDetails(false)}
          >
            <RiCloseFill />
          </button>
        </Modal.Header>
        <Modal.Body className='m-5'>
          <div className='d-flex gap-10'>
            <div className={`${style.img_div}`}>
              <img
                src={`${image_path}${product.image}`}
                style={{width: '20rem', height: '30rem'}}
              />
            </div>
            <div className=' px-5 '>
              <div className='border-bottom'>
                <p className='text-gray-700 fs-2 fw-bold mb-2'>
                  {product.label}
                  <span className='badge badge-light-primary fs-4'>
                    {product.unit}
                    {product.unit_type}
                  </span>
                </p>
                <p className='badge badge-light-success fs-4  '>₹{product.price}</p>
                <div className=''>
                  <p className='mb-1 fs-5 fw-bold text-gray-800'>Products:</p>
                  <div className='ms-3'>
                    {product.products?.map((product: any) => {
                      return (
                        <p className='mb-1 fs-6 fw-bold text-gray-600'>
                          <RxDotFilled />
                          {product.label}
                        </p>
                      )
                    })}
                  </div>
                </div>
              </div>
              <p className='pt-3'>
                <span className='fw-bold fs-5 text-gray-700'> Product Code:</span>
                <span className='fw-bold fs-6 text-gray-500 ms-3'>{product.code}</span>
              </p>
              <p className='border-bottom pb-3'>
                <span className='fw-bold fs-5 text-gray-700'>Inventory:</span>
                <span className='fw-bold fs-6 text-gray-500 ms-3'>{product.current_stock} </span>
                {product.stock <= 49 && (
                  <span className='badge badge-light-danger'>Manage this product stock</span>
                )}
              </p>
              <p className='fw-bold text-gray-500 fs-4'>Description</p>
              <p className='text-gray-600 fw-semibold '>{product.description}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ProductDetails
