import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import AddCourier from './AddCourier'
import AddAccount from './AddAccount'
import style from '../../../_metronic/assets/sass/settings.module.css'
import BigCalendar from './BigCalendar'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {
  AccountType,
  Company,
  CourierCompany,
  CourierCompanyWithBarcode,
} from '../../models/setting_models'
import {ToastContainer} from 'react-toastify'
import AddCompanyDetails from './AddCompanyDetails'
import {image_path} from '../../../utils'
import EditCourier from './EditCourier'
import loader from '../../../_metronic/assets/sass/loader.module.css'
import Dispatch from '../orders/Dispatch'

const SettingWrapper = (props: any) => {
  const [accounts, setAccounts] = useState<AccountType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isAccountLoading, setIsAccountLoading] = useState(true)
  const [isCourierLoading, setIsCourierLoading] = useState(true)

  const [companyDetails, setCompanyDetails] = useState<Company>({
    name: '',
    address: '',
    mobile: '',
    gst: '',
    email: '',
    image_name: '',
  })

  const [couriers, setCouriers] = useState<CourierCompanyWithBarcode[]>([])

  useEffect(() => {
    getAllCourierCompany()
    getAllAccountTypes()
    getCompanyDetail()
  }, [])

  const getAllCourierCompany = async () => {
    const data: CourierCompany[] | undefined = await supabaseData.getAllCourierCompany()
    const bar_codes: CourierCompanyWithBarcode[] = await supabaseData.getAllTrackingIds(data)

    if (bar_codes) setCouriers(bar_codes)
    setIsCourierLoading(false)
  }

  const getAllAccountTypes = async () => {
    const data: AccountType[] | undefined = await supabaseData.getAllAccountTypes()
    if (data) setAccounts(data)
    setIsAccountLoading(false)
  }

  const deleteAccountType = async (value: string) => {
    console.log('account id ->', value)
    if (window.confirm('Are You sure, you want delete this account?')) {
      const data: AccountType = await supabaseData.deleteAccountType(value)
      if (data) {
        window.location.reload()
        // const filterAccounts = accounts.filter((x) => x.value !== value)
        // // return filterProducts
        // setAccounts(filterAccounts)
      }
    }
  }

  const deleteCourierCompany = async (value: string) => {
    if (window.confirm('Are You sure, you want delete this courier?')) {
      const data: CourierCompany = await supabaseData.deleteCourierCompany(value)
      if (data) {
        window.location.reload()
        // const filterAccounts = accounts.filter((x) => x.value !== value)
        // // return filterProducts
        // setAccounts(filterAccounts)
      }
    }
  }

  const getCompanyDetail = async () => {
    const data: Company | undefined = await supabaseData.getCompanyDetail()
    if (data) setCompanyDetails(data)
    setIsLoading(false)
  }

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <h3>Settings</h3>
      <div className='row mt-5'>
        <div className='col-4'>
          <div className='card p-4 ps-8 mb-5'>
            {isLoading ? (
              <div className={loader.custom_loader_container} style={{height: '300px'}}>
                {' '}
                <p className={loader.custom_loader}></p>
              </div>
            ) : (
              <>
                <div className='d-flex justify-content-around align-items-center mb-5'>
                  <h4 className='text-gray-700 text-hover-primary fw-bolder'>Company Details</h4>
                  <AddCompanyDetails
                    setCompanyDetails={setCompanyDetails}
                    companyDetails={companyDetails}
                  />
                </div>
                <div className={`${style.card_wrapper}`}>
                  <p className='fw-bold fs-1 text-gray-600'> {companyDetails.name}</p>
                  <div>
                    <p className='mb-1 fs-4 fw-bold text-gray-600 d-flex gap-2'>
                      <span>Address: </span> <span> {companyDetails.address}</span>
                    </p>
                    <p className='mb-1 fs-4 fw-bold text-gray-600 d-flex gap-6'>
                      <span>Mobile:</span> <span>{companyDetails.mobile}</span>
                    </p>
                    <p className='mb-1 fs-4 fw-bold text-gray-600 d-flex gap-12'>
                      <span>GST:</span> <span>{companyDetails.gst}</span>
                    </p>
                    <p className='mb-1 fs-4 fw-bold text-gray-600 d-flex gap-9'>
                      <span>Email:</span> <span> {companyDetails.email}</span>
                    </p>
                    <img
                      className=''
                      style={{border: '1px solid black', width: '90px', height: '30px'}}
                      src={`${image_path}${companyDetails.image_name}`}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className='card p-4 ps-8 mb-5'>
            {isAccountLoading ? (
              <div className={loader.custom_loader_container} style={{height: '300px'}}>
                {' '}
                <p className={loader.custom_loader}></p>
              </div>
            ) : (
              <>
                <div className='d-flex justify-content-around align-items-center mb-5'>
                  <h4 className='text-gray-700 text-hover-primary fw-bolder'>Manage Account</h4>
                  <AddAccount accounts={accounts} setAccounts={setAccounts} />
                </div>
                <div className={`${style.card_wrapper}`}>
                  <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 '>
                    <thead>
                      <tr className='border-0'>
                        <th className='p-0 min-w-110px'></th>
                        <th className='p-0 min-w-50px'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {accounts.map((account: any) => {
                        return (
                          <tr key={account.value}>
                            <td>
                              <p className='text-gray-600 fw-bold text-hover-primary fs-6 p-0 m-0'>
                                {account.label}
                              </p>
                            </td>

                            <td className='text-end'>
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4'
                                onClick={() => deleteAccountType(account.value)}
                              >
                                <KTIcon iconName='trash' className='fs-3' />
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
          <div className='card p-4 ps-8'>
            {isCourierLoading ? (
              <div className={loader.custom_loader_container} style={{height: '300px'}}>
                {' '}
                <p className={loader.custom_loader}></p>
              </div>
            ) : (
              <>
                <div className='d-flex justify-content-around align-items-center mb-5'>
                  <h4 className='text-gray-700 text-hover-primary fw-bolder'>Manage Couriers</h4>
                  <AddCourier />
                </div>
                <div className={`${style.card_wrapper}`}>
                  <table className='table table-row-dashed table-row-gray-200 align-middle gs-0'>
                    <tbody>
                      {couriers.map((courier: CourierCompanyWithBarcode) => {
                        return (
                          <tr key={courier.value}>
                            <td>
                              <p className='text-gray-600 fw-bold text-hover-primary p-0 m-0 fs-6'>
                                {courier.label}
                              </p>
                            </td>
                            <td>
                              <p className='text-gray-600 fw-bold p-0 m-0'>
                                <span className='p-0'>
                                  ({courier.total_barcode}-{courier.is_use} =
                                  {courier.total_barcode - courier.is_use})
                                </span>
                              </p>
                            </td>
                            <td>
                              <p className='text-danger fw-bold text-hover-primary p-0 m-0 fs-6'></p>
                            </td>
                            <td>
                              <p className='text-gray-600 fw-bold text-hover-primary p-0 m-0 fs-6'></p>
                            </td>

                            <td className='text-end d-flex'>
                              <EditCourier courierData={courier} />
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4'
                                onClick={() => deleteCourierCompany(courier.value)}
                              >
                                <KTIcon iconName='trash' className='fs-3' />
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
        <div className='col-8 '>
          <div className='card p-5 align-self-start'>
            <h4>Calendar</h4>
            <BigCalendar />
          </div>
          <div className='card p-4 ps-8 mt-5'>
            <div className='d-flex align-items-center justify-content-between'>
              <h5 className='fs-2 text-gray-700'>Pincode Sheet</h5>
              <Dispatch title={'Pincode Sheet'} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingWrapper
