import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import AddNewCustomer from './AddNewCustomer'
import Select from 'react-select'
import {dimention} from '../AppDataDefine'
import style from '../../../_metronic/assets/sass/order.module.css'
import {CgCloseR} from 'react-icons/cg'
import barcodeStyle from '../../../_metronic/assets/sass/barcodepdf.module.css'

import clsx from 'clsx'
import GenerateBarcodePdf from './GenerateBarcodePdf'
import {supabaseData} from '../../supabase/CreatedSupabaseMethod'
import {image_path} from '../../../utils'
import moment from 'moment'
import PincodeAddress from '../customers/PincodeAddress'

const EditOrder = ({
  order,
  // customers,
  sales_men,
  courierCompanies,
  accounts,
  products,
  showEdit,
  handleModel,
  companyDetails,
}: any) => {
  // const [show, setShow] = useState(false)
  const formTitle = 'Edit Order'
  const [manually, setManually] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null)
  const [customersList, setCustomersList] = useState<any[]>([])
  const [showCustomerDetail, setShowCustomerDetail] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<any>(null)
  const [selectedProductsList, setSelectedProductsList] = useState<any[]>([])
  const [selectedSalesman, setSelectedSalesman] = useState<any>(null)
  const [courierWeight, setCourierWeight] = useState<string>(order.courier_weight)
  const [courierCode, setCourierCode] = useState<string>(order.tracking_id)
  const [amount, setAmount] = useState<number>(order.payment_data ? order.payment_data.amount : 0)
  const [referenceId, setReferenceId] = useState<string | null>(null)
  const lsGetItem = localStorage.getItem('user_data')
  const [shippingCharge, setShippingCharge] = useState<number>(
    parseInt(order.shipping_charges) ? parseInt(order.shipping_charges) : 0
  )
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null)
  const [showAddress, setShowAddress] = useState(false)
  const [leaves, setLeaves] = useState<any[]>([])
  const [date, setDate] = useState('')
  // const [orderDate, setOrderDate] = useState('')
  const [dimension, setDimension] = useState<any[]>([])
  // const [selectedDimension, setSelectDimension] = useState('')
  const [selectedPaymentModeObj, setSelectedPaymentModeObj] = useState<any>({})
  const [selectedCustomerObj, setSelectedCustomerObj] = useState<any>({})
  const [selectedSalesManObj, setSelectedSalesManObj] = useState<any>({})
  const [selectedCourierObj, setSelectedCourierObj] = useState<any>({})
  const [selectedDimensionObj, setSelectedDimensionObj] = useState<any>(null)
  const [selectedPaymentMethodObj, setSelectedPaymentMethodObj] = useState<any>(null)
  // const [selectedAddress, setSelectedAddress] = useState('')
  const [selectedAddressObj, setSelectedAddressObj] = useState<any>(null)
  const [editOrder, setEditOrder] = useState<any>(order)
  const [isCancel, setIsCancel] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [updateOrder, setUpdateOrder] = useState<any>(null)
  const [office, setOffice] = useState()
  const [reMark, setReMAark] = useState<string>('CB')
  const [formErrors, setFormErrors] = useState<any>({
    paymentModeField: '',
    clientField: '',
    salesmanField: '',
    courierField: '',
    trackingIdField: '',
    addressField: '',
    productField: '',
    CourierWeightField: '',
  })

  const [shippingAddress, setShippingAddress] = useState<any>({
    address: '',
    city: '',
    district: '',
    state: '',
    pincode: '',
    taluka: '',
    village: '',
    at_post: '',
  })
  const [order_manager, setOrderManager] = useState<string>('')
  const [selectedCourier, setSelectedCourier] = useState(null)
  const [selectedPaymentMode, setSelectedPaymentMode] = useState<any>(null)
  const [paymentModes, setPaymentModes] = useState([
    {value: 'Prepaid', label: 'Prepaid'},
    {value: 'Cash On Delivery', label: 'Cash On Delivery'},
  ])
  const [selectedAccount, setSelectedAccount] = useState(null)

  useEffect(() => {
    console.log('Sales mane', sales_men)

    // console.log('Order ->', order)
    // if (order) {
    //   setEditOrder(order)
    console.log('ORDER -->', editOrder)
    // }
  }, [])

  const user: any = localStorage.getItem('user_data')
  const user_data: any = JSON.parse(user)
  useEffect(() => {
    if (
      user_data.role.includes('Admin') ||
      user_data.role.includes('Sub Admin') ||
      user_data.role.includes('Order Manager') ||
      user_data.role.includes('Order Inventory Manager')
    ) {
      // @ts-ignore

      setOrderManager(user_data.id)
    }
  }, [])

  useEffect(() => {
    // ------- Payment Mode ------
    const selectedPaymentMode = () => {
      paymentModes.map((mode: any) => {
        if (mode.value === editOrder?.payment_mode) {
          setSelectedPaymentModeObj({
            value: mode.value,
            label: mode.label,
          })
        }
        return {
          value: mode.value,
          label: mode.label,
        }
      })
    }

    //   ----------- customer -------
    // const selectCustomer = () => {
    //   customers?.map((customer: any) => {
    //     if (customer.value === editOrder?.customer_id) {
    //       setSelectedCustomerObj({
    //         value: customer.value,
    //         label: customer.label,
    //       })
    //       setSelectedSalesManObj({
    //         value: editOrder.sales_man.id,
    //         label: editOrder.sales_man.name,
    //       })
    //     }
    //     return {
    //       value: customer.value,
    //       label: customer.label,
    //     }
    //   })
    // }

    // ------- sales man --------
    const selectedSalesman = () => {
      sales_men?.map((sales: any) => {
        // console.log('Sales man ->', sales)

        if (sales.value === editOrder.sales_man.id) {
          setSelectedSalesManObj({
            value: editOrder.sales_man.id,
            label: editOrder.sales_man.name,
          })
        }
        return {
          value: sales.value,
          label: sales.label,
        }
      })
    }
    // --------Couriers --------------
    const selectedCourier = () => {
      courierCompanies.map((courier: any) => {
        if (courier.value === editOrder?.order_courier_provider?.id) {
          setSelectedCourierObj({
            value: courier.value,
            label: courier.label,
          })
        }
        return {
          value: courier.value,
          label: courier.label,
        }
      })
    }
    // ---------------dimension-----------------
    const selectedDimension = () => {
      dimension.map((option: any) => {
        if (option.value === editOrder?.dimension) {
          setSelectedDimensionObj({
            value: option.value,
            label: option.label,
          })
        }
        return {
          value: option.value,
          label: option.label,
        }
      })
    }
    // ----------- Account -------
    if (editOrder?.payment_mode === 'Prepaid' && editOrder.payment_data) {
      // const selectedAccount = () => {
      accounts.map((account: any) => {
        if (account.value === editOrder?.payment_data.account_type?.id) {
          setSelectedPaymentMethodObj({
            value: account.value,
            label: account.label,
          })
        }
        // return {
        //   value: account.id,
        //   label: account.type,
        // }
      })
      // }
    }
    // --------Address--------
    const selectedAddress = () => {
      // console.log('IN SELECTED ADDRESS', customer_addresses)
      editOrder?.address?.map((address: any) => {
        if (address.id === editOrder.address_id) {
          setSelectedAddressObj({
            value: address.id,
            label: `${address.address} ${address.city} ${address.district} ${address.state} Pincode: ${address.pincode}`,
          })
        }
        return {
          value: address.id,
          label: `${address.address} ${address.city} ${address.district} ${address.state} Pincode: ${address.pincode}`,
        }
      })
    }

    // ---------------------------------
    // if (order.id) {
    selectedPaymentMode()
    // selectCustomer()
    selectedSalesman()
    selectedCourier()
    selectedAddress()
    selectedDimension()
    // selectedAccount()
    // }
  }, [editOrder])

  useEffect(() => {
    if (dimention) setDimension(dimention)
  }, [])
  // useEffect(() => {
  //   if (allLeaves) {
  //     setLeaves(allLeaves)
  //   }
  // }, [])

  const handleChangeInputAddress = (e: any) => {
    setShippingAddress({...shippingAddress, [e.target.name]: e.target.value})
  }

  // const handleShow = () => setShow(true)

  const handleCustomerSelect = async (selectedOption: any) => {
    if (selectedOption) {
      const customerWithMobile = customersList.find(
        (customer) =>
          customer.value === selectedOption.value || customer.mobile === selectedOption.mobile
      )
      setSelectedSalesman(customerWithMobile.users)
      setSelectedCustomer(customerWithMobile || selectedOption)
      setShowCustomerDetail(true)

      setFormErrors({clientField: false})
      // console.log('Customer -->', selectedCustomer)
    }
  }

  const handleSalesmanSelect = (selectedOption: any) => {
    setSelectedSalesman(selectedOption.value)
    setSelectedSalesManObj(selectedOption)
  }

  useEffect(() => {
    if (editOrder && editOrder.order_items) {
      setSelectedProductsList([...editOrder.order_items])
    }
  }, [editOrder])

  const handleProductSelect = async (selectedOption: any) => {
    selectedOption.id = selectedOption.value ? selectedOption.value : selectedOption.id
    setSelectedProduct(selectedOption)
    delete selectedOption.id

    const item = await supabaseData.addOrderItem(
      selectedOption.value,
      editOrder.id,
      selectedOption.price
    )

    console.log('new product select ->', selectedOption, editOrder.id)
    console.log('selected products ->', selectedProductsList)
    setSelectedProductsList([...selectedProductsList, item])
  }

  // useEffect(() => {
  //   const defaultCustomer = customersList.find((customer) => customer.value === '1')
  //   setSelectedCustomer(defaultCustomer)

  //   const defaultSalesman = sales_men.find((salesman: any) => salesman.value === '1')
  //   setSelectedSalesman(defaultSalesman)
  // }, [])

  const handleCancel = async () => {
    console.log(order)
    const data: any = await supabaseData.cancelOrder(order.id, order_manager)
    if (data) {
      // setShow(false)
      handleModel()
      clearData()
      window.location.reload()
    }
  }

  const handleChangeInput = (e: any, index: number) => {
    console.log(' target ->', e.target.value)
    const {name, value} = e.target
    setSelectedProductsList((prevState) => {
      const updatedProducts = [...prevState]
      const updatedProduct = {
        ...updatedProducts[index],
        [name]: parseInt(value),
      }

      updatedProduct.sub_total = updatedProduct.price * updatedProduct.units
      updatedProducts[index] = updatedProduct
      return updatedProducts
    })
  }

  const calculateTotalOfTotals = () => {
    const data: number = selectedProductsList.reduce(
      (sum: number, product: any) => sum + parseInt(product.sub_total),
      0
    )
    const totalAmount: number = data + shippingCharge
    // console.log(data)
    return totalAmount
  }

  const handleSaveAndPrint = async (e: any) => {
    const data: any = await updateOrderDetails(e)

    if (data) {
      data.order_items.map((item: any) => {
        item.label = item.products.name
        item.code = item.products.code
        item.image = item.products.image
        item.gst = item.products.gst
        delete item.products
        return item
      })
      setUpdateOrder(data)
      setIsPrinting(true)
      setTimeout(() => {
        window.print()
        setIsPrinting(false)
      }, 1000)
    }
  }

  const handleSave = async (e: any) => {
    const data: any = await updateOrderDetails(e)
    if (data) {
      //  clearForm()
      window.location.reload()
    }
  }

  const updateOrderDetails = async (e: any) => {
    e.preventDefault()
    // const selectedAddress = editOrder.addresses?.find(
    //   (address: any) => address.id === selectedAddressId
    // )
    if (
      !selectedPaymentModeObj ||
      !selectedCustomerObj ||
      !selectedSalesManObj ||
      !courierCode ||
      (!selectedAddressObj && shippingAddress.address === '') ||
      selectedProductsList.length === 0
      //  ||
      // !courierWeight
    ) {
      console.log('selectedProductsList', selectedProductsList.length)
      console.log('shippingAddress.address ->', shippingAddress.address)
      console.log('selectedPaymentModeObj ->', selectedPaymentModeObj)
      console.log('selectedAddressObj ->', selectedAddressObj)
      setFormErrors({
        paymentModeField: selectedPaymentModeObj ? false : true,
        clientField: selectedCustomerObj ? false : true,
        salesmanField: selectedSalesManObj ? false : true,
        trackingIdField: courierCode ? false : true,
        addressField: selectedAddressObj || shippingAddress.address ? false : true,
        productField: selectedProductsList.length !== 0 ? false : true,
        // CourierWeightField: courierWeight ? false : true,
      })
      return
    }

    const updateOrder = {
      // ...editOrder,
      id: editOrder.id,
      customer_id: editOrder.customer_id,
      sales_person_id: selectedSalesManObj.value,
      payment_mode: selectedPaymentModeObj ? selectedPaymentModeObj.value : editOrder.payment_mode,
      payment_method: selectedPaymentMethodObj ? selectedPaymentMethodObj?.value : null,
      courier_provider_id: selectedCourierObj
        ? selectedCourierObj.value
        : editOrder.courier_provider_id,
      address_id: !selectedAddressId ? editOrder.address_id : selectedAddressId,
      tracking_id:
        courierCode !== '' ? courierCode.trim().replace(/\s+/g, '') : editOrder?.tracking_id,
      // dimension: selectedDimensionObj.value,
      shipping_charges: shippingCharge,
      address: shippingAddress.address !== '' ? shippingAddress.address : null,
      city: shippingAddress.city !== '' ? shippingAddress.city : null,
      district: shippingAddress.district !== '' ? shippingAddress.district : null,
      state: shippingAddress.state !== '' ? shippingAddress.state : null,
      pincode: shippingAddress.pincode !== '' ? shippingAddress.pincode : null,
      taluka: shippingAddress.taluka !== '' ? shippingAddress.taluka : null,
      village: shippingAddress.village !== '' ? shippingAddress.village : null,
      at_post: shippingAddress.at_post !== '' ? shippingAddress.at_post : null,
      edited_by: order_manager,
      edited_at: new Date(),
      total_amount: calculateTotalOfTotals(),
      paid_amount: amount !== 0 ? amount : +editOrder.paid_amount,
      payment_status: editOrder.return_date
        ? 'Return'
        : amount === calculateTotalOfTotals()
        ? 'Paid'
        : 'Pending',
      order_status: isCancel ? 'Cancel' : 'Pending',
      order_items: selectedProductsList,
      courier_weight: courierWeight !== '' ? courierWeight : editOrder.courier_weight,
      payment: {
        id: editOrder.payment_data ? editOrder.payment_data.id : null,
        amount: amount !== 0 ? amount : +editOrder.paid_amount,
        transaction_id: referenceId !== '' ? referenceId : editOrder.payment?.transaction_id,
        payment_method: selectedPaymentMethodObj ? selectedPaymentMethodObj?.value : null,
        created_by: amount !== 0 ? order_manager : null,
      },
      remark: reMark,
    }

    console.log('UPDATE ORDER =>', updateOrder)

    const data: any = await supabaseData.editOrder(updateOrder)
    return data
  }
  const clearData = () => {
    setSelectedCourierObj(null)
    setSelectedCustomerObj(null)
    setSelectedAddressObj(null)
    setSelectedPaymentMethodObj(null)
    setSelectedPaymentModeObj(null)
    setSelectedSalesManObj(null)
    setCourierCode('')
    setCourierWeight('')
  }

  const deleteProduct = async (id: any) => {
    if (window.confirm('Are You sure, you want delete product?')) {
      console.log('VALUE', id)
      await supabaseData.deleteItemFromOrder(id)

      const filterProducts = selectedProductsList.filter((x) => x.id !== id)
      // console.log('PRODUCT LIST', filterProducts)
      setSelectedProductsList(filterProducts)
    }
  }

  // useEffect(() => {
  //   const calculateBusinessDaysFromToday = (days: number) => {
  //     const today = editOrder.order_date !== '' ? editOrder.order_date : new Date()
  //     let currentDay = new Date(today)

  //     const isWeekday = (date: any) => {
  //       const day = date.getDay()
  //       return day !== 0
  //     }

  //     console.log('Current Date -->', currentDay)

  //     const isLeave = (date: any) => leaves.includes(date?.toISOString().split('T')[0])

  //     for (let i = 0; i < days; ) {
  //       currentDay.setDate(currentDay.getDate() + 1)

  //       if (isWeekday(currentDay) && !isLeave(currentDay)) {
  //         i++
  //       }
  //     }

  //     const formattedDate = currentDay?.toISOString().split('T')[0]
  //     return formattedDate
  //   }
  //   const days =
  //     selectedCourierObj && selectedCourierObj.followup_days ? selectedCourierObj.followup_days : 7

  //   console.log('DAYS -->', days)

  //   const calculatedDate = calculateBusinessDaysFromToday(days)
  //   setDate(calculatedDate)
  // }, [leaves, selectedCourierObj, date])

  return (
    <>
      {/* <div
        className='btn btn-icon btn-bg-light btn-active-color-primary text-hover-primary btn-sm me-1'
        onClick={handleShow}
      >
        <KTIcon iconName='pencil' className='fs-3' />
      </div> */}
      <Modal show={showEdit} className={`modal-xl `}>
        <Modal.Header>
          <Modal.Title>Edit Order Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={updateOrderDetails}>
            <div style={{maxHeight: '650px', overflowY: 'scroll'}}>
              <div className='row row-cols-4 mb-3'>
                <div className='col-3 d-flex mt-1'>
                  <label className='col-4 form-label d-flex pt-2 '>
                    <span> Order Date</span>
                  </label>
                  <div className='col-8'>
                    <input
                      style={{height: '38px'}}
                      type='date'
                      className='form-control form-control-sm form-control-solid mx-0'
                      name='date'
                      value={moment(editOrder.order_date).format('YYYY-MM-DD')}
                      readOnly
                    />
                  </div>
                </div>
                <div className='col-3 d-flex '>
                  <label className='col-3 form-label pt-2 fs-5 d-flex  '>
                    Courier <span className='text-danger ms-1'>*</span>
                  </label>
                  <div className='col-8 p-1 pe-3'>
                    <Select
                      options={courierCompanies}
                      className={
                        !selectedCourier && selectedPaymentMode === 'Cash On Delivery'
                          ? 'border border-2 border-danger rounded'
                          : selectedCourier
                          ? ''
                          : ''
                      }
                      value={selectedCourierObj}
                      onChange={(selectedOption: any) => {
                        console.log(' COURIER VALUE -->', selectedOption)
                        setSelectedCourier(selectedOption.label)
                        setSelectedCourierObj(selectedOption)
                      }}
                    />
                    {/* <input
                      className='form-control form-control-sm form-control-solid'
                      value={editOrder.order_courier_provider.name}
                      readOnly
                    /> */}
                  </div>
                </div>
                <div className='col-3 '>
                  <input
                    style={{height: '38px'}}
                    className='form-control form-control-solid mt-1 '
                    type='text'
                    value={courierCode}
                    onChange={(e: any) => {
                      setCourierCode(e.target.value)
                      setFormErrors({trackingIdField: false})
                    }}
                    placeholder='Enter tracking code '
                    // required
                  />
                </div>
                <div className='col-3 d-flex mt-1'>
                  <label className='form-label pt-2 me-2 '>
                    <span> Follow Up Call</span>
                  </label>
                  <div>
                    <input
                      style={{height: '38px'}}
                      type='date'
                      className='form-control form-control-sm form-control-solid mx-0'
                      name='date'
                      // value={moment(editOrder.followup_date).format('YYYY-MM-DD')}
                      value={moment(editOrder.followup_date).format('YYYY-MM-DD')}
                      // onChange={(e: any) => setDate(e.target.value)}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className='row row-cols-3 pe-2 mb-3 '>
                <div className='col-5 d-flex '>
                  <label className='col-3 form-label pt-3 fs-5 '>
                    Client Name <span className='text-danger me-2'>*</span>
                  </label>
                  <div className='col-9 pt-1'>
                    {/* <Select
                      id='SelExample'
                      className={`${
                        formErrors.clientField && 'border border-2 border-danger rounded'
                      } `}
                      // options={customersList.map((customer) => ({
                      //   label: `${customer.label} - ${customer.mobile}`,
                      //   value: customer.value,
                      //   mobile: customer.mobile,
                      // }))}
                      value={selectedCustomerObj}
                      // onChange={handleCustomerSelect}
                      // getOptionLabel={(option) => option.label}
                      isDisabled
                    /> */}
                    <input
                      className='form-control form-control-sm form-control-solid'
                      value={`${editOrder.customers.name} - ${editOrder.customers.mobile}`}
                      readOnly
                    />
                  </div>
                  {/* <AddNewCustomer customers={customers} setCustomers={setCustomers} /> */}
                </div>
                <div className='col-3 d-flex '>
                  <label className='col-4 form-label pt-3 fs-5'>
                    Sales Man <span className='text-danger'>*</span>
                  </label>
                  <div className='col-8 pt-1'>
                    <Select
                      options={sales_men}
                      className={`${
                        formErrors.salesmanField && 'border border-2 border-danger rounded'
                      } `}
                      value={selectedSalesManObj}
                      onChange={handleSalesmanSelect}
                      isDisabled={
                        user_data.role.includes('Admin') || user_data.role.includes('Sub Admin')
                          ? false
                          : true
                      }
                    />
                    {/* <input
                      className='form-control form-control-sm form-control-solid'
                      value={editOrder.sales_man.name}
                      readOnly
                    /> */}
                  </div>
                </div>
                <div className='col-4 d-flex'>
                  <label className=' col-4 form-label pt-3 fs-5 d-flex'>
                    Payment Mode <span className='text-danger'>*</span>
                  </label>
                  <div className='col-8 h-20px m-0 p-0 '>
                    <Select
                      className={`${
                        formErrors.paymentModeField && 'border border-2 border-danger rounded'
                      }`}
                      options={paymentModes}
                      value={selectedPaymentModeObj}
                      onChange={(selectedOption: any) => {
                        // console.log('VALUE', selectedOption)
                        // @ts-ignore
                        setSelectedPaymentMode(selectedPaymentModeObj.label)
                        setSelectedPaymentModeObj(selectedOption)
                        setFormErrors({paymentModeField: false})
                        // console.log('PAYMEN OBJ', selectedPaymentModeObj)
                      }}
                    />
                    {/* <input
                      className='form-control form-control-sm form-control-solid'
                      value={editOrder.payment_mode}
                      readOnly
                    /> */}
                  </div>
                </div>
              </div>
              <div className='col-8 d-flex row '>
                <label className='col form-label pt-3 fs-5  fw-bold'>
                  Address<span className='text-danger'>*</span>
                </label>
                <div className='col-10'>
                  <Select
                    options={editOrder?.address?.map((address: any) => ({
                      label: `${address.address} ${address.city} ${address.district} ${address.state} Pincode: ${address.pincode}`,
                      value: address.id,
                    }))}
                    value={selectedAddressObj}
                    onChange={(selectedOption: any) => {
                      // console.log('VALUE', selectedOption)
                      setSelectedAddressId(selectedOption.value)
                      setSelectedAddressObj(selectedOption)
                      // console.log('ADD OBJ', selectedAddressObj)
                    }}
                  />
                </div>
              </div>
              <div className=''>
                <p className='text-gray-800 fw-bold fs-5 my-5'>
                  <KTIcon iconName='geolocation' className='fs-5 me-1 text-gray-700' />
                  Shipping Address:
                </p>
                {showAddress ? (
                  <div className='d-flex border-bottom'>
                    <div>
                      <PincodeAddress
                        formTitle={formTitle}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                        customerInfo={shippingAddress}
                        setCustomerInfo={setShippingAddress}
                        setOffice={setOffice}
                        manually={manually}
                        setManually={setManually}
                      />
                    </div>
                    {/* <div className='mb-5 row'>
                      <div className='col-8'>
                        <input
                          className='form-control form-control-solid'
                          placeholder='Enter address details'
                          name='address'
                          type='text'
                          value={shippingAddress.address}
                          onChange={handleChangeInputAddress}
                        />
                      </div>
                      <div className='col-4'>
                        <input
                          className='form-control form-control-solid'
                          placeholder='Enter city'
                          name='city'
                          type='text'
                          value={shippingAddress.city}
                          onChange={handleChangeInputAddress}
                        />
                      </div>
                      <div className='row mt-5'>
                        <div className='col-4'>
                          <input
                            className='form-control form-control-solid ms-4'
                            placeholder='Enter village'
                            name='village'
                            type='text'
                            value={shippingAddress.village}
                            onChange={handleChangeInputAddress}
                          />
                        </div>
                        <div className='col-4'>
                          <input
                            className=' form-control form-control-solid ms-6'
                            placeholder='Enter at_post'
                            name='at_post'
                            type='text'
                            value={shippingAddress.at_post}
                            onChange={handleChangeInputAddress}
                          />
                        </div>
                        <div className='col-4'>
                          <input
                            className='form-control form-control-solid ms-6'
                            placeholder='Enter taluka'
                            name='taluka'
                            type='text'
                            value={shippingAddress.taluka}
                            onChange={handleChangeInputAddress}
                          />
                        </div>
                      </div>
                      <div className='row mt-5'>
                        <div className='col-4'>
                          <input
                            className='form-control form-control-solid'
                            placeholder='Enter district'
                            name='district'
                            type='text'
                            value={shippingAddress.district}
                            onChange={handleChangeInputAddress}
                          />
                        </div>
                        <div className='col-4'>
                          <input
                            className='form-control form-control-solid ms-4'
                            placeholder='Enter state'
                            name='state'
                            type='text'
                            value={shippingAddress.state}
                            onChange={handleChangeInputAddress}
                          />
                        </div>
                        <div className='col-4'>
                          <input
                            className=' form-control form-control-solid ms-6'
                            placeholder='Enter pincode'
                            name='pincode'
                            type='number'
                            value={shippingAddress.pincode}
                            onChange={handleChangeInputAddress}
                          />
                        </div>
                      </div>
                    </div> */}
                    <CgCloseR
                      onClick={() => setShowAddress(false)}
                      className='ms-10 me-3 fw-bolder text-gray-500 text-hover-primary'
                      style={{fontSize: '40px', marginTop: '-40px'}}
                    />
                  </div>
                ) : (
                  <span
                    className='fw-bold text-gray-600 text-hover-primary my-4 ms-15 align-items-center'
                    style={{cursor: 'pointer'}}
                    onClick={() => setShowAddress(true)}
                  >
                    <span className='pt-2'>
                      <KTIcon iconName='plus' className='fs-3 me-1 mt-2' />
                      <span className='fs-7 mb-5'>Add Shipping Address</span>
                    </span>
                  </span>
                )}
              </div>
              <div className='mt-8  border-b border-gray-200 dark:border-gray-700 border-bottom pb-5'>
                <div className='col d-flex'>
                  <label className='form-label pt-2 fs-5 me-3' style={{width: '110px'}}>
                    Product <span className='text-danger'>*</span>
                  </label>
                  <div className='col-5 pt-1'>
                    <Select
                      className={formErrors.productField && 'border border-2 border-danger rounded'}
                      id='SelExample'
                      options={products}
                      value={selectedProduct}
                      onChange={handleProductSelect}
                    />
                  </div>
                </div>
              </div>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-200px'>Name</th>
                    <th className='min-w-100px'>Price</th>
                    <th className='min-w-100px'>Units</th>
                    <th className='min-w-150px '>Total</th>
                    <th></th>
                  </tr>
                </thead>
                {/* {selectedProductsList.length <= 0 ? (
                  <div
                    className='d-flex justify-content-end mx-auto pt-5 '
                    style={{width: '500px', justifyContent: 'center'}}
                  >
                    <p className='fs-5 fw-semibold text-gray-600 '>No product selected</p>
                  </div>
                ) : ( */}
                <tbody>
                  {selectedProductsList.map((item: any, index: number) => {
                    let id = item.value ? item.value : item.id
                    return (
                      <tr key={id}>
                        <td>
                          <div className='d-flex cursor-pointer'>
                            <div>
                              <img
                                className={style.set_product_image}
                                src={`${image_path}${item.image}`}
                              />
                            </div>
                            <div className='ms-5 fw-semi-bold'>
                              <p className='mb-0 fs-6 fw-bold'>{item.label}</p>
                              <p className='mb-0  fs-8 fw-bold text-gray-600'>CODE{item.code}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className=' me-4'>
                            <input
                              style={{width: '100px'}}
                              type='number'
                              className='form-control form-control-solid form-control-sm'
                              name='price'
                              value={item.price}
                              onChange={(e) => handleChangeInput(e, index)}
                            />
                          </div>
                        </td>
                        <td>
                          <div className=' me-4'>
                            <input
                              style={{width: '100px'}}
                              type='number'
                              name='units'
                              className='form-control form-control-solid form-control-sm '
                              value={item.units}
                              onChange={(e) => handleChangeInput(e, index)}
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <input
                              style={{width: '150px'}}
                              type='number'
                              name='total'
                              className='form-control form-control-solid form-control-sm'
                              value={item.sub_total}
                              onChange={(e) => handleChangeInput(e, index)}
                            />
                          </div>
                        </td>
                        <td>
                          <button
                            type='button'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={() => deleteProduct(id)}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* )} */}
              </table>
              {/* {selectedProductsList.length > 0 && ( */}
              <div className='text-end'>
                <div className='d-flex align-items-center justify-content-end border-bottom border-top py-5 row'>
                  <div className='col-2 ps-50'>
                    <label className='col fw-bold'>Shipping Charge:</label>
                  </div>
                  <div className='col-2'>
                    <input
                      className='form-control form-control-sm  form-control-solid col-2 fw-semibold'
                      type='number'
                      value={shippingCharge}
                      onChange={(e) => setShippingCharge(+e.target.value)}
                    />
                  </div>
                  <div className='col-2'></div>
                </div>
                <div className='mt-3 d-flex align-items-center justify-content-end row'>
                  <div className='col-2'>
                    <h5>Totals: </h5>
                  </div>
                  <div className='col-1 text-gray-700 fw-bold'>{calculateTotalOfTotals()}</div>
                  <div className='col-3'></div>
                </div>
              </div>
              {/* )} */}
              <div className='row border-top pt-5'>
                <div className='col-6'>
                  {/* <div className='mt-4 border-b border-gray-200 dark:border-gray-700  pb-5'>
                    <div className='col d-flex'>
                      <label className='form-label pt-2 fs-5 me-2' style={{width: '122px'}}>
                        Dimension <span className='text-danger'>*</span>
                      </label>
                      <div className='col-8 pt-1'>
                        <Select
                          options={dimension}
                          value={selectedDimensionObj}
                          onChange={(selectedOption: any) => {
                            // console.log('VALUE', selectedOption)
                            setSelectDimension(selectedOption.label)
                            setSelectedDimensionObj(selectedOption)
                          }}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className='mt-4 border-b border-gray-200 dark:border-gray-700  pb-5'>
                    <div className='col d-flex'>
                      <label className='form-label pt-2 fs-5 me-3 mt-2' style={{width: '120px'}}>
                        Courier Weight <span className='text-danger'>*</span>
                      </label>
                      <div className='col-8 pt-1 '>
                        <input
                          className={`form-control form-control-solid ${
                            formErrors.CourierWeightField &&
                            'form-control form-control-solid form-control-sm mt-2 py-3 border border-2 border-danger rounded'
                          } `}
                          type='text'
                          value={courierWeight}
                          placeholder='Enter Courier Weight'
                          onChange={(e: any) => {
                            setCourierWeight(e.target.value)
                            setFormErrors({CourierWeightField: false})
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='pb-5'>
                    {selectedPaymentModeObj?.label !== 'Cash On Delivery' && (
                      <>
                        <div className='col d-flex'>
                          <label className='form-label pt-2 fs-5 me-3' style={{width: '120px'}}>
                            Account
                          </label>
                          <div className='col-8 pt-1'>
                            <Select
                              options={accounts}
                              value={selectedPaymentMethodObj}
                              onChange={(selectedOption: any) => {
                                // console.log('VALUE', selectedOption)
                                setSelectedAccount(selectedOption.label)
                                setSelectedPaymentMethodObj(selectedOption)
                              }}
                            />
                          </div>
                        </div>
                        <div className='ms-20 col-8'>
                          <input
                            className='form-control form-control-solid form-control-sm mt-5 ms-19'
                            type='text'
                            value={
                              referenceId ? referenceId : editOrder?.payment_data?.transaction_id
                            }
                            onChange={(e: any) => setReferenceId(e.target.value)}
                            placeholder='Enter reference id'
                          />
                        </div>
                        <div className='mt-4 border-b border-gray-200 dark:border-gray-700  pb-5'>
                          <div className='col d-flex'>
                            <label
                              className='form-label pt-2 fs-5 me-3 mt-2'
                              style={{width: '113px'}}
                            >
                              Amount
                            </label>
                            <div className='col-8 pt-1 '>
                              <input
                                className='form-control form-control-solid form-control-sm mt-2 py-3'
                                type='number'
                                value={amount !== 0 ? amount : editOrder?.payment_data?.amount}
                                onChange={(e: any) => setAmount(+e.target.value)}
                                placeholder='Enter amount'
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className='mt-4 border-b border-gray-200 dark:border-gray-700  pb-5'>
                    <div className='col d-flex'>
                      <label className='form-label pt-2 fs-5 me-3 mt-2' style={{width: '113px'}}>
                        Re Mark
                      </label>
                      <div className='col-8 pt-1 '>
                        <input
                          className='form-control form-control-solid form-control-sm mt-2 py-3'
                          type='text'
                          value={reMark}
                          onChange={(e: any) => setReMAark(e.target.value)}
                          placeholder='Enter re mark'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* {showCustomerDetail && (
                  <div className='border-start my-5 col-6'>
                    <h5 className='text-gray-700 text-center '>Last Five Orders Details</h5>
                    <div className='accordion accordion-flush' id='accordionFlushExample'>
                      {orders.slice(-5).map((order: any) => {
                        return (
                          <div className='accordion-item' key={order.id}>
                            <h2 className='accordion-header'>
                              <button
                                className='accordion-button collapsed text-hover-primary p-3 text-hover-border-primary-subtle border-bottom '
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target={`#collapse-${order.id}`}
                                aria-expanded='false'
                                aria-controls={`collapse-${order.id}`}
                              >
                                <span>
                                  <span className='text-gray-700 fs-6 fw-bold'>

                                    Order ID: {order.id}
                                  </span>
                                  <br />
                                  <span className='fw-semibold fs-7'>
                                    <span className='text-gray-600'>Order date: {order.date}</span>
                                    <span className='text-danger ms-15'>
                                      Return Date:{order.date}
                                    </span>
                                  </span>
                                </span>
                              </button>
                            </h2>
                            <div
                              id={`collapse-${order.id}`}
                              className='accordion-collapse collapse'
                              data-bs-parent='#accordionFlushExample'
                            >
                              <div className='accordion-body'>
                                <div className='row border-bottom'>
                                  <div className='col-6'>
                                    <div className='d-flex '>
                                      <p className='fs-6 fw-bold m-0'>
                                        <span>Order Id: </span>
                                        <span className='text-gray-700'>{order.id}</span>
                                      </p>
                                      <span
                                        className={clsx(
                                          'badge-sm fw-bold px-2 ms-3 ',
                                          {'badge-light-primary': order.order_status === 'Pending'},
                                          {'badge-light-danger': order.order_status === 'Return'},
                                          {'badge-light-success': order.order_status === 'Deliver'},
                                          {
                                            'badge-light-warning':
                                              order.order_status === 'On the way',
                                          }
                                        )}
                                      >
                                        {order.order_status}
                                      </span>
                                    </div>
                                    <p className='fw-bold fs-8 text-gray-600'>
                                      <span>Order Date:</span> {order.date}
                                    </p>
                                  </div>
                                  <div className='col-6 mb-3 fs-8'>
                                    <div className='row'>
                                      <p className='col-6 mb-0 fw-bold text-gray-700'>
                                        Courier Company:
                                      </p>
                                      <p className='col-6 mb-0 fw-semibold'>{order.courier}</p>
                                    </div>
                                    <div className=''>
                                      <span className='fw-bold text-gray-700'>Total Amount: </span>
                                      <span className='fw-bolder ms-1'>₹{order.amount}</span>
                                      <p className={`badge-sm fw-bold px-2 badge-light-success `}>
                                        Payment: {order.payment_mode}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className='border-bottom pb-5'>
                                  {order.items.map((item: any, i: number) => {
                                    return (
                                      <div key={i}>
                                        <div className='d-flex row mt-1'>
                                          <div className='col-1'>
                                            <img
                                              src={item.image}
                                              style={{
                                                width: '25px',
                                                height: '25px',
                                                borderRadius: '20%',
                                              }}
                                            />
                                          </div>
                                          <div className='ms-1 col-5'>
                                            <p className='fs-7 fw-bold mb-0'>{item.label}</p>
                                            <p className='fs-8 fw-semibold text-gray-600'>
                                              {item.description}
                                            </p>
                                          </div>
                                          <div className='col-2'>
                                            <p className='fs-8 fw-bold text-gray-700 ms-5'>
                                              ₹{item.price}
                                            </p>
                                          </div>
                                          <div className='col-2'>
                                            <p className='fs-8 fw-bold text-gray-700 ms-5'>
                                              {item.units} Units
                                            </p>
                                          </div>
                                          <div className='col-1'>
                                            <p className='fs-8 fw-bold text-gray-700 ms-5'>

                                              ₹{item.total}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>

                                {order.order_status === 'Return' && (
                                  <>

                                    <div>
                                      <h6 className='text-gray-700 text-center mb-2'>
                                        Return Items
                                      </h6>
                                      <div className='d-flex justify-content-between'>
                                        <span className={`badge-sm fw-bold px-2 badge-light-info `}>
                                          Return Payment: {order.return_payment}
                                        </span>
                                        <span
                                          className='col-4 text-end fs-5 fw-bold'
                                          style={{justifyContent: 'space-between'}}
                                        >
                                          <span className='text-gray-600 me-3'>Refund Total: </span>
                                          <span className='badge badge-light-danger fs-8'>
                                            ₹{order.return_amount}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0'>
                                      <thead>
                                        <tr className='fw-bold text-muted'>
                                          <th className='min-w-100'></th>
                                          <th className='min-w-250'></th>
                                          <th className='min-w-50 fs-8'>Price</th>
                                          <th className='min-w-50 fs-9'>Return Items</th>
                                          <th className='min-w-100 fs-9'>Damage Items</th>
                                          <th className='min-w-50'></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {order.return_items.map((item: any, i: number) => {
                                          return (
                                            <tr key={i} className='m-0'>
                                              <td>
                                                <div className='col-1'>
                                                  <img
                                                    src={item.image}
                                                    style={{
                                                      width: '25px',
                                                      height: '25px',
                                                      borderRadius: '20%',
                                                    }}
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <p className='fs-7 fw-bold mb-0'>{item.label}</p>
                                                <p className='fs-8 fw-semibold text-gray-600'>
                                                  {item.description}
                                                </p>
                                              </td>
                                              <td>
                                                <div className='col-1'>
                                                  <p className='fs-8 fw-bold text-gray-700 '>
                                                    ₹{item.price}
                                                  </p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className='col-2'>
                                                  <p className='fs-8 fw-bold text-gray-700 '>
                                                    {item.return_items}
                                                  </p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className='col-2'>
                                                  <p className='fs-8 fw-bold text-gray-700 '>
                                                    {item.damage_items}
                                                  </p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className='col-1'>
                                                  <p className='fs-8 fw-bold text-gray-700 '>

                                                    ₹{item.total}
                                                  </p>
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )} */}
              </div>
            </div>
            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  type='submit'
                  variant='primary'
                  size='sm'
                  className='me-3'
                  onClick={handleSaveAndPrint}
                >
                  Save & Print
                </Button>
                <Button
                  type='submit'
                  variant='primary'
                  size='sm'
                  className='me-3'
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button
                  type='button'
                  className='me-3'
                  variant='danger'
                  size='sm'
                  onClick={handleCancel}
                >
                  Cancel Order
                </Button>
                <Button variant='secondary' size='sm' className='me-3' onClick={handleModel}>
                  Close
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <div className={barcodeStyle.print_visible}>
        {isPrinting && updateOrder && (
          <GenerateBarcodePdf order={[updateOrder]} companyDetails={companyDetails} />
        )}
      </div>
    </>
  )
}

export default EditOrder
