import React, {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import axios from 'axios'
import {api_url} from '../../../utils'

const Bnpl = () => {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const handleStartDateChange = (e: any) => {
    const enteredDate = e.target.value
    const dateFormat = /^\d{4}-\d{2}-\d{2}$/

    if (enteredDate.match(dateFormat) || enteredDate === '') {
      setStartDate(enteredDate)
    }
  }
  const handleEndDateChange = (e: any) => {
    const enteredDate = e.target.value
    const dateFormat = /^\d{4}-\d{2}-\d{2}$/

    if (enteredDate.match(dateFormat) || enteredDate === '') {
      setEndDate(enteredDate)
    }
  }

  const handleSubmitData = async (e: any) => {
    e.preventDefault()
    // console.log('Date -->', date)
    try {
      const response = await axios.post(
        `${api_url}/export_file`,
        {startDate, endDate, file_type: 'BNPL'},
        {
          responseType: 'blob',
        }
      )

      // Create a URL for the blob object
      const url = window.URL.createObjectURL(response.data)

      // Create a link element to trigger the download
      const a = document.createElement('a')
      a.href = url
      a.download =
        startDate !== '' && endDate !== ''
          ? `${startDate}-${endDate}_BNPL.xlsx`
          : `${startDate}_BNPL.xlsx` // Set the desired file name

      // Trigger a click event on the link to start the download
      a.click()

      // Clean up: Revoke the object URL to free up resources
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading Excel file:', error)
    }
    setStartDate('')
    setEndDate('')
    setShow(false)
  }

  return (
    <>
      <div className='btn btn-sm btn-light-primary me-3' onClick={handleShow}>
        {/* <KTIcon iconName='plus' className='fs-3' /> */}
        BNPL
      </div>
      <Modal show={show} className={`modal-md `}>
        <Modal.Header className='bg-primary-subtle'>
          <Modal.Title>BNPL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={handleSubmitData}>
            <div className='col-12 d-flex align-items-center mb-5'>
              <label className=' col-2 me-2 fw-bold text-gray-700'>Start Date</label>
              <div className='col-8'>
                <input
                  type='date'
                  className='form-control form-control-sm form-control-solid  '
                  placeholder='Search...'
                  value={startDate}
                  onChange={handleStartDateChange}
                  required
                />
              </div>
            </div>
            <div className='col-12 d-flex align-items-center mb-5'>
              <label className=' col-2 me-2 fw-bold text-gray-700'>EndDate</label>
              <div className='col-8'>
                <input
                  type='date'
                  className='form-control form-control-sm form-control-solid  '
                  placeholder='Search...'
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
            <Modal.Footer>
              <div className='mt-5 d-flex justify-content-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='me-3'
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>

                <Button type='submit' variant='primary' size='sm'>
                  Export
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default Bnpl
